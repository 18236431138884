import { motion } from 'framer-motion';
import { useContext } from 'react';
import ButtonExit from '../../../components/buttons/button-exit';
import ButtonNext from '../../../components/buttons/button-next';
import EditStep from '../../../components/job-post/edit-step';
import MiniCompany from '../../../components/mini-company';
import { stagger } from '../../../framer';
import { step_two } from '../../../helpers';
import { lang } from '../../../lang';
import { CreateJobContext } from '../../../utilities/context';

const getTitle = (step: number, sub_step: number) => {
  const getTitle = {
    1: {
      1: lang.step_three.title,
    },
    2: {
      1: "Job Post Type",
      2: lang.step_three.industry,
      3: lang.step_three.location,
      4: lang.step_three.job_title,
      5: lang.step_three.job_category,
      6: lang.step_three.position_level,
      7: lang.step_three.employment_type,
      8: lang.step_three.skill,
      9: lang.step_three.salary,
      10: lang.step_three.experience,
      11: lang.step_three.minimum_qualification,
      12: lang.step_three.referral_fee,
      13: lang.step_three.disbursement,
      14: lang.step_three.description,
      15: lang.step_three.head_count,
      16: lang.step_three.privacy,
      17: lang.step_three.qualities,
    },
  };
  return getTitle[step][sub_step];
};

const getUrl = (step: number) => {
  const getUrl = {
    1: '/create-job/2/1',
    2: '/create-job/2/3',
    3: '/create-job/2/6',
    4: '/create-job/2/2',
    5: '/create-job/2/4',
    6: '/create-job/2/5',
    7: '/create-job/2/7',
    8: '/create-job/2/8',
    9: '/create-job/2/9',
    10: '/create-job/2/12',
    11: '/create-job/2/13',
    12: '/create-job/2/10',
    13: '/create-job/2/11',
    14: '/create-job/2/14',
    15: '/create-job/2/15',
    16: '/create-job/2/16',
    17: '/create-job/2/17',
  };
  return getUrl[step];
};

const StepThreeOne = () => {
  const { company, common_job, loading, _jobPost, jobId, isDuplicate } =
    useContext(CreateJobContext);

    const getType  = (type:string) => {
      let message = ''
      switch (type) {
          case 'full':
              message = "100% after 30 days"
          break;
          case 'half':
              message = "50% after 30 days and 50% after 90 days"
          break;
          case 'third':
              message = "50% after 30 days and 50% after 180 days"
          break;
          case 'forth':
              message = "50% after 90 days and 50% after 180 days"
          break;
          default:
              message = ''
          break;
      }
  
      return message
    }

  const getView = (step: number) => {
    const getView = {
      1: common_job.blue_collar ? "Blue Collar" : "Office Based",
      2: localStorage.getItem('industry'),
      3: common_job.home_based ? (
        'Home-based'
      ) : (
        <div>
          <p className="p-0 pb-1 m-0">{common_job?.city_name}</p>
          <p>{common_job?.address}</p>
        </div>
      ),
      4: common_job.title,
      5: localStorage.getItem('category'),
      6: common_job.job_position_level,
      7: common_job.job_employment_type,
      8: (
        <div className="mt-3">
          {common_job.skills?.map(skill => {
            if (skill?.value) {
              return (
                <div className="inline px-3 py-1 mr-2 bg-gray-100 border border-gray-300 rounded-full">
                  {skill?.value}
                </div>
              );
            }
          })}
        </div>
      ),
      9: (
        <div>{`${common_job.minimum_salary} - ${common_job.maximum_salary}`}</div>
      ),
      10: common_job.job_experience,
      11: common_job.minimum_qualification,
      12: common_job.referral_fee,
      13: getType(common_job.disbursement_type),
      14: common_job.description
        ?.split('\n')
        ?.map(str => (
          <p className="mb-2 text-sm pj-blue-100 text-pj-blue-100">{str}</p>
        )),
      15: common_job.referral_count,
      16: common_job.visible ? 'Public' : 'Private',
      17: (
        <div>
          <p>- {common_job.trait_1}</p>
          <p>- {common_job.trait_2}</p>
          <p>- {common_job.trait_3}</p>
          <p>- {common_job.trait_4}</p>
        </div>
      ),
    };

    return getView[step];
  };

  return (
    <div className="flex flex-col flex-1">
      <div
        className="flex-initial overflow-auto h-screen/.5"
        id="journal-scroll"
      >
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          className="w-full"
        >
          <EditStep
            title={getTitle(1, 1)}
            bold={false}
            url={`/create-job/1/1/${common_job?.id ? `${common_job.id}${isDuplicate? "/duplicate":""}`: ''}`}
          >
            <EditStep.Content>
              {company && <MiniCompany {...company} />}
            </EditStep.Content>
          </EditStep>
          {[...Array(16)].map((val, index) => (
            <EditStep
              title={getTitle(2, index + 1)}
              url={`${getUrl(index + 1)}/${common_job?.id ? `${common_job.id}${isDuplicate? "/duplicate":""}`: ''}`}
              jobId={jobId}
              isDuplicate={isDuplicate}
            >
              <EditStep.Content>
                <div>{getView(index + 1)}</div>
              </EditStep.Content>
            </EditStep>
          ))}
        </motion.div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row items-end flex-1 p-4">
          <div className="flex items-end justify-start w-1/2 h-20">
            <ButtonExit />
          </div>
          <div className="flex items-end justify-end w-1/2 h-20">
            <ButtonNext
              label="Post This Job"
              buttonType="submit"
              onClick={_jobPost}
              // disabled={loading}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default StepThreeOne;
