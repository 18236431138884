import Paper from '@material-ui/core/Paper';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  MonthView,
  Toolbar,
  DateNavigator,
  Appointments,
} from '@devexpress/dx-react-scheduler-material-ui';
import moment from 'moment';
import AnimatedSelect from '../../components/animated-select';
import { RiArrowUpSLine } from 'react-icons/ri';
import { useContext, useState } from 'react';
import { ScheduleContext } from '../../utilities/context';
import ScheduleInfo from './components/schedule-info';
import Modal from '../../components/modal';
import Interview from '../../components/schedule/interview';
import { Interview as Sched } from '../../components/interview';
import { motion } from 'framer-motion';
import { rotate } from '../../framer';
import { AiOutlineClockCircle } from 'react-icons/ai';
import { RiArrowRightSFill, RiArrowRightSLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import Time from '../dashboard/components/starts';

const SchedulerView = () => {
  const {
    schedules,
    show,
    info,
    isEdit,
    control,
    nextSchedules,
    handleSubmit,
    _dateChange,
    _closeHandler,
    _editHandler,
    _showInfo,
    _submitHandler,
  } = useContext(ScheduleContext);
  const currentDate = moment().toISOString();
  const variants = {
    show: {
      height: '100%',
      transition: {
        duration: 0.6,
      },
    },
    hide: {
      height: 'auto',
      transition: {
        duration: 0.6,
      },
    },
  };
  interface SetView {
    toview: number,
    id: string
  }
  const [toggle, setToggle] = useState<boolean>(false);
  const [view, setView] = useState<SetView>({ toview: 0, id: "" })
  const handleView = (toview: number, id = "") => {
    return () => { setView({ toview: toview, id: id }) }
  }
  return (
    <Paper style={{ boxShadow: 'none' }}>
      <div
        className="flex w-full bg-white border-l flex-col"
        style={{ height: `calc(100vh - 3rem)` }}
      >
        <div className="w-full h-16 shadow flex justify-end items-center px-4 relative z-50">
          {/* <AnimatedSelect
            options={[{ label: 'test', value: 'test' }]}
            onClick={() => {}}
            label="Most Recent"
          /> */}
        </div>
        <div className="w-full h-full p-4 relative z-40">
          <motion.div
            className="absolute bottom-0 right-0 bg-white z-20 inline border"
            variants={variants}
            initial="hide"
            animate={toggle ? 'show' : 'hide'}
            style={{ width: 450 }}
          >
            <div
              className="w-full flex flex-row justify-between p-3 cursor-pointer border-b"
              onClick={() => setToggle(toggle => !toggle)}
            >
              <div className="text-pj-blue-100 text-xs font-semibold flex justify-center">
                NEXT ON YOUR SCHEDULE
              </div>
              <motion.div
                variants={rotate}
                animate={toggle ? 'hide' : 'show'}
                className="flex text-lg justify-center items-center text-pj-blue-100"
              >
                <RiArrowUpSLine />
              </motion.div>
            </div>
            {toggle && (
              <>
                {' '}
                {nextSchedules && nextSchedules.length === 0 ? (
                  <div className="h-full flex justify-center items-center text-center ">
                    <p className="text-pj-blue-100 text-sm">
                      You have no other events remaining on your schedule.{' '}
                      <br />
                      <Link to="/applicant-board" className="text-pj-blue-400">
                        Review Applicant Board
                      </Link>
                    </p>
                  </div>
                ) : nextSchedules && nextSchedules.length === 1 ? (
                  <div>
                    {nextSchedules.map(
                      (a, index) =>
                        moment().isBefore(
                          moment(a.date)
                            .hour(moment(a.start_time).get('hour'))
                            .minute(moment(a.start_time).get('minute'))
                        ) && (
                          <Sched
                            seeker={a.name}
                            time={
                              moment(a.date)
                                .startOf('day')
                                .add(moment(a.start_time).get('hours'), 'hours')
                                .isSame(moment(), 'day')
                                ? `Today ${moment(a.date)
                                  .startOf('day')
                                  .add(
                                    moment(a.start_time).get('hours'),
                                    'hours'
                                  )
                                  .format('HH:mm')}`
                                : moment(a.date)
                                  .startOf('day')
                                  .add(
                                    moment(a.start_time).get('hours'),
                                    'hours'
                                  )
                                  .format('ddd, MMM DD hh:mm A')
                            }
                            position={
                              <>
                                Interview for the{' '}
                                <span className="text-pj-blue-500 font-semibold">
                                  {a.job_title}
                                </span>{' '}
                                position
                              </>
                            }
                            schedule={
                              <>
                                <div className="flex-auto">
                                  <Time date={a.date} start={a.start_time} />
                                </div>
                                <div className="flex text-pj-green-300 pt-1 text-lg">
                                  <AiOutlineClockCircle />
                                </div>
                              </>
                            }
                            first={index === 0 ? true : false}
                          />
                        )
                    )}
                    {/* <div className="h-52 flex justify-center items-center text-center ">
                      <p className="text-pj-blue-100 text-sm">
                        You have no other events remaining on your schedule.{' '}
                        <br />
                        <Link
                          to="/applicant-board"
                          className="text-pj-blue-400"
                        >
                          Review Applicant Board
                        </Link>
                      </p>
                    </div> */}
                  </div>
                ) : (
                  nextSchedules &&
                  nextSchedules.map((a, index) => (
                    <div>
                      {moment().isBefore(
                        moment(a.date)
                          .hour(moment(a.start_time).get('hour'))
                          .minute(moment(a.start_time).get('minute'))
                      ) && (
                          <Sched
                            seeker={a.name}
                            time={
                              moment(a.date)
                                .startOf('day')
                                .add(moment(a.start_time).get('hours'), 'hours')
                                .isSame(moment(), 'day')
                                ? `Today ${moment(a.date)
                                  .startOf('day')
                                  .add(
                                    moment(a.start_time).get('hours'),
                                    'hours'
                                  )
                                  .format('HH:mm')}`
                                : moment(a.date)
                                  .startOf('day')
                                  .add(
                                    moment(a.start_time).get('hours'),
                                    'hours'
                                  )
                                  .format('ddd, MMM DD hh:mm A')
                            }
                            position={
                              <div className="text-sm pt-1">
                                Interview for the{' '}
                                <span className="text-pj-blue-500 font-semibold">
                                  {a.job_title}
                                </span>{' '}
                                position
                              </div>
                            }
                            schedule={
                              <>
                                <div className="flex-auto">
                                  <Time date={a.date} start={a.start_time} />
                                </div>
                                <div className="flex text-pj-green-300 pt-1 text-lg">
                                  <AiOutlineClockCircle />
                                </div>
                              </>
                            }
                            first={index === 0 ? true : false}
                          />
                        )}
                    </div>
                  ))
                )}
                {nextSchedules && nextSchedules.length === 0 ? (
                  <div className="h-full flex justify-center items-center text-center ">
                    <p className="text-pj-blue-100 text-sm">
                      You have no other events remaining on your schedule.{' '}
                      <br />
                      <Link to="/applicant-board" className="text-pj-blue-400">
                        Review Applicant Board
                      </Link>
                    </p>
                  </div>
                ) : (
                  <div className="h-full flex justify-center items-center text-center ">
                    <p className="text-pj-blue-100 text-sm">
                      You have no other events remaining on your schedule.{' '}
                      <br />
                      <Link to="/applicant-board" className="text-pj-blue-400">
                        Review Applicant Board
                      </Link>
                    </p>
                  </div>
                )}
              </>
            )}
          </motion.div>
          <div className="border rounded-md w-full">
            <Scheduler data={schedules} height="auto">
              <ViewState
                defaultCurrentDate={currentDate}
                onCurrentDateChange={date => _dateChange(date)}
              />
              <MonthView />
              <Toolbar />
              <DateNavigator />
              <Appointments
                appointmentComponent={({ children }) => <div>{children}</div>}
                appointmentContentComponent={(val: any) => {
                  const scheduleThisDay = val.data.arrayOfAppointments
                  const filteredSched = scheduleThisDay.filter((data, index) => val.data.startDate === view.id ? index <= view.toview : index === 0)
                  return (
                    <div
                      className={`flex justify-start items-center cursor-pointer ${val.data.disabled ? 'text-gray-400' : 'text-pj-blue-100'
                        }`}
                    >
                      <div
                        className={`rounded-full h-2 w-2 ${val.data.disabled ? 'bg-gray-400' : 'bg-pj-green-300'
                          }`}
                      ></div>
                      <div className=' flex flex-col w-full text-xs bg-white shadow-lg rounded border'>
                        {
                          filteredSched.map((sched) => {
                            return <div
                              className=" rounded p-1 hover:bg-gray-50 border-b"
                              onClick={() => _showInfo(sched)}
                            >
                              {moment(sched.start_time).format('h:mma')}{' '}
                              <div
                                className={`${val.data.disabled
                                  ? 'text-gray-400'
                                  : 'text-pj-blue-500'} truncate ... w-full overflow-hiddenv capitalize`
                                }
                              >
                                {sched.name} - {sched.job_title}
                              </div>
                            </div>
                          })
                        }
                        {scheduleThisDay.length > 1 && (view.id !== val.data.startDate ?
                          (
                            <div onClick={handleView(scheduleThisDay.length, val.data.startDate)} className=' self-end mr-2 hover:bg-gray-50 rounded p-1 hover:text-gray-700 cursor-pointer'>
                              View more
                            </div>
                          ) :
                          (
                            <div onClick={handleView(0)} className=' self-end mr-2 hover:bg-gray-50 rounded p-1 hover:text-gray-700 cursor-pointer'>
                              View less
                            </div>))}

                      </div>
                    </div>
                  );
                }}
              />
            </Scheduler>
          </div>
        </div>
      </div>
      {info && (
        <ScheduleInfo
          data={info}
          show={show}
          _closeHandler={_closeHandler}
          _editHandler={_editHandler}
        />
      )}
      <Modal
        title="Reschedule this interview"
        closable
        onClose={_closeHandler}
        show={isEdit}
      >
        <Interview
          info={{
            name: info?.name ?? '',
            profile_image: info?.profile_image ?? null,
            company_name: info?.company_name ?? '',
            company_image: info?.company_image ?? null,
            job_title: info?.job_title ?? '',
          }}
          control={control}
          handleSubmit={handleSubmit}
          _submitHandler={_submitHandler}
          _onClose={_closeHandler}
        />
      </Modal>
    </Paper>
  );
};

export default SchedulerView;
