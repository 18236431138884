import { auth_api, encryptPayload } from "./main";

export const socialLogin2 = async (data: any) => {
  let response = {};
  let payload = encryptPayload(JSON.stringify(data));

  await auth_api.post('social_login', {data: payload})
    .then(res => {
      console.log(res, 'res');
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response
};

export const login_v2 = async (data: any) => {
  let response = {};
  let payload = encryptPayload(JSON.stringify(data));

  await auth_api.post('login', {data: payload})
    .then(res => {
      response = res.data
    })
    .catch(err => {
      response =  err.response.data
    });

  return response
};

export const register = async (data: any) => {
  let response = {};
  let payload = encryptPayload(JSON.stringify(data));

  await auth_api.post('register', {data: payload})
    .then(res => {
      response = res.data
    })
    .catch(err => {
      response =  err.response.data
    });

  return response
}

export const registerWithCompany = async (data: any) => {
  let response = {};
  let payload = encryptPayload(JSON.stringify(data));

  try{
    await auth_api.post('registerWithCompany', {data: payload})
    .then(res => {
      response = res.data
    })
    .catch(err => {
      throw err
    });

  return response
  }catch(e){
    throw e
  }
}

export const sendForgotPasswordCode = async (data: any) => {
  let response = {};
  let payload = encryptPayload(JSON.stringify(data));

  await auth_api.post('/send-code', {data: payload})
    .then(res => {
      response = res.data
    })
    .catch(err => {
      response =  err.response.data
    });

  return response
}

export const verifyForgotPasswordCode = async (data: any) => {
  let response = {};
  let payload = encryptPayload(JSON.stringify(data));

  await auth_api.post('/verify-code', {data: payload})
    .then(res => {
      response = res.data
    })
    .catch(err => {
      response =  err.response.data
    });

  return response
}

export const changePassword = async (data: any) => {
  let response = {};
  let payload = encryptPayload(JSON.stringify(data));

  await auth_api.post('change-password', { data: payload })
    .then(res => {
      response = res.data
    })
    .catch(err => {
      response =  err.response.data
    });

  return response
};