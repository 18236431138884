import { useForm } from "react-hook-form";
import { Board2Context } from "../../utilities/context";
import BoardView from "./view";
import { useEffect, useState } from "react";
import { BoardJobDetail, BoardPayloadType, CommonJob, Option, Skill } from "../../utilities/types";
import { auth } from "../../utilities/auth";
import { getBoardInformation, getJobDetails, getJobs, getJobsCount } from "../../utilities/api_v2/jobs";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useRecoilState, useSetRecoilState } from "recoil";
import { boardFilterJobsAtom, jobListAtom, jobIdAtom, jobDetailsAtom, jobDataAtom, whereCheckerAtom, applicantCSVAtom } from "../../utilities/atoms/applicant_board/applicantBoardAtoms";
import TrelloBoard from "./pages/board";
import Details from "./pages/details";
import Match from "./pages/match";
import { getInitials, recoilSetter } from "../../utilities/helper";
import { boardAtom } from "../../utilities/atoms/applicant_board/boardAtoms";
import { checkBoxAtom } from "../../utilities/atoms/applicant_board/checkedApplicantAtoms";
import { defaultApplicantToShowPerBin } from "../../utilities/board-helper";

export type Params = {
  id: undefined | string;
  type: BoardPayloadType;
};

const emptyBoard = {
  status: null,
  rejected: [],
  applied: [],
  pre_screened: [],
  for_interview: [],
  shortlisted: [],
  hired: [],
  suggesteds: [],
  invited: [],
  counts : {
    "not selected": 0,
    "applied": 0,
    "pre-screened": 0,
    "for interview": 0,
    "shordlisted": 0,
    "hired": 0,
},
}

const BoardController = () => {
  const params: Params = useParams();
  const { handleSubmit: handleSubmitSearch, register, setValue } = useForm();

  //recoil states
  const [filter, setFilter] = useRecoilState(boardFilterJobsAtom);
  const [whereChecker, setWhereChecker] = useRecoilState(whereCheckerAtom);
  const setCheckedApplicant = useSetRecoilState(checkBoxAtom)
  const [jobData, setJobData] = useRecoilState(jobDataAtom);
  const [job_details, setJobDetails] = useRecoilState(jobDetailsAtom);
  const [{ jobList: jobs, count: jobsCount }, setJobsData] = useRecoilState(jobListAtom);
  const [jobId, setJobId] = useRecoilState(jobIdAtom);
  const setBoardLaneData = useSetRecoilState(boardAtom);

  //useStates
  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(null)

  const { where, limit, sort, searchText, selectedCompany } = filter

  const defaultWhere = {
    status: { equals: "Active" },
    AND: [
      {
        OR: [
          {
            employer_id: {
              in: null
            }
          },
          {
            employer_companies: {
              sub_employers_company: {
                some: {
                  sub_employer_id: auth.getId(),
                },
              },
            },
          },
        ],
      },
      {
        OR: [
          {
            title: {
              contains: searchText,
              mode: 'insensitive',
            },
          },
          {
            employer_companies: {
              name: {
                contains: searchText,
                mode: 'insensitive',
              },
            },
          },
        ],
      },
    ],
    // visible: { equals: false },
  };
  const _getJobsCount = async () => {
    await getJobsCount({ where: where, id: auth.getId() }).then((res: any) => {
      recoilSetter(setJobsData, { count: res.data })
    })
  }

  const _getJobList = async (where, order, date, limit, id, orderNewApplicants) => {
    setLoading(true);
    await getJobs({ where, order, date, limit, id, orderNewApplicants }).then((res: any) => {
      const jobs = res.data.job
      if (jobs) {
        const items: BoardJobDetail[] = jobs.map(job => {
          return {
            title: job.title,
            logo: job.company?.company_image ?? '',
            id: job.id,
            status: job.status,
            company_name: job.company?.name ?? '',
            newly: job?.unread?.aggregate?.count,
          };
        });
        recoilSetter(setJobsData, { jobList: items })
      }
      setLoading(false)
    })
  };

  const _getJobPost = async () => {
    await getJobDetails({ id: parseInt(params.id) }).then((res: any) => {
      const job_details = res?.data
      let skills: Skill[] = [];
      if (job_details && params && params.id) {
        if (job_details.common_jobs[0]?.common_job_skills) {
          skills = job_details.common_jobs[0]?.common_job_skills.map(
            skillName => {
              return {
                name: skillName.skill_name,
                value: skillName.skill_name,
              };
            }
          );
        }
        const data = job_details.common_jobs[0];
        let open = data?.openings[0]?.referral_count;
        let filled = data?.filled.aggregate.count;
        let jobOpenings = open - filled;
        const job_info: CommonJob = {
          title: data?.title,
          address: data?.address,
          description: data?.description,
          city_id: data?.city_id,
          company_detail_id: data?.company_detail_id,
          employer_id: auth.getId(),
          home_based: data?.home_based,
          industry_id: data?.industry_id,
          job_type_id: data?.job_type_id,
          province_id: data?.province_id,
          status: data?.status,
          visible: data?.visible ?? null,
          referral_count: data?.common_orders[0]?.referral_count,
          referral_fee: data?.common_orders[0]?.referral_fee,
          job_employment_type:
            data?.common_job_informations[0]?.job_employment_type,
          job_experience: data?.common_job_informations[0]?.job_experience,
          job_position_level:
            data?.common_job_informations[0]?.job_position_level,
          maximum_salary: data?.common_job_informations[0]?.maximum_salary,
          minimum_salary: data?.common_job_informations[0]?.minimum_salary,
          minimum_qualification:
            data?.common_job_informations[0]?.minimum_qualification,
          show_salary: data?.common_job_informations[0]?.show_salary,
          applied: data?.applicants.aggregate.count,
          processed: data?.processed.aggregate.count,
          filled: data?.filled.aggregate.count,
          opening: jobOpenings,
          posted: data?.created_at,
          company_name: data?.employer_company.name,
          company_logo: data?.employer_company.company_image,
          skills,
          disbursement_type: data?.common_orders[0]?.disbursement_type,
        };
        setJobDetails(job_info)
      }
    })
  };

  const _getBoardData = async (id: number) => {
    recoilSetter(setBoardLaneData, {
      ...emptyBoard,
      company_detail_id: 0,
      industry_name: "",
      isLoading: true
    })
    setJobDetails(null)
    await getBoardInformation({
      id,
      take:defaultApplicantToShowPerBin
    }).then((res: any) => {
      setCheckedApplicant({
        status: "",
        applicantIds: []
      })
      const boardInformationData = res?.data
      if (boardInformationData) {
        const {
          job_title,
          logo,
          company_name,
          company_id,
          rejected,
          applied,
          pre_screened,
          for_interview,
          shortlisted,
          hired,
          status,
          company_detail_id,
          industry_name,
          rejected_count,
          applied_count,
          pre_screened_count,
          for_interview_count,
          shortlisted_count,
          hired_count,
        } = boardInformationData;
        const boardData = {
          status,
          rejected,
          applied,
          pre_screened,
          for_interview,
          shortlisted,
          suggesteds: [],
          invited: [],
          hired,
          counts :{
            "Rejected": rejected_count,
            "Applied": applied_count,
            "Shortlisted": pre_screened_count,
            "For Interview": for_interview_count,
            "For Hire": shortlisted_count,
            "Hired": hired_count,
        },
        }

        //board data setter
        recoilSetter(setBoardLaneData, {
          ...boardData,
          isLoading: false,
          company_detail_id,
          industry_name
        })

        //job data setter
        const job_data = {
          type: 'company_info',
          title: job_title,
          company_name: company_name,
          company_id,
          logo: logo,
          payload: null,
          id: parseInt(params.id),
        }
        setJobData(job_data)
      } else {
        recoilSetter(setBoardLaneData, {
          ...emptyBoard,
          company_detail_id: 0,
          industry_name: "",
          isLoading: false
        })
      }
    })
  }

  //convert to string filter values to check if the filter changes to trigger the query getBoardData
  const _filterToText = () => {
    const searchText = where?.AND[1].OR[0].title.contains.toString();
    const sortText = sort?.value;
    const selectedCompanyText = selectedCompany.value
    const limitText = limit.toString()
    return `${searchText + sortText + selectedCompanyText + limitText}`
  }

  const _addTenToLimit = () => {
    recoilSetter(setFilter, { limit: filter.limit + 10 })
  }

  const _handleSearch = (data: any) => {
    recoilSetter(setFilter, { searchText: data.search, limit: 10 })
  };

  const _handleSort = (option: any) => {
    recoilSetter(setFilter, { sort: option, limit: 10 })
  };

  const _handleSelect = (option: any) => {
    recoilSetter(setFilter, { selectedCompany: option, limit: 10 })
    if (option.value === null) {
      recoilSetter(setFilter, { where: defaultWhere })
    } else {
      if (option.value === 'new applicants') {
        recoilSetter(setFilter, {
          where: {
            ...defaultWhere,
            // AND: [
            //   ...defaultWhere.AND,
            //   {
            //     common_job_applications: {
            //       some: {
            //         is_viewed: false,
            //       },
            //     },
            //   },
            // ],
          }
        })
      } else {
        recoilSetter(setFilter, {
          where: {
            ...defaultWhere,
            AND: [
              ...defaultWhere.AND,
              {
                visible: { equals: option.value },
              },
            ],
          }
        })
      }
    }
  };

  //view changer every change of params type
  useEffect(() => {
    if (params.type) {
      //<TrelloBoard />
      const _getView = {
        board: <TrelloBoard />,
        details: < Details />,
        match: <Match />,
      };
      setView(_getView[params.type])
    }
  }, [params.type])

  useEffect(() => {
    const paramsId = parseInt(params.id)
    if (!!paramsId && jobId !== paramsId) {
      _getBoardData(paramsId);
      setJobId(paramsId)
    }
  }, [params.id])

  useEffect(() => {
    params.type === 'details' && !job_details && _getJobPost()
  }, [params.type])

  useEffect(() => {
    if (searchText !== where?.AND[1].OR[0].title.contains) {
      recoilSetter(setFilter, { where: defaultWhere })
    }
  }, [searchText])

  useEffect(() => {
    setValue("search", searchText)
    if (selectedCompany.value === "new applicants") {
      recoilSetter(setFilter, {
        where: {
          ...defaultWhere,
          // AND: [
          //   ...defaultWhere.AND,
          //   {
          //     common_job_applications: {
          //       some: {
          //         is_viewed: false,
          //       },
          //     },
          //   },
          // ],
        }
      })
    }
  }, [])

  useEffect(() => {
    const date = moment().startOf('day').subtract(3, 'days')
    const orderNewApplicants = selectedCompany.value === "new applicants"
    if (where && _filterToText() !== whereChecker) {
      if (!orderNewApplicants || (orderNewApplicants && limit === 10)) {
        _getJobList(where, sort.value, date, limit, auth.getId(), orderNewApplicants);
        _getJobsCount()
      }
      setWhereChecker(_filterToText())
    }
  }, [where, sort, limit, selectedCompany, jobs])

  const value = {
    handleSubmitSearch,
    register,
    _handleSearch,
    _handleSelect,
    _handleSort,
    _addTenToLimit,
    loading,
    selectedCompany,
    sort,
    jobs,
    limit,
    id: params.id,
    view,
    jobsCount,
    jobData,
    viewType: params.type,
    job_details,
  }
  return (
    <Board2Context.Provider value={value}>
      <BoardView />
    </Board2Context.Provider>
  );
};

export default BoardController;
