import { motion } from 'framer-motion';
import { slideUp } from '../../framer';
import { OnClickFunction, OnClickHandleFunction } from '../../utilities/types';
import Avatar from '../avatar';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ConfirmButton from '../dialogs/confirmation-button';
import { deleteJob as deleteCurrentJob } from '../../utilities/api_v2/job-posts';

type Props = {
  active: boolean;
  title?: string | undefined;
  company_name?: string;
  newly: number;
  logo?: string | null;
  onClick?: OnClickFunction;
  _handleRefresh?: OnClickHandleFunction;
  id?: number;
  status?: any;
};

const JobItem = ({
  active,
  title,
  company_name,
  newly,
  logo,
  onClick,
  _handleRefresh,
  id,
  status,
}: Props) => {
  const notify = () =>
    toast('Copied to clipboard!', {
      position: 'top-right',
      autoClose: 1000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
    });
    
    const _deleteJob = async () => {
     return await deleteCurrentJob({
      id: id,
    }).then(()=>{
      toast.success('SuccessFully deleted job!', {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
      _handleRefresh?.();
    }).catch((e)=>{ 
      toast.error('Error on Deleting Job !', {
        position: 'top-right',
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });
    })
    }
    
  const deleteJob = (e: any) => {
    e.stopPropagation();
    toast(
      <ConfirmButton
        message="Are you sure you want to delete this job?"
        onConfirm={_deleteJob}
      />,
      {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        closeOnClick: false,
      }
    );
  };

  return (
    <>
      <motion.div
        className={`p-4 border-b-2 cursor-pointer ${active ? 'bg-gray-100' : 'bg-white'
          } hover:bg-gray-100`}
        variants={slideUp}
        whileHover={{
          scale: 1.01,
          transition: {
            duration: 0.2,
          },
        }}
      >
        <div className="flex justify-between text-base font-medium pj-blue-400">
          <div
            className="flex items-center justify-start w-32 h-16 text-2xl text-pj-blue-500"
            onClick={() => onClick && onClick(id)}
          >
            {logo && logo !== '' ? (
              <div
                className="w-32 h-16 bg-gray-100 bg-center bg-cover border border-gray-200"
                style={{ backgroundImage: `url("${logo}")` }}
              ></div>
            ) : (
              <Avatar name={company_name ?? ''} url={null} size={50} />
            )}
          </div>
          <div className="">
            {newly > 0 && (
              <Link to={`/applicant-board/${id}/board`} className="w-full">
                <span className="px-1 text-xs text-white bg-green-400 rounded-full">
                  {`${newly} NEW`}
                </span>
              </Link>
            )}
            {status == 'active' && (
              <div
                className="flex items-center justify-center w-full py-5"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${encodeURI(
                      `https://seeker.pasajob.com/jobpage/${title &&
                      title
                        .replace(/ /g, '-')
                        .replace(/[^\w\s]/gi, '-')
                        .replace('/', '-')
                      }?id=${id}`
                    )}`
                  );
                  notify();
                }}
              >
                {/* <FaCopy className="text-lg text-gray-300" /> */}
                {/* <button className="relative flex items-center justify-center h-10 bg-gray-100 border-2 border-gray-200 rounded-full w-100 text-m text-pj-blue-100">
              <div>Copy Link </div>
              <div></div>
            </button> */}
                <span className="w-full px-3 text-xs text-center bg-gray-100 border-2 border-gray-200 rounded-full w-100 text-pj-blue-100">
                  Copy Link
                </span>
              </div>
            )}
            {["active", "pending"].includes(status)  && (
              <Link
                to={`/create-job/3/1/${id}/duplicate`}
                className="w-full px-4 text-xs bg-gray-100 border-2 border-gray-200 rounded-full w-100 text-pj-blue-100"
              >
                Duplicate Job
              </Link>
            )}
            {status == 'archived' && false && (
              <div
                className="flex items-center justify-center w-full py-5"
                onClick={$event => deleteJob($event)}
              >
                {/* <FaCopy className="text-lg text-gray-300" /> */}
                {/* <button className="relative flex items-center justify-center h-10 bg-gray-100 border-2 border-gray-200 rounded-full w-100 text-m text-pj-blue-100">
              <div>Copy Link </div>
              <div></div>
            </button> */}
                <span className="px-1 px-3 text-xs bg-gray-100 border-2 border-gray-200 rounded-full w-100 text-pj-blue-100">
                  Delete Job
                </span>
              </div>
            )}
          </div>
        </div>
        <div className="w-full py-1" onClick={() => onClick && onClick(id)}>
          <div className="w-full text-pj-blue-400">{title}</div>
          <div className="w-full text-sm text-pj-blue-100">
            {company_name}
          </div>
        </div>
      </motion.div>
    </>
  );
};

JobItem.defaultProps = {
  active: false,
  newly: 0,
};

export default JobItem;
