import { useContext, useState, useEffect } from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';

const ProcessApp = () => {

  return (
    <div className="h-full">
      <div className="p-4 px-8 flex flex-col justify-around bg-gray-100 text-lg text-pj-blue-100 pt-10 mt-10 ml-12">
        <div className="py-2">
          You can process applications both on mobile app and on the web.
        </div>
        <div className="py-2">
          Go to the Job Posts Page.
        </div>
        <div className="py-2">
          Select the role you want to process applications for.
        </div>
        <div className="py-2">
          Once you see the job board, select the cards on the Applicants List.
        </div>
        <div className="py-2">
          Click View Profile to review their credentials. <br />
          Choose to move the card to the left (Not Qualified) or
          to the right (Pre-screened).
        </div>
        <div className="py-2">
          Once the candidate is in the Pre-screened stage, you will be
          able to see their contact details. After reviewing their profile,
          you may choose to drag their card to Not Qualified or For Interview.
        </div>
        <div className="py-2">
          Dragging the card to For Interview will launch the Interview Scheduler.
          Pick a location, date and time, then click Submit.
        </div>
        <div className="py-2">
          After your interviews and deliberation, drag your chosen candidate to
          Offered. Once they accept your offer, their card will automatically be
          moved to Hired.
        </div>
      </div>
    </div>
  );
};

export default ProcessApp;
