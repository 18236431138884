import { ChangeEvent, useContext } from 'react';
import InterviewTemplate from '../../../components/interview-template';
import { CheckboxesContext } from '../../../utilities/context';
import SeekerProfile from './profile';
import { Checkbox } from '@material-ui/core';
import { useRecoilState } from 'recoil';
import { boardAtom } from '../../../utilities/atoms/applicant_board/boardAtoms';
import { checkBoxAtom } from '../../../utilities/atoms/applicant_board/checkedApplicantAtoms';
import { boardLabelFixer } from '../../../utilities/helper';
import { loadMoreCommonJobApplicationByStatus } from '../../../utilities/api_v2/jobs';
import { useParams } from 'react-router-dom';
import { Params } from '../index past';
import { useBoardLoadMoreStore } from '../../../store/board-load-more';

const Card = (val: any) => {
  const params:Params = useParams();
  const [checkedApplicants, setCheckedApplicant] = useRecoilState(checkBoxAtom)
  const [board_data, setBoardData] = useRecoilState(boardAtom)
  const { setLoadMoreIsLoading, loadMoreByStatus, setLoadMoreByStatus } = useBoardLoadMoreStore()

  const {
    _editInterview,
  } = useContext(CheckboxesContext)

  //function to check applicant (add id to checkedApplicants)
  const checkApplicant = (checked: boolean, id: number) => {
    if (checked) {
      setCheckedApplicant((prev) => {
        // for ids to remain sorted
        const sortedId = board_data[boardLabelFixer(checkedApplicants.status)].filter((data) => [...prev.applicantIds, id].includes(data.id)).map((data) => data.id)
        return ({ ...prev, applicantIds: sortedId })
      })
    } else {
      setCheckedApplicant((prev) => ({ ...prev, applicantIds: prev.applicantIds.filter((checkedId) => checkedId !== id) }))
    }
  }

  //checker if card is active
  const activeToCheckCard = (title: string) => {
    return title.toLowerCase() === checkedApplicants.status.toLowerCase()
  }

  const handleOnChange = (e: ChangeEvent<HTMLInputElement>, id: number) => {
    e.stopPropagation()
    checkApplicant(e.target.checked, id)
  }

  const isChecked = (id: number) => {
    return checkedApplicants.applicantIds.includes(id)
  }

  const handleOnLoadMore = async () => {
    setLoadMoreIsLoading({status:val.laneId, isLoading:true})
    try {
      const numberOfApplicantsToLoad = 50
      const toTake = loadMoreByStatus[val.laneId].take + numberOfApplicantsToLoad
      await loadMoreCommonJobApplicationByStatus({status:val.laneId, take:toTake, id:parseInt(params.id)})
      .then(({data}:{data:any}) => {
        setLoadMoreByStatus({status:val.laneId, take:data?.length >= toTake ? toTake : loadMoreByStatus[val.laneId].take })
        setBoardData((prev)=>({
          ...prev,
          [boardLabelFixer(val.laneId)]:data
        }))
      }).catch(e=>{
        console.log(e)
      })
    }catch(e){
      console.log(e)
    }
  }
  
  if(val.id===-1){
    return <div>
      {
        loadMoreByStatus[val.laneId].isLoading ? 
        <p className='pt-2 text-sm text-gray-400 '>Loading ...</p> :
        <p  className='pt-2 text-sm text-gray-500 cursor-pointer hover:text-gray-700' onClick={handleOnLoadMore} >Load more</p>
      }
    </div>
  }

  const name = val.laneId === "Rejected" ? `${val.first_name} ${val.last_name?.[0]}` : val.name

  return val.id === 0 ? (
    <div></div>
  ) : (
    <div
      className={`p-3 border-b border-gray-300`}
      style={{
        width: 310,
        paddingLeft: 20,
        backgroundColor:
          board_data?.status === 'Filled Up' && val?.laneId !== 'Hired'
            ? '#E0e0e0'
            : '#F5F6F7',
      }}
    >
      <div className="flex flex-row items-start justify-between">
        {
          activeToCheckCard(val.laneId) && <Checkbox size='small' style={{ padding: 0, marginRight: 5 }} color='primary' onChange={(e) => handleOnChange(e, val.id)} checked={
            isChecked(val.id)}
          />
        }
        <SeekerProfile
          name={name}
          job_title={val.job_title}
          seeker_id={val.seeker_id}
          job_id={val.job_id}
          application_id={val.id}
          profile_image={val.profile_image}
          job_status={board_data?.status}
          lane={val}
          agingDays={val.agingDays}
        />
        {/* {!!val.profile_ranking && 
        
          <Tooltip
          color={"#1c4464"}
          title={<>
            <div className="text-sm">
              Profile Ranking
            </div>
          </>}
          key={"ranking"}
          placement="top">
            <div className='mx-2 text-base text-pj-orange-200'>{val.profile_ranking}%</div>
        </Tooltip>
        } */}
        </div>
      {val.referrer && (
        <div
          className={`p-1 border border-gray-200 rounded-md text-pj-blue-400 text-sm text-left mt-2  ${board_data?.status === 'Filled Up' && val?.laneId !== 'Hired'
            ? 'bg-gray-200'
            : 'bg-gray-100'
            }`}
        >
          Referred By: {val.referrer}
        </div>
      )}
      {val.interviews?.length > 0 && (
        <InterviewTemplate
          {...val.interviews[0]}
          onClick={_editInterview}
          applicant={val}
          short
        />
      )}
    </div>
  );
};

export default Card;
