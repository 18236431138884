import React, { useEffect, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { step_one, step_three, step_two } from '../../helpers';
import { lang } from '../../lang';
import { CreateJobContext } from '../../utilities/context';
import { CreateJobInitialState } from '../../utilities/states';
import {
  Action,
  Address,
  CommonJob,
  Company,
  CompanyAddress,
  Job,
  MainStep,
  OnClickFunction,
} from '../../utilities/types';
import StepOne from './steps/step-one-one';
import StepThreeOne from './steps/step-three-one';
import StepThreeTwo from './steps/step-three-two';
import StepTwoEight from './steps/step-two-eight';
import StepTwoEleven from './steps/step-two-eleven';
import StepTwoFifteen from './steps/step-two-fifteen';
import StepTwoFive from './steps/step-two-five';
import StepTwoFour from './steps/step-two-four';
import StepTwoFourteen from './steps/step-two-fourteen';
import StepTwoNine from './steps/step-two-nine';
import StepTwoNineteen from './steps/step-two-nineteen';
import StepTwo from './steps/step-two-one';
import StepTwoSeven from './steps/step-two-seven';
import StepTwoSix from './steps/step-two-six';
import StepTwoSixteen from './steps/step-two-sixteen';
import StepTwoTen from './steps/step-two-ten';
import StepTwoThirteen from './steps/step-two-thirteen';
import StepTwoThree from './steps/step-two-three';
import StepTwoTwelve from './steps/step-two-twelve';
import StepTwoTwenty from './steps/step-two-twente';
import StepTwoTwentyOne from './steps/step-two-twenty-one';
import StepTwoTwo from './steps/step-two-two';
import CreateJobView from './view';
import { useRecoilState } from 'recoil';
import { checkoutAtom } from '../../utilities/atoms/checkout';
import { createJob, getJobDetail } from '../../utilities/api_v2/job_create';
import { updateJob } from '../../utilities/api_v2/checkout';
import StepTwoZero from './steps/step-two-zero';

const reducer = (state: Job, action: Action): Job => {
  switch (action.type) {
    case 'steps':
      switch (action.payload) {
        case 1:
          return {
            ...state,
            steps: { 1: true, 2: false, 3: false, 4: false },
          };
        case 2:
          return {
            ...state,
            steps: { 1: false, 2: true, 3: false, 4: false },
          };
        case 3:
          return {
            ...state,
            steps: { 1: false, 2: false, 3: true, 4: false },
          };
        case 4:
          return {
            ...state,
            steps: { 1: false, 2: false, 3: false, 4: true },
          };
        default:
          return { ...state };
      }
    case 'details':
      return {
        ...state,
        details: {
          ...(action.payload as MainStep),
        },
      };
    case 'data':
      return {
        ...state,
        common_job: {
          ...state.common_job,
          ...(action.payload as CommonJob),
        },
      };
    default:
      throw new Error();
  }
};

export type Params = {
  step: string;
  sub: string;
  job_id?: string;
  is_duplicate?:string
};

type Step = {
  title: string;
  elem: React.ReactNode;
};

const stepTwo: Step[] = [
  {
    title: lang.create_job.step_two_zero,
    elem: <StepTwoZero />,
  },
  {
    title: lang.create_job.step_two_one,
    elem: <StepTwo />,
  },
  {
    title: lang.create_job.step_two_two,
    elem: <StepTwoTwo />,
  },
  {
    title: lang.create_job.step_two_three,
    elem: <StepTwoThree />,
  },
  {
    title: lang.create_job.step_two_four,
    elem: <StepTwoFour />,
  },
  {
    title: lang.create_job.step_two_five,
    elem: <StepTwoFive />,
  },
  {
    title: lang.create_job.step_two_six,
    elem: <StepTwoSix />,
  },
  {
    title: lang.create_job.step_two_seven,
    elem: <StepTwoSeven />,
  },
  {
    title: lang.create_job.step_two_eight,
    elem: <StepTwoEight />,
  },
  {
    title: lang.create_job.step_two_nine,
    elem: <StepTwoNine />,
  },
  {
    title: lang.create_job.step_two_twenty_one,
    elem: <StepTwoTwentyOne />,
  },
  {
    title: lang.create_job.step_two_ten,
    elem: <StepTwoTen />,
  },
  {
    title: lang.create_job.step_two_eleven,
    elem: <StepTwoEleven />,
  },
  {
    title: lang.create_job.step_two_twelve,
    elem: <StepTwoTwelve />,
  },
  {
    title: lang.create_job.step_two_ninteen,
    elem: <StepTwoNineteen />,
  },
  {
    title: lang.create_job.step_two_twenty,
    elem: <StepTwoTwenty />,
  },
  {
    title: lang.create_job.step_two_thirteen,
    elem: <StepTwoThirteen />,
  },
  {
    title: lang.create_job.step_two_thirteen,
    elem: <StepTwoFourteen />,
  },
  {
    title: lang.create_job.step_two_thirteen,
    elem: <StepTwoFifteen />,
  },
  {
    title: lang.create_job.step_two_thirteen,
    elem: <StepTwoSixteen />,
  },
];

const stepThree: Step[] = [
  {
    title: '',
    elem: <StepThreeOne />,
  },
  {
    title: '',
    elem: <StepThreeTwo />,
  },
];

type Skills = {
  skill_name: string | null | undefined;
  job_id?: number;
};

type Trait = {
  trait: string | null | undefined;
  job_id?: number;
};

const CreateJobController = () => {
  const { step, sub, job_id, is_duplicate }: Params = useParams();
  const isDuplicate = is_duplicate === "duplicate"
  const [commonJobs, setCommonJobs] = useRecoilState(checkoutAtom);
  const history = useHistory();
  const location: any = useLocation();
  const [checkout, setCheckout] = useState<boolean>(false);
  const [jobId, setJobId] = useState<number | null>(null);
  const [referral_fee, setReferralFee] = useState<number | null>(null);
  const [referral_count, setReferralCount] = useState<number | null>(null);
  const [with_applicants, setWithApplicants] = useState<boolean>(false);
  const [isBoost, setIsBoost] = useState<boolean>(false);
  const [confirmJobPostData, setConfirmJobPostData] = useState<any>(null);

  const [state, dispatch] = useReducer(reducer, CreateJobInitialState);
  const [company, setCompany] = useState<Company | null>();
  const [loading, setLoading] = useState<boolean>(true);
  const [addresses, setAddresses] = useState([] as CompanyAddress[]);
  const [active, setActive] = useState<string>('company_address');
  const [min, setMin] = useState<number>(300);

  const [job_detail, setJobDetail] = useState(null)
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    register,
    reset,
    setError,
    watch
  } = useForm<any>({
    defaultValues: { ...state.common_job },
  });

  const _createJob = async (data: any) => {
    setLoading(true);
    await createJob(data).then((job: any) => {
      const dt = job.data
      setJobId(dt.id);
      setConfirmJobPostData(null)
      if (checkout) {
        history.push({
          pathname: `/checkout/4/1/${dt.id}`,
          state: {
            prev: `/create-job/3/1/${dt.id}`,
          },
        });
      } else {
        history.push(
          `/create-job/3/2/${dt.id}`
        );
      }
    }).catch((e) => {
      console.log(e);
    }).finally(()=>
      setLoading(false))
  }

  const _updateJob = async (data: any) => {
    setLoading(true);
    await updateJob(data).then((job: any) => {
      const { id } = job.data
      console.log(job)
      if (id) {
        _getJob(id);
      }
      if (checkout) {
        history.push({
          pathname: `/checkout/4/1/${id}`,
          state: { prev: `/create-job/3/1/${id}` },
        });
      } else {
        history.push(`/create-job/3/2/${id}`);
      }
    }).catch((e) => {
      console.log(e)
    }).finally(()=>
      setLoading(false))
  }

  const _getJob = async (job_id: number) => {
    let payload = {
      id: job_id,
    };
    const job_details: any = await getJobDetail(payload);
    setJobDetail(job_details.data)
    setLoading(false)
  };
  useEffect(() => {
    if (
      referral_fee &&
      referral_count &&
      state.common_job.referral_fee &&
      (state.common_job.referral_fee > referral_fee ||
        state.common_job.referral_count > referral_count)
    ) {
      setCheckout(true);
    }
  }, [state.common_job]);

  // useEffect(() => {
  //   console.log(state)
  //   if (location.state && location.state.boost && state.common_job.referral_fee) {
  //     setMin(state.common_job.referral_fee)
  //   }
  // }, [location, state.common_job.referral_fee])

  useEffect(() => {
    if (state.company) {
      console.log(state.company);
    }
  }, [state.company]);

  useEffect(() => {
    if (!referral_fee && !referral_count) {
      setCheckout(true);
    } else {
      setCheckout(false);
    }
  }, [referral_fee, referral_count]);

  useEffect(() => {
    if (!!commonJobs) {
      if (jobId) {
        _getJob(jobId);
      }
      if (checkout && jobId) {
        history.push({
          pathname: `/checkout/4/1/${jobId}`,
          state: { prev: `/create-job/3/1/${jobId}` },
        });
      } else if (jobId) {
        history.push(`/create-job/3/2/${jobId}`);
      }
    }
  }, [commonJobs])

  const _selectedCompany: OnClickFunction = (data: Company) => {
    setCompany(data);
    const address: Address = {
      address: data?.address ?? '',
      city_id: data?.city_id ?? 0,
      province_id: data?.province_id ?? 0,
      home_based: false,
      city_name: data?.city_name,
    };
    dispatch({
      type: 'data',
      payload: { ...state.common_job, company_detail_id: data.id, ...address, erp_owner:data.erp_owner },
    });
    history.push('/create-job/2/1');
  };

  const getView = (step: number, sub_step: number) => {
    let totalSteps: number = 0;
    let view: React.ReactNode;
    let heading: string = '';
    switch (step) {
      case 1:
        totalSteps = step_one;
        view = <StepOne />;
        heading = lang.create_job.step_one;
        break;
      case 2:
        totalSteps = step_two;
        view = stepTwo[sub_step - 1].elem;
        heading = stepTwo[sub_step - 1].title;
        break;
      case 3:
        totalSteps = step_three;
        view = stepThree[sub_step - 1].elem;
        heading = stepThree[sub_step - 1].title;
        break;
      default:
        console.log('bugggg????');
        break;
    }
    dispatch({
      type: 'details',
      payload: {
        totalSteps,
        subStep: {
          heading,
          step: sub_step,
          view,
        },
      },
    });
  };

  const _submitForm: OnClickFunction = (data: CommonJob) => {
    if(data.blue_collar){
      data.referral_fee = 100;
      setValue('referral_fee', 100);
    }
    if (data.industry_id !== state.common_job.industry_id) {
      data.job_type_id = null;
      setValue('job_type_id', null);
    }
    dispatch({
      type: 'data',
      payload: { ...state.common_job, ...data },
    });
    //
    let subStep: number = parseInt(sub);
    let mainStep: number = parseInt(step);
    if (location.state?.isEdit && sub !== '2') {
      history.push({
        pathname: `/create-job/3/1/${job_id ? `${job_id}${isDuplicate? "/duplicate":""}`: ''}`,
      });
    } else if (state.details.totalSteps > subStep) {
      subStep++;
      if (location.state?.isEdit) {
        history.push({
          pathname: `/create-job/${step}/${subStep}/${job_id ? `${job_id}${isDuplicate? "/duplicate":""}`: ''}`,
          state: { isEdit: true },
        });
      } else {
        history.push(`/create-job/${step}/${subStep}/${job_id ? `${job_id}${isDuplicate? "/duplicate":""}`: ''}`);
      }
    } else if ((state.details.totalSteps = subStep)) {
      mainStep++;
      history.push(`/create-job/${mainStep}/1/${job_id ? `${job_id}${isDuplicate? "/duplicate":""}`: ''}`);
    }
  };

  const _jobPost: OnClickFunction = () => {
    let traits: Trait[] = [];
    let common_job_skills: Skills[] = [];
    if (state.common_job.id) {
      traits = [
        { trait: state.common_job.trait_1, job_id: state.common_job.id },
        { trait: state.common_job.trait_2, job_id: state.common_job.id },
        { trait: state.common_job.trait_3, job_id: state.common_job.id },
        { trait: state.common_job.trait_4, job_id: state.common_job.id },
      ];
      if (state.common_job.skills) {
        state?.common_job?.skills.map(({ value }) =>
          common_job_skills.push({
            skill_name: value,
            job_id: state.common_job.id,
          })
        );
      }
    } else {
      traits = [
        { trait: state.common_job.trait_1 },
        { trait: state.common_job.trait_2 },
        { trait: state.common_job.trait_3 },
        { trait: state.common_job.trait_4 },
      ];

      if (state.common_job.skills) {
        state?.common_job?.skills.map(({ value }) =>
          common_job_skills.push({
            skill_name: value,
          })
        );
      }
    }
    let data: any = {
      visible: false
    }

    data = {
      ...state.common_job,
      traits: traits,
      common_job_skills,
    };

    localStorage.setItem('visible', data.visible);

    delete data.skills;
    delete data.trait_1;
    delete data.trait_2;
    delete data.trait_3;
    delete data.trait_4;
    delete data?.city_name;
    if (state.common_job.id && !isDuplicate) {
      if (checkout) {
        setCommonJobs({
          ...data,
          old_referral_count_2: job_detail.order[0].referral_count,
          old_referral_fee_2: job_detail.order[0].referral_fee,
          isNewJob: job_detail.transactions.length === 0,
          referral_fee: parseInt(data.referral_fee)
        })
      } else {
        _updateJob({
          ...data
        })
      }
    } else {
      setConfirmJobPostData(data)
    }
  };

  const _createJobPost = () => {
    const data = confirmJobPostData
    if(data){
    _createJob({ ...data, referral_fee: parseFloat(data.referral_fee) });
    }
  }



  const _insertAddress = (data: CompanyAddress) => {
    setAddresses([...addresses, data]);
  };

  const _privacyHandler = (visible: boolean) => {
    dispatch({ type: 'data', payload: { ...state.common_job, visible } });
  };

  const _setMin = (min: number) => {
    setMin(min);
  }

  useEffect(() => {
    dispatch({ type: 'steps', payload: parseInt(step) });
    getView(parseInt(step), parseInt(sub));
  }, [step, sub]);

  useEffect(() => {
    reset(state.common_job);
  }, [state]);

  useEffect(() => {
    if (parseInt(step) > 1 && !job_id && !state.common_job.company_detail_id) {
      history.push('/create-job/1/1');
    }
  }, [step, sub, job_id]);

  useEffect(() => {
    if (job_id && !job_detail) {
      setJobId(parseInt(job_id));
      _getJob(parseInt(job_id));
    }
    if (!job_id) {
      setLoading(false)
    }
  }, [job_detail, job_id]);

  useEffect(() => {
    if (job_detail) {
      let newJob: any = job_detail;
      newJob = {
        ...newJob,
        ...newJob.information[0],
        ...newJob.order[0],
        title:(isDuplicate ?"Copy of ":"" )+newJob.title,
        company_name: newJob.company.name,
        company_logo: newJob.company.logo,
        erp_owner:newJob.company.erp_owner,
        trait_1: newJob.traits?.[0]?.trait,
        trait_2: newJob.traits?.[1]?.trait,
        trait_3: newJob.traits?.[2]?.trait,
        trait_4: newJob.traits?.[3]?.trait,
        city_name: newJob?.common_city?.name,
        address: newJob?.address,
      } as CommonJob;
      let company: any = { ...newJob.company };
      company = {
        ...company,
        city_name: company.city_name.name,
        province_name: company.province_name.name,
        total_jobs: company.total_jobs.aggregate.count,
      } as Company;

      localStorage.setItem('industry', newJob.industry_name);
      localStorage.setItem('category', newJob.job_type_name);
      setReferralFee(newJob.old_referral_fee);
      setReferralCount(newJob.old_referral_count);
      if (
        newJob.hired.aggregate.count <= 0 &&
        newJob.transactions.length > 0
      ) {
        setMin(newJob.order[0].referral_fee)
      }

      delete newJob.information;
      delete newJob.order;
      delete newJob.company;
      delete newJob?.common_city;
      setCompany(company);
      dispatch({ type: 'data', payload: newJob as CommonJob });
      reset(newJob);
      setActive(newJob.home_based ? 'home_based' : 'company_address');

      if (company.address !== newJob.address) {
        _insertAddress({
          address: newJob.address,
          city_id: newJob.city_id,
          province_id: newJob.province_id,
          home_based: false,
          city_name: newJob?.city_name,
        });
        if (!newJob.home_based) {
          setActive('0');
        }
      }

      if (
        newJob.hired.aggregate.count > 0 &&
        newJob.transactions.length > 0
      ) {
        setWithApplicants(true);
      }
      delete newJob.applicants;
      delete newJob.transactions;
      // history.push(`/ create - job / 2 / 1 / ${ newJob.id } `);
    }
  }, [job_detail]);

  // useEffect(() => {
  //   console.log(referral_fee, referral_count, with_applicants);
  // }, [referral_count, referral_fee, with_applicants]);

  const value: Job = {
    ...state,
    errors,
    control,
    company,
    loading,
    step: parseInt(step),
    sub: parseInt(sub),
    jobId,
    addresses,
    active,
    with_applicants,
    referral_fee,
    referral_count,
    min,
    setActive,
    _submitForm,
    setValue,
    _selectedCompany,
    handleSubmit,
    getValues,
    register,
    _jobPost,
    _insertAddress,
    _privacyHandler,
    _setMin,
    watch,
    confirmJobPostData, 
    setConfirmJobPostData,
    _createJobPost,
    isDuplicate
  } as Job;

  return (
    <CreateJobContext.Provider value={value}>
      <CreateJobView />
    </CreateJobContext.Provider>
  );
};

export default CreateJobController;
