import React from 'react';
import { PDFDownloadLink } from '@react-pdf/renderer';
import TransactionCV from './transaction-cv';

type Props = {
  renderer: React.ReactNode;
  amount?: string | string[];
  transaction_id?: string | null;
  created_at?: string | null;
  total_vat?: string | string[];
  sub_total?: string | string[];
  job_position?: string | null;
  logo?: string | null;
  company_name?: string | null;
  total_amount?: string | string[];
  transaction_type?: string;
  processing_fee?: string | string[];
  total_commission?: string | string[];
};

const TransactionPDF = (props: Props) => {
  return (
    <PDFDownloadLink
      document={<TransactionCV {...props} />}
      fileName={`Transaction.pdf`}
      className="cursor-pointer"
    >
      {({ blob, url, loading, error }) => props.renderer}
    </PDFDownloadLink>
  );
};

export default TransactionPDF;
