import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { FaqsContext } from '../../utilities/context';
import { FaqsData } from '../../utilities/types';
import AddingUsers from './pages/adding-users';
import CreateAccount from './pages/create-account';
import PostingJob from './pages/post-job';
import ProcessApp from './pages/process-app';
import FaqsView from './view';

type Params = {
  page: string;
};

const FaqsController = () => {
  const [view, setView] = useState<React.ReactNode | null>();
  const [pageType, setPageType] = useState<string>();
  const params: Params = useParams();

  const _getView = {
    'create-an-account': <CreateAccount />,
    'adding-users': <AddingUsers />,
    'posting-job': <PostingJob />,
    'process-app': <ProcessApp />,
  };

  useEffect(() => {
    if (params.page) {
      const view = _getView[params.page];
      setView(view);
      setPageType(params.page);
    }
  }, [params]);

  const value: FaqsData = {
    view,
    pageType,
  };

  return (
    <FaqsContext.Provider value={value}>
      <FaqsView />
    </FaqsContext.Provider>
  );
};

export default FaqsController;
