import { abbreviate } from "../../../helpers";
import { CVScrapperDataType } from "../../../utilities/types";
import { useEffect } from "react";


export const CVScrapperLayout = ({cvScrapperData }:{cvScrapperData:CVScrapperDataType;}) => {
    return  <div className="grid w-full grid-cols-3 border rounded" style={{ minHeight : 1000}}>
              <div className="flex flex-col items-center p-8 space-y-5 text-xs text-white bg-pj-orange-200">

                {cvScrapperData.details.profile_image ? 
                <img src={cvScrapperData.details.profile_image} alt="seeker_profile" className="object-cover w-40 h-40 rounded-full" /> : 
                <div className="flex items-center justify-center w-40 h-40 text-2xl text-gray-400 bg-gray-100 rounded-full">
                  {abbreviate(cvScrapperData.details.name)}
                </div>
              }
                <div className="flex flex-col w-full gap-3">
                  <p className="font-bold ">ABOUT ME</p>
                  <div className="border-t-2 border-white "/>
                  <div>
                  {cvScrapperData.about.mobile_number && <p>{ cvScrapperData.about.mobile_number}</p>}
                  {cvScrapperData.about.email && <p>{ cvScrapperData.about.email}</p>}
                  </div>
                  <div>{cvScrapperData.about.about_me}</div>
                </div>

                <div className="flex flex-col w-full gap-3">
                  <p className="font-bold ">EXPERIENCE LEVEL</p>
                  <div className="border-t-2 border-white "/>
                  <div>
                  <p>{ cvScrapperData.experience_level}</p>
                  </div>
                </div>

                <div className="flex flex-col w-full gap-3">
                  <p className="font-bold ">SKILLS</p>
                  <div className="border-t-2 border-white "/>
                  <div className="grid gap-2 ">
                    {cvScrapperData.skills.map((skill)=>(<div>
                      <p key={skill.id} className="inline-block px-3 py-1 font-semibold capitalize bg-white rounded-full text-pj-orange-200">{skill.name} </p>
                    </div>))}
                </div>
              </div>
              </div>

            <div className="flex flex-col w-full col-span-2 gap-4 p-8 text-pj-blue-300">
              <div className="flex flex-row justify-end ">
                <div className="flex flex-col gap-1">
                  <p className="text-xs ">Created with</p>
                  <img alt="pasajob_logo" src="/hires-pdf.jpg" className=" w-36"/>
                </div>
              </div>
              <p className="text-3xl font-bold">{cvScrapperData.details.name}</p>
              <p className="px-5 py-1 text-base font-semibold text-white rounded-full bg-pj-orange-200">{cvScrapperData.job_title} - {cvScrapperData.position}</p>
              <div className="flex flex-col w-full gap-5 pt-10">

                <div className="flex-col w-full">
                  <p className="font-semibold text-pj-orange-200">WORK EXPERIENCE</p>
                  {
                    cvScrapperData.work_experience.map((work_exp)=>(
                      <div className="flex flex-col w-full pt-2 mt-2 text-xs border-t">
                          <div className="flex flex-row items-center justify-between">
                            <p className="text-sm font-semibold uppercase">{work_exp.position_title}</p>
                            <p>{work_exp.joined_date}</p>
                          </div>
                          <div className="flex flex-row justify-between ">
                            <p>{work_exp.company_name}</p>
                            <p>{}</p>
                          </div>
                          <div className="py-2 mt-2 border-t "/>
                          <p className="">{work_exp.description}</p>
                      </div>
                    ))
                  }
                </div>

                <div className="flex-col w-full">
                  <p className="font-semibold text-pj-orange-200">EDUCATION</p>
                  {
                    cvScrapperData.education.map((edu)=>(
                      <div className="flex flex-col w-full gap-2 pt-2 mt-2 text-xs border-t">
                          <p className="text-sm font-semibold uppercase">{edu.institute_university}</p>
                          <div className="flex flex-row items-end justify-between">
                            <div className="flex flex-col gap-1">
                              {edu.qualification && <p>{edu.qualification}</p>}
                              <p>{edu.field_of_study}</p>
                            </div>
                            <div className="flex flex-col">
                              <p>{edu.graduate_date}</p>
                            </div>
                          </div>
                      </div>
                    ))
                  }
                </div>

                <div className="flex-col w-full">
                  <p className="font-semibold text-pj-orange-200">LICENSES AND CERTIFICATION</p>
                  {
                    cvScrapperData.licence_and_certification.map((landc)=>(
                      <div className="flex flex-row items-start justify-between pt-2 mt-2 border-t">
                        <p className="text-sm font-semibold uppercase">{landc.name}</p>
                        <p className=" whitespace-nowrap">{landc.year_attained}</p>
                      </div>
                    ))
                  }
                </div>

              </div>

            </div>
            </div>

}

//separate layout from PDF View
export const DownloadableCVScrapperLayout = ({cvScrapperData, targetRef, toPDF }:{cvScrapperData:CVScrapperDataType; targetRef?:any; toPDF:() => void}) => {
    useEffect(() => {
        const timer = setTimeout(() => {
            toPDF()
        }, 100);
        
        return () => clearTimeout(timer);
      }, [toPDF]);
    return  <div ref={targetRef || null} className="grid w-full grid-cols-3 border rounded" style={{ minHeight : 1000}}>
              <div className="flex flex-col items-center p-8 space-y-5 text-xs text-white bg-pj-orange-200">
             
                <div 
                className={`relative flex items-center justify-center w-40 h-40 text-2xl text-gray-400 bg-gray-100 rounded-full`} 
                style={!cvScrapperData.details.profile_image?{}:{
                    backgroundImage: `url(${cvScrapperData.details.profile_image})`, // Correct URL syntax
                    backgroundSize: 'cover', // Correct camelCase syntax
                    backgroundPosition: 'center', // Correct camelCase syntax
                    backgroundColor: '#f3f4f6', // Correct camelCase syntax
                    color: 'gray',
                    borderRadius: '50%',
                    overflow: 'hidden',
                    position: 'relative',
                  }}> 
                  <div className={`${cvScrapperData.details.profile_image ? "hidden":"absolute top-12"}`}>{abbreviate(cvScrapperData.details.name)}</div>
                </div>
                <div className="flex flex-col w-full gap-3">
                  <p className="font-bold ">ABOUT ME</p>
                  <div className="border-t-2 border-white "/>
                  <div>
                  {cvScrapperData.about.mobile_number && <p>{ cvScrapperData.about.mobile_number}</p>}
                  {cvScrapperData.about.email && <p>{ cvScrapperData.about.email}</p>}
                  </div>
                  <div>{cvScrapperData.about.about_me}</div>
                </div>

                <div className="flex flex-col w-full gap-3">
                  <p className="font-bold ">EXPERIENCE LEVEL</p>
                  <div className="border-t-2 border-white "/>
                  <div>
                  <p>{ cvScrapperData.experience_level}</p>
                  </div>
                </div>

                <div className="flex flex-col w-full gap-3">
                  <p className="font-bold ">SKILLS</p>
                  <div className="border-t-2 border-white "/>
                  <div className="grid gap-2 ">
                    {cvScrapperData.skills.map((skill)=>(<div>
                      <p key={skill.id} className="relative inline-block px-3 py-1 font-semibold capitalize bg-white rounded-full text-pj-orange-200">
                      <span className="invisible ">{skill.name}</span>
                      <div className="absolute left-0 right-0 text-center" style={{ top:-2}}>{skill.name}</div>
                      </p>
                    </div>))}
                </div>
              </div>
              </div>

            <div className="flex flex-col w-full col-span-2 gap-4 p-8 text-pj-blue-300">
              <div className="flex flex-row justify-end ">
                <div className="flex flex-col gap-1">
                  <p className="mb-2 -mt-2 text-xs">Created with</p>
                  <img alt="pasajob_logo" src="/hires-pdf.jpg" className=" w-36"/>
                </div>
              </div>
              <p className="text-3xl font-bold">{cvScrapperData.details.name}</p>
              <p className="relative px-5 py-1 text-base font-semibold text-white rounded-full bg-pj-orange-200">
                <span className="invisible ">{cvScrapperData.job_title} - {cvScrapperData.position}</span>
                <div className="absolute right-0 left-3 text-start" style={{ top:-4}}>{cvScrapperData.job_title} - {cvScrapperData.position}</div>
              </p>
              <div className="flex flex-col w-full gap-5 pt-10">

                <div className="flex-col w-full">
                  <p className="font-semibold text-pj-orange-200">WORK EXPERIENCE</p>
                  {
                    cvScrapperData.work_experience.map((work_exp)=>(
                      <div className="flex flex-col w-full pt-2 mt-2 text-xs border-t">
                          <div className="flex flex-row items-center justify-between">
                            <p className="text-sm font-semibold uppercase">{work_exp.position_title}</p>
                            <p>{work_exp.joined_date}</p>
                          </div>
                          <div className="flex flex-row justify-between ">
                            <p>{work_exp.company_name}</p>
                            <p>{}</p>
                          </div>
                          <div className="py-2 mt-2 border-t "/>
                          <p className="">{work_exp.description}</p>
                      </div>
                    ))
                  }
                </div>

                <div className="flex-col w-full">
                  <p className="font-semibold text-pj-orange-200">EDUCATION</p>
                  {
                    cvScrapperData.education.map((edu)=>(
                      <div className="flex flex-col w-full gap-2 pt-2 mt-2 text-xs border-t">
                          <p className="text-sm font-semibold uppercase">{edu.institute_university}</p>
                          <div className="flex flex-row items-end justify-between">
                            <div className="flex flex-col gap-1">
                              {edu.qualification && <p>{edu.qualification}</p>}
                              <p>{edu.field_of_study}</p>
                            </div>
                            <div className="flex flex-col">
                              <p>{edu.graduate_date}</p>
                            </div>
                          </div>
                      </div>
                    ))
                  }
                </div>


                <div className="flex-col w-full">
                  <p className="font-semibold text-pj-orange-200">LICENSES AND CERTIFICATION</p>
                  {
                    cvScrapperData.licence_and_certification.map((landc)=>(
                      <div className="flex flex-row items-start justify-between pt-2 mt-2 border-t">
                        <p className="text-sm font-semibold uppercase">{landc.name}</p>
                        <p className=" whitespace-nowrap">{landc.year_attained}</p>
                      </div>
                    ))
                  }
                </div>

              </div>

            </div>
            </div>

}