import { useEffect, useState } from 'react';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { useHistory, useParams } from 'react-router-dom';

type Props = {
  exit: boolean;
  step?: number;
  sub?: number;
};

type Params = {
  step: string;
  sub: string;
  job_id?: string;
};

const ButtonExit = ({ exit }: Props) => {
  const history = useHistory();
  const { step, sub }: Params = useParams();
  const params: Params = useParams();
  const [id, setId] = useState<number | unknown>();
  const actualStep = {
    1: 1,
    2: 17,
    3: 2,
  };

  let mainStep = parseInt(step);
  let subStep = parseInt(sub) - 1;
  if (subStep === 0) {
    mainStep = mainStep - 1;
    subStep = actualStep[mainStep];
  }

  useEffect(() => {
    if (params.job_id) {
      setId(parseInt(params.job_id));
    }
  }, [params]);

  const url =
    mainStep === 0
      ? '/dashboard'
      : `/create-job/${mainStep}/${subStep}/${id ?? ''}`;
  return (
    <div
      className={`${id?"hidden":"flex"} flex-row justify-center cursor-pointer text-base `}
      onClick={() => history.push(url)}
    >
      {!exit && (
        <div className="h-10 flex justify-center items-center">
          <RiArrowLeftSLine className="text-2xl text-gray-400" />
        </div>
      )}
      <div
        className={`h-10 flex justify-center items-center uppercase text-gray-400 ${
          exit && 'pl-3'
        }`}
      >
        {exit ? 'Exit' : 'Back'}
      </div>
    </div>
  );
};

ButtonExit.defaultProps = {
  exit: false,
};

export default ButtonExit;
