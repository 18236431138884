import moment from 'moment';
import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { auth } from '../../utilities/auth';
import { JobPostContext } from '../../utilities/context';
import { JobPostInitialState } from '../../utilities/states';
import {
  CommonJob,
  Company,
  JobPosts,
  JobType,
  Option,
  Skill,
  Sort,
  Traits,
} from '../../utilities/types';
import JobView from './view';
import { getCommonJobs, getCommonJobsCount, getJobPost } from '../../utilities/api_v2/job-posts';
import { getSubEmployers } from '../../utilities/api_v2/companies';

type Params = {
  status: string;
  id: string;
};

const JobsController = () => {
  const { handleSubmit, register } = useForm();
  const [show, setShow] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');
  const [loading, setIsLoading] = useState(false)
  const [jobsCount, setJobsCount] = useState(0)
  const params: Params = useParams();
  const history = useHistory();
  const [jobPosts, setJobPosts] = useState<JobPosts[]>([]);
  const [limit, setLimit] = useState(10);
  const [job, setJob] = useState<CommonJob>({ ...JobPostInitialState.job });
  const [id, setId] = useState<number>(0);
  const [sub_employers, setSubEmployers] = useState([]);
  const [sort, setSort] = useState<Option>({
    label: 'Most Recent',
    value: 'desc',
  });
  const [selectedCompany, setSelectedCompany] = useState<Option>({
    label: 'All Jobs',
    value: null,
  });

  //function to get status in params
  const getStatus = (status: any) => {
    const statuses = {
      active: 'Active',
      pending: 'Pending',
      archived: 'Filled Up',
    };
    return statuses[status];
  };

  //default value of where to use in querying data
  const defaultWhere = {
    AND: [
      {
        OR: [
          {
            employer_id: {
              in: sub_employers
            }
          },
          {
            employer_companies: {
              sub_employers_company: {
                some: {
                  sub_employer_id: auth.getId(),
                },
              },
            },
          },
        ],
      },
      {
        OR: [
          {
            title: {
              contains: searchText,
              mode: 'insensitive',
            },
          },
          {
            employer_companies: {
              name: {
                contains: searchText,
                mode: 'insensitive',
              },
            },
          },
        ],
      },
      {OR:[
        { status: { equals: getStatus(params.status) } },
        getStatus(params.status) ==="Filled Up" ? { status: { equals:"Closed"  } } : null
      ]}
    ],
  };

  const [where, setWhere] = useState<object>(defaultWhere);

  useEffect(() => {
    (async () => {
      console.log(0)
      const res:any = await getSubEmployers({id: auth.getId()})
      setSubEmployers(res.data)
    })();
  }, []);

  useEffect(() => {
      if(sub_employers){
        setWhere(defaultWhere)
      }
  }, [sub_employers]);

  //use to update where everytime searchText state and params change
  useEffect(() => {
    setWhere(defaultWhere);
    if (!params.id) {
      setShow(false);
    }
    if (!params.status) {
      history.push('/job-posts/active');
    }
  }, [searchText, params]);

  const getAllCommonJobs = async () => {
    (async () => {
      if(sub_employers.length){
        setIsLoading(true)
        let payload = {
          where: where,
          limit: limit,
          order: sort.value,
          date: moment().startOf('day').subtract(3, 'days').toDate(),
        };
        const result: any = await getCommonJobs(payload).then((data) => {
          return data
        });
        if (result.data) {
          const items: JobPosts[] = result.data.map(employer_company => {
            return {
              title: employer_company.title,
              id: employer_company.id,
              company_name: employer_company.employer_companies?.name,
              logo: employer_company.employer_companies?.company_image,
              newly: employer_company.unread,
            };
          });
          setJobPosts(items);
        }
        setIsLoading(false)
      }
    })();
  }

  // get data and set it to jobPosts state
  useEffect(() => {
    getAllCommonJobs()
  }, [where, limit, sort]);

  useEffect(() => {
    (async () => {
      let payload = {
        where: where,
      };
      const result: any = await getCommonJobsCount(payload);
      setJobsCount(result.data)
    })();
  }, [where]);

  useEffect(() => {
    if (params.id) {
      setJob({ ...JobPostInitialState.job });
      (async () => {
        let payload = {
          id: parseInt(params.id),
        };
        const job_details: any = await getJobPost(payload);
        let skills: Skill[] = [];
        let traits: Traits[] = [];
        if (job_details.data) {
          if (job_details.data.common_job_skills) {
            skills = job_details.data.common_job_skills.map(
              skillName => {
                return {
                  name: skillName.skill_name,
                  value: skillName.skill_name,
                };
              }
            );
          }
          if (job_details.data.employer_traits) {
            traits = job_details.data.employer_traits.map(
              ideal_qualities => {
                return {
                  name: ideal_qualities.trait,
                };
              }
            );
          }
          const data = job_details.data;
          let open = data?.openings?.referral_count;
          let filled = data?.filled?.count;
          let jobOpenings = open - filled;
          const job_info: CommonJob = {
            title: data?.title,
            address: data?.address,
            description: data?.description,
            city_id: data?.city_id,
            company_detail_id: data?.company_detail_id,
            employer_id: auth.getId(),
            home_based: data?.home_based,
            industry_id: data?.industry_id,
            job_type_id: data?.job_type_id,
            province_id: data?.province_id,
            status: data?.status,
            visible: data?.visible,
            referral_count: data?.common_orders?.[0]?.referral_count,
            referral_fee: data?.common_orders?.[0]?.referral_fee,
            disbursement_type: data?.common_orders?.[0]?.disbursement_type,
            job_employment_type:
              data?.common_job_information?.[0]?.job_employment_type,
            job_experience: data?.common_job_information?.[0]?.job_experience,
            job_position_level:
              data?.common_job_information?.[0]?.job_position_level,
            maximum_salary: data?.common_job_information?.[0]?.maximum_salary,
            minimum_salary: data?.common_job_information?.[0]?.minimum_salary,
            minimum_qualification:
              data?.common_job_information?.[0]?.minimum_qualification,
            show_salary: data?.common_job_information?.[0]?.show_salary,
            applied: data?.applicants?.count,
            processed: data?.processed?.count,
            filled: data?.filled?.count,
            opening: jobOpenings,
            posted: data?.created_at,
            company_name: data?.employer_companies?.name,
            company_logo: data?.employer_companies?.company_image,
            skills,
            traits,
          };
          setJob(job_info);
        }
      })();
      setId(parseInt(params.id));
      setShow(true);
    }
  }, [params]);


  const _handleRefresh = () => {
    getAllCommonJobs()
  };

  // useEffect(() => {
  //   setLimit(10);
  // }, [where]);

  const _handleSearch = (data: any) => {
    //trigger state of searchText to update where state
    setSearchText(data.search);
  };

  const _handleSort = (option: any) => {
    setSort(option);
  };

  const _handleSelect = (option: any) => {
    setSelectedCompany(option);
    if (option.value === null) {
      setWhere(defaultWhere);
    } else {
      if (option.value === 'new applicants') {
        setWhere({
          AND: [
            {
              common_job_applications: {
                some: {
                  is_viewed: false,
                },
              },
            },
            ...defaultWhere.AND,
          ],
        });
      } else {
        setWhere({
          AND: [
            {
              visible: { equals: option.value },
            },
            ...defaultWhere.AND,
          ],
        });
      }
    }
  };

  // useEffect(() => {
  //   if (selectedCompany) {
  //     setWhere({
  //       ...where,
  //       visible: { _eq: selectedCompany.value },
  //       status: { _eq: getStatus(params.status) },
  //     });
  //   }
  // }, [selectedCompany]);

  const _clickHandler = (id: number) => {
    history.push(`/job-posts/${params.status}/${id}`);
    setShow(false);
    setTimeout(() => {
      setShow(true);
    }, 300);
  };

  const addTenToLimit = () => {
    setLimit(prev => prev + 10);
  };

  const value: JobType = {
    job,
    id,
    show,
    jobPosts,
    status: params.status,
    selectedCompany,
    sort,
    loading,
    register,
    handleSubmit,
    _clickHandler,
    _handleSearch,
    _handleSelect,
    _handleSort,
    _handleRefresh,
    limit,
    addTenToLimit,
    jobsCount,
  };

  return (
    <JobPostContext.Provider value={value}>
      <JobView />
    </JobPostContext.Provider>
  );
};

export default JobsController;
