import { create } from "zustand"
import { defaultApplicantToShowPerBin } from "../utilities/board-helper";

type LoadMoreStatus =  {
    take :number;
    isLoading: boolean;
};

const loadMoreByStatusDefault = {
    take:defaultApplicantToShowPerBin,
    isLoading:false
}
const defaultData = {
    "Rejected":loadMoreByStatusDefault,
    "Applied":loadMoreByStatusDefault,
    "Shortlisted":loadMoreByStatusDefault,
    "For Interview":loadMoreByStatusDefault,
    "For Hire":loadMoreByStatusDefault,
    "Hired":loadMoreByStatusDefault,
}

type State = {
    loadMoreByStatus : {
        "Rejected": LoadMoreStatus;
        "Applied": LoadMoreStatus;
        "Shortlisted": LoadMoreStatus;
        "For Interview": LoadMoreStatus;
        "For Hire": LoadMoreStatus;
        "Hired": LoadMoreStatus;
    };
}

type Actions = {
    setLoadMoreByStatus : ({status, take}:{status : 'Rejected' | 'Applied' | 'Shortlisted' | 'For Interview' | 'For Hire' | 'Hired'; take:number;}) => void;
    setLoadMoreIsLoading : ({status, isLoading}:{status : 'Rejected' | 'Applied' | 'Shortlisted' | 'For Interview' | 'For Hire' | 'Hired', isLoading:boolean;}) => void;
    reset: ()=> void
}


export const useBoardLoadMoreStore = create<State & Actions>((set, get) => ({

    loadMoreByStatus : defaultData,
    setLoadMoreByStatus : ({status, take}) => {
        const loadMoreByStatus = get().loadMoreByStatus
        set({
            loadMoreByStatus:{ ...loadMoreByStatus, [status]:{ isLoading:false, take}}
        })
    },
    setLoadMoreIsLoading : ({status, isLoading}:{status: 'Rejected' | 'Applied' | 'Shortlisted' | 'For Interview' | 'For Hire' | 'Hired', isLoading:boolean}) => {
        const loadMoreByStatus = get().loadMoreByStatus
        set({
            loadMoreByStatus:{ 
                ...loadMoreByStatus, 
                [status]:{ 
                    ...loadMoreByStatus[status], 
                    isLoading
                }
            }
        })
    },
    reset : () => {
        const data = {
            "Rejected":loadMoreByStatusDefault,
            "Applied":loadMoreByStatusDefault,
            "Shortlisted":loadMoreByStatusDefault,
            "For Interview":loadMoreByStatusDefault,
            "For Hire":loadMoreByStatusDefault,
            "Hired":loadMoreByStatusDefault,
        }
        set({
            loadMoreByStatus:data
        })
    }
}))