import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
  Font,
} from '@react-pdf/renderer';
import moment from 'moment';
import _ from 'lodash';
import {
  SeekerEducation,
  SeekerExperience,
  SeekerJobExperience,
  SeekerSkill,
} from '../utilities/types';
import RubikLight from '../fonts/Rubik-Light.ttf';
import RubikMedium from '../fonts/Rubik-Medium.ttf';

Font.register({
  family: 'Rubik',
  fonts: [
    {
      src: RubikLight,
      fontWeight: 500,
    },
    {
      src: RubikMedium,
      fontWeight: 700,
    },
  ],
});

type Props = {
  name: string;
  email: string;
  mobile_number: string;
  experience: SeekerExperience;
  job_experiences: SeekerJobExperience[];
  job_title: string;
  position: string;
  skills: SeekerSkill[];
  education: SeekerEducation[];
};

const CVDownload = ({
  name,
  email,
  mobile_number,
  experience,
  job_experiences,
  job_title,
  position,
  skills,
  education,
}: Props) => {
  console.log(experience, 'test');
  const getInitials = string => {
    if (string) {
      return string.match(/\b\w/g).join('').toUpperCase();
    }
    return null;
  };
  return (
    <Document>
      <Page size="A4" style={styles.page} wrap>
        <View>
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                width: '30%',
                height: '100%',
                backgroundColor: '#21CCAB',
                paddingTop: 50,
              }}
            >
              <View style={{ justifyContent: 'center', alignItems: 'center' }}>
                <View
                  style={{
                    width: 80,
                    height: 80,
                    backgroundColor: 'rgb(30, 183, 153)',
                    border: 'solid',
                    borderWidth: 1,
                    borderColor: 'white',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: 40,
                  }}
                >
                  <Text
                    style={{
                      color: 'white',
                      fontSize: 18,
                      fontFamily: 'Rubik',
                    }}
                  >
                    {getInitials(name)}
                  </Text>
                </View>
              </View>
              <View style={{ paddingHorizontal: 20, marginTop: 30 }}>
                <View
                  style={{
                    paddingBottom: 5,
                    marginBottom: 5,
                    borderBottom: 'solid',
                    borderBottomWidth: 1,
                    borderBottomColor: 'white',
                  }}
                >
                  <Text
                    style={{
                      textTransform: 'uppercase',
                      color: 'white',
                      fontSize: 10,
                      fontFamily: 'Rubik',
                      fontWeight: 700,
                    }}
                  >
                    About Me
                  </Text>
                </View>
                <View style={{ marginBottom: 5 }}>
                  <Text
                    style={{
                      textTransform: 'uppercase',
                      color: 'white',
                      fontSize: 10,
                      fontFamily: 'Rubik',
                    }}
                  >
                    {mobile_number}
                  </Text>
                </View>
                <View style={{ marginBottom: 5 }}>
                  <Text
                    style={{
                      textTransform: 'lowercase',
                      color: 'white',
                      fontSize: 10,
                      fontFamily: 'Rubik',
                    }}
                  >
                    {email ?? ''}
                  </Text>
                </View>
                <View
                  style={{
                    marginTop: 40,
                    paddingBottom: 5,
                    marginBottom: 5,
                    borderBottom: 'solid',
                    borderBottomWidth: 1,
                    borderBottomColor: 'white',
                  }}
                >
                  <Text
                    style={{
                      textTransform: 'uppercase',
                      color: 'white',
                      fontSize: 10,
                      fontFamily: 'Rubik',
                      fontWeight: 700,
                    }}
                  >
                    Experience Level
                  </Text>
                </View>
                <View style={{ marginBottom: 5 }}>
                  <Text
                    style={{
                      color: 'white',
                      fontSize: 10,
                      fontFamily: 'Rubik',
                    }}
                  >
                    {experience?.is_since_year
                      ? `Working Since ${moment(
                          experience.since_year,
                          'YYYY-MM-DD'
                        ).format('MMM YYYY')}`
                      : `${experience?.experience_level ?? ''} ${
                          experience?.since_year &&
                          moment(experience?.since_year).format('MMM YYYY')
                        }`}
                  </Text>
                </View>
                <View
                  style={{
                    marginTop: 40,
                    paddingBottom: 5,
                    marginBottom: 5,
                  }}
                >
                  <Text
                    style={{
                      textTransform: 'uppercase',
                      color: 'white',
                      fontSize: 10,
                      fontFamily: 'Rubik',
                      fontWeight: 700,
                    }}
                  >
                    Skills
                  </Text>
                </View>
                {skills?.length > 0 &&
                  _.orderBy(skills, 'name', 'desc')?.map(skill => {
                    return (
                      <View
                        style={{
                          marginBottom: 5,
                        }}
                      >
                        <View
                          style={{
                            paddingVertical: 5,
                            paddingHorizontal: 6,
                            backgroundColor: 'white',
                            borderRadius: 20,
                            alignSelf: 'flex-start',
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 11,
                              color: '#21CCAB',
                              textTransform: 'capitalize',
                              fontFamily: 'Rubik',
                              fontWeight: 700,
                            }}
                          >{`${skill.name}`}</Text>
                        </View>
                      </View>
                    );
                  })}
                {/* <View style={{ marginTop: 50 }}>
									<View style={{ marginBottom: 10 }}>
										<Text style={{ fontSize: 11, color: "#ffffff" }}>
											Created with
										</Text>
									</View>
									<Image
										src={require("../../../assets/pdf-green.jpg")}
										style={{ width: 100 }}
									/>
								</View> */}
              </View>
            </View>
            <View
              style={{
                paddingHorizontal: 20,
                width: '70%',
              }}
            >
              <View style={{ width: '100%', height: 30 }} fixed></View>
              <View
                style={{
                  flexDirection: 'row',
                  justifyContent: 'flex-end',
                  marginTop: 20,
                }}
              >
                <View style={{ flex: 2 }}></View>
                <View style={{ flex: 1 }}>
                  <Text
                    style={{
                      fontSize: 9,
                      color: '#677F91',
                      fontFamily: 'Rubik',
                    }}
                  >
                    Created with
                  </Text>
                  <View style={{ marginVertical: 5 }}>
                    <Text style={{
                      color: '#677F91',
                      fontFamily: 'Rubik',
                      letterSpacing: '5px',
                      fontWeight: 'bold'
                    }}>
                      PASAJOB
                    </Text>
                  </View>
                </View>
              </View>
              <View style={{ marginTop: 20 }}>
                <Text
                  style={{
                    fontSize: 22,
                    color: '#3C5364',
                    textTransform: 'capitalize',
                    fontFamily: 'Rubik',
                  }}
                >{`${name ?? ''}`}</Text>
              </View>
              <View
                style={{
                  backgroundColor: '#21CCAB',
                  marginVertical: 10,
                  paddingHorizontal: 10,
                  paddingVertical: 5,
                  borderRadius: 10,
                  marginBottom: 40,
                }}
              >
                <Text
                  style={{
                    fontSize: 11,
                    color: 'white',
                    textTransform: 'uppercase',
                    fontFamily: 'Rubik',
                    fontWeight: 700,
                  }}
                >{`${job_title} ${position}`}</Text>
              </View>
              <View
                style={{
                  paddingBottom: 10,
                }}
              >
                <Text
                  style={{
                    color: '#21CCAB',
                    fontSize: 10,
                    textTransform: 'uppercase',
                    fontFamily: 'Rubik',
                    fontWeight: 700,
                  }}
                >
                  Work Experience
                </Text>
              </View>
              {job_experiences.map(job => {
                return (
                  <>
                    <View
                      style={{
                        flexDirection: 'row',
                        paddingVertical: 10,
                        borderTop: 'solid',
                        borderTopWidth: 1,
                        borderTopColor: '#DCDCDC',
                      }}
                    >
                      <View style={{ flex: 1, justifyContent: 'center' }}>
                        <Text
                          style={{
                            fontSize: 14,
                            color: '#3C5364',
                            textTransform: 'capitalize',
                            fontFamily: 'Rubik',
                          }}
                        >
                          {job.position_title}
                        </Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          justifyContent: 'center',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 11,
                            color: '#677F91',
                            marginBottom: 5,
                            fontFamily: 'Rubik',
                          }}
                        >
                          {job.position_level}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        paddingBottom: 5,
                      }}
                    >
                      <View style={{ flex: 1, justifyContent: 'center' }}>
                        <Text
                          style={{
                            fontSize: 11,
                            color: '#677F91',
                            marginBottom: 5,
                            textTransform: 'capitalize',
                            fontFamily: 'Rubik',
                          }}
                        >
                          {job.company_name}
                        </Text>
                      </View>
                      <View
                        style={{
                          flex: 1,
                          justifyContent: 'center',
                          alignItems: 'flex-end',
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 11,
                            color: '#677F91',
                            marginBottom: 5,
                            fontFamily: 'Rubik',
                          }}
                        >
                          {`${moment(job.joined_date_from).format(
                            'MMM YYYY'
                          )} - ${
                            job.is_present
                              ? 'Present'
                              : moment(job.joined_date_to).format('MMM YYYY')
                          }`}
                        </Text>
                      </View>
                    </View>
                    <View
                      style={{
                        paddingVertical: 10,
                        paddingBottom: 20,
                        borderTop: 'solid',
                        borderTopWidth: 1,
                        borderTopColor: '#DCDCDC',
                        marginBottom: 20,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 11,
                          color: '#3C5364',
                          lineHeight: 2,
                          fontFamily: 'Rubik',
                        }}
                      >
                        {job.experience_description}
                      </Text>
                    </View>
                  </>
                );
              })}
              <View
                style={{
                  paddingVertical: 10,
                }}
              >
                <Text
                  style={{
                    color: '#21CCAB',
                    fontSize: 10,
                    textTransform: 'uppercase',
                    fontFamily: 'Rubik',
                    fontWeight: 700,
                  }}
                >
                  Education
                </Text>
              </View>
              {education.map(edu => {
                return (
                  <>
                    <View
                      style={{
                        paddingTop: 10,
                        borderTop: 'solid',
                        borderTopWidth: 1,
                        borderTopColor: '#DCDCDC',
                        marginBottom: 5,
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 14,
                          color: '#3C5364',
                          fontFamily: 'Rubik',
                        }}
                      >{`${edu.institute_university}`}</Text>
                    </View>
                    <View style={{ marginBottom: 5 }}>
                      <Text
                        style={{
                          fontSize: 11,
                          color: '#677F91',
                          fontFamily: 'Rubik',
                        }}
                      >{`${edu.major}`}</Text>
                    </View>
                    <View
                      style={{
                        flexDirection: 'row',
                        paddingBottom: 20,
                      }}
                    >
                      <View style={{ flex: 1 }}>
                        <Text
                          style={{
                            fontSize: 11,
                            color: '#677F91',
                            fontFamily: 'Rubik',
                          }}
                        >{`${edu.field_of_study}`}</Text>
                      </View>
                      <View style={{ flex: 1, alignItems: 'flex-end' }}>
                        <Text
                          style={{
                            fontSize: 11,
                            color: '#677F91',
                            textAlign: 'right',
                          }}
                        >
                          {`Graduated ${moment(edu.graduate_date).format(
                            'YYYY'
                          )}`}
                        </Text>
                      </View>
                    </View>
                  </>
                );
              })}
              <View style={{ width: '100%', height: 60 }} fixed></View>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

// Create styles
const styles = StyleSheet.create({
  page: {
    backgroundColor: '#ffffff',
  },
  info: {
    paddingLeft: 20,
    marginBottom: 10,
  },
  child: {},
  heading: {
    fontSize: 12,
  },
  section: {
    margin: 10,
  },
  headerText: {
    fontSize: 16,
    marginBottom: 20,
  },
});

export default CVDownload;
