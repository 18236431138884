import { abbreviate } from '../helpers';

type Props = {
  name: string;
  url: string | null | undefined;
  size: number;
  height?: number;
  width?: number;
};

const Avatar = ({ name, url, size, height, width }: Props) => {
  return url ? (
    <div
      className={`border border-gray-200 bg-center bg-cover bg-gray-100 ${!height && 'rounded-full'
        }`}
      style={{
        width: width ? width : size,
        height: height ? height : size,
        backgroundImage: `url("${url}")`,
      }}
    ></div>
  ) : (
    <div
      className="rounded-full border border-gray-200 bg-center bg-cover bg-gray-100 flex justify-center items-center text-sm text-pj-blue-100"
      style={{ width: size, height: size }}
    >
      {abbreviate(name)}
    </div>
  );
};

export default Avatar;
