import axios from "axios";
import { SetterOrUpdater } from "recoil";
import { auth } from "./auth";

    export const CHECK_IF_CONTAINS_LINK = (value:string|null) => {
        const urlPattern = /(https?:\/\/[^\s]+)|([^\s]+\.[^\s]+)/g;
        let flag = false

        if (value && urlPattern.test(value)) {
            flag = true
        }

        return flag
    };

    export const getInitials = (str: string) => {
        return str.split(' ')
            .map(word => word.charAt(0))
            .join('.');
    }
    export const statusFixer = (status:string) => {
        if(status === 'Shortlisted'){
            return 'PreScreened'
        }
        else if(status === 'For Hire'){
            return 'Shortlisted'
        }
        else {
            return status
        }
    }

export const uploadFile = async(fileInput:any) =>{
    var formdata = new FormData();
    formdata.append("file", fileInput, fileInput.name);

    const api = axios.create({
        baseURL: `https://admin-web-vert.vercel.app/api/`,
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });

    const response =  await api.post("upload", formdata)

    if(response.status === 200){
        return {
            success: true,
            url: response.data.url,
        }
    }
    else {
        return {
            success: false,
        }
    }
}

export const boardLabelFixer = (label:string) =>{
    switch(label){
        case "Rejected":
            return "rejected";
        case "Applied":
            return "applied";
        case "Shortlisted":
            return "pre_screened";
        case "For Interview":
            return "for_interview";
        case "For Hire":
            return "shortlisted";
        case "Hired":
            return "hired";
    }
}

export const updateBoardRecoilInfo = (ids: number[], status: string, sourceLane: string, data: any, boardInfo: any, setterBoardInfo: SetterOrUpdater<any>) => {
    const sourceFixed = boardLabelFixer(sourceLane)
    const targetFixed = boardLabelFixer(status)
    const newData = boardInfo[sourceFixed].filter((data) => !ids.includes(data.id))
    setterBoardInfo(prev => {
        if(status === sourceLane){
            return {
              ...prev,
              [targetFixed]: [ ...data,...prev[targetFixed].filter((data) => !ids.includes(data.id))],
            }
        }
      return {
        ...prev,
        [targetFixed]: [ ...data,...prev[targetFixed]],
        [sourceFixed]: newData,
        counts:{
          ...prev.counts,
          [status]: prev.counts[status] + ids.length,
          [sourceLane]: prev.counts[sourceLane] - ids.length,
        }
      }
    })
  }


export const recoilSetter = (setterVariable: SetterOrUpdater<any>, value: object) => {
    setterVariable(prev => {
        return {
            ...prev,
            ...value
        }
    })
}
export const teamID = [1357, 5638, 5524]

export const canHaveMinimumRF = () => {
    const userId = auth.getUserData().user.id
    console.log(userId)
    return teamID.includes(userId)
}

export const isOnTeam = () => {
    return true
    const userId = auth.getUserData().user.id
    return teamID.includes(userId)
}

export const showDownloadCVsToTeam = (userId:number) => {
    return teamID.includes(userId)
}

//[joeanne@pasajob.com , jobs@pasajob.com] accounts
export const recruitersId = [ 5638, 8364]

export const isRecruiters = () => {
    const userId = auth.getUserData().user.id

    return recruitersId.includes(userId)
}

export const removeAgingOnCertainAccounts = () => {
    //[joeanne@pasajob.com , jobs@pasajob.com] accounts
    const ids = [5638, 8364]
    const userId = auth.getUserData().user.id
    return !ids.includes(userId)
}

export const employerToShowDownloadCVApplicant = [2292, 2123]

export const industryHelper = (props: any) => {
    if(props?.profile_industry){
        return props?.profile_industry
    }
    else if(props?.job_experiences.length){
        return props?.job_experiences?.[0]?.common_industries?.industry_name 
    }
    else{
        return 'No data available'
    }
}

export const specializationHelper = (props: any) => {
    if(props?.profile_specialization){
        return props?.profile_specialization
    }
    else if(props?.job_experiences.length){
        return props?.job_experiences?.[0]?.specialization
    }
    else{
        return 'No data available'
    }
}