import { employer_api, employer_api_v2 } from "./main";

//queries -->

export const getCompanies = async (data: any) => {
    let response = {};

    await employer_api.post('getCompanies', data)
        .then(res => {
            console.log(res, 'res');
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
};

export const getCompaniesCount = async (data: any) => {
    let response = {};

    await employer_api.post('getCompaniesCount', data)
        .then(res => {
            console.log(res, 'res');
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
};

export const getSubEmployers = async (data: any) => {
    let response = {};

    await employer_api.post('getSubEmployers', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
}

export const getCompany = async (data: any) => {
    let response = {};
    await employer_api.post('getCompany', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
}

export const getEmployees = async (data: any) => {
    let response = {};

    await employer_api_v2.post('companies/getEmployees',data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
}

export const getCompaniesSubEmployers = async (data: any) => {
    let response = {};

    await employer_api_v2.post('companies/getCompaniesSubEmployers', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
}

export const getEmployerHR = async (data: any) => {
    let response = {};

    await employer_api_v2.post('companies/getEmployerHR', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
}

export const getAPIKey = async (data: any) => {
    let response = {};

    await employer_api_v2.post('companies/getAPIKey', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
}

export const generateApiKey = async (data: any) => {
    let response = {};
    await employer_api_v2.post('companies/generateApiKey', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
}

export const getCompanyFiles = async (data: any) => {
    let response = {};

    await employer_api_v2.post('companies/getCompanyFiles', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
}

//mutations -->

export const createCompany = async (data: any) => {
    let response = {};

    await employer_api_v2.post('companies/createCompany', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
}

export const updateCompany = async (data: any) => {
    let response = {};

    await employer_api_v2.post('companies/updateCompany', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
}

export const updateSubEmployers = async (data: any) => {
    let response = {};

    await employer_api_v2.post('companies/updateSubEmployers', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
}

export const removeEmployee = async (data: any) => {
    let response = {};

    await employer_api_v2.post('companies/removeEmployee', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
}

export const insertSubEmployerCompany = async (data: any) => {
    let response = {};

    await employer_api_v2.post('companies/insertSubEmployerCompany', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
}

export const insertCompanyFiles = async (data: any) => {
    let response = {};

    await employer_api_v2.post('companies/insertCompanyFiles', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
}

export const companiesCount = async (data: any) => {
    let response = {};

    await employer_api_v2.post('companies/companiesCount', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
}

