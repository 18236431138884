import { employer_api } from "./main";

export const getBoardInformation = async (data: any) => {
    let response = {};

    await employer_api.post('getBoardInformation2', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
};

export const loadMoreCommonJobApplicationByStatus = async (data:{id:number; status : 'Rejected' | 'Applied' | 'Shortlisted' | 'For Interview' | 'For Hire' | 'Hired', take:number}) => {

    return await employer_api.post('loadMoreCommonJobApplicationByStatus' , data)
        .then(res => {
            return res?.data;
        })
        .catch(err => {
            throw err
        });

};

export const getJobInformation = async (data: any) => {
    let response = {};

    await employer_api.post('getJobInformation', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
};

export const getJobDetails = async (data: any) => {
    let response = {};

    await employer_api.post('getJobDetails', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
};

export const getJobs = async (data: any) => {
    let response = {};

    await employer_api.post('getJobs', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
};

export const getJobsCount = async (data: any) => {
    let response = {};
  
    await employer_api
      .post('getJobsCount', data)
      .then(res => {
        console.log(res, 'res');
        response = res?.data;
      })
      .catch(err => {
        response = err?.response?.data;
      });
  
    return response;
  };  

export const getApplicationsByJobId = async (data: any) => {
    let response = {};

    await employer_api.post('getApplicationsByJobId', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
};

export const getSuggestedCandidates2 = async (data: any) => {
    let response = {};

    await employer_api.post('getSuggestedCandidate', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
};


export const updateManyJobApplications = async (data: any) => {
    let response = {};

    await employer_api.post('updateManyJobApplications', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
};

export const createInterview = async (data: any) => {
    let response = {};

    await employer_api.post('createInterview', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
};


