import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { api } from '../../utilities/api';
import { auth } from '../../utilities/auth';
import { PageContext } from '../../utilities/context';
import OTPView from './view';

type ForgotPassword = {
  otp: string;
};

const OTPController = () => {
  const history = useHistory();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<ForgotPassword>();

  const _submitHandler = async (data: object) => {
    const result: any = await api.sendOTP(data);
    if (result.data.data.verify_otp.activated) {
      auth.storeToken(result.data.data.verify_otp.token);
      window.location.reload();
    }
  };

  const value: object = { control, errors, handleSubmit, _submitHandler };
  return (
    <PageContext.Provider value={value}>
      <OTPView />
    </PageContext.Provider>
  );
};

export default OTPController;
