import ProfilePageView from "./view";
import { ProfilePageContext } from "../../utilities/context";
import ProfilePage from "./pages/profile";
import ResetPassword from "./pages/reset-password";
import { OnClickFunction, ProfileData, ProfileInfo } from "../../utilities/types";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import { auth } from "../../utilities/auth";
import { useForm } from 'react-hook-form';
import FaqsView from "../faqs/view";
import { file_upload } from '../../utilities/api';
import Resizer from 'react-image-file-resizer';
import { changePassword } from "../../utilities/api_v2/authentication";
import { getEmployeeName } from "../../utilities/api_v2/dashboard";
import { editProfile as editProfile2 } from "../../utilities/api_v2/profile_page";
import { uploadFile } from "../../utilities/helper";

type Params = {
  page: string | undefined;
};

const ProfilePageController = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    setValue,
  } = useForm<any>();
  const [view, setView] = useState<React.ReactNode | null>();
  const [confirm, setConfirm] = useState<boolean>(false)
  const [confirmProfile, setConfirmProfile] = useState<boolean>(false)
  const [pageType, setPageType] = useState<string>();
  const [info, setInfo] = useState<ProfileInfo>();
  const [userData, setUserData] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  const getUser = async ({id}:{id:number}) => {
    setLoading(true)
    return await getEmployeeName({
      id,
    }).then((data:any)=>{
      const name = data?.data;
      const profile_image = name.employer_profile?.profile_image
      setUserData(name);
      profile_image && setFile(profile_image)
    }).finally(()=>{
      setLoading(false)
    })
  }

  const _editProfile = async ({id,common_user,employer_profile}:any) => {
    return await editProfile2({id,common_user,employer_profile})
  }

  const params: Params = useParams();

  const _getView = {
    faqs: <FaqsView />,
    profile: <ProfilePage />,
    'change-password': <ResetPassword />,
  }

  useEffect(() => {
    if (params.page) {
      const view = _getView[params.page]
      setView(view);
      setPageType(params.page);
      getUser({
          id: auth.getId(),
        });
    }
  }, [params]);
  
  useEffect(() => {
    if(userData){
      const temp: ProfileInfo = {
        id: userData.id,
        first_name: userData?.first_name,
        last_name: userData?.last_name,
        position: userData?.employer_profile?.position,
        company: userData?.employer_companies[0]?.name,
        contact_number: userData?.mobile_number,
        email: userData?.email,
        profile: userData?.employer_profile?.profile_image,
      }
      setInfo(temp);
      Object.keys(temp).map(k => setValue(k, temp[k]));
    }
  }, [userData]);

  const resizeFile = async (file: any) =>
  new Promise(resolve => {
    Resizer.imageFileResizer(
      file,
      300,
      300,
      'JPEG',
      50,
      0,
      uri => {
        resolve(uri);
      },
      'base64'
    );
  }).then(res => {
    return res;
  });

  const [file, setFile] = useState<any>(null);

  const _submitForm: OnClickFunction = async (data) => {
    delete data.current_password;
    delete data.password;
    delete data.confirm_password;
    delete data.company;

    if (file) {
      const url: any = await uploadFile(data.profile);
      if (!url.success) {
        return;
      }
      data = { ...data, profile: url.url };
    }
    
    if(data){
      // editProfile({
      //   variables: {
      //     id: auth.getId(),
      //     common_user:{
      //       first_name: data.first_name,
      //       last_name: data.last_name,
      //       mobile_number: data.contact_number,
      //       email: data.email,
      //     },
      //     employer_profile:{
      //       position: data.position,
      //       profile_image: data.profile,
      //     },
      //   },
      // });
      _editProfile({
        id: auth.getId(),
        common_user:{
          first_name: data.first_name,
          last_name: data.last_name,
          mobile_number: data.contact_number,
          email: data.email,
        },
        employer_profile:{
          position: data.position,
          profile_image: data.profile,
        },
      })
    }
  }

  const _resetPassword: OnClickFunction = async (data) => {
    delete data.first_name;
    delete data.last_name;
    delete data.position;
    delete data.company;
    delete data.contact_number;
    delete data.email;

    const token = auth.getToken();

    let payload = {
      token,
      current_password: data.current_password,
      new_password: data.new_password,
    }

    let result:any = await changePassword(payload);

    if(result?.data.errors){
      setError('current_password', {
        type: 'focus',
        message: 'Old password is incorrect',
      });
    } else {
      setConfirm(true);
    }
  }

  const clearError = () => {
    clearErrors && clearErrors();
  };

  const cancelChangePass = () => {
    clearError();
    const clear = {
      current_password: null,
      new_password: null,
      confirm_password: null,
    }
    Object.keys(clear).map(k => setValue && setValue(k, ''));
  }

  const confirmButton:OnClickFunction = () => {
    getUser({
        id: auth.getId(),
    });
    clearError();
    const clear = {
      current_password: null,
      new_password: null,
      confirm_password: null,
    }
    Object.keys(clear).map(k => setValue && setValue(k, ''));
    setConfirm(false)
  }

  const confirmButtonProfile:OnClickFunction = () => {
    getUser({
        id: auth.getId(),
    });
    setConfirmProfile(false);
  }

  const _beforeUpload = async (e: any) => {
    setValue('profile', e.target.files[0]);
    const result = await resizeFile(e.target.files[0]);
    setFile(result);
  };

  const value: ProfileData = {
    view,
    file,
    pageType,
    control,
    handleSubmit,
    errors,
    watch,
    setError,
    clearErrors,
    setValue,
    _submitForm,
    loading,
    info,
    _resetPassword,
    clearError,
    cancelChangePass,
    confirm,
    confirmButton,
    _beforeUpload,
    confirmButtonProfile,
    confirmProfile,
  }
  return (
  <ProfilePageContext.Provider value={value}>
    <ProfilePageView />
  </ProfilePageContext.Provider>
  )
}

export default ProfilePageController;