import axios from 'axios';
import CryptoJS from 'crypto-js';
import { auth } from './auth';
import mutations from './mutations';
import { print } from 'graphql';
import { CompanyData } from './types';
import queries from './queries';

export const encryptData = val => {
  return CryptoJS.AES.encrypt(val, '@m&#oJECePgX&tbr!tc@unsn8*A6b3').toString();
};

const axios_api = axios.create({
  // baseURL: 'https://auth.gjobs.pasajob.com/employer/',
  baseURL: 'https://auth.api.pasajob.com/employer/',
  headers: {
    Authorization:'Basic Z2Nhc2hhcHA6eXBlbVlzUWg4WlNmN2RPZ053TFhEaW4zYk1RSTFDTnU=',
    "Content-Type": "application/json",
  },
});

export const api = {
  async sendOTP(code: object) {
    const fingerprint = await auth.fingerprint();
    const id = auth.getId();
    const data = auth.encrypt({
      ...code,
      user_type: 'employer_id',
      device_name: fingerprint,
      installation_id: fingerprint,
      id,
    });
    return axios
      .post(`${process.env.REACT_APP_API_URL}`, {
        query: print(mutations.VERIFY_OTP),
        variables: {
          data,
        },
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log(err);
      });
  },
};

export const file_upload = async (data: any) => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_AUTH_SERVICE,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const payload = new FormData();
  payload.append('file', data);

  const response = await api
    .post('upload', payload)
    .then(res => {
      if (res.status === 200) {
        return {
          success: true,
          source: res.data,
        };
      }
    })
    .catch(err => {
      return {
        success: false,
      };
    });
  return response;
};

export const file_upload_new = async (data: any) => {
  const api = axios.create({
    baseURL: process.env.REACT_APP_AUTH_SERVICE,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const payload = new FormData();
  payload.append('file', data);

  const response = await api
    .post('upload-new', payload)
    .then(res => {
      if (res.status === 200) {
        return {
          success: true,
          source: res.data,
        };
      }
    })
    .catch(err => {
      return {
        success: false,
      };
    });
  return response;
};

export const cloudinary = async (data: any) => {
  const api = axios.create({
    baseURL: `https://api.cloudinary.com/v1_1/smartparkme/`,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  const payload = new FormData();
  payload.append('file', data);
  payload.append('upload_preset', 'pasajob-employer');

  const response = await api
    .post('upload', payload)
    .then(res => {
      if (res.status === 200) {
        return {
          success: true,
          source: res.data,
        };
      }
    })
    .catch(err => {
      return {
        success: false,
      };
    });
  return response;
};

export const getScore = async (seeker_id: number, job_id: number) => {
  let response = {};
  await axios
    .post(`${process.env.REACT_APP_AUTH_URL}`, {
      query: print(queries.GET_SCORE),
      variables: {
        seeker_id,
        job_id,
      },
    })
    .then(res => {
      response = { ...res, successful: true };
    })
    .catch(err => {
      response = { ...err.response, successful: false };
    });

  return response;
};

export const register = async (data: any) => {
  let response = {};
  await axios
    .post(`${process.env.REACT_APP_AUTH_URL}`, {
      query: print(queries.REGISTER_USER),
      variables: {
        data: data,
      },
    })
    .then(res => {
      console.log(res);
      response = { ...res, successful: true };
    })
    .catch(err => {
      console.log(err);
      response = { ...err.response, successful: false };
    });
  return response;
};

export const forgetPassword = async data => {
  let is_success = false;
  await axios
    .post(`${process.env.REACT_APP_AUTH_URL}`, {
      query: print(mutations.SEND_FORGOT_PASSWORD_CODE),
      variables: {
        data,
      },
    })
    .then(async res => {
      is_success = true;
    })
    .catch(err => {
      is_success = false;
    });
  return is_success;
};

export const socialLogin = async payload => {
  let response = {};
  await axios
    .post(`${process.env.REACT_APP_AUTH_URL}`, {
      query: print(mutations.SOCIAL_LOGIN),
      variables: { ...payload },
    })
    .then(async res => {
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });
  return response;
};

export const changePassword = async fields => {
  let response = {};
  await axios
    .post(`${process.env.REACT_APP_AUTH_URL}`, {
      query: print(mutations.CHANGE_PASSWORD),
      variables: {
        data: fields,
      },
    })
    .then(res => {
      response = { ...res, successful: true };
    })
    .catch(err => {
      response = { ...err.response, successful: false };
    });
  return response;
};

export const signed_image = async key => {
  let response: any = null;
  const requestOptions: any = {
    method: 'GET',
    redirect: 'follow',
  };
  await fetch(
    `${process.env.REACT_APP_AUTH_SERVICE}/s3-signed-url?bucket=pasajob-v2&key=${key}`,
    requestOptions
  )
    .then(response => response.text())
    .then(result => (response = result))
    .catch(error => console.log('error', error));
  return response;
};

export const sendRecoveryCode = async data => {
  let is_success = {};
  await axios
    .post(`${process.env.REACT_APP_AUTH_URL}`, {
      query: print(mutations.VERIFY_FORGOT_PASSWORD_CODE),
      variables: {
        data,
      },
    })
    .then(async res => {
      let data = res.data.data.forgot_password_new_password_email;
      is_success = { ...res.data.data, success: true };
    })
    .catch(err => {
      is_success = { ...err.response, success: false };
    });
  return is_success;
};
