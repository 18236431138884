import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { forgetPassword } from '../../utilities/api';
import { auth } from '../../utilities/auth';
import { PageContext } from '../../utilities/context';
import { OnClickFunction } from '../../utilities/types';
import ForgotPasswordView from './view';
import { sendRecoveryCode } from '../../utilities/api';
import { sendForgotPasswordCode, verifyForgotPasswordCode } from '../../utilities/api_v2/authentication';

type ForgotPassword = {
  email: string;
};

const ForgotPasswordController = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    clearErrors,
    setError,
  } = useForm<ForgotPassword>();
  const {
    control: control2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    clearErrors: clearErrors2,
    setError: setError2,
  } = useForm<any>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [showOtpModal, setShowOtpModal] = useState<boolean>(true)
  const [modalMessage, setModalMessage] = useState<string>('');
  const [email, setEmail] = useState<string>();
  const [code, setCode] = useState<string>();
  const [emailError, setEmailError] = useState<boolean>(false);


  const _submitForm: OnClickFunction = async data => {
    let result:any = await sendForgotPasswordCode(data);
    if (result.data.errors) {
      setError('email', {
        type: 'focus',
        message: result.data.errors.message,
      });
      setModalMessage("Email doesn't exist. Please enter a valid email. ");
      setShowModal(true);
      setEmailError(true);
    } else {
      setShowOtpModal(false);
      setEmail(data.email);
    }
  };

  const sendCode = async() => {
    if(!code || code.length < 5){
      setModalMessage("Code is required and must be a 5 digit code");
      setShowModal(true);
      setEmailError(true);
      return
    }

    let data = {
      email,
      code
    }

    let result:any = await verifyForgotPasswordCode(data);
    if (result.data.errors) {
      setModalMessage(result.data.errors.message);
      setShowModal(true);
      setEmailError(true);
    } else {
      setShowModal(true);
      setModalMessage("OTP Verified Successfully. New Password has been sent to your email! ");
      setEmailError(false);
    }
  };

  const clearError = () => {
    clearErrors && clearErrors();
  };
  const resetForm = () => {
    setShowOtpModal(true);
    setEmail('');
  };

  const emailErrorButton = () => {
    setShowModal(false)
  }

  const value: object = {
    control,
    errors,
    control2,
    errors2,
    showModal,
    showOtpModal,
    modalMessage,
    emailError,
    sendCode,
    _submitForm,
    handleSubmit,
    clearErrors,
    clearError,
    handleSubmit2,
    clearErrors2,
    resetForm,
    emailErrorButton,
    setCode
  };
  return (
    <PageContext.Provider value={value}>
      <ForgotPasswordView />
    </PageContext.Provider>
  );
};

export default ForgotPasswordController;
