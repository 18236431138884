import { Popover, Tooltip } from 'antd';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { useRecoilState } from 'recoil';
import { activeAging } from '../../../utilities/atoms/applicant_board/agingPopover';
import { IoMdWarning } from 'react-icons/io';
import { _getStatus } from '../../../utilities/states';
import { CgChevronLeftO, CgChevronRightO } from 'react-icons/cg';
import { CheckboxesContext } from '../../../utilities/context';
import { useContext } from 'react';

const AgingProgress = ({remainingDays, application_id, name, status,data}:{remainingDays:number; application_id:number; name:string; status:string, data:any}) => {
  const [activeId, setActiveId] = useRecoilState(activeAging)
  const {
    moveThroughAgingModal,
  } = useContext(CheckboxesContext);
  
  const value = remainingDays > 11 ? 0 : remainingDays > 7 ? 24 : remainingDays > 3 ? 49 : remainingDays > 0 ? 73 : 100

  const activeOptionPerStatus = {
    Applied:["Shortlisted"],
    Shortlisted:["For Interview","For Hire"],
    'For Interview':["For Hire"],
    "For Hire":["Hired"],
  }

  const moveOptions = Object.keys(_getStatus).filter((key)=>activeOptionPerStatus[status]?.includes(key))

  const onOpenAgingPopover = () => {
    if(activeId===application_id){
      setActiveId(null)
    }else{
      setActiveId(application_id)
    }
  }

  const onMove = ({sourceId, targetLaneId}) => {
    console.log(data)
    moveThroughAgingModal({sourceId, targetLaneId, cardDetails:data})
    // console.log({sourceId, targetLaneId, ids})
  }

  const content = (
    <div className='flex flex-col w-60'>
      <div className="flex flex-row items-center gap-1 px-2 text-sm text-white">
        {name?.toUpperCase()} {status} will be moved to 'Not Selected' in {remainingDays} day/s
      </div>
      <div className='mt-2 '>
            {moveOptions.map(k => {
                return (
                  <div
                    className="flex flex-row items-center justify-between w-full p-2 px-4 mb-2 text-sm text-white rounded-full cursor-pointer bg-pj-blue-200"
                    onClick={()=>onMove({sourceId:status, targetLaneId:k})}
                  >
                    {_getStatus[k]}
                    <CgChevronRightO className="text-lg" />
                  </div>
                );
            })}

            <div
              onClick={()=>onMove({sourceId:status, targetLaneId:"Rejected"})}
              className="flex flex-row items-center justify-between w-full p-2 px-4 mb-2 text-sm text-gray-100 rounded-full cursor-pointer bg-pj-blue-100"
            >
              <CgChevronLeftO className="mr-2 text-lg" />
              Not Selected
            </div>
        </div>
    </div>
  )
  return (
    <PopoverForAging remainingDays={remainingDays} open={activeId===application_id} content={content}>
      <ToolTipForAging remainingDays={remainingDays}>
          <div className='flex flex-col items-center gap-2 px-2 text-xs lowercase rounded-full' onClick={onOpenAgingPopover}>
          <div className={`w-5 h-5 border-1.5 rounded-full ${remainingDays <= 5 ? "border-red-400":"border-gray-400"}`}>
            <CircularProgressbar 
            value={value}
            strokeWidth={50}
            styles={buildStyles({
              strokeLinecap:"butt",
              trailColor:remainingDays <= 5 ? "#F87171" : "#9CA3AF",
              pathColor:"white",
            })}
            />
          </div>
            <div className='flex flex-col -mt-1 text-gray-500'>
              <div>{remainingDays} days </div>
              <div className='-mt-1'>to move</div>
            </div>
          </div>
      </ToolTipForAging>
        </PopoverForAging>
  );
};

const ToolTipForAging = ({remainingDays, children}:{remainingDays:number; children:JSX.Element}) => {
  return (
  <Tooltip
      arrow={true}
      color={"#1c4464"}
      key={"download"}
      title={
        <div className="w-48 p-2">
        You have {remainingDays} days left to move the candidate’s application forward
        </div>}
      placement="bottomRight">
        {children}
    </Tooltip>
  )
}

const PopoverForAging= ({remainingDays, open, children, content}:{remainingDays:number; open:boolean; children:JSX.Element; content:JSX.Element;}) => {
  return (
  <Popover 
    color={"#1c4464"} 
    placement="topRight" 
    open={open} 
    content={content} 
    title={<div className="flex flex-row items-center gap-1 text-base text-white"><IoMdWarning/>HURRY!</div>}
    trigger="click"
    >
      {children}
  </Popover>
  )
}

export default AgingProgress;
