import { useEffect } from 'react';
import { useState } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import { motion } from 'framer-motion';
import { OnClickFunction } from '../utilities/types';

type Props = {
  children?: React.ReactNode;
  show?: boolean;
  closable?: boolean;
  title?: string;
  width?: number;
  onClose?: OnClickFunction | undefined;
  top?: number;
};

const Modal = ({
  children,
  show,
  closable,
  title,
  width,
  onClose,
  top,
}: Props) => {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (show !== undefined && show !== visible) {
      setVisible(show);
    }
  }, [show]);

  return (
    <motion.div
      className={`shadow main-modal fixed w-full inset-0 z-50 overflow-hidden flex justify-center items-center animated fadeIn faster bg-opacity-60 bg-black ${!visible && 'hidden'
        }`}
    >
      <div
        className="p-4 bg-white rounded"
        style={{ marginTop: top ? -top : 0, width: width }}
      >
        <div className="flex w-full text-lg text-pj-blue-100 justify-end flex-row justify-between">
          <div className="text-pj-blue-500 text-sm font-semibold pl-2">{title}</div>
          {closable && (
            <div>
              <RiCloseLine
                className="cursor-pointer"
                onClick={() => {
                  setVisible(visible => !visible);
                  onClose && onClose(false);
                }}
              />
            </div>
          )}
        </div>
        {children}
      </div>
    </motion.div>
  );
};

Modal.defaultProps = {
  show: false,
  closable: false,
  width: 500,
  top: 0,
};

export default Modal;
