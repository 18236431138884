import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { socialLogin } from '../../utilities/api';
import { auth } from '../../utilities/auth';
import { LoginContext, PageContext } from '../../utilities/context';
import { LoginData } from '../../utilities/types';
import LoginView from './view';
import { login_v2, socialLogin2 } from '../../utilities/api_v2/authentication';

type Login = {
  email: string;
  password: string;
};

const LoginController = () => {
  const [show, setShow] = useState<boolean>(false);

  const history = useHistory();

  useEffect(() => {
    const search = history.location.search; // could be '?foo=bar'
    const params = new URLSearchParams(search);
    const auth_token = params.get('auth_token'); // bar

    if (auth_token) {
      auth.storeToken(auth_token);
      window.location.reload();
    }
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const _checker = (data: any) => {
    if (data?.data?.data?.auth_login?.token) {
      auth.storeToken(data.data.data.auth_login.token);
      if (auth.isOTP()) {
        history.push('/one-time-password');
      } else {
        window.location.reload();
      }
    } else if (data?.data?.social_signup) {
      auth.storeToken(data.data.social_signup.token);
      window.location.reload();
    }
  };

  const _submitHandler = async (data: Login) => {
    const result: any = await login_v2(data);
    if (result?.data?.errors) {
      setShow(true);
      // history.push('/signup');
    } else {
      auth.storeToken(result.data.token);
      window.location.reload()
    }
  };

  const _googleSuccess = async (data: any) => {
    let profile = data.profileObj;
    let payload = {
      first_name: profile?.givenName,
      last_name: profile?.familyName,
      email: profile?.email.toLowerCase(),
      user_type: 'employer',
      is_social_account: true,
    };
    
    let loginResponse:any = await socialLogin2(payload);
    auth.storeToken(loginResponse.data.token)
    window.location.reload()
  };

  const _googleFailure = (data: any) => {
    console.log(data);
  };

  const _linkedinSuccess = (data: any) => {
    console.log(data);
  };

  const _linkedinFailure = (data: any) => {
    console.log(data);
  };

  const _facebookHandler = async (data: any) => {
    const employer_info = {
      is_social_account: true,
      user_type: 'employer',
    };
    let payload = Object.assign(data, employer_info);
    payload.email = payload.email.toLowerCase();
    let loginResponse = await socialLogin(payload);
    _checker(loginResponse);
  };

  const value: LoginData = {
    show,
    _submitHandler,
    handleSubmit,
    _googleSuccess,
    _googleFailure,
    _linkedinSuccess,
    _linkedinFailure,
    _facebookHandler,
    control,
    errors,
  };
  return (
    <LoginContext.Provider value={value}>
      <LoginView />
    </LoginContext.Provider>
  );
};

export default LoginController;
