import {
  ApolloClient,
  InMemoryCache,
  split,
  HttpLink,
  ApolloLink,
} from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { auth } from './auth';
const getHeaders = () => {
  const headers = {} as any;
  const token = auth.getToken();
  if (token) {
    headers.authorization = `Bearer ${token}`;
  }
  return headers;
};

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_HASURA_URL_HTTP,
  headers: getHeaders(),
});

const wsLink = new WebSocketLink({
  uri: process.env.REACT_APP_HASURA_URL_WS || '',
  options: {
    reconnect: true,
    connectionParams: {
      headers: getHeaders(),
    },
  },
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink
);

// apollo client setup
export const client = new ApolloClient({
  link: ApolloLink.from([splitLink]),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});
