import { useContext, useState, useEffect } from 'react';
import { RiDeleteBinLine } from 'react-icons/ri';
import { lang } from '../../../../lang';
import { ManageUserContext } from '../../../../utilities/context';
import Info from '../components/info';
import Modal from '../../../../components/modal';
import ButtonNext from '../../../../components/buttons/button-next';
import InputField from '../../../../components/forms/input';

const Employees = () => {
  const {
    data,
    deleteModal,
    _deleteEmployee,
    modalMessage,
    employeeDetails,
    _removeUser,
    _cancelRemove,
    isCancel,
    closeDeleteModal,
  } = useContext(ManageUserContext);

  return (
    <div>
      <div className="p-4 px-8 flex flex-row justify-around bg-gray-100 border-b">
        <div className="w-1/6 flex justify-start"></div>
        <div className="w-2/6 flex justify-start text-pj-blue-100 text-sm font-semibold">
          Name
        </div>
        <div className="w-3/6 flex justify-start text-pj-blue-100 text-sm font-semibold">
          Email
        </div>
      </div>
      <div className="p-4 px-8">
        {data.map((val: any) => (
          <Info
            name={val.name}
            label={val.email}
            id={val.id}
            icon={
              <RiDeleteBinLine
                onClick={() => _deleteEmployee && _deleteEmployee(val)}
              />
            }
          />
        ))}
      </div>
      <Modal show={deleteModal} width={450}>
        <div className="flex justify-center text-xl text-gray-600">
          <div className="text-lg text-pj-blue-500">{modalMessage}</div>
        </div>
        <div className="p-3 border-2 border-gray-200 rounded-lg m-3 bg-gray-100 text-base grid grid-cols-2 gap-4 text-pj-blue-500">
          <div className="flex ml-2">{`${employeeDetails?.first_name} ${employeeDetails?.last_name}`}</div>
          <div className="flex ml-6">{employeeDetails?.email}</div>
        </div>
        <div className="flex justify-center mt-5 gap-2">
          <ButtonNext
            label={isCancel === true ? 'Yes' : 'OK'}
            onClick={
              isCancel === true
                ? () => _removeUser && _removeUser(employeeDetails)
                : closeDeleteModal
            }
          />

          {isCancel && (
            <>
              {' '}
              <ButtonNext label="Cancel" onClick={_cancelRemove} light />{' '}
            </>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Employees;
