import { useContext, useEffect } from 'react';
import ButtonNext from '../../../components/buttons/button-next';
import InputField from '../../../components/forms/input';
import { ProfilePageContext } from '../../../utilities/context';
import Loading from '../../../components/loader';
import Modal from '../../../components/modal';

const ResetPassword = () => {
  const {
    control,
    handleSubmit,
    watch,
    setError,
    errors,
    loading,
    clearError,
    _resetPassword,
    cancelChangePass,
    confirm,
    confirmButton,
  } = useContext(ProfilePageContext);
  const watchPassword = watch && watch('new_password');
  const watchConfirmPassword = watch && watch('confirm_password');

  useEffect(() => {
    if (watchPassword !== watchConfirmPassword) {
      setError &&
        setError('confirm_password', {
          type: 'focus',
          message: 'Password did not match!',
        });
    } else {
      clearError && clearError(true);
    }
  }, [watchPassword, watchConfirmPassword]);
  return (
    <div className="h-full p-4 w-full px-20 mt-12">
    {loading ? (
      <Loading show={loading} />
    ) : (
      <form
        onSubmit={ handleSubmit && _resetPassword && handleSubmit(_resetPassword)}
      >
          <div className="px-24">
            <InputField
                control={control}
                label="Current Password"
                type="password"
                name="current_password"
                toggle
                dark
                rules={{ required: 'Old password is required' }}
                error={errors?.current_password}
              />
            <InputField
                control={control}
                label="New Password"
                type="password"
                name="new_password"
                toggle
                dark
                rules={{ required: 'Password is required' }}
                error={errors?.new_password}
              />
            <InputField
                control={control}
                label="Confirm Password"
                type="password"
                name="confirm_password"
                toggle
                dark
                rules={{ required: 'Confirm password is required' }}
                error={errors?.confirm_password}
              />
          </div>
        <div className="flex justify-center">
        <div className="flex justify-center mt-5 gap-5">
          <ButtonNext
            label="Save Changes"
            buttonType="submit"
          />
          <ButtonNext
            label="Cancel"
            buttonType="button"
            onClick={cancelChangePass}
            light
          />
        </div>
        </div>
      </form>
     )} 
     <Modal show={confirm} width={400}>
        <div className="flex justify-center text-xl text-gray-600">
          <div className="text-lg text-pj-blue-500">Your password has been reset successfully</div>
        </div>
        <div className="flex justify-center mt-5 w-full px-24">
          <ButtonNext label="OK" onClick={confirmButton} full />
        </div>
      </Modal>
  </div>
  )
}

export default ResetPassword
