import { RiArrowDownSLine } from 'react-icons/ri';
import { motion } from 'framer-motion';
import React, { useState } from 'react';
import { OnClickFunction } from '../utilities/types';

type Option = {
  value: any;
  label: string;
};

type Props = {
  label: string | React.ReactNode;
  options: Option[];
  left?: number;
  onClick: OnClickFunction;
  style?: any;
};

const AnimatedSelect = ({ label, options, onClick, left, style }: Props) => {
  const [show, setShow] = useState<boolean>(false);
  const variants = {
    show: {
      opacity: 1,
      display: 'block',
      y: 0,
      transition: {
        duration: 0.6,
      },
    },
    hide: {
      opacity: 0,
      display: 'none',
      y: -50,
      transition: {
        duration: 0.6,
      },
    },
  };
  return (
    <div className="relative flex z-50">
      <div
        className={`${
          style ??
          'flex-auto pr-2 justify-center content-center text-pj-blue-100 text-sm'
        }`}
      >
        {label}
      </div>
      <div className="w-3 relative">
        <div
          onClick={() => setShow(!show)}
          className="cursor-pointer  text-lg text-pj-blue-100 "
        >
          <RiArrowDownSLine />
        </div>
        <motion.div
          variants={variants}
          initial="hide"
          animate={show ? 'show' : 'hide'}
          className="p-2 shadow-md absolute top-7 right-0  rounded-md bg-white border z-50 w-32"
          style={{ zIndex: 400, left: left }}
        >
          {options.map((option: Option, index: number) => {
            return (
              <div
                className="text-pj-blue-100 block cursor-pointer"
                onClick={() => {
                  setShow(false);
                  onClick(option);
                }}
                key={`${index}`}
                style={{ fontSize: 14 }}
              >
                {option.label}
              </div>
            );
          })}
        </motion.div>
      </div>
    </div>
  );
};

export default AnimatedSelect;
