import { motion } from 'framer-motion';
import { useState, forwardRef } from 'react';
import {
    Control,
    Controller,
    FieldError,
    FieldName,
    RegisterOptions,
} from 'react-hook-form';
import { slideIn } from '../../framer';
import { OnClickFunction } from '../../utilities/types';
import DatePicker from 'react-datepicker';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import moment from 'moment';
import { BiCalendarEvent } from 'react-icons/bi';
import { FiClock } from 'react-icons/fi';
interface TInputProps {
    rules?: Exclude<
        RegisterOptions,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs'
    >;
    defaultValue?: any;
    name: FieldName<String>;
    error?: FieldError | any;
    control?: Control;
}

interface Input extends TInputProps {
    label: string;
    type?: string;
    toggle?: boolean;
    max?: string | number | undefined;
    min?: string | number | undefined;
    maxlength?: number | undefined;
    placeholder?: string;
    rows?: number;
    dark?: boolean;
    onIncrement?: OnClickFunction;
    pattern?: string;
    disabled?: boolean;
}

const InputField = ({
    label,
    type,
    toggle = false,
    rules,
    defaultValue = '',
    name,
    error,
    control,
    min = undefined,
    max = undefined,
    placeholder,
    dark = false,
    pattern,
    maxlength = undefined,
    disabled,
}: Input) => {
    const [show, setShow] = useState<boolean>(false);

    const _onToggle = () => {
        setShow(!show);
    };
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, name, ref } }) => (
                <div
                    className={`flex justify-start -mx-3 pj-focus ${type === 'hidden' && 'hidden'
                        }`}
                >
                    <div className="w-full px-3 mb-5">
                        <label
                            className={`text-sm block text-left focus:border-green-200 ${dark ? 'text-pj-blue-500' : 'text-pj-blue-100'
                                }`}
                        >
                            {label}
                            <div className="flex pt-1">
                                <input
                                    type={toggle && show ? 'text' : type}
                                    className={`w-full z-10 -mr-10 pl-3 py-2.5 rounded border border-gray-200 outline-none  text-sm ${toggle ? 'pr-12' : 'pr-3'
                                        } ${error
                                            ? 'border-red-400 focus:border-red-400'
                                            : 'focus:border-pj-green-200'
                                        }`}
                                    onChange={onChange}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                    min={min}
                                    max={max}
                                    placeholder={placeholder}
                                    pattern={pattern}
                                    maxLength={maxlength}
                                    disabled={disabled}
                                />
                                {toggle && (
                                    <div
                                        className="z-20 flex items-center justify-end w-10 pr-2 text-sm text-center text-gray-400 cursor-pointer"
                                        onClick={() => _onToggle()}
                                    >
                                        {show ? 'hide' : 'show'}
                                    </div>
                                )}
                            </div>
                        </label>
                        {error && (
                            <motion.div
                                variants={slideIn}
                                initial="initial"
                                animate="animate"
                                className="block pt-1 text-xs text-left text-red-400"
                            >
                                {error.message}
                            </motion.div>
                        )}
                    </div>
                </div>
            )}
        />
    );
};

InputField.Date = ({
    label,
    rules,
    defaultValue = '',
    name,
    error,
    control,
    dark = false,
}: Input) => {
    const ExampleCustomInput = forwardRef(({ value, onClick }: any, ref: any) => (
        <div className="relative z-20" style={{ width :468}}>
            <input
                autoComplete='off'
                type="tel"
                className={`w-full z-10 -mr-10 pl-3 py-2.5 rounded border border-gray-200 outline-none  text-sm pr-3 ${error
                    ? 'border-red-400 focus:border-red-400'
                    : 'focus:border-pj-green-200'
                    }`}
                value={value && moment(value).format('LL')}
                name={name}
                ref={ref}
                onFocus={onClick}
            />
            <div className="absolute text-lg text-gray-400 right-3 top-3">
                <BiCalendarEvent />
            </div>
        </div>
    ));

    const currentDate: Date = new Date();

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, name, ref } }) => (
                <div className="relative z-50 flex -mx-3 pj-focus">
                    <div className="w-full px-3 mb-5">
                        <label
                            className={`text-sm block text-left -ml-0 focus:border-green-200 ${dark ? 'text-pj-blue-500' : 'text-pj-blue-100'
                                }`}
                        >
                            {label}
                            <div className="flex pt-1">
                                <DatePicker
                                    selected={value}
                                    onChange={onChange}
                                    customInput={<ExampleCustomInput />}
                                    minDate={moment().toDate()}
                                    // maxDate={moment().add(8, 'days').toDate()}
                                    renderCustomHeader={({
                                        date,
                                        decreaseMonth,
                                        increaseMonth,
                                    }: any) => {
                                        return (
                                            <div className="flex flex-row p-2">
                                                <div className="flex items-center justify-start flex-auto pl-2 text-sm text-pj-blue-500">
                                                    {moment(date).format('MMMM YYYY')}
                                                </div>
                                                <div
                                                    className="flex items-center justify-center text-lg cursor-pointer text-pj-blue-100"
                                                    onClick={decreaseMonth}
                                                >
                                                    <RiArrowLeftSLine />
                                                </div>
                                                <div
                                                    className="flex items-center justify-center text-lg cursor-pointer text-pj-blue-100"
                                                    onClick={increaseMonth}
                                                >
                                                    <RiArrowRightSLine />
                                                </div>
                                            </div>
                                        );
                                    }}
                                    className="bg-white border-0 shadow-md"
                                />
                            </div>
                        </label>
                        {error && (
                            <motion.div
                                variants={slideIn}
                                initial="initial"
                                animate="animate"
                                className="block pt-1 text-xs text-left text-red-400"
                            >
                                {error.message}
                            </motion.div>
                        )}
                    </div>
                </div>
            )}
        />
    );
};

InputField.Time = ({
    label,
    rules,
    defaultValue = '',
    name,
    error,
    control,
    dark = false,
}: Input) => {
    const CustomTime = forwardRef(({ value, onClick }: any, ref: any) => {
        return (
            <div className="relative z-10" style={{ width :230}}>
                <input
                    autoComplete='off'
                    type="tel"
                    className={`w-full z-10 -mr-10 pl-3 py-2.5 rounded border border-gray-200 outline-none  text-sm pr-3 ${error
                        ? 'border-red-400 focus:border-red-400'
                        : 'focus:border-pj-green-200'
                        }`}
                    value={value}
                    name={name}
                    ref={ref}
                    onFocus={onClick}
                />
                <div className="absolute text-lg text-gray-400 right-3 top-3">
                    <FiClock />
                </div>
            </div>
        );
    });

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, name, ref } }) => (
                <div className="relative z-40 flex -mx-3 pj-focus">
                    <div className="w-full px-3 mb-5">
                        <label
                            className={`text-sm block text-left -ml-0 focus:border-green-200 ${dark ? 'text-pj-blue-500' : 'text-pj-blue-100'
                                }`}
                        >
                            {label}
                            <div className="flex pt-1">
                                <DatePicker
                                    selected={value}
                                    onChange={onChange}
                                    showTimeSelect
                                    showTimeSelectOnly
                                    timeIntervals={30}
                                    customInput={<CustomTime />}
                                    className="bg-white border-0 shadow-md"
                                    dateFormat="h:mm aa"
                                />
                            </div>
                        </label>
                        {error && (
                            <motion.div
                                variants={slideIn}
                                initial="initial"
                                animate="animate"
                                className="block pt-1 text-xs text-left text-red-400"
                            >
                                {error.message}
                            </motion.div>
                        )}
                    </div>
                </div>
            )}
        />
    );
};

InputField.Tel = ({
    label,
    rules,
    defaultValue = '',
    name,
    error,
    control,
    min = undefined,
    max = undefined,
    placeholder,
    dark = false,
    pattern,
    maxlength = undefined,
}: Input) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, name, ref } }) => (
                <div className="flex -mx-3 pj-focus">
                    <div className="w-full px-3 mb-5">
                        <label
                            className={`text-sm focus:border-green-200 ${dark ? 'text-pj-blue-500' : 'text-pj-blue-100'
                                }`}
                        >
                            {label}
                            <div className="flex pt-1">
                                <input
                                    type="tel"
                                    className={`w-full z-10 -mr-10 pl-3 py-2.5 rounded border border-gray-200 outline-none  text-sm pr-3 ${error
                                        ? 'border-red-400 focus:border-red-400'
                                        : 'focus:border-pj-green-200'
                                        }`}
                                    onChange={onChange}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                    min={min}
                                    max={max}
                                    maxLength={maxlength}
                                    placeholder={placeholder}
                                />
                            </div>
                        </label>
                        {error && (
                            <motion.div
                                variants={slideIn}
                                initial="initial"
                                animate="animate"
                                className="pt-1 text-xs text-red-400"
                            >
                                {error.message}
                            </motion.div>
                        )}
                    </div>
                </div>
            )}
        />
    );
};

InputField.Number = ({
    label,
    rules,
    defaultValue = '',
    name,
    error,
    control,
    min = undefined,
    max = undefined,
    placeholder,
    dark = false,
    maxlength = undefined,
    disabled,
}: Input) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, name, ref } }) => (
                <div className="flex -mx-3 pj-focus">
                    <div className="w-full px-3 mb-5">
                        <label
                            className={`text-sm focus:border-green-200 ${dark ? 'text-pj-blue-500' : 'text-pj-blue-100'
                                }`}
                        >
                            {label}
                            <div className="flex pt-1">
                                <input
                                    type="number"
                                    className={`w-full z-10 -mr-10 pl-3 py-2.5 rounded border border-gray-200 outline-none  text-sm pr-3 ${error
                                        ? 'border-red-400 focus:border-red-400'
                                        : 'focus:border-pj-green-200'
                                        }`}
                                    onChange={onChange}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                    min={min}
                                    max={max}
                                    maxLength={maxlength}
                                    placeholder={placeholder}
                                    pattern="[\d| ]{16,22}"
                                    disabled={disabled}
                                />
                            </div>
                        </label>
                        {error && (
                            <motion.div
                                variants={slideIn}
                                initial="initial"
                                animate="animate"
                                className="pt-1 text-xs text-red-400"
                            >
                                {error.message}
                            </motion.div>
                        )}
                    </div>
                </div>
            )}
        />
    );
};

InputField.Textarea = ({
    label,
    rules,
    defaultValue = '',
    name,
    error,
    control,
    placeholder,
    rows = 10,
    dark = false,
}: Input) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, name, ref } }) => (
                <div className="flex -mx-3 pj-focus">
                    <div className="w-full px-3 mb-5">
                        <label
                            className={`text-sm focus:border-green-200 ${dark ? 'text-pj-blue-500' : 'text-pj-blue-100'
                                }`}
                        >
                            {label}
                            <div className="flex pt-1">
                                <textarea
                                    className={`w-full z-10 -mr-10 pl-3 py-2.5 rounded border border-gray-200 outline-none  text-sm pr-3 ${error
                                        ? 'border-red-400 focus:border-red-400'
                                        : 'focus:border-pj-green-200'
                                        }`}
                                    onChange={onChange}
                                    value={value}
                                    name={name}
                                    rows={rows}
                                    ref={ref}
                                    placeholder={placeholder}
                                    id="journal-scroll"
                                />
                            </div>
                        </label>
                        {error && (
                            <motion.div
                                variants={slideIn}
                                initial="initial"
                                animate="animate"
                                className="pt-1 text-xs text-red-400"
                            >
                                {error.message}
                            </motion.div>
                        )}
                    </div>
                </div>
            )}
        />
    );
};

InputField.Count = ({
    label,
    rules,
    defaultValue = '',
    name,
    error,
    control,
    dark = false,
    min = 0,
    onIncrement,
    disabled,
}: Input) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, name, ref } }) => (
                <div className="flex -mx-3 pj-focus">
                    <div className="w-full px-3 mb-5">
                        <label
                            className={`text-sm focus:border-green-200 ${dark ? 'text-pj-blue-500' : 'text-pj-blue-100'
                                }`}
                        >
                            {label}
                            <div className="relative flex flex-row w-32 h-10 mt-3 border border-gray-400 rounded-lg">
                                <button
                                    type="button"
                                    className="flex w-20 h-full font-semibold text-white border border-gray-400 rounded cursor-pointer bg-pj-blue-100 focus:outline-none"
                                    onClick={() => {
                                        return (disabled && value <= defaultValue) ? null : onIncrement && onIncrement('minus')
                                    }
                                    }
                                >
                                    <span className="m-auto">-</span>
                                </button>
                                <input
                                    type="hidden"
                                    className="p-1 text-xs text-center border-gray-400 md:p-2 md:text-base focus:outline-none"
                                    min={min}
                                    value={value}
                                    onChange={onChange}
                                    name={name}
                                />
                                <div className="flex items-center justify-center w-32 text-xs bg-white cursor-default md:text-base">
                                    <span>{min}</span>
                                </div>

                                <button
                                    type="button"
                                    className="flex w-20 h-full font-semibold text-white border border-gray-400 rounded cursor-pointer bg-pj-blue-100 focus:outline-none"
                                    onClick={() =>
                                        // disabled ? null : 
                                        onIncrement && onIncrement('plus')
                                    }
                                >
                                    <span className="m-auto">+</span>
                                </button>
                            </div>
                        </label>
                        {error && (
                            <motion.div
                                variants={slideIn}
                                initial="initial"
                                animate="animate"
                                className="pt-1 text-xs text-red-400"
                            >
                                {error.message}
                            </motion.div>
                        )}
                    </div>
                </div>
            )}
        />
    );
};

InputField.defaultProps = {
    type: 'text',
};

export default InputField;
