import { Props } from 'framer-motion/types/types';
import { useState } from 'react';
import AnimatedSelect from '../animated-select';

const PageHeader = ({ data, name, id, company }: Props) => {
  return (
    <nav className="py-5 bg-white px-4">
      <div className="md:flex items-center justify-between">
        <div className="flex justify-between items-center">
          <div className="text-2xl font-bold text-gray-800 md:text-3xl">
            <h3 className="text-base text-pj-blue-300">
              Hi {name}! Welcome to PasaJob
            </h3>
          </div>
        </div>
        <AnimatedSelect
          options={data ?? []}
          onClick={e => id(e)}
          label={
            <div>
              View as
              <span className="font-semibold text-pj-blue-300">
                {` ${company.label}`}
              </span>
            </div>
          }
        />
      </div>
    </nav>
  );
};

export default PageHeader;
