import { motion } from 'framer-motion';
import { useContext } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import JobSteps from '../../components/job-post/step';
import MiniCompany from '../../components/mini-company';
import { slideIn } from '../../framer';
import { CreateJobContext } from '../../utilities/context';
import { Job } from '../../utilities/types';
import { CircularProgress } from '@material-ui/core';
import { Modal } from 'antd';
import { isOnTeam } from '../../utilities/helper';

const CreateJobView = () => {
  const { steps, 
    details, 
    company, 
    step, 
    loading, 
    setConfirmJobPostData,
    confirmJobPostData,
    _createJobPost }: Job = useContext(CreateJobContext);

  return (
    <motion.div
      variants={slideIn}
      className="flex fixed items-center justify-center w-full h-screen bg-pj-blue-500"
    >
      <Modal closable={false} style={{ top: "50%", transform: "translate(10%, -50%)",}} title="This service is temporarily unavailable." open={!isOnTeam()} footer={[]}>
        <div className="mt-2">
          As mentioned in our latest email regarding all-in pricing, our platform will undergo maintenance as we implement important updates. 
          If you would like to post a job during this period, please get in touch with our sales team at team@pasajob.com. 
          Thank you!</div>
        <div className=' flex justify-end gap-2'>
          <Link to="/dashboard">
            <button className=' px-5 py-2 mt-5 bg-pj-green-200 text-white rounded cursor-pointer'>
              Go Back
            </button>
          </Link>
        </div>
      </Modal>
      <Modal style={{ top: "50%", transform: "translate(10%, -50%)",}} title="Job Post Privacy" open={!!confirmJobPostData} footer={[]}>
        <div>You can't change the visibility of the job post once you submit. Would you like to proceed?</div>
        <div className=' flex justify-end gap-2'>
          <button className=' px-5 py-2 border rounded cursor-pointer' onClick={()=>setConfirmJobPostData(null)}>Cancel</button>
          <button className=' px-5 py-2 bg-pj-green-200 text-white rounded cursor-pointer' onClick={_createJobPost}>Proceed</button>
        </div>
      </Modal>
      <div className="flex flex-col h-screen w-full">
        <div className="h-32 flex justify-center items-center">
          <JobSteps {...steps} />
        </div>
        <motion.div
          variants={slideIn}
          animate="animate"
          initial="initial"
          className="flex flex-1 justify-center overflow-auto"
        >
          <div className="w-2/5 flex">
            <div className="w-full flex-1 rounded-tl-lg rounded-tr-lg overflow-hidden relative bg-white flex flex-col ">
              {loading && <div className='absolute flex items-center justify-center top-0 bottom-0 left-0 right-0 bg-black opacity-10' >
                <CircularProgress style={{ color: "white" }} size={50} />
              </div>}
              <div className="flex p-4">
                <div className="text-2xl flex-auto">
                  {details.totalSteps > 1 && (
                    <div className="bg-pj-green-200 bg-opacity-25 w-full mb-4 h-2 rounded-full mt-1">
                      <div
                        className="bg-pj-green-200 h-full rounded-full"
                        style={{
                          width: `${(details.subStep.step / details.totalSteps) * 100
                            }%`,
                        }}
                      ></div>
                    </div>
                  )}
                  {details.subStep.heading}
                </div>
                <div
                  className={`w-10 transparent flex items-start justify-center ${details.totalSteps > 1 ? 'items-start' : 'items-center'
                    }`}
                >
                  <Link to="/dashboard">
                    <AiOutlineClose className="text-xl text-pj-blue-100" />
                  </Link>
                </div>
              </div>
              {details.subStep.view}
              {step !== 3 && (
                <div className="h-24 w-full border-t border-gray-200">
                  {company && (
                    <div className="p-4">
                      <div className="text-base text-pj-blue-500 pb-2">
                        You are posting a job for:
                      </div>
                      <MiniCompany {...company} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default CreateJobView;
