import { GrFacebookOption, GrLinkedin } from 'react-icons/gr';
import { AiOutlineGoogle } from 'react-icons/ai';
import { OnClickFunction } from '../../utilities/types';

interface SocialLoginProps {
  label: string;
  onClick: OnClickFunction;
}

const SocialLogin = ({ label = '', onClick }: SocialLoginProps) => {
  return (
    <button
      className="border-gray-200 border bg-gray-100 px-4 py-3 font-semibold text-white w-full flex items-center space-x-2 rounded-full relative my-3"
      onClick={onClick}
    >
      <span className="float-left absolute">
        {label === 'Facebook' ? (
          <GrFacebookOption className="text-pj-blue-300 text-lg" />
        ) : label === 'Google' ? (
          <AiOutlineGoogle className="text-pj-blue-300 text-lg" />
        ) : (
          <GrLinkedin className="text-pj-blue-300 text-lg" />
        )}
      </span>
      <span className="w-full ml-0 text-pj-blue-300">{label}</span>
    </button>
  );
};

export default SocialLogin;
