import { OnClickFunction, Scheduler } from '../../../utilities/types';
import Modal from '../../../components/modal';
import Avatar from '../../../components/avatar';
import InterviewTemplate from '../../../components/interview-template';
import { Link } from 'react-router-dom';
import { BiCalendarEvent } from 'react-icons/bi';

type Props = {
  data: Scheduler;
  show: boolean;
  _editHandler: OnClickFunction;
  _closeHandler: OnClickFunction;
};

const ScheduleInfo = ({ data, show, _closeHandler, _editHandler }: Props) => {
  return (
    <Modal
      title="Interview for"
      closable
      onClose={_closeHandler}
      show={show}
      width={400}
    >
      <div className="rounded-lg border p-2 my-4">
        <Avatar
          name={data.company_name}
          url={data.company_image}
          size={30}
          height={30}
          width={50}
        />
        <div className="text-sm text-pj-blue-100 pt-2">{`${data.job_title} • ${data.company_name}`}</div>
      </div>
      <div className="text-sm text-pj-blue-500">Interviewee</div>
      <div className="rounded-lg border p-2 my-2 flex flex-row bg-gray-100">
        <div className="flex justify-center items-center mr-2">
          <Avatar name={data.name} url={data.profile_image} size={50} />
        </div>
        <div className="flex-auto flex justify-start items-center mr-2">
          <div className="flex flex-col justify-center">
            <div className="text-sm font-semibold flex items-center text-pj-blue-500">
              {data.name}
            </div>
            <div className="text-xs text-pj-blue-100 flex items-center">
              {data.job_title}
            </div>
          </div>
        </div>
        <div className="flex w-20 justify-center items-center">
          <Link
            to={`/messages/${data.seeker_id}/${data.job_id}`}
            className="text-pj-blue-100 text-lg rounded-full border py-2 px-4 font-semibold"
          >
            Chat
          </Link>
        </div>
      </div>
      <InterviewTemplate
        address={data.address}
        date={data.date}
        start_time={data.start_time}
        end_time={data.end_time}
        onClick={() => _editHandler(data)}
      />
    </Modal>
  );
};

export default ScheduleInfo;
