import { Link, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';
import { menu } from '../../framer';
import { lang } from '../../lang';

type Menu = {
  link: string;
  image?: string;
  name: string;
};

type Props = {
  show: boolean;
};

const SidebarTwo = ({ show }: Props) => {
  const routes: Menu[] = [
    {
      link: '/dashboard',
      name: lang.menu.dashboard,
    },
    {
      link: '/job-posts',
      name: lang.menu.job_post,
    },
    {
      link: '/applicant-board',
      name: lang.menu.applicant_board,
    },
    {
      link: '/my-companies',
      name: lang.menu.my_companies,
    },
    {
      link: '/calendar',
      name: lang.menu.scheduler,
    },
    // {
    //   link: '/messages',
    //   name: lang.menu.messages,
    // },
    // {
    //   link: '/credits',
    //   name: 'Employer Credits',
    // },
    {
      link: '/transactions',
      name: lang.menu.transaction,
    },
  ];
  const location = useLocation();
  return (
    <div className="relative" style={{ zIndex: 1000 }}>
      {routes.map((route, index) => (
        <Link to={route.link} key={`${index}`}>
          <div
            className={`flex flex-row p-4 pl-4 py-5 content-center  border-l-4 ${
              location.pathname.includes(route.link)
                ? 'border-pj-green-200'
                : 'border-white'
            }`}
          >
            <div className="pt-1">
              {index === 0 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.75"
                  height="18.75"
                  viewBox="0 0 18.75 18.75"
                  className={`fill-current ${
                    location.pathname.includes(route.link)
                      ? 'text-pj-blue-500'
                      : 'text-pj-blue-100'
                  }`}
                >
                  <path
                    id="Path_2737"
                    data-name="Path 2737"
                    d="M587.52,745.218v-3.187h10.687v3.187Zm10.687,12.188H587.52V746.718h10.687Zm-15.375-15.375h3.188v15.375h-3.188Zm16.313-1.688H581.9a.749.749,0,0,0-.75.75v17.25a.749.749,0,0,0,.75.75h17.25a.75.75,0,0,0,.75-.75v-17.25A.75.75,0,0,0,599.145,740.343Z"
                    transform="translate(-581.145 -740.343)"
                  />
                </svg>
              ) : index === 1 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.75"
                  height="18.75"
                  viewBox="0 0 18.75 18.75"
                  className={`fill-current ${
                    location.pathname.includes(route.link)
                      ? 'text-pj-blue-500'
                      : 'text-pj-blue-100'
                  }`}
                >
                  <g
                    id="Group_5060"
                    data-name="Group 5060"
                    transform="translate(-580.589 -771.534)"
                  >
                    <path
                      id="Path_2739"
                      data-name="Path 2739"
                      d="M598.589,771.534h-17.25a.749.749,0,0,0-.75.75v17.25a.749.749,0,0,0,.75.75h17.25a.749.749,0,0,0,.75-.75v-17.25A.749.749,0,0,0,598.589,771.534Zm-16.312,7.375h15.375v4H582.277Zm15.375-5.687v4H582.277v-4ZM582.277,788.6v-4h15.375v4Z"
                    />
                    <path
                      id="Path_2740"
                      data-name="Path 2740"
                      d="M583.735,775.972h4.31a.19.19,0,0,0,.19-.19v-1.12a.184.184,0,0,0-.19-.19h-4.31a.19.19,0,0,0-.19.19v1.12A.2.2,0,0,0,583.735,775.972Z"
                    />
                    <path
                      id="Path_2741"
                      data-name="Path 2741"
                      d="M588.045,780.159h-4.31a.19.19,0,0,0-.19.19v1.12a.2.2,0,0,0,.19.19h4.31a.19.19,0,0,0,.19-.19v-1.12A.184.184,0,0,0,588.045,780.159Z"
                    />
                    <path
                      id="Path_2742"
                      data-name="Path 2742"
                      d="M588.045,785.847h-4.31a.19.19,0,0,0-.19.19v1.12a.2.2,0,0,0,.19.19h4.31a.19.19,0,0,0,.19-.19v-1.12A.184.184,0,0,0,588.045,785.847Z"
                    />
                  </g>
                </svg>
              ) : index === 2 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.75"
                  height="18.75"
                  viewBox="0 0 18.75 18.75"
                  className={`fill-current ${
                    location.pathname.includes(route.link)
                      ? 'text-pj-blue-500'
                      : 'text-pj-blue-100'
                  }`}
                >
                  <path
                    id="Path_2738"
                    data-name="Path 2738"
                    d="M598.589,805.672h-17.25a.749.749,0,0,0-.75.75v17.25a.749.749,0,0,0,.75.75h8.719a.749.749,0,0,0,.75-.75v-3.425h7.781a.749.749,0,0,0,.75-.75V806.422A.749.749,0,0,0,598.589,805.672Zm-9.469,17.062h-6.843V807.359h6.843v15.375Zm8.532-4.175h-6.844v-11.2h6.844Z"
                    transform="translate(-580.589 -805.672)"
                  />
                </svg>
              ) : index === 3 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.405"
                  height="18.374"
                  viewBox="0 0 19.405 18.374"
                  className={`fill-current ${
                    location.pathname.includes(route.link)
                      ? 'text-pj-blue-500'
                      : 'text-pj-blue-100'
                  }`}
                >
                  <path
                    id="Path_2739"
                    data-name="Path 2739"
                    d="M596.332,857.78h-2.766v-8.766h2.766Zm-7.195-8.766h2.742v8.766h-2.742Zm-4.43,0h2.742v8.766h-2.742Zm5.813-6.218,6.353,4.622H584.166Zm8.953,6.218a.751.751,0,0,0,.438-1.359l-8.951-6.511a.742.742,0,0,0-.881,0l-8.951,6.511a.751.751,0,0,0,.441,1.359h1.451v8.766h-1.688a.187.187,0,0,0-.187.187v1.219a.187.187,0,0,0,.187.187h18.375a.188.188,0,0,0,.188-.187v-1.219a.188.188,0,0,0-.188-.187H598.02v-8.766Z"
                    transform="translate(-580.817 -840.999)"
                  />
                </svg>
              ) : index === 4 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.75"
                  height="18.75"
                  viewBox="0 0 18.75 18.75"
                  className={`fill-current ${
                    location.pathname.includes(route.link)
                      ? 'text-pj-blue-500'
                      : 'text-pj-blue-100'
                  }`}
                >
                  <path
                    id="Path_2740"
                    data-name="Path 2740"
                    d="M582.832,876.811v-3.187h3v1.125a.188.188,0,0,0,.188.187h1.312a.188.188,0,0,0,.188-.187v-1.125h6v1.125a.187.187,0,0,0,.187.187h1.313a.187.187,0,0,0,.187-.187v-1.125h3v3.187Zm15.375,10.5H582.832v-8.906h15.375Zm.938-15.375h-3.938v-1.5a.187.187,0,0,0-.187-.187h-1.313a.187.187,0,0,0-.187.187v1.5h-6v-1.5a.188.188,0,0,0-.188-.187H586.02a.188.188,0,0,0-.188.187v1.5H581.9a.75.75,0,0,0-.75.75v15.563a.749.749,0,0,0,.75.75h17.25a.75.75,0,0,0,.75-.75V872.686A.75.75,0,0,0,599.145,871.936Z"
                    transform="translate(-581.145 -870.249)"
                  />
                </svg>
              ) : index === 5 ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="21"
                  viewBox="0 0 21 21"
                  className={`fill-current ${
                    location.pathname.includes(route.link)
                      ? 'text-pj-blue-500'
                      : 'text-pj-blue-100'
                  }`}
                >
                  <path
                    id="Path_2741"
                    data-name="Path 2741"
                    d="M595.28,920.893a8.672,8.672,0,0,1-6.143,2.522h-.04a8.749,8.749,0,0,1-4.057-1.019l-.2-.106h-3.3v-3.3l-.105-.2a8.739,8.739,0,0,1,1.5-10.239,8.649,8.649,0,0,1,6.159-2.576h.04a8.722,8.722,0,0,1,6.141,14.915Zm3.541-10.265a10.486,10.486,0,0,0-9.684-6.432h-.047a10.505,10.505,0,0,0-9.328,15.235v3.562a1.077,1.077,0,0,0,1.078,1.078h3.565a10.5,10.5,0,0,0,4.685,1.125h.049a10.454,10.454,0,0,0,7.4-3.035,10.536,10.536,0,0,0,2.287-11.533Zm-15.5,4.068a1.127,1.127,0,1,0,.33-.795A1.126,1.126,0,0,0,583.324,914.7Zm9.375,0a1.127,1.127,0,1,0,.33-.795A1.126,1.126,0,0,0,592.7,914.7Zm-4.687,0a1.125,1.125,0,1,0,.329-.795A1.125,1.125,0,0,0,588.012,914.7Z"
                    transform="translate(-578.637 -904.196)"
                  />
                </svg>
              ) : (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18.75"
                  height="18.75"
                  viewBox="0 0 18.75 18.75"
                  className={`fill-current ${
                    location.pathname.includes(route.link)
                      ? 'text-pj-blue-500'
                      : 'text-pj-blue-100'
                  }`}
                >
                  <path
                    id="Path_2742"
                    data-name="Path 2742"
                    d="M582.495,953.212l2.557,2.009a.158.158,0,0,0,.255-.125v-1.251h4.821a2.34,2.34,0,0,0,2.337-2.346V949.65a.16.16,0,0,0-.16-.16h-1.188a.159.159,0,0,0-.159.16V951.5a.833.833,0,0,1-.832.834h-4.819v-1.252a.157.157,0,0,0-.255-.124l-2.557,2.009A.154.154,0,0,0,582.495,953.212Zm.1-3.162h1.188a.16.16,0,0,0,.16-.159v-1.849a.833.833,0,0,1,.832-.835h4.821v1.252a.157.157,0,0,0,.255.124l2.557-2.008a.159.159,0,0,0,0-.251l-2.557-2.009a.158.158,0,0,0-.255.124v1.252H584.77a2.34,2.34,0,0,0-2.336,2.346v1.849A.159.159,0,0,0,582.593,950.05Zm12.544,7.406H579.762V942.081h15.375Zm.937-17.062h-17.25a.749.749,0,0,0-.75.75v17.25a.749.749,0,0,0,.75.75h17.25a.749.749,0,0,0,.75-.75v-17.25A.749.749,0,0,0,596.074,940.394Z"
                    transform="translate(-578.074 -940.394)"
                  />
                </svg>
              )}
            </div>
            <motion.span
              variants={menu}
              animate={show ? 'textVisible' : 'textInvisible'}
              className={`pl-3 pt-1 text-base ${
                location.pathname.includes(route.link)
                  ? 'text-pj-blue-500 font-semibold'
                  : 'text-pj-blue-100'
              }`}
            >
              {route.name}
            </motion.span>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default SidebarTwo;
