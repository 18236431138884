import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import ButtonExit from '../../../components/buttons/button-exit';
import ButtonNext from '../../../components/buttons/button-next';
import InputField from '../../../components/forms/input';
import Loading from '../../../components/loader';
import { stagger } from '../../../framer';
import { lang } from '../../../lang';
import { CreateJobContext } from '../../../utilities/context';
import { OnClickFunction } from '../../../utilities/types';
import { getTraits } from '../../../utilities/api_v2/job_create';

type Trait = {
  label: string;
  value: string;
};

const StepTwoFifteen = () => {
  const { control, handleSubmit, errors, _submitForm, setValue, common_job } =
    useContext(CreateJobContext);

  const [traits, setTraits] = useState<Trait[]>([]);
  const [select, setSelect] = useState<string | null | undefined>(
    common_job.trait_3
  );
  const [loading, setLoading] = useState(false)

  // const { data: _traits, loading } = useQuery(queries.TRAITS, {
  //   fetchPolicy: 'no-cache',
  //   variables: {
  //     group: 3,
  //   },
  // });

  const _getTraits = async () => {
    setLoading(true)
    return await getTraits({group:3}).then((t)=>{
      const items: Trait[] = t.data.map(({ label, value }) => ({
        label,
        value,
      }));
      console.log(items)
      setTraits(items);
    }).finally(()=>setLoading(false))
  }

  const _onClick: OnClickFunction = (position: string) => {
    setSelect(position);
    setValue && setValue('trait_3', position);
  };

  // useEffect(() => {
  //   if (_traits) {
  //     const items: Trait[] = _traits.traits.map(({ label, value }) => ({
  //       label,
  //       value,
  //     }));
  //     setTraits(items);
  //   }
  // }, [_traits]);

  useEffect(()=>{
    console.log("sean")
    _getTraits()
  },[])

  return (
    <form
      className="flex flex-col flex-1"
      onSubmit={handleSubmit && _submitForm && handleSubmit(_submitForm)}
    >
      <InputField
        name="trait_3"
        label=""
        control={control}
        error={errors?.trait_3}
        type="hidden"
        defaultValue={select}
        rules={{ required: true }}
      />
      <div className="flex flex-initial p-4 border-b">
        <p className="p-0 m-0 text-base text-pj-blue-100">
          {lang.step_two.quality}
        </p>
      </div>
      <div
        className="flex-initial overflow-auto h-screen/2"
        id="journal-scroll"
      >
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          className="w-full overflow-x-hidden overflow-y"
          id="journal-scroll"
        >
          <div
            className="flex flex-col flex-1 overflow-x-hidden"
            id="journal-scroll"
          >
            <div className="overflow-y">
              {traits.map(({ label, value }, index) => {
                return (
                  <div
                    className={`p-4 py-6 text-pj-blue-300 text-lg flex flex-initial cursor-pointer ${
                      select === value && 'bg-pj-green-100'
                    }`}
                    onClick={() => _onClick(value)}
                    key={`${index}`}
                  >
                    {label}
                  </div>
                );
              })}
            </div>
          </div>
        </motion.div>
        <Loading show={loading} />
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row items-end flex-1 p-4">
          <div className="flex items-end justify-start w-1/2 h-20">
            <ButtonExit />
          </div>
          <div className="flex items-end justify-end w-1/2 h-20">
            <ButtonNext />
          </div>
        </div>
      </div>
    </form>
  );
};

export default StepTwoFifteen;
