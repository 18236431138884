import { useState, useReducer, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { registerWithCompany } from '../../../utilities/api_v2/authentication';
import { auth } from '../../../utilities/auth';
import { ManageUserContext } from '../../../utilities/context';
import { ManageUserInitialState } from '../../../utilities/states';
import {
  ApiKeyInfo,
  EmployeeDetails,
  ManageEmployeeInfo,
  ManageUserActions,
  ManageUserData,
  ManageUserInfo,
  ManageUserTypes,
  OnClickFunction,
} from '../../../utilities/types';
import Employees from './pages/employees';
import HRTeam from './pages/hr-team';
import ManageUserView from './view';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router';
import moment from 'moment';
import ApiKey from './pages/api-key';
import { generateApiKey, getAPIKey, getCompaniesSubEmployers, getEmployees, getEmployerHR, insertSubEmployerCompany, removeEmployee, updateSubEmployers } from '../../../utilities/api_v2/companies';

const reducer = (
  state: ManageUserData,
  actions: ManageUserActions
): ManageUserData => {
  switch (actions.type) {
    case 'hr-team':
      return {
        ...state,
        view: actions.view,
        type: actions.type,
        data: actions.payload as ManageUserInfo[],
        id: actions.id,
      };
    case 'employees':
      return {
        ...state,
        view: actions.view,
        type: actions.type,
        data: actions.payload as ManageEmployeeInfo[],
        id: actions.id,
      };
    case 'api-key':
      return {
        ...state,
        view: actions.view,
        type: actions.type,
        data: actions.payload as ApiKeyInfo[],
        id: actions.id,
      };
    default:
      throw new Error();
  }
};

type Params = {
  id: string | undefined;
  type: string | undefined;
};

const ManageUserController = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setError,
    clearErrors,
    setValue,
  } = useForm<any>();
  const params: Params = useParams();
  const history = useHistory();
  const active = 'Active';
  const [state, dispatch] = useReducer(reducer, ManageUserInitialState);
  const [id, setId] = useState<number>();
  const [confirm, setConfirm] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [showExisting, setShowExisting] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(true);
  const [isCancel, setIsCancel] = useState<boolean>(true);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [employerUsers, setEmployerUsers] = useState<[]>([]);
  const [employeeDetails, setEmployeeDetails] = useState<EmployeeDetails>();
  const [sub_employers, setSubEmployers] = useState<any>(null);
  const [employee, setEmployee] = useState<any>(null);
  const [employer_users, setEmployer_Users] = useState<any>(null);
  const [api_key, setApiKey] = useState<any>(null);

  const _getSubEmployers = async ({company_id}) => {
    await getCompaniesSubEmployers({company_id}).then((data:any)=>{
      setSubEmployers(data?.data)
    })
  }

  const _getEmployees = async ()=> {
    await getEmployees({
      company_id: parseInt(params.id),
    }).then((data:any)=>{
      setEmployee(data?.data)
    })
  }

  const _getEmployerUsers = async () => {
    await getEmployerHR({
      employer_id: auth.getId(),
    }).then((data:any)=>{
      setEmployer_Users(data?.data)
    })
  }

  const _getAPIKey = async () => {
    await getAPIKey({
      company_id: params?.id ?  parseInt(params.id): 0,
    }).then((data:any)=>{
      setApiKey(data?.data)
    })
  }
  
  const _updateSubEmployers = async ({data}:any) => {
    await updateSubEmployers({data}).then(()=>{
      setModalMessage('User details have been updated');
      setShow(false);
      closeModal && closeModal(true);
      setConfirm(true);
    })    
  }
  
  const _removeEmployee = async ({user_id}:{user_id:number}) => {
    await removeEmployee({user_id}).then(()=>{
      setIsCancel(false);
      setModalMessage('User has been deleted');
    })
  }

  const _insertSubEmployerCompany = async ({
    company_id,
    sub_employer_id
  }) => {
    await insertSubEmployerCompany({
      company_id,
      sub_employer_id
    })
  }

  const fetchSubEmployers = () => {
    _getSubEmployers({company_id:parseInt(params.id),})
  }
  const _getView = {
    'hr-team': <HRTeam />,
    employees: <Employees />,
    'api-key': <ApiKey />,
  };

  useEffect(() => {
    if(employer_users){

      let processData = employer_users.map(user => {
        return {
          ...user,
          value:user.id,
          label: user.email
        }
      })
      setEmployerUsers(processData);
    }
  },[employer_users])

  useEffect(() => {
    if (params && params.type && params.id) {
      if ((params.type as ManageUserTypes) === 'hr-team') {
        fetchSubEmployers()
        _getEmployerUsers()
      } else if ((params.type as ManageUserTypes) === 'employees') {
        _getEmployees()
      } else {
        _getAPIKey()
      }
    }
  }, [params]);

  useEffect(() => {
    if (params && params.type && params.id && sub_employers) {
      console.log(sub_employers)
      const subEmployers = sub_employers?.map(
        (item: any) => {
          return {
            name: `${item.common_user.first_name} ${item.common_user.last_name}`,
            last_login: item.common_user.logins?.[0]?.created_at
              ? moment(item.common_user.logins?.[0]?.created_at).format('LLL')
              : 'No Record',
            id: item.common_user.id,
            email: item.common_user.email,
          };
        }
      );
      dispatch({
        type: params.type as ManageUserTypes,
        payload: subEmployers as ManageUserInfo[],
        view: _getView[params.type],
        id: parseInt(params.id),
      });
    }
  }, [sub_employers, params]);

  useEffect(() => {
    if (params && params.type && params.id && employee) {
      const employees = employee?.map((item: any) => {
        return {
          name: `${item.first_name} ${item.last_name}`,
          email: item.email ?? item.mobile_number,
          id: item.id,
        };
      });
      dispatch({
        type: params.type as ManageUserTypes,
        payload: employees as ManageEmployeeInfo[],
        view: _getView[params.type],
        id: parseInt(params.id),
      });
    }
  }, [employee, params]);

  useEffect(() => {
    if (params && params.type && params.id && api_key) {
      dispatch({
        type: params.type as ManageUserTypes,
        payload: api_key.api_key as ApiKeyInfo[],
        view: _getView[params.type],
        id: parseInt(params.id),
      });
    }
  }, [api_key, params])

  const _submitForm: OnClickFunction = async data => {
    delete data.confirm_password;
    delete data.name;
    delete data.last_login;

    if(data.email.includes(" ") || data.password.includes(" ")){
      toast.error('Email or password must not contain a space!', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
      });

      return
    }

    if (id) {
      data = auth.encrypt({
        ...data,
        id: id,
      });
      console.log(data, "hereeeee")
      _updateSubEmployers({data})
    } else {
      if (data) {
        data = {
          ...data,
          user_type: 'employer',
          employer_id: auth.getId(),
          company_id: params.id,
        }
        await registerWithCompany(data)
        .then(()=>{
          setConfirm(true);
          setModalMessage('HR team member has been added');
          setShow(false);})
          .catch(()=>{
            setError('email', {
              type: 'focus',
              message: "Email already used.",
            });
          })
      }
    }
  };

  const _editEmployee = async val => {
    setShow(true);
    setIsEdit(true);
    const user = sub_employers?.filter(
      (employer: any) => employer.common_user.id === val.id
    );
    setId(val.id);
    setValue('first_name', user[0].common_user.first_name);
    setValue('last_name', user[0].common_user.last_name);
    setValue('email', user[0].common_user.email);
  };

  const _deleteEmployee: OnClickFunction = async val => {
    setModalMessage('Are you sure you want to delete this user?');
    setDeleteModal(true);
    const temp = employee?.filter(
      (item: any) => item.id === val.id
    );

    const tempObject: EmployeeDetails = {
      first_name: temp[0].first_name,
      last_name: temp[0].last_name,
      email: temp[0].email,
      id: temp[0].id,
    };
    setId(val.id);
    setEmployeeDetails(tempObject);
  };

  const addEmployee: OnClickFunction = () => {
    setShow(true);
    setIsEdit(false);
  };

  const generateAPI: OnClickFunction = () => {
    generateApiKey({
        company_id: parseInt(params.id),
        employer_id: auth.getId()
    }).then(()=>{
      _getAPIKey()
    }).catch((e)=>{
      console.log(e)
    })
  };

  const closeModal: OnClickFunction = () => {
    setShow(false);
    setConfirm(false);
    const resetObject = {
      first_name: null,
      last_name: null,
      email: null,
      password: null,
      confirm_password: null,
    };
    Object.keys(resetObject).map(k => setValue && setValue(k, ''));
    clearError();
    setId && setId(undefined);
    setIsEdit(false);
  };

  const clearError = () => {
    clearErrors && clearErrors();
  };

  const confirmButton: OnClickFunction = () => {
    if ((params.type as ManageUserTypes) === 'hr-team') {
      fetchSubEmployers();
      setConfirm(false);
    }
  };

  const closeShowExisting: OnClickFunction = () => {
    setShowExisting(false);
    setErrorMessage("");
  };

  const openShowExisting: OnClickFunction = () => {
    setShowExisting(true);
  };

  const addExistingUser: OnClickFunction = (payload) => {
    setErrorMessage("")

    let checker = sub_employers.find(sub => sub?.common_user?.id == payload.user)
    
    if(checker){
      setErrorMessage('Account already added !')
      return
    }
    _insertSubEmployerCompany({
        company_id:params.id,
        sub_employer_id: payload.user
    })

  }

  const closeConfirmModal: OnClickFunction = () => {
    setConfirm(false);
    clearError();
  };

  const _removeUser: OnClickFunction = async data => {
    _removeEmployee({user_id:data.id})
  };

  const _cancelRemove: OnClickFunction = () => {
    setDeleteModal(false);
    setEmployeeDetails(undefined);
  };

  const closeDeleteModal: OnClickFunction = () => {
    _getEmployees()
    setDeleteModal(false);
    setIsCancel(true);
  };

  const value: ManageUserData = {
    ...state,
    control,
    errors,
    setId,
    handleSubmit,
    watch,
    _submitForm,
    setError,
    clearErrors,
    setValue,
    _editEmployee,
    addEmployee,
    closeModal,
    clearError,
    show,
    isEdit,
    confirmButton,
    confirm,
    modalMessage,
    deleteModal,
    _deleteEmployee,
    closeConfirmModal,
    employeeDetails,
    _removeUser,
    _cancelRemove,
    isCancel,
    closeDeleteModal,
    generateAPI,
    addExistingUser,
    setShowExisting,
    showExisting,
    closeShowExisting,
    openShowExisting,
    employerUsers,
    errorMessage
  };

  return (
    <ManageUserContext.Provider value={value}>
      <ManageUserView />
    </ManageUserContext.Provider>
  );
};

export default ManageUserController;
