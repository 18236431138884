import { employer_api_v2 } from "./main";

export const editProfile = async (data: any) => {
    let response = {};

    await employer_api_v2.post('profile-page/editProfile', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
};