import { OnClickFunction } from '../../utilities/types';

interface ButtonProps {
  label: string;
  onClick?: OnClickFunction;
  type?: 'submit' | 'reset' | 'button' | undefined;
}

const Button = ({ label = '', onClick, type = 'submit' }: ButtonProps) => {
  return (
    <button
      type={type}
      className="transition duration-200 bg-pj-green-200 hover:bg-pj-green-200 focus:bg-pj-green-200 focus:shadow-sm focus:ring-4 focus:ring-green-500 focus:ring-opacity-50 text-white w-full py-2 rounded-full text-sm shadow-sm hover:shadow-md font-semibold text-center inline-block no-outline"
      onClick={onClick}
    >
      <span className="inline-block mr-2 text-xl">{label}</span>
      {/* <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        className="w-4 h-4 inline-block"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M17 8l4 4m0 0l-4 4m4-4H3"
        />
      </svg> */}
    </button>
  );
};

Button.defaultProps = {
  type: 'submit',
};

export default Button;
