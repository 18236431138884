import { AiOutlinePlusCircle } from 'react-icons/ai';
import { OnClickFunction } from '../utilities/types';

type Props = {
  label: string;
  onClick?: OnClickFunction;
};

const AddButton = ({ label, onClick }: Props) => {
  return (
    <div
      className="flex flex-row p-2 px-3 rounded-full border border-gray-200 cursor-pointer"
      onClick={onClick}
    >
      <div className="flex-auto flex justify-start items-center h-5 text-sm text-pj-blue-500">
        {label}
      </div>
      <div className="w-5 h-5 flex justify-center items-center text-pj-blue-500 text-2xl">
        <AiOutlinePlusCircle />
      </div>
    </div>
  );
};

AddButton.defaultProps = {
  label: '',
};

export default AddButton;
