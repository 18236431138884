import { PDFDownloadLink } from '@react-pdf/renderer';
import React from 'react';

import CVDownload from '../components/cv';
import {
  SeekerExperience,
  SeekerJobExperience,
  SeekerSkill,
} from '../utilities/types';

type Props = {
  renderer: React.ReactNode;
  name: string;
  email: string;
  mobile_number: string;
  experience: SeekerExperience;
  job_experiences: SeekerJobExperience[];
  job_title: string;
  position: string;
  skills: SeekerSkill[];
  education: any;
  disabled?:boolean;
};

const DownloadPDF = (props: Props) => {
  return (
    props.disabled? <>{props.renderer}</>:<PDFDownloadLink
    document={<CVDownload {...props} />}
    fileName={`${props.name}-CV.pdf`}
    className="cursor-pointer"
  >
    {({ blob, url, loading, error }) => props.renderer}
  </PDFDownloadLink>
  );
};

export default DownloadPDF;
