import moment from 'moment';
import { useRef, useState } from 'react';
import { lang } from '../../../lang';
import { CandidateProfile } from '../../../utilities/types';
import Attachments from './attachments';
import { convertStringToHttps } from '../../../helpers';
import { industryHelper, specializationHelper } from '../../../utilities/helper';
const Profile = (props: CandidateProfile) => {
  const aboutRef: any = useRef(null);
  const educationRef: any = useRef(null);
  const experienceRef: any = useRef(null);
  const containerRef: any = useRef(null);
  const [active, setActive] = useState<number>(0);
  return (
    <div className="w-full max-h-screen">
      <div className="p-4 pb-0 border-b">
        <ul className="flex items-end justify-center pb-0">
          <li
            className="flex flex-col justify-between w-32 h-12 px-3 cursor-pointer"
            onClick={() => {
              containerRef.current.scrollTo({
                top: aboutRef.current.offsetTop - 100,
                left: 0,
                behavior: 'smooth',
              });
              setActive(0);
            }}
          >
            <div
              className={`w-full text-center uppercase text-xs pt-3 ${active === 0 ? 'text-pj-blue-500' : 'text-pj-blue-100'
                }`}
            >
              ABOUT
            </div>
            <div
              className={`w-full h-1 rounded-t ${active === 0 ? 'bg-pj-green-300' : 'bg-white'
                }`}
            ></div>
          </li>
          <li
            className="flex flex-col justify-between w-32 h-12 px-3 cursor-pointer"
            onClick={() => {
              containerRef.current.scrollTo({
                top: experienceRef.current.offsetTop,
                left: 0,
                behavior: 'smooth',
              });
              setActive(2);
            }}
          >
            <div
              className={`w-full text-center uppercase text-xs pt-3 ${active === 2 ? 'text-pj-blue-500' : 'text-pj-blue-100'
                }`}
            >
              EXPERIENCE
            </div>
            <div
              className={`w-full h-1 rounded-t ${active === 2 ? 'bg-pj-green-300' : 'bg-white'
                }`}
            ></div>
          </li>
          <li
            className="flex flex-col justify-between w-32 h-12 px-3 cursor-pointer"
            onClick={() => {
              containerRef.current.scrollTo({
                top: educationRef.current.offsetTop - 60,
                left: 0,
                behavior: 'smooth',
              });
              setActive(1);
            }}
          >
            <div
              className={`w-full text-center uppercase text-xs pt-3 ${active === 1 ? 'text-pj-blue-500' : 'text-pj-blue-100'
                }`}
            >
              EDUCATION
            </div>
            <div
              className={`w-full h-1 rounded-t ${active === 1 ? 'bg-pj-green-300' : 'bg-white'
                }`}
            ></div>
          </li>
        </ul>
      </div>
      <div
        style={{ height: 'calc(100vh - 7rem)', overflow: 'auto' }}
        id="journal-scroll"
        ref={containerRef}
      >
        <div className="h-auto">
          <div
            className="p-8 py-4 text-gray-400 border-b bg-gray-50"
            ref={aboutRef}
          >
            ABOUT
          </div>
          <div className="p-8 py-4 text-gray-400 border-b">
            <div className="text-base font-semibold capitalize text-pj-blue-500">
              current job title
            </div>
            <div className="text-sm text-pj-blue-100">
              {props.job_title ?? 'No data Available'}
            </div>
          </div>

          <div className="p-8 py-4 text-gray-400 border-b">
            <div className="text-base font-semibold capitalize text-pj-blue-500">
              postition level
            </div>
            <div className="text-sm text-pj-blue-100">
              {(props.position || props.other?.position) ?? 'No data Available'}
            </div>
          </div>

          <div className="p-8 py-4 text-gray-400 border-b">
            <div className="text-base font-semibold capitalize text-pj-blue-500">
              experience level
            </div>
            <div className="text-sm text-pj-blue-100">
            {props?.experience?.since_year ? `${props?.experience?.experience_level} ${ props?.experience?.experience_level === 'I have been working since' ? moment(props?.experience?.since_year).format('MMMM YYYY') : ''}`: 'No data available'}
            {/* {props?.experience?.since_year ? `${props?.experience?.experience_level} ${moment(props?.experience?.since_year).format('MMMM YYYY')}`: 'No data available'} */}
            </div>
          </div>

          <div className="p-8 py-4 text-gray-400 border-b">
            <div className="text-base font-semibold capitalize text-pj-blue-500">
              Industry
            </div>
            <div className="text-sm text-pj-blue-100">
            {industryHelper(props)}
              {/* {props?.job_experiences?.[0]?.common_industries?.industry_name ?? 'No data available'} */}
            </div>
          </div>

          <div className="p-8 py-4 text-gray-400 border-b">
            <div className="text-base font-semibold capitalize text-pj-blue-500">
              Specialization
            </div>
            <div className="text-sm text-pj-blue-100">
              {specializationHelper(props)}
              {/* {props?.job_experiences?.[0]?.specialization ?? 'No data available'} */}
            </div>
          </div>

          {/* <div className="p-8 text-gray-400 border-b">
            <div className="mb-5 text-base font-semibold text-pj-blue-500">
              Skills
            </div>
            {props.skills &&
              props.skills?.length > 0 &&
              props.skills.map(({ name }) => (
                <div className="inline p-2 px-4 mb-2 mr-2 text-sm border border-gray-200 rounded-full text-pj-blue-100">
                  {name}
                </div>
              ))}
          </div> */}
        </div>
        <div
          className="p-8 py-4 text-gray-400 border-b bg-gray-50"
          ref={experienceRef}
        >
          EXPERIENCE
        </div>
        {props.job_experiences &&
          props.job_experiences.map((experience, index) => {
            return (
              <div className="p-8 pb-0 border-b" key={index}>
                <div className="pb-4 mb-5 border-b ">
                  <div className="text-base font-semibold text-pj-blue-500">
                    {experience.position_title}
                  </div>
                  <div className="flex items-center text-sm text-pj-blue-100">
                    {experience.company_name} {(<span className='px-1 mb-1 text-2xl '>•</span>)} {experience.position_level}
                  </div>
                  <div className="text-sm text-pj-blue-100">
                    {`${moment(experience.joined_date_from).format(
                      'MMM YYYY'
                    )} - ${experience.is_present
                      ? 'Present'
                      : moment(experience.joined_date_to).format('MMM YYYY')
                      }`}
                  </div>
                  <div className="flex items-center text-sm text-pj-blue-100">
                    {experience.specialization} {(<span className='px-1 mb-1 text-2xl '>•</span>)} {experience.common_industries?.industry_name}
                  </div>
                  <div className="flex items-center text-sm text-pj-blue-100">
                    {experience.common_location_countries?.name}
                  </div>
                </div>
                <div className="my-4 text-sm text-pj-blue-400">
                  {experience.experience_description}
                </div>
                {/* commented for the mean time  */}
                {/* {experience.monthly_salary && (
                  <div className="mb-4 text-sm text-pj-blue-100">
                    {`Salary: ${lang.formatString(
                      lang.general.php,
                      experience.monthly_salary
                    )}`}
                  </div>
                )} */}
              </div>
            );
          })}


        <div
          className="p-8 py-4 text-gray-400 border-b bg-gray-50"
          ref={educationRef}
        >
          EDUCATION
        </div>
        {props.education &&
          props.education.map((education, index) => {
            return (
              <div className="p-8 border-b" key={index}>
                <div className="text-base font-semibold text-pj-blue-500">
                  {education.institute_university}
                </div>
                <div className="text-sm text-pj-blue-100">
                  {education.qualification}
                </div>
                <div className="flex flex-row justify-between mt-4">
                  <div className="text-sm text-pj-blue-400">
                    {education.field_of_study}
                  </div>
                  <div className="text-sm text-pj-blue-100">{`Graduated ${moment(
                    education.graduate_date
                  ).format('YYYY')}`}</div>
                </div>
              </div>
            );
          })}
        {/* { props.status === 'Shortlisted' &&  
          (
            <div>
              <div className="p-8 py-4 text-gray-400 border-b bg-gray-50">
                ATTACHMENT
              </div>
              <div className="pl-2 pr-5 ml-6 ">
                {props.uploaded_resume && 
                  <div className="flex flex-row items-center justify-between mt-3">
                    <a href={convertStringToHttps(props?.uploaded_resume)} target="_blank" download className="inline-block px-4 py-2 mb-3 text-sm text-white rounded-md bg-pj-green-200">
                      View Attachment 
                    </a>
                  </div>
                }
              </div>
            </div>
          )
        } */}
        <div
          className="p-8 py-4 text-gray-400 border-b bg-gray-50"
        >
          TRAININGS, LICENSES & CERTIFICATIONS
        </div>
        <div>
        {props.licenses &&
          props.licenses.map((license, index) => {
            return (
              <div className="p-8 border-b" key={index}>
                <div className="flex flex-row justify-between mt-2">
                  <div className="text-sm text-pj-blue-400">
                    {license.name}
                  </div>
                  <div className="text-sm text-pj-blue-100">{`${moment(
                    license.year_attained
                  ).format('YYYY')}`}</div>
                </div>
              </div>
            )
          })
        }
        </div>
      </div>
    </div>
  );
};

export default Profile;
