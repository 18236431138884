import { Modal } from "antd";
import { IoMdWarning } from "react-icons/io";

const NoSlotsModal = ({show, setShow}:{show:boolean; setShow:(show:boolean)=>void}) => {
    const _onClose = () => {
        setShow(false)
    }
    return ( 
        <Modal
          open={show}
          title={<div className="flex flex-row items-center gap-1 text-pj-blue-500"><IoMdWarning/>0 Profile Views Left</div>}
          closable
          onCancel={_onClose}
          footer={[]}
          width={300}
        >
            <div className=" text-pj-blue-500">To view more profiles, kindly process the candidates' applications by either moving them forward or moving them to 'Not Selected'.</div>
        </Modal> );
}
 
export default NoSlotsModal;