import { useContext } from 'react'
import { motion } from 'framer-motion';
import { EmployerCreditContext } from '../../utilities/context';
import Search from '../../components/forms/search';
import AnimatedSelect from '../../components/animated-select';
import { slideIn, stagger } from '../../framer';
import moment from 'moment';
import { abbreviate } from '../../helpers';
import { lang } from '../../lang';
import Credits from '../../components/employer-credit/credits';
import CreditTransaction from '../../components/employer-credit/credit_transasction';

const EmployerCreditView = () => {
  const {
    show,
    transacType,
    sort,
    transactionList,
    transactionDetails,
    _handleSelect,
    _clickHandler,
    _handleSearch,
    _handleSort,
    handleSubmit,
    register
  } = useContext(EmployerCreditContext);
  return (
    <div className='grid min-h-full'>
      <div className=' flex flex-col'>
        <Credits />
        <div className="grid grid-cols-3 h-3/4 flex-1 gap-0  overflow-hidden">
          <div className="border-r-2 bg-white">
            <div className="p-4">
              <div className="relative text-gray-600">
                <form onSubmit={handleSubmit && handleSubmit(_handleSearch)}>
                  {register && <Search name="search" register={register} />}
                </form>
              </div>
            </div>
            <div className="border-b-2 flex px-4 py-1">
              <div className="w-30">
                <AnimatedSelect
                  options={[
                    { label: 'All Transactions', value: null },
                    { label: 'New Job', value: 'New Job' },
                  ]}
                  onClick={_handleSelect}
                  label={transacType && transacType?.label}
                />
              </div>
              <div className="flex-auto items-end" />
              <div className="w-30">
                <AnimatedSelect
                  options={[
                    { label: 'Most Recent', value: 'desc' },
                    { label: 'Oldest', value: 'asc' },
                  ]}
                  onClick={_handleSort}
                  label={sort && sort?.label}
                />
              </div>
            </div>
            <motion.div
              variants={stagger}
              initial="initial"
              animate="animate"
              className="overflow-y-scroll overflow-x-hidden h-full"
              style={{ height: 'calc(75vh - 9.5rem)' }}
              id="journal-scroll"
            >
              {transactionList?.map(transactionItem => (
                <CreditTransaction {...transactionItem} active={transactionItem?.id === transactionDetails?.id}
                  onClick={_clickHandler}
                />
              ))}
            </motion.div>
          </div>
          <div className="col-span-2 bg-gradient-to-b from-gradiant-top to-gradiant-bottom flex flex-col content-center justify-center">
            {show && (
              <motion.div
                variants={stagger}
                animate="animate"
                initial="initial"
                className="px-6"
              >
                <motion.div variants={slideIn} className="self-center">
                  <div className="text-sm p-6 shadow-lg rounded-lg bg-white pj-blue-300 font-normal">
                    <div className="text-xs flex justify-between border-b-2 border-gray-500 pb-3">
                      <div className=" font-semibold text-gray-600 text-lg">
                        {transactionDetails?.transaction_type?.toUpperCase()}
                      </div>
                      <div className="flex  text-lg">
                        <div className="pr-1 pj-blue-100 text-pj-blue-100">
                          AMOUNT:
                        </div>
                        <div className=" font-semibold text-gray-600">
                          {transactionDetails?.amount
                            ? lang.formatString(
                              lang.general.php_total,
                              transactionDetails?.amount.toLocaleString(undefined)
                            )
                            : 'PHP 0'}
                        </div>
                      </div>
                    </div>
                    <div className="border-b-2 border-gray-500 py-3">
                      <div className="border-b-2 border-gray-200 pb-3">
                        <div className="text-base pb-1 font-medium text-pj-blue-400">
                          {transactionDetails?.job_position
                            ? `${transactionDetails?.job_position?.toUpperCase()}`
                            : '---'}
                        </div>
                        <div className="flex content-center">
                          <div
                            className={`w-8 h-8 rounded-full border-2 border-gray-300 bg-cover bg-center flex justify-center items-center text-gray-500 text-xl ${(!transactionDetails?.logo ||
                              transactionDetails?.logo.length <= 0) &&
                              'bg-gray-200 tracking-wide'
                              }`}
                            style={{
                              backgroundImage: `url(${transactionDetails?.logo})`,
                            }}
                          >
                            {(!transactionDetails?.logo ||
                              transactionDetails?.logo.length <= 0) &&
                              abbreviate(
                                transactionDetails?.company_name
                                  ? transactionDetails?.company_name
                                  : ''
                              )}
                          </div>
                          <div className="pl-2 self-center text-sm pj-blue-100 text-pj-blue-100">
                            {transactionDetails?.company_name
                              ? `${transactionDetails?.company_name}`
                              : '---'}
                          </div>
                        </div>
                      </div>
                      <div className="py-2">
                        <div className="flex justify-between pb-2">
                          <div className="text-sm pj-blue-100 text-pj-blue-100">
                            Transaction ID
                          </div>
                          <div className="text-sm pj-blue-100 text-pj-blue-100">
                            {transactionDetails?.transaction_id
                              ? `${transactionDetails?.transaction_id}`
                              : '---'}
                          </div>
                        </div>
                        <div className="flex justify-between">
                          <div className="text-sm pj-blue-100 text-pj-blue-100">
                            Date of Transaction
                          </div>
                          <div className="text-sm pj-blue-100 text-pj-blue-100">
                            {transactionDetails?.created_at
                              ? moment(transactionDetails?.created_at).format('LL')
                              : '---'}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-b-2 border-gray-500 py-3">
                      <div className="flex justify-between pb-2">
                        <div className="text-sm pj-blue-100 text-pj-blue-100">
                          Referral Fee
                        </div>
                        <div className="text-sm pj-blue-100 text-pj-blue-100">
                          {transactionDetails?.total_amount
                            ? lang.formatString(
                              lang.general.php_total,
                              transactionDetails?.total_amount.toLocaleString(
                                undefined
                              )
                            )
                            : 'PHP 0'}
                        </div>
                      </div>

                      <div className="flex justify-between pb-2">
                        <div className="text-sm pj-blue-100 text-pj-blue-100">
                          Number of unfilled roles
                        </div>
                        <div className="text-sm pj-blue-100 text-pj-blue-100">
                          0
                        </div>
                      </div>

                      <div className="flex justify-between pb-2">
                        <div className="text-sm pj-blue-100 text-pj-blue-100">
                          Processing Fee (15%)
                        </div>
                        <div className="text-sm pj-blue-100 text-pj-blue-100">
                          {transactionDetails?.total_commission
                            ? lang.formatString(
                              lang.general.php_total,
                              transactionDetails?.total_commission.toLocaleString(
                                undefined
                              )
                            )
                            : 'PHP 0'}
                        </div>
                      </div>
                      <div className="flex justify-between pb-2">
                        <div className="text-sm pj-blue-100 text-pj-blue-100">
                          Posting Fee
                        </div>
                        <div className="text-sm pj-blue-100 text-pj-blue-100">
                          {transactionDetails?.posting_fee
                            ? lang.formatString(
                              lang.general.php_total,
                              transactionDetails?.posting_fee.toLocaleString(
                                undefined
                              )
                            )
                            : 'PHP 0'}
                        </div>
                      </div>
                      <div className="flex justify-between pb-2">
                        <div className="text-sm pj-blue-100 text-pj-blue-100">
                          VAT (12%)
                        </div>
                        <div className="text-sm pj-blue-100 text-pj-blue-100">
                          {transactionDetails?.total_vat
                            ? lang.formatString(
                              lang.general.php_total,
                              transactionDetails?.total_vat.toLocaleString(
                                undefined
                              )
                            )
                            : 'PHP 0'}
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between py-2 font-medium text-base">
                      <div className="pj-blue-100 text-pj-blue-100">TOTAL</div>
                      <div className="pj-blue-100 text-pj-blue-100">
                        {transactionDetails?.amount
                          ? lang.formatString(
                            lang.general.php_total,
                            transactionDetails?.amount.toLocaleString(undefined)
                          )
                          : 'PHP 0'}
                      </div>
                    </div>
                  </div>
                </motion.div>
                <motion.div variants={slideIn} className="flex flex-row-reverse">
                  {/* <TransactionPDF
              renderer={
                <div className="font-normal w-full pr-5 text-pj-blue-300 border-gray-200 border bg-gray-100 px-4 py-2 font-semibold text-white flex items-center space-x-2 rounded-full relative my-3">
                  Download PDF
                </div>
              }
              amount={
                transactionDetails?.amount
                  ? lang.formatString(
                    lang.general.php_total,
                    transactionDetails?.amount.toLocaleString(undefined)
                  )
                  : 'PHP 0'
              }
              transaction_id={
                transactionDetails.transaction_id
                  ? `${transactionDetails.transaction_id}`
                  : '---'
              }
              created_at={
                transactionDetails.created_at
                  ? moment(transactionDetails.created_at).format('LL')
                  : '---'
              }
              total_vat={
                transactionDetails?.total_vat
                  ? lang.formatString(
                    lang.general.php_total,
                    transactionDetails?.total_vat.toLocaleString(undefined)
                  )
                  : 'PHP 0'
              }
              job_position={
                transactionDetails.job_position
                  ? `${transactionDetails.job_position}`
                  : '---'
              }
              company_name={
                transactionDetails.company_name
                  ? `${transactionDetails.company_name}`
                  : '---'
              }
              logo={transactionDetails.logo}
              sub_total={
                transactionDetails?.total_amount
                  ? lang.formatString(
                    lang.general.php_total,
                    transactionDetails?.total_amount.toLocaleString(
                      undefined
                    )
                  )
                  : 'PHP 0'
              }
              total_amount={
                transactionDetails?.amount
                  ? lang.formatString(
                    lang.general.php_total,
                    transactionDetails?.amount.toLocaleString(undefined)
                  )
                  : 'PHP 0'
              }
              transaction_type={transactionDetails?.transaction_type ?? '---'}
              processing_fee={
                transactionDetails?.posting_fee
                  ? lang.formatString(
                    lang.general.php_total,
                    transactionDetails?.posting_fee.toLocaleString(
                      undefined
                    )
                  )
                  : 'PHP 0'
              }
              total_commission={
                transactionDetails?.total_commission
                  ? lang.formatString(
                    lang.general.php_total,
                    transactionDetails?.total_commission.toLocaleString(
                      undefined
                    )
                  )
                  : 'PHP 0'
              }
            /> */}
                </motion.div>
              </motion.div>
            )}
          </div>
        </div></div>
    </div>
  )
}

export default EmployerCreditView