import { useContext, useState, useEffect } from 'react';
import { RiDeleteBinLine } from 'react-icons/ri';
import { lang } from '../../../../lang';
import { ManageUserContext } from '../../../../utilities/context';
import Info from '../components/info';
import Modal from '../../../../components/modal';
import ButtonNext from '../../../../components/buttons/button-next';
import InputField from '../../../../components/forms/input';

const ApiKey = () => {
    const {
        data,
        generateAPI
    } = useContext(ManageUserContext);

    return (
        <>
            <div className="p-4 px-8 flex flex-row justify-around bg-gray-100 border-b">
                <div className="w-1/6 flex justify-start"></div>
                <div className="w-2/6 flex justify-start text-pj-blue-100 text-sm font-semibold">
                    Username
                </div>
                <div className="w-3/6 flex justify-start text-pj-blue-100 text-sm font-semibold">
                    Key
                </div>
            </div>
            <div className="p-4 px-8">
                {data.map((val: any) => (
                    <Info
                        name={val.username}
                        label={val.key}
                        id={val.id}
                        icon={<></>}
                    />
                ))}
            </div>
            {data.length <= 0 && <div className="p-4 px-8 flex justify-center">
                <div
                    className="inline p-2 px-4 border rounded-full text-pj-blue-100 bg-gray-100 text-sm font-semibold cursor-pointer"
                    onClick={generateAPI}
                >
                    Generate API
                </div>
            </div>}
        </>
    );
};

export default ApiKey;
