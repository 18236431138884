import { useContext } from 'react';
import ButtonNext from '../../../components/buttons/button-next';
import InputField from '../../../components/forms/input';
import { ProfilePageContext } from '../../../utilities/context';
import Loading from '../../../components/loader';
import Upload from '../../../components/forms/upload';
import Modal from '../../../components/modal';
import { Link } from 'react-router-dom';

const ProfilePage = () => {
  const {
    control,
    handleSubmit,
    errors,
    watch,
    setError,
    clearErrors,
    setValue,
    _submitForm,
    loading,
    info,
    file,
    _beforeUpload,
    confirmProfile,
    confirmButtonProfile,
  } = useContext(ProfilePageContext);
  return (
    <div className="w-full h-full p-4 px-20 mt-12">
    {loading ? (
      <Loading show={loading} />
    ) : (
      <form
        onSubmit={handleSubmit && _submitForm && handleSubmit(_submitForm)}
      >
        <div className="flex justify-center my-10">
          <Upload file={file} onChange={_beforeUpload} />
        </div>
        <div className="flex flex-row space-between">
          <div className="w-1/2 pr-4">
            <InputField
              control={control}
              label="First Name"
              name="first_name"
              defaultValue={info?.first_name}
              rules={{ required: 'First name is required' }}
              error={errors?.first_name}
            />
          </div>
          <div className="w-1/2 pl-4">
            <InputField
              control={control}
              label="Last Name"
              name="last_name"
              defaultValue={info?.last_name}
              rules={{ required: 'Last name is required' }}
              error={errors?.last_name}
            />
          </div>
        </div>
        <div className="flex flex-row space-between">
          <div className="w-1/2 pr-4">
            <InputField
              control={control}
              label="Position"
              name="position"
              defaultValue={info?.position}
              // rules={{ required: 'Position is required' }}
              error={errors?.position}
            />
          </div>
          <div className="w-1/2 pl-4">
            <InputField
              control={control}
              label="Company"
              name="company"
              defaultValue={info?.company}
              error={errors?.company}
              disabled
            />
          </div>
        </div>
        <div className="flex flex-row space-between">
          <div className="w-1/2 pr-4">
            <InputField
              control={control}
              label="Contact Number"
              name="contact_number"
              defaultValue={info?.contact_number}
              error={errors?.contact_number}
            />
          </div>
          <div className="w-1/2 pl-4">
            <InputField
              control={control}
              label="Email"
              name="email"
              defaultValue={info?.email}
              error={errors?.email}
            />
          </div>
        </div>
        <div className="flex justify-center">
        <div className="flex justify-center gap-5 mt-5">
          <ButtonNext
            label="Save Changes"
            buttonType="submit"
          />
          <Link to={'/dashboard'}>
            <ButtonNext
              label="Cancel"
              buttonType="button"
              light
            />
          </Link>
        </div>
        </div>
      </form>
     )} 
     <Modal show={confirmProfile} width={400}>
        <div className="flex justify-center text-xl text-gray-600">
          <div className="text-lg text-pj-blue-500">Your profile has been updated successfully</div>
        </div>
        <div className="flex justify-center w-full px-24 mt-5">
          <ButtonNext label="OK" onClick={confirmButtonProfile} full />
        </div>
      </Modal>
  </div>
  )
}

export default ProfilePage
