import { atom } from "recoil";

export const boardAtom = atom<any>({
    key: 'board',
    default: {
        status: null,
        rejected: [],
        applied: [],
        pre_screened: [],
        for_interview: [],
        shortlisted: [],
        hired: [],
        counts : {
            "Rejected": 0,
            "Applied": 0,
            "Shortlisted": 0,
            "For Interview": 0,
            "For Hire": 0,
            "Hired": 0,
        },

        suggesteds: [],
        invited: [],
        company_detail_id: 0,
        industry_name: "",
        isLoading: false
    },
});