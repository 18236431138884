import { motion } from 'framer-motion';
import { CgChevronLeftO, CgChevronRightO } from 'react-icons/cg';
import { _getStatus } from '../utilities/states';
import { OnClickFunction } from '../utilities/types';

type Props = {
  status:
    | 'Applied'
    | 'Rejected'
    | 'For Interview'
    | 'Shortlisted'
    | 'For Hire'
    | 'Hired'
    | null
    | undefined;
  onClick: OnClickFunction;
  onInvite: OnClickFunction;
  onResigned: OnClickFunction;
  invite?: boolean;
  isResigned: boolean;
};

const variant = {
  initial: {
    opacity: 0,
    y: -150,
    zIndex: -10,
    transition: {
      duration: 0.4,
    },
  },
  hover: {
    opacity: 1,
    y: 0,
    zIndex: 100,
    transition: {
      duration: 0.4,
    },
  },
};

const MoveButton = ({
  status,
  onClick,
  onInvite,
  invite,
  onResigned,
  isResigned,
}: Props) => {
  if (invite) {
    return null;
  }
  const activeOptionPerStatus = {
    Applied:["Shortlisted"],
    Shortlisted:["For Interview","For Hire"],
    'For Interview':["For Hire"],
    "For Hire":["Hired"],
  }

  const moveOptions = Object.keys(_getStatus).filter((key)=>activeOptionPerStatus[status]?.includes(key))

  const disableInviteToApply = true
  
  return status ? (
    status !== 'Hired' ? (
      <motion.div
        className="relative flex flex-row justify-between p-2 pl-4 rounded-full cursor-pointer rounde-full bg-pj-green-200"
        initial="initial"
        whileHover={status!=="Rejected" && "hover"}
        animate="initial"
      >
        <div className="text-white">Move Applicant</div>
        <div className="flex items-center text-2xl text-white">
          <CgChevronRightO />
        </div>
        <motion.div
          variants={variant}
          className="absolute left-0 w-full pb-5 bottom-full"
        >
          <div className="left-0 w-full p-4 bg-white border border-gray-100 rounded-lg shadow-md bottom-full">
            <div className="mb-5 text-sm font-semibold text-pj-blue-500">
              Move applicant to:
            </div>
            {moveOptions.map(k => {
                return (
                  <div
                    className="flex flex-row items-center justify-between w-full p-2 px-4 mb-2 text-sm bg-gray-100 border rounded-full cursor-pointer text-pj-blue-100"
                    onClick={() => onClick(k)}
                  >
                    {_getStatus[k]}
                    <CgChevronRightO className="text-lg" />
                  </div>
                );
            })}
            <div
              onClick={() => onClick('Rejected')}
              className="flex flex-row items-center justify-between w-full p-2 px-4 mb-2 text-sm bg-gray-100 border rounded-full cursor-pointer text-pj-blue-100"
            >
              <CgChevronLeftO className="mr-2 text-lg" />
              Not Selected
            </div>
          </div>
        </motion.div>
      </motion.div>
    ) : (
      <div
        className={`flex flex-row justify-center p-2 pl-4 rounded-full rounde-full  relative ${
          isResigned ? 'bg-gray-500' : 'bg-red-500 cursor-pointer'
        }`}
        onClick={() => !isResigned && onResigned(null)}
      >
        <div className="text-white">
          {!isResigned ? `Mark as Resigned` : `Resigned`}
        </div>
      </div>
    )
  ) : (
    <div
      className={`relative flex flex-row justify-center p-2 pl-4 rounded-full cursor-pointer ${disableInviteToApply ? " bg-gray-200 text-gray-400":"bg-pj-green-200 text-white"}`}
      onClick={() => disableInviteToApply ? null : onInvite(null) }
    >
      <div>Invite to Apply</div>
    </div>
  );
};

export default MoveButton;
