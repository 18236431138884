import React from 'react';
import { motion } from 'framer-motion';
import { stagger } from '../../framer';

type Props = {
  title: string;
  headerRight?: React.ReactElement;
  children?: React.ReactNode;
  height?: number | string;
};

const Card = ({ title, headerRight, children, height = 'auto' }: Props) => {
  return (
    <motion.div className="bg-white shadow-lg rounded-lg pb-2">
      <div className="flex p-4 border-b-2">
        <div className="flex-auto">
          <span className="text-gray-350 uppercase text-sm">{title}</span>
        </div>
        <div className="flex text-xl items-end text-gray-350">
          {headerRight}
        </div>
      </div>
      <motion.div
        variants={stagger}
        initial="initial"
        animate="animate"
        style={{ height: height }}
      >
        {children}
      </motion.div>
    </motion.div>
  );
};

export default Card;
