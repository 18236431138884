import React, { useState, useEffect, useRef } from 'react';
import { usePdf } from 'react-pdf-js';
import { FaChevronLeft,FaChevronRight } from "react-icons/fa";

 
const PdfViewer = ({url}) => {
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState(null);
 
  const renderPagination = (page, pages) => {
    if (!pages) {
      return null;
    }
    const prevDisable = page===1
    const nextDisable = page===pages
    let previousButton = <button className={`flex flex-row items-center gap-2 ${prevDisable ? "text-gray-200": "hover:bg-gray-100"}`} disabled={prevDisable} onClick={() => setPage(page - 1)}><FaChevronLeft /> Previous</button>;
    let nextButton = <button className={`flex flex-row items-center gap-2 ${nextDisable ? "text-gray-200": "hover:bg-gray-100"}`} disabled={nextDisable}  onClick={() => setPage(page + 1)}>Next<FaChevronRight/></button>;
    return (
      <nav>
        {pages!==1 && <div className="flex flex-row justify-between w-full">
          {previousButton}
          <div>Page {page}</div>
          {nextButton}
        </div>}
      </nav>
    );
  }
 
  const canvasEl = useRef(null);
 
  const [loading, numPages] = usePdf({
    file: url,
    page,
    canvasEl
  });
 
  useEffect(() => {
    setPages(numPages);
  }, [numPages]);
 
  return (
    <div>
      {loading && <span>Loading...</span>}
      <canvas ref={canvasEl} />
      {renderPagination(page, pages)}
    </div>
  );
}
 
export default PdfViewer;