import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { AiOutlineQuestionCircle } from 'react-icons/ai';

const variant = {
  initial: {
    opacity: 0,
    y: -100,
    zIndex: -10,
    transition: {
      duration: 0.4,
    },
  },
  hover: {
    opacity: 1,
    y: 0,
    zIndex: 10000,
    transition: {
      duration: 0.4,
    },
  },
};

type Props = {
  children: React.ReactNode;
  defaultOffset?: number;
};

const Question = ({ children, defaultOffset = 0 }: Props) => {
  const [offset, setOffset] = useState<number>(0);
  const [right, setRight] = useState<number>(0);
  const positionRef: any = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      setOffset(
        positionRef?.current?.getBoundingClientRect()?.y + 20 + defaultOffset
      );
      setRight(positionRef?.current?.getBoundingClientRect()?.x);
    }, 1000);
  }, [positionRef]);

  return (
    <motion.div
      className="w-6 justify-end items-center flex cursor-pointer relative z-50"
      initial="initial"
      whileHover="hover"
      animate="initial"
      ref={positionRef}
      style={{ zIndex: 10000 }}
    >
      <AiOutlineQuestionCircle className="text-gray-400 hover:text-pj-blue-500" />
      <motion.div
        variants={variant}
        className={`fixed whitespace-normal w-64 p-2 bg-pj-blue-500 text-white text-xs text-left rounded-md`}
        style={{ top: offset }}
      >
        {children}
      </motion.div>
    </motion.div>
  );
};

export default Question;
