import { motion } from 'framer-motion';
import { slideUp } from '../../framer';
import moment from 'moment';
import { lang } from '../../lang';
import { abbreviate } from '../../helpers';

type OnClickFunction = (a: any) => void;

type Props = {
    active?: boolean;
    transaction_id: string;
    date?: string;
    type?: string;
    amount: number;
    logo?: string | null | undefined;
    job_position?: string | undefined;
    id?: number;
    transaction_type?: string | null;
    onClick?: OnClickFunction;
    company_name?: string | null;
};

const CreditTransaction = ({ active, transaction_id, date, type, amount, logo, job_position, id, transaction_type, onClick, company_name }: Props) => {
    // TODO: update log
    return (
        <motion.div
            className={`p-5 border-b cursor-pointer ${active ? 'bg-gray-100' : 'bg-white'
                } hover:bg-gray-100`}
            variants={slideUp}
            whileHover={{
                scale: 1.01,
                transition: {
                    duration: 0.2,
                },
            }}
            onClick={() => onClick && onClick(id)}
        >
            <div className="text-sm pj-blue-100 flex py-2">
                <div className="flex-auto text-sm pj-blue-100 text-pj-blue-100">TRANSACTION ID: {transaction_id}</div>
                <div className="w-15">
                    <span className="px-1 text-sm pj-blue-100 text-pj-blue-100">
                        {true ? moment(date).format('LL') : '---'}
                    </span>
                </div>
            </div>
            <div className="text-base pj-blue-400 flex rounded-lg border px-4 py-2 pj-blue-100 text-pj-blue-100">
                <div className="flex-auto font-semibold text-gray-600">{transaction_type?.toUpperCase()}</div>
                <div className="w-15">
                    <span className="pj-blue-300 font-normal px-1">
                        AMOUNT:
                    </span>
                    <span className="pj-blue-300 font-semibold text-gray-600 px-1">
                        {amount
                            ? lang.formatString(
                                lang.general.php_total,
                                amount.toLocaleString(
                                    undefined
                                )
                            )
                            : 'PHP 0'}
                    </span>
                </div>
            </div>
            <div className="flex justify-center items-center py-2">
                <div className="w-10">
                    <div className={`w-8 h-8 rounded-full border-2 border-gray-300 bg-cover bg-center flex justify-center items-center text-gray-500 text-xl ${(!logo || logo.length <= 0) &&
                        'bg-gray-200 tracking-wide'
                        }`}
                        style={{ backgroundImage: `url(${logo})` }}
                    >
                        {(!logo || logo.length <= 0) && abbreviate(company_name ? company_name : '')}
                    </div>
                </div>
                <div className="flex-auto text-sm pj-blue-100 text-pj-blue-100 flex flex-col">
                    <div className=' -mb-1'>{job_position}</div>
                    <div>{company_name}</div>
                </div>
            </div>
        </motion.div>
    );
};

CreditTransaction.defaultProps = {
    active: false,
    newly: 0,
};

export default CreditTransaction;
