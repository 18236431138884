import { motion } from 'framer-motion';
import { slideIn, stagger } from '../../framer';
import { lang } from '../../lang';

type Props = {
  1: boolean;
  2: boolean;
  3: boolean;
  4: boolean;
};

const JobSteps = (props: Props) => {
  return (
    <motion.div
      variants={stagger}
      initial="initial"
      animate="animate"
      className="w-2/5"
    >
      <motion.div
        variants={stagger}
        initial="initial"
        animate="animate"
        className="grid grid-cols-4 py-2 gap-3"
      >
        <motion.div
          variants={slideIn}
          className={`p-1  rounded-full ${
            props[1] ? 'bg-pj-green-200' : 'bg-pj-green-100'
          }`}
        ></motion.div>
        <motion.div
          variants={slideIn}
          className={`p-1  rounded-full ${
            props[2] ? 'bg-pj-green-200' : 'bg-pj-green-100'
          }`}
        ></motion.div>
        <motion.div
          variants={slideIn}
          className={`p-1  rounded-full ${
            props[3] ? 'bg-pj-green-200' : 'bg-pj-green-100'
          }`}
        ></motion.div>
        <motion.div
          variants={slideIn}
          className={`p-1  rounded-full ${
            props[4] ? 'bg-pj-green-200' : 'bg-pj-green-100'
          }`}
        ></motion.div>
      </motion.div>
      <motion.div
        variants={stagger}
        initial="initial"
        animate="animate"
        className="grid grid-cols-4 py-2 gap-2"
      >
        <motion.div
          variants={slideIn}
          className={`p-1  rounded-full text-center uppercase ${
            props[1] ? 'text-pj-green-300' : 'text-pj-green-100'
          }`}
        >
          {lang.steps.company}
        </motion.div>
        <motion.div
          variants={slideIn}
          className={`p-1  rounded-full text-center uppercase ${
            props[2] ? 'text-pj-green-300' : 'text-pj-green-100'
          }`}
        >
          {lang.steps.job_details}
        </motion.div>
        <motion.div
          variants={slideIn}
          className={`p-1  rounded-full text-center uppercase ${
            props[3] ? 'text-pj-green-300' : 'text-pj-green-100'
          }`}
        >
          {lang.steps.review}
        </motion.div>
        <motion.div
          variants={slideIn}
          className={`p-1  rounded-full text-center uppercase ${
            props[4] ? 'text-pj-green-300' : 'text-pj-green-100'
          }`}
        >
          {lang.steps.payment}
        </motion.div>
      </motion.div>
    </motion.div>
  );
};

export default JobSteps;
