import { useContext, useEffect, useState } from 'react';
import Board from 'react-trello';
import { Board2Context, BoardContext, CheckboxesContext } from '../../../utilities/context';
import Card from '../board-components/card';
import Header from '../board-components/header';
import Modal from '../../../components/modal';
import ButtonNext from '../../../components/buttons/button-next';
import moment from 'moment';
import { BoardApplicant, Interview as InterviewType, OnClickFunction } from '../../../utilities/types';
import { createInterview } from '../../../utilities/api_v2/jobs';
import { useParams } from 'react-router-dom';
import { updateManyJobApplications } from '../../../utilities/api_v2/jobs';
import { boardLabelFixer, getInitials, recoilSetter, removeAgingOnCertainAccounts, updateBoardRecoilInfo } from '../../../utilities/helper';
import { CircularProgress } from '@material-ui/core';
import Interview from '../../../components/schedule/interview';
import { useForm } from 'react-hook-form';
import { Params } from '..';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { boardAtom } from '../../../utilities/atoms/applicant_board/boardAtoms';
import { applicantCSVAtom, jobDataAtom } from '../../../utilities/atoms/applicant_board/applicantBoardAtoms';
import { checkBoxAtom } from '../../../utilities/atoms/applicant_board/checkedApplicantAtoms';
import { BsEyeFill } from 'react-icons/bs';
import { FaClipboardUser } from "react-icons/fa6";
import { forInterviewAging, preScreenedAging, shortlistedAging } from '../../../utilities/board-helper';
import NoSlotsModal from '../board-components/modal-noslots';
import { activeAging } from '../../../utilities/atoms/applicant_board/agingPopover';

export type CheckedApplicant = {
  status: string,
  applicantIds: number[]
}

const CheckedApplicantDefault: CheckedApplicant = {
  status: "",
  applicantIds: []
}


export const defaultValue: InterviewType = {
  address: null,
  date: null,
  start_time: null,
  end_time: null,
};

const defaultLaneData = {
  lanes: [
    {
      id: 'Rejected',
      title: 'Not Selected',
      label: 0,
      cards: [],
    },
    {
      id: 'Applied',
      title: 'Applied',
      label: 0,
      cards: [],
    },
    {
      id: 'Shortlisted',
      title: 'Pre-Screened',
      label: 0,
      cards: [],
    },
    {
      id: 'For Interview',
      title: 'For Interview',
      label: 0,
      cards: [],
    },
    {
      id: 'For Hire',
      title: 'Shortlisted',
      label: 0,
      cards: [],
    },
    {
      id: 'Hired',
      title: 'Hired',
      label: 0,
      cards: [],
    },
  ],
}


const TrelloBoard = () => {
  const {
    control,
    setValue,
    formState: { errors },
    reset,
    handleSubmit,
  } = useForm<any>({
    defaultValues: {
      ...defaultValue,
    },
  });
  const params: Params = useParams();
  const [applicant, setApplicant] = useState<BoardApplicant>();
  const [confirmation, setConfirmation] = useState<boolean>(false);
  const [appliedModal, setAppliedModal] = useState<boolean>(false);
  const [id, setId] = useState<number>(0);
  const [targetLane, setTargetLane] = useState<string>('');
  const [sourceLane, setSourceLane] = useState<string>('');
  const [show, setShow] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [error_message, setErrorMessage] = useState<string>('');
  const [laneData, setLaneData] = useState(defaultLaneData)
  const [isHiredOpen, setIsHiredOpen] = useState(false);
  const [noSlotsOpen, setNoSlotsOpen] = useState(false);
  const [hireLoading, setHiringLoading] = useState(false)
  const setActiveAgingId = useSetRecoilState(activeAging)

  //recoil 
  const [checkedApplicants, setCheckedApplicant] = useRecoilState(checkBoxAtom)
  const [boardInformationData, setBoardInformationData] = useRecoilState(boardAtom)
  const { company_name, logo, title } = useRecoilValue(jobDataAtom);
  const setCSVData = useSetRecoilState(applicantCSVAtom);
  const _updateManyJobApplications = async (ids: number[], status: string, sourceLane?: string) => {
    setHiringLoading(true)
    const payload = {
      ids,
      status
    }
    recoilSetter(setBoardInformationData, { isLoading: true })
    return await updateManyJobApplications(payload).then((data: any) => {
      if (params && params.type && params.id) {
        // _getBoardData(parseInt(params.id));
      }
      if (sourceLane) {
        updateBoardRecoilInfo(ids, status, sourceLane, data.data, boardInformationData, setBoardInformationData)
      }
      // _getSuggestedCandidate();
      setCheckedApplicant(CheckedApplicantDefault)
      return data
    }).catch((error) => {
      console.log(error)
      setError(true);
      //sample message
      setErrorMessage("Update Many Job Application Error");
    }).finally(() => {
      recoilSetter(setBoardInformationData, { isLoading: false })
      setSourceLane('')
      setHiringLoading(false);
      setConfirmation(false);
      setAppliedModal(false);
    })
  }

  const _createInterview = async (data) => {
    const payload = {
      ...data
    }
    return await createInterview(payload).then((data) => {
       _updateManyJobApplications([payload.application_id], 'For Interview', sourceLane)
      _onClose(false);
      reset({ "address": "" })
      return data
    }).catch((error) => {
      setError(true);
      //sample message
      setErrorMessage("Create Interview Error");
    })
  }



  const _onClose = (val = false) => {
    recoilSetter(setBoardInformationData, boardInformationData)
    setShow(false);
    setErrorMessage('');
    setError(false);
    setConfirmation(false);
    setAppliedModal(false)
    // reset()
    reset({ "address": "" })
  };

  const setControlData = (val: any) => {
    setValue('address', val.address);
    setValue('date', moment(val.date).toDate());
    setValue('start_time', moment(val.start_time).toDate());
    setValue('end_time', moment(val.end_time).toDate());
  }

  const _editInterview: OnClickFunction = (info: any) => {
    setControlData(info.interviews[0])
    setApplicant(info);
    setShow(true);
    setSourceLane('For Interview')
  };

  const _submitHandler: OnClickFunction = (data: any) => {
    if (applicant?.id) {
      data = {
        address: data.address,
        start_time: data.start_time,
        end_time: data.end_time,
        date: moment(data.date).format('YYYY-MM-DD'),
      };
      _createInterview({
        ...data,
        application_id: applicant.id,
      })
    }
  };

  const _confirmHandler = (status = 'Hired', arrayOfIds = [], many = id === 0 ? true : false) => {
    _updateManyJobApplications(many ? arrayOfIds : [id], status, sourceLane)
  };

  const agingHeaderDatas = [
    {
      status: "Shortlisted",
      slots:preScreenedAging - boardInformationData?.pre_screened?.length,
      icon:<BsEyeFill/>
    },
    {
      status: "For Interview",
      slots: forInterviewAging - boardInformationData?.for_interview?.length,
      icon:<FaClipboardUser/>
    },
    {
      status: "For Hire",
      slots:shortlistedAging - boardInformationData?.shortlisted?.length,
      icon:<FaClipboardUser />

    },
  ] 

  const dontHaveSlot = ({targetLaneId, idsCount}:{targetLaneId:string; idsCount:number | undefined}) => {
    setActiveAgingId(null)
    const slots = agingHeaderDatas.find(aging => aging.status === targetLaneId)?.slots
    if(slots !== undefined){
      if(slots>0){
        if(!!idsCount && idsCount>slots){
          setNoSlotsOpen(true)
          return true
        }
        return false
      }else {
        setNoSlotsOpen(true)
        return true
      }
    }else {
      return false
    }
  }

  const moveThroughAgingModal = ({sourceId, targetLaneId, cardDetails}) => {
    _onDragEnd(null,sourceId, targetLaneId, null, cardDetails)
  }

  const _onDragEnd: OnClickFunction = (
    cardId,
    sourceId,
    targetLaneId,
    position,
    cardDetails
  ) => {     
    //for loadmore buttons per card
    if(cardId === -1){
      return false
    }
    //if from  - not selected lane, same lane, hired lane
    if(sourceId === "Rejected" || sourceId === targetLaneId || sourceId === 'Hired'){
      return false;
    }
    
    if(removeAgingOnCertainAccounts() && dontHaveSlot({targetLaneId, idsCount:undefined})){
      return false
    }else if(!cardDetails.alreadyHired){

      setSourceLane(sourceId)
      // cant drag back to applied card
      if (targetLaneId === 'Applied') {
        return false;
      }
  
      // if move from applied to prescreened show modal for acknowledgement
      if (sourceId === 'Applied' && targetLaneId === 'Shortlisted') {
        setTargetLane(targetLaneId);
        setAppliedModal(true)
        setId(cardDetails.id);
        return;
      }

      // disable going back on for interview
      if(sourceId === "For Interview" && targetLaneId === "Shortlisted"){
        return false
      }

      // disable going back on Shortlisted
      if(sourceId === "For Hire"){
        if(targetLaneId === "Shortlisted"){
          return false
        }else if(targetLaneId === "For Interview"){
          return false
        }
      }
  
      if (targetLaneId === 'For Interview') {
        setApplicant(cardDetails);
        setShow(true);
        return;
      }
  
      if (targetLaneId === 'Hired') {
        setConfirmation(true);
        setId(cardDetails.id);
        setTargetLane(targetLaneId);
        return false;
      }
      _updateManyJobApplications([cardDetails.id], targetLaneId, sourceId)
    }else {
      if(targetLaneId === "Rejected"){
        _updateManyJobApplications([cardDetails.id], targetLaneId, sourceId)
      }else{
        setIsHiredOpen(true)
        return false;
      }
    }
  };

  const _onMoveMany: OnClickFunction = (
    sourceId,
    targetLaneId,
    arrayOfIds,
  cardDetails) => {
    if(removeAgingOnCertainAccounts() && dontHaveSlot({targetLaneId, idsCount:arrayOfIds.length})){
      return;
    }
    if (targetLaneId === 'For Interview' && cardDetails) {
      setApplicant(cardDetails);
      setShow(true);
      return;
    }
    if (sourceId === 'Applied' && targetLaneId === "Shortlisted") {
      setAppliedModal(true)
      setId(0)
      setTargetLane(targetLaneId);
      setSourceLane(sourceId)
      return;
    }
    _updateManyJobApplications(arrayOfIds, targetLaneId, sourceId)
    setActiveAgingId(null)
  }

  const _onCloseOnBoard = (val = false) => {
    _onClose(false)
    setConfirmation(false);
    setAppliedModal(false)
  };

  //created this function to fix conflict on direct assigning data - PS Dont know why direct assigning not working
  const toDestructureLaneData = (bdata) => {
    return bdata?.map((data) => {
      return {
        ...data
      }
    }) || []
  }

  useEffect(() => {
    const board_data = boardInformationData
    if (board_data) {
      const displayLoadMore = (status:'Rejected' | 'Applied' | 'Shortlisted' | 'For Interview' | 'For Hire' | 'Hired') => {
        const count = board_data.counts[status]
        const length = board_data[boardLabelFixer(status)].length
        return count > length ? [{id:-1}]:[]
      }
      setLaneData({
        lanes: [
          {
            id: 'Rejected',
            title: 'Not Selected',
            label: board_data?.rejected?.length,
            cards: board_data?.rejected ? [...toDestructureLaneData(board_data.rejected), ...displayLoadMore("Rejected")] : [],
          },
          {
            id: 'Applied',
            title: 'Applied',
            label: board_data?.applied?.length,
            cards: board_data?.applied ? [...toDestructureLaneData(board_data.applied), ...displayLoadMore("Applied")] : [],
          },
          {
            id: 'Shortlisted',
            title: 'Pre-Screened',
            label: board_data?.pre_screened?.length,
            cards: board_data?.pre_screened ? [...toDestructureLaneData(board_data.pre_screened), ...displayLoadMore("Shortlisted")] : [],
          },
          {
            id: 'For Interview',
            title: 'For Interview',
            label: board_data?.for_interview?.length,
            cards: board_data?.for_interview ? [...toDestructureLaneData(board_data.for_interview), ...displayLoadMore("For Interview")] : [],
          },
          {
            id: 'For Hire',
            title: 'Shortlisted',
            label: board_data?.shortlisted?.length,
            cards: board_data?.shortlisted ? [...toDestructureLaneData(board_data.shortlisted), ...displayLoadMore("For Hire")] : [],
          },
          {
            id: 'Hired',
            title: 'Hired',
            label: board_data?.hired?.length,
            cards: board_data?.hired ? [...toDestructureLaneData(board_data.hired), ...displayLoadMore("Hired")] : [],
          },
        ],
      })
    } else {
      setLaneData(defaultLaneData)
    }
  }, [boardInformationData])

  useEffect(() => {
    const applied = laneData.lanes[1].cards || []
    const pre_screened = laneData.lanes[2].cards || []

    //csv data setter for applicant
    const applicantCSVData = applied.slice(0, -1).map((applicant: any) => {
      return {
        name: getInitials(applicant.name),
        email: applicant.email,
        mobile_number: applicant.mobile_number,
        date: moment(applicant.created_at).format("LL")
      }
    })

    // //csv data setter for pre-screened
    const preScreenedCSVData = pre_screened.map((pre_screened: any) => {
      return {
        first_name: pre_screened.first_name,
        last_name: pre_screened.last_name,
        email: pre_screened.email,
        mobile_number: pre_screened.mobile_number,
        date: moment(pre_screened.created_at).format("LL"),
        company_name: company_name,
        job_title: title,
        current_job_title: pre_screened.job_title,
        referrer: pre_screened.referrer
      }
    })
    recoilSetter(setCSVData, { applicantCSVData, preScreenedCSVData })
  }, [laneData])
  
  const Section = (val: any) => {
    return <div className="p-1"></div>;
  };

  const components = {
    Card: Card,
    LaneHeader: Header,
    LaneFooter: Section,
    // ScrollableLane: ScrollableLane,
  };

  const value = {
    _onMoveMany,
    _editInterview,
    agingHeaderDatas,
    moveThroughAgingModal
  }

  return (
    <CheckboxesContext.Provider value={value}>
      <div className='relative '>
        {boardInformationData.isLoading && <div className='absolute top-0 bottom-0 left-0 right-0 z-40 flex items-center justify-center ' style={{ backgroundColor: "rgba(255, 255, 255, 0.1)" }}>
          <CircularProgress style={{ color: "rgba(255, 181, 135, 0.78)", opacity: 1 }} size={50} />
        </div>}
        <Board
          data={laneData}
          style={{
            maxHeight: 'calc(100vh - 11rem)',
          }}
          laneStyle={{
            backgroundColor: '#F5F6F7',
            borderRadius: 7,
            padding: 0,
            width: 300,
          }}
          className="gradient-30"
          cardStyle={{ width: '100%' }}
          hideCardDeleteIcon
          collapsibleLanes
          components={components}
          cardDragClass="rounded-lg shadow-xl bg-white"
          handleDragEnd={_onDragEnd}
          // eventBusHandle={_setEventBus}
          cardDraggable={boardInformationData?.status === 'Filled Up' ? false : true}
        />
        <NoSlotsModal show={noSlotsOpen} setShow={setNoSlotsOpen}/>
        <Modal title="Error" show={error} closable onClose={_onClose} width={350}>
          <div className="w-full p-2 pt-5 text-sm text-left text-pj-blue-500">
            {error_message}
            <div className="w-full pt-5">
              <ButtonNext
                label="OK"
                buttonType="button"
                onClick={_onClose}
                full
              />
            </div>
          </div>
        </Modal>
        <Modal
          title="Already Hired"
          closable
          onClose={setIsHiredOpen}
          show={isHiredOpen}
        >
          <div className='p-4 text-sm '>
          This candidate has already been hired by another company.
          </div>
        </Modal>
        <Modal title="Candidate Info Acknowledgment" onClose={_onClose} show={appliedModal}>
          <div className="p-2 text-left text-pj-blue-500">
            By moving the candidate's card, you acknowledge you acquired this applicant's information via PasaJob. Once candidate details have been shared, you cannot move the candidate card back to the Applied bin.
          </div>
          <div className="flex flex-row px-10 space-around">
            <div className="w-full p-2">
              <ButtonNext label="CANCEL" onClick={_onClose} full light />
            </div>
            <div className="w-full p-2">
              <ButtonNext label="CONFIRM" onClick={() => _confirmHandler('Shortlisted', checkedApplicants.applicantIds)} full />
            </div>
          </div>
        </Modal>
        <Modal title="Hire" closable onClose={_onCloseOnBoard} show={confirmation}>
          <div className="p-2 text-left text-pj-blue-500">
            Once you move the candidate card to Hired, referral fees will be
            released based on your disbursement preference. Proceed?
          </div>
          <div className="flex flex-row px-10 space-around">
            <div className="w-full p-2">
              <ButtonNext label="CANCEL" onClick={_onCloseOnBoard} full light />
            </div>
            <div className="w-full p-2">
              <ButtonNext disabled={hireLoading} label="OK" onClick={() => _confirmHandler("Hired")} full />
            </div>
          </div>
        </Modal>

        <Modal
          show={show}
          title="Schedule an Interview"
          closable
          onClose={_onClose}
          top={100}
        >
          <Interview
            _onClose={_onClose}
            handleSubmit={handleSubmit}
            _submitHandler={_submitHandler}
            control={control}
            info={{
              name: applicant?.name ?? '',
              profile_image: applicant?.profile_image ?? null,
              company_name,
              company_image: logo ?? null,
              job_title: applicant?.job_title ?? '',
            }}
            errors={errors}
          />
        </Modal>
      </div>
    </CheckboxesContext.Provider>
  );
};

export default TrelloBoard;