import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import ButtonExit from '../../../components/buttons/button-exit';
import ButtonNext from '../../../components/buttons/button-next';
import InputField from '../../../components/forms/input';
import Loading from '../../../components/loader';
import { stagger } from '../../../framer';
import { lang } from '../../../lang';
import { CreateJobContext } from '../../../utilities/context';
import { OnClickFunction } from '../../../utilities/types';
import { getExperiences } from '../../../utilities/api_v2/job_create';

type Experience = {
  label: string;
  value: string;
};

const StepTwoTen = () => {
  const { control, handleSubmit, errors, _submitForm, setValue, common_job } =
    useContext(CreateJobContext);

  const [experiences, seExperiences] = useState<Experience[]>([]);
  const [select, setSelect] = useState<string | null>();


  const [loading, setLoading] = useState(false)

  const _getExperiences = async () => {
    setLoading(true)
    return await getExperiences().then((exp)=>{
      const items: Experience[] = exp.data.map(
        ({ label, value }) => ({ label, value })
      );
      seExperiences(items);
      console.log(items, "_getExperiences")
    }).finally(()=>setLoading(false))
  }

  const _onClick: OnClickFunction = (experience: string) => {
    setSelect(experience);
    setValue && setValue('job_experience', experience);
  };

  useEffect(() => {
    setSelect(common_job.job_experience);
  }, [common_job.job_experience]);

  useEffect(()=>{
    _getExperiences()
  },[])

  return (
    <form
      className="flex flex-col flex-1"
      onSubmit={handleSubmit && _submitForm && handleSubmit(_submitForm)}
    >
      <InputField
        name="job_experience"
        label=""
        control={control}
        error={errors?.job_experience}
        type="hidden"
        defaultValue={select}
        rules={{ required: true }}
      />
      <div className="flex flex-initial p-4 border-b">
        <p className="p-0 m-0 text-base text-pj-blue-100">
          {lang.step_two.select_work_experience}
        </p>
      </div>
      <div
        className="flex-initial overflow-auto h-screen/2"
        id="journal-scroll"
      >
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          className="w-full overflow-x-hidden overflow-y"
          id="journal-scroll"
        >
          <div
            className="flex flex-col flex-1 overflow-x-hidden"
            id="journal-scroll"
          >
            <div className="overflow-y">
              {experiences.map(({ label, value }, index) => {
                return (
                  <div
                    className={`p-4 py-6 text-pj-blue-300 text-lg flex flex-initial cursor-pointer ${
                      select === value && 'bg-pj-green-100'
                    }`}
                    onClick={() => _onClick(value)}
                    key={`${index}`}
                  >
                    {label}
                  </div>
                );
              })}
            </div>
          </div>
        </motion.div>
        <Loading show={loading} />
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row items-end flex-1 p-4">
          <div className="flex items-end justify-start w-1/2 h-20">
            <ButtonExit />
          </div>
          <div className="flex items-end justify-end w-1/2 h-20">
            <ButtonNext />
          </div>
        </div>
      </div>
    </form>
  );
};

export default StepTwoTen;
