import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { RiArrowDownSLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { auth } from '../utilities/auth';
import Avatar from './avatar';
import { getEmployeeName } from '../utilities/api_v2/dashboard';

const variant = {
  show: {
    opacity: 1,
    display: 'block',
    y: 0,
    transition: {
      duration: 0.5,
    },
  },
  hide: {
    opacity: 0,
    display: 'none',
    y: 50,
    transition: {
      duration: 0.5,
    },
  },
};

const ProfileSelect = ({ open }: { open: boolean }) => {
  const [show_profile, setShowProfile] = useState<boolean>(false);
  const [employer_info, setEmployerInfo] = useState<any>(null);

  const _employeeName = async () => {
    return await getEmployeeName({
      id: auth.getId(),
    }).then((data:any)=>{
      const name = data?.data;
      setEmployerInfo(name);
    })
  }

  useEffect(()=>{
    _employeeName()
  },[])

  return (
    <div
      className="relative flex flex-row items-center justify-start px-3 pl-4 mt-10 mb-3 cursor-pointer"
      onClick={() => setShowProfile(show_profile => !show_profile)}
      style={{ zIndex: 2000 }}
    >
      <Avatar
        name={`${employer_info?.first_name ?? ''} ${
          employer_info?.last_name ?? ''
        }`}
        url={employer_info?.employer_profile?.profile_image}
        size={open ? 50 : 40}
      />
      {open && (
        <>
          <RiArrowDownSLine className="w-5 h-5 ml-2 text-lg text-pj-blue-100" />
          <motion.div
            variants={variant}
            initial="hide"
            animate={show_profile ? 'show' : 'hide'}
            className="absolute z-10 p-2 bg-white border border-gray-100 rounded-md shadow-lg top-full"
            style={{ minHeight: 50, zIndex: 1000, width: 150 }}
          >
            <Link
              to="/settings/profile"
              className="block p-2 py-1 text-sm rounded text-pj-blue-500 hover:bg-green-100"
            >
              Settings
            </Link>
            <Link
              to="/logout"
              className="block p-2 py-1 text-sm rounded text-pj-blue-500 hover:bg-green-100"
            >
              Logout
            </Link>
          </motion.div>
        </>
      )}
    </div>
  );
};

export default ProfileSelect;
