import { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/buttons/button';
import { abbreviate } from '../../../helpers';
import { lang } from '../../../lang';
import { CheckoutContext } from '../../../utilities/context';
import { useRecoilValue } from 'recoil';
import { checkoutAtom } from '../../../utilities/atoms/checkout';

const StepFourOne = () => {
  const commonJobs = useRecoilValue(checkoutAtom)
  const { checkout } = useContext(CheckoutContext);
  const location = useHistory();

  const table: { title: string; value: string }[] = [
    {
      title: lang.step_four.referral_fee,
      value: lang.formatString(
        lang.step_four.amount,
        checkout.referral_fee.toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })
      ) as string,
    },
    commonJobs?.referral_count && commonJobs?.old_referral_count_2 && !commonJobs.isNewJob && {
      title: lang.step_four.additional_headcount,
      value: (commonJobs.referral_count - commonJobs.old_referral_count_2).toString(),
    },
    {
      title: commonJobs && !commonJobs.isNewJob ? lang.step_four.total_number_of_individual : lang.step_four.number_of_individual,
      value: checkout.referral_count.toString(),
    },
    {
      title: lang.step_four.processing_fee,
      value: lang.formatString(
        lang.step_four.amount,
        checkout.processing_fee.toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })
      ) as string,
    },
    {
      title: lang.step_four.posting_fee,
      value: lang.formatString(
        lang.step_four.amount,
        checkout.posting_fee.toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })
      ) as string,
    },
    {
      title: lang.step_four.vat,
      value: lang.formatString(
        lang.step_four.amount,
        checkout.vat.toLocaleString(undefined, { minimumFractionDigits: 2 })
      ) as string,
    },
  ];

  return (
    <div className="p-4">
      <div className="flex flex-row space-between">
        <div className="w-1/2 text-lg text-pj-green-300">
          {lang.step_four.new_job}
        </div>
        <div className="w-1/2 text-right text-lg text-pj-blue-400">
          {lang.formatString(
            lang.step_four.amount,
            checkout.total.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          )}
        </div>
      </div>
      <div className="w-full rounded-lg border-gray-200 p-4 mt-4">
        <div
          className={`w-32 h-16 bg-cover bg-left flex justify-center items-center text-gray-500 ${(!checkout.company_image || checkout.company_image.length <= 0) &&
            'bg-gray-200 text-2xl tracking-wide'
            }`}
          style={{ backgroundImage: `url(${checkout.company_image})` }}
        >
          {(!checkout.company_image || checkout.company_image.length <= 0) &&
            abbreviate(checkout.company_name ?? '')}
        </div>
        <div className="text-lg text-pj-blue-400 mt-2">{`${checkout.job_title} • ${checkout.company_name}`}</div>
      </div>
      <div className="h-1 bg-pj-blue-500 w-full my-6"></div>
      {table.map(val => {
        //return for empty col in table to remove padding
        if (!val) { return <></> }
        return (
          <div className="flex flex-row space-between mb-2">
            <div className="w-1/2 text-base text-pj-blue-100">{val.title}</div>
            <div className="w-1/2 text-right text-base text-pj-blue-100">
              {val.value}
            </div>
          </div>
        );
      })}
      <div className="h-1 bg-pj-blue-500 w-full my-6"></div>
      <div className="flex flex-row space-between">
        <div className="w-1/2 text-lg text-pj-blue-500 font-semibold">
          {lang.step_four.total_amount}
        </div>
        <div className="w-1/2 text-right text-lg text-pj-blue-500 font-semibold">
          {lang.formatString(
            lang.step_four.amount,
            checkout.total.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          )}
        </div>
      </div>
      <div className="flex flex-row justify-end mt-32">
        <Button
          to={{
            pathname: `/checkout/4/2/${checkout.id}`,
            state: { prev: location.location.pathname },
          }}
          label={lang.step_four.continue_to_payment}
        />
      </div>
    </div>
  );
};

export default StepFourOne;
