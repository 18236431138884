import InputField from '../forms/input';
import 'react-datepicker/dist/react-datepicker.css';
import ButtonNext from '../buttons/button-next';
import {
  BoardApplicant,
  BoardJobInfo,
  HookForm,
  OnClickFunction,
  ScheduleInfo,
} from '../../utilities/types';
import Avatar from '../avatar';

interface Props extends HookForm {
  _submitHandler?: OnClickFunction | undefined;
  _onClose?: OnClickFunction | undefined;
  info: ScheduleInfo;
}

const Interview = ({
  info,
  control,
  errors,
  _submitHandler,
  handleSubmit,
  _onClose,
}: Props) => {
  return (
    <>
      <div className="flex flex-row py-2">
        <div className="pr-2">
          <Avatar name={info.company_name} url={info.company_image} size={25} />
        </div>
        <div className="flex items-center justify-center text-sm text-pj-blue-100">
          {info.company_name}
        </div>
      </div>
      <div className="p-1 text-sm text-left text-pj-blue-500">Interviewee</div>
      <div className="flex flex-row p-2 bg-gray-100 border rounded-lg">
        <div className="pr-2">
          <Avatar name={info.name} url={info.profile_image} size={50} />
        </div>
        <div className="flex flex-col justify-center">
          <div className="flex items-center justify-start text-sm flex-start text-pj-blue-500">
            {info.name}
          </div>
          <div className="flex items-center justify-start text-sm flex-start text-pj-blue-100">
            {info.job_title}
          </div>
        </div>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit(_submitHandler)()
        }
        }
        className="mt-5"
      >
        <InputField.Date
          control={control}
          name="date"
          label="Date"
          dark
          rules={{ required: 'Date is required' }}
          error={errors && errors.date}
        />
        <InputField
          control={control}
          name="address"
          label="Location"
          dark
          placeholder="Select a Location"
          rules={{ required: 'Place is required' }}
          error={errors && errors.address}
        />
        <div className="flex flex-row justify-between">
          <div className="w-full pr-1">
            <InputField.Time
              control={control}
              name="start_time"
              label="Start Time"
              dark
              rules={{ required: 'Start time is required' }}
              error={errors && errors.start_time}
            />
          </div>
          <div className="w-full pl-1">
            <InputField.Time
              control={control}
              name="end_time"
              label="End Time"
              dark
              rules={{ required: 'End time is required' }}
              error={errors && errors.end_time}
            />
          </div>
        </div>
        <div className="flex flex-row justify-around pt-5">
          <div style={{ width: '30%' }} className="pr-1">
            <button
              className={`flex w-full flex-row justify-center px-4 rounded-full text-base h-10 items-center text-pj-blue-100 cursor-pointer bg-gray-100 border border-gray-200`}
              type={'button'}
              onClick={_onClose}
            >
              {"Cancel"}
            </button>
            {/* <ButtonNext label="Cancel" light full onClick={_onClose} /> */}
          </div>
          <div style={{ width: '70%' }} className="pl-1">
            <ButtonNext label="Save this Schedule" buttonType="submit" full />
          </div>
        </div>
      </form>
    </>
  );
};
export default Interview;
