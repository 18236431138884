import { BsBoxArrowInLeft, BsQuestionCircle } from "react-icons/bs";

const Credits = () => {
    const styles = {
        cardStyle: {
            fontSize: "calc(1px + 1.7vmin)",
            height: '15vh',
            minWidth: 'calc(30vh)'
        },
        creditFont: { fontSize: "calc(10px + 2vmin)" },
        phpFont: { fontSize: "calc(1px + 1.8vmin)" }

    }
    return (
        <div className='flex justify-center items-center flex-row bg-pj-blue-500 h-1/4'>
            <div className=' flex flex-row justify-center items-center w-full gap-5'>
                <div className='rounded-lg bg-white flex items-center justify-center flex-col'
                    style={styles.cardStyle}>
                    <div className=' flex flex-row text-gray-400 mb-1'>
                        AVAILABLE CREDITS<BsQuestionCircle size={12} className="ml-2" />
                    </div>
                    <div className=' font-semibold font-display flex flex-row justify-center items-start gap-1 text-gray-600' style={styles.creditFont}>
                        <span style={styles.phpFont}>Php</span>700,000.00
                    </div>
                </div>
                <div className='rounded-lg bg-white flex items-center justify-center flex-col'
                    style={styles.cardStyle}>
                    <div className=' flex flex-row text-gray-400 mb-1'>
                        ACTIVE JOB POSTS VALUE<BsQuestionCircle size={12} className="ml-2" />
                    </div>
                    <div className=' font-semibold font-display flex flex-row justify-center items-start gap-1 text-gray-600' style={styles.creditFont}>
                        <span style={styles.phpFont}>Php</span>700,000.00
                    </div>
                </div>
                <div className='rounded-lg bg-white flex items-center justify-center flex-col'
                    style={styles.cardStyle}>
                    <div className=' flex flex-row text-gray-400 mb-1'>
                        TOTAL<BsQuestionCircle size={12} className="ml-2" />
                    </div>
                    <div className=' font-semibold font-display flex flex-row justify-center items-start gap-1 text-gray-600' style={styles.creditFont}>
                        <span style={styles.phpFont}>Php</span>700,000.00
                    </div>
                </div>
                <div className='rounded-lg bg-white flex items-center justify-center flex-col'
                    style={styles.cardStyle}>
                    <div className=' flex flex-row text-gray-400 mb-1'>
                        <BsBoxArrowInLeft className="ml-2 text-gray-600" style={{ fontSize: "calc(1px + 5vmin)" }} />
                    </div>
                    <div className=' font-display flex flex-row justify-center items-start gap-1 text-gray-600'>
                        Top Up Credits
                    </div>
                </div>
            </div>
        </div>);
}

export default Credits;