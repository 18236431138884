import { motion } from 'framer-motion';
import { useContext } from 'react';
import ButtonExit from '../../../components/buttons/button-exit';
import ButtonNext from '../../../components/buttons/button-next';
import InputField from '../../../components/forms/input';
import { stagger } from '../../../framer';
import { lang } from '../../../lang';
import { CreateJobContext } from '../../../utilities/context';

const StepTwo = () => {
  const { control, handleSubmit, errors, _submitForm, jobId } =
    useContext(CreateJobContext);
  return (
    <form
      onSubmit={handleSubmit && _submitForm && handleSubmit(_submitForm)}
      className="flex flex-1 flex-col"
    >
      <div className="flex-initial h-screen/2">
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          className="w-full p-4"
        >
          <InputField
            label={lang.step_two.job_title}
            name="title"
            type="text"
            control={control}
            rules={{ required: true }}
            error={errors?.title}
            placeholder={lang.step_two.job_title_placeholder}
          />
        </motion.div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row flex-1 items-end p-4">
          <div className="h-20 w-1/2 flex justify-start items-end">
            {!jobId && <ButtonExit />}
          </div>
          <div className="h-20 w-1/2 flex justify-end items-end">
            <ButtonNext />
          </div>
        </div>
      </div>
    </form>
  );
};

export default StepTwo;
