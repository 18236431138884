import { useEffect } from 'react';

type StatusType =
  | 'Applied'
  | 'Rejected'
  | 'For Interview'
  | 'Shortlisted'
  | 'For Hire'
  | 'Hired'
  | 'Invited'
  | 'Not Yet Invited'
  | undefined
  | null;

type Props = {
  status: StatusType;
};

const Status = (props: Props) => {
  const getStatus = (status: StatusType) => {
    if (!status) {
      return null;
    }
    const statuses = {
      Rejected: 'Not Qualified',
      Applied: 'Applied',
      Shortlisted: 'Pre-Screened',
      'For Interview': 'For Interview',
      'For Hire': 'Shortlisted',
      Hired: 'Hired',
      Invited: 'Invited',
      'Not Yet Invited': 'Not Yet Invited',
    };
    return statuses[status];
  };

  return (
    <div className="flex justify-end py-4">
      <div
        className={`border rounded-full  h-auto inline px-2 leading-6 p-0 items-center flex uppercase text-xs ${
          props.status === 'Not Yet Invited'
            ? 'border-gray-300 text-pj-blue-100'
            : 'border-pj-green-200 text-pj-green-200'
        }`}
      >
        {getStatus(props.status)}
      </div>
    </div>
  );
};

export default Status;
