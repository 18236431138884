import { motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import ButtonExit from '../../../components/buttons/button-exit';
import ButtonNext from '../../../components/buttons/button-next';
import InputField from '../../../components/forms/input';
import CeoModal from '../../../components/job-post/ceo-modal';
import Loading from '../../../components/loader';
import { stagger } from '../../../framer';
import { lang } from '../../../lang';
import { CreateJobContext } from '../../../utilities/context';
import { OnClickFunction } from '../../../utilities/types';
import { getPositions } from '../../../utilities/api_v2/job_create';

type Position = {
  label: string;
  value: string;
  referral_amount: number;
};

const StepTwoFour = () => {
  const {
    control,
    handleSubmit,
    errors,
    _submitForm,
    setValue,
    common_job,
    _setMin,
  } = useContext(CreateJobContext);

  const [positions, sePositions] = useState<Position[]>([]);
  const [modal, setModal] = useState(false);
  const [select, setSelect] = useState<string | null>();
  const [loading, setLoading] = useState(false);
  const _getPositions = async () => {
    setLoading(true)
    return await getPositions().then((p)=>{
      const items: Position[] = p.data.map(
        ({ label, value }) => ({ label, value })
      );
      sePositions(items);
    })
    .finally(()=>setLoading(false))
  }

  const _onClick: OnClickFunction = (
    position: string,
    referral_amount: number
  ) => {
    setSelect(position);
    if (setValue && _setMin) {
      setValue('job_position_level', position);
      _setMin(referral_amount);
    }
    if(position == 'CEO / VP / Director' && localStorage.getItem('isAdmin') == 'false' ){
      setModal(true);
    }
  };

  useEffect(() => {
    setSelect(common_job.job_position_level);
  }, [common_job.job_position_level]);

  useEffect(()=>{
    _getPositions()
  },[])

  return (
    <>
    <CeoModal dialog={modal} setDialog={setModal}/>
    <form
      className="flex flex-col flex-1"
      onSubmit={handleSubmit && _submitForm && handleSubmit(_submitForm)}
    >
      <InputField
        name="job_position_level"
        label=""
        control={control}
        error={errors?.job_position_level}
        type="hidden"
        defaultValue={select}
        rules={{ required: true }}
      />
      <div className="flex flex-initial p-4 border-b">
        <p className="p-0 m-0 text-base text-pj-blue-100">
          {lang.step_two.select_level}
        </p>
      </div>
      <div
        className="flex-initial overflow-auto h-screen/2"
        id="journal-scroll"
      >
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          className="w-full overflow-x-hidden overflow-y"
          id="journal-scroll"
        >
          <div
            className="flex flex-col flex-1 overflow-x-hidden"
            id="journal-scroll"
          >
            <div className="overflow-y">
              {positions.map(({ label, value, referral_amount }, index) => {
                return (
                  <div
                    className={`p-4 py-6 text-pj-blue-300 text-lg flex flex-initial cursor-pointer ${
                      select === value && 'bg-pj-green-100'
                    }`}
                    onClick={() => _onClick(value, referral_amount)}
                    key={`${index}`}
                  >
                    {label}
                  </div>
                );
              })}
            </div>
          </div>
        </motion.div>
        <Loading show={loading} />
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row items-end flex-1 p-4">
          <div className="flex items-end justify-start w-1/2 h-20">
            <ButtonExit />
          </div>
          <div className="flex items-end justify-end w-1/2 h-20">
            <ButtonNext />
          </div>
        </div>
      </div>
    </form>
    </>
  );
};

export default StepTwoFour;
