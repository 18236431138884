import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import ButtonNext from '../../components/buttons/button-next';
import CompanyList from '../../components/company-list';
import Search from '../../components/forms/search';
import { auth } from '../../utilities/auth';
import { Company } from '../../utilities/types';
import { CompanyContext } from '../../utilities/context';
import { Link } from 'react-router-dom';
import Loading from '../../components/loader';
import { getCompanies, getCompaniesCount } from '../../utilities/api_v2/companies';

type Props = {
  children: React.ReactNode;
};

const CompanyTemplate = ({ children }: Props) => {
  const { resetFields } = useContext(CompanyContext);
  const [limit, setLimit] = useState(10)
  const { handleSubmit, register } = useForm();
  const [companiesCount, setCompaniesCount] = useState(0)
  const [loading, setLoading] = useState(false);
  // const [getCompany, { data: employer_companies, loading }] = useLazyQuery(
  //   queries.COMPANIES,
  //   {
  //     fetchPolicy: 'no-cache',
  //   }
  // );
  const [companies, setCompanies] = useState<Company[]>([]);
  const [where, setWhere] = useState<object>({
    // _or: [
    //   {
    //     employer_id: { _eq: auth.getId() },
    //   },
    //   {
    //     sub_employers_companies: {
    //       sub_employer_id: {
    //         _eq: auth.getId(),
    //       },
    //     },
    //   },
    // ],
    "OR": [
      {
        "employer_id": auth.getId()
      },
      {
        "sub_employers_company": {
          "some": {
            "sub_employer_id": auth.getId()
          }
        }
      }
    ]
  });
  useEffect(() => {
    (async () => {
      let payload = {
        where: where,
      };
      const result: any = await getCompaniesCount(payload);
      setCompaniesCount(result.data)
      console.log(result)
    })();
  }, [where]);

  const addTenToLimit = () => {
    setLimit((prev) => prev + 10)
  }

  useEffect(() => {
    setLimit(10)
  }, [where])

  // useEffect(() => {
  //   getCompany({
  //     variables: {
  //       where,
  //       limit
  //     },
  //   });
  //   console.log(companies)
  // }, [limit, where]);

  useEffect(() => {
    (async () => {
      let payload = {
        where: where,
        limit: limit,
      }
      setLoading(true)
      const result: any = await getCompanies(payload);
      setLoading(false)
      if (result.data.length) {
        const items: Company[] = result.data.map(
          ({
            name,
            id,
            company_image,
            address,
            province_id,
            city_id,
            pending,
            rejected,
            _count,
            employer_id,
            common_location_cities: { name: city_name },
            common_location_provinces: { name: province_name },
          }) => ({
            name,
            id,
            logo: company_image,
            total_jobs: _count.common_jobs,
            address,
            city_id,
            pending,
            rejected,
            province_id,
            city_name,
            province_name,
            employer_id,
            total_files: _count.company_files,
          })
        );

        console.log(items, 'items')
        setCompanies(items);
      }
    })();
  }, [limit, where]);


  // useEffect(() => {
  //   if (employer_companies) {
  //     const items: Company[] = employer_companies.employer_companies.map(
  //       ({
  //         name,
  //         total_jobs,
  //         id,
  //         company_image,
  //         address,
  //         province_id,
  //         city_id,
  //         pending,
  //         employer_id,
  //         total_files,
  //         common_location_city: { name: city_name },
  //         common_location_province: { name: province_name },
  //       }) => ({
  //         name,
  //         id,
  //         logo: company_image,
  //         total_jobs: total_jobs.aggregate.count,
  //         address,
  //         city_id,
  //         pending,
  //         province_id,
  //         city_name,
  //         province_name,
  //         employer_id,
  //         total_files: total_files.aggregate.count,
  //       })
  //     );
  //     setCompanies(items);
  //   }
  // }, [employer_companies]);

  const _searchCompany = (data: any) => {
    if (data.search.length > 0) {
      setWhere({
        ...where,
        name: {
          contains: data.search,
          mode: 'insensitive',
        },
      });
    } else {
      const newWhere: any = { ...where };
      delete newWhere?.name;
      setWhere({ ...newWhere });
    }
  };
  
  return (
    <div
      className="grid h-full grid-cols-3 gap-0 overflow-auto"
      id="journal-scroll"
    >
      <div className="w-full overflow-scroll bg-white border-l-2 border-r-2">
        <form
          onSubmit={handleSubmit && handleSubmit(_searchCompany)}
          className="flex items-center justify-center w-full h-20 px-4 border-b"
        >
          {register && <Search name="search" register={register} />}
        </form>
        <div className="max-h-full overflow-scroll-y">
          {loading && limit <= 10 ?
            <div className="w-full h-72">
              <Loading show={loading} />
            </div>
            : (companies.length ? <>
              {companies.map(company => (
                <CompanyList {...company} />
              ))}
              {loading ?
                <div className='w-full mt-3 text-center text-gray-350'>Loading ...</div> :
                (limit < companiesCount ? <div className='w-full my-3 text-center text-gray-600 cursor-pointer ' onClick={addTenToLimit}>Load more</div> : <></>)
              }</> : <div className='flex items-center justify-center w-full mb-10 text-gray-350 mt-36'>Currently no data available</div>)}

          <div className="flex justify-center py-4">
            <Link to='/my-companies'>
              <ButtonNext
                label="Add New Company"
                onClick={resetFields}
                light
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="h-full col-span-2 gradient-30">{children}</div>
    </div>
  );
};

export default CompanyTemplate;
