export const test = null;

let abbreviate: (words: string) => string;

const step_one: number = 1;
const step_two: number = 20;
const step_three: number = 2;

abbreviate = (words: string) => {
  if (words) {
    return words.replace(/(\w)\w*\W*/g, function (_, i) {
      return i.toUpperCase();
    });
  }
  return '';
};

function clearNumber(value = '') {
  return value.replace(/\D+/g, '');
}

function formatCVC(value, prevValue, allValues = {}) {
  const clearValue = clearNumber(value);
  let maxLength = 3;

  return clearValue.slice(0, maxLength);
}

function formatExpirationDate(value) {
  const clearValue = clearNumber(value);

  if (clearValue.length >= 3) {
    return `${clearValue.slice(0, 2)}/${clearValue.slice(2, 4)}`;
  }

  return clearValue;
}

const formatCreditCardNumber = value => {
  if (!value) {
    return value;
  }
  const clearValue = clearNumber(value);
  let nextValue = `${clearValue.slice(0, 4)} ${clearValue.slice(
    4,
    10
  )} ${clearValue.slice(10, 15)}`;

  return nextValue.trim();
};

const convertStringToHttps = (httpText: string) => {
  if (httpText.startsWith("http://")) {
    return httpText.replace("http://", "https://");
  }

  return httpText
}

export { abbreviate, step_one, step_two, step_three, formatCreditCardNumber, convertStringToHttps };
