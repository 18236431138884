import { RiUploadLine } from 'react-icons/ri';
import { OnClickFunction } from '../../utilities/types';

type Props = {
  file?: string | null;
  onChange?: OnClickFunction;
  disabled?: boolean;
};

const Upload = ({ file, onChange, disabled }: Props) => {
  return (
    <div className="w-24 h-24 border border-gray-300 rounded-full bg-white items-center cursor-pointer">
      <label className="w-full h-full flex justify-center items-center flex-col">
        {!file ? (
          <div className="p-4">
            <div className="w-full flex justify-center items-center">
              <RiUploadLine className="text-gray-400" />
            </div>
            <div className="w-full text-gray-400 text-xs text-center">
              Upload company logo
            </div>
          </div>
        ) : (
          <div
            className="bg-cover bg-center w-full h-full rounded-full"
            style={{ backgroundImage: `url("${file}")` }}
          ></div>
        )}
        <input 
          type="file" 
          className="hidden" 
          onChange={onChange} 
          disabled={disabled} 
          accept="image/png, image/jpeg"
        />
      </label>
    </div>
  );
};

export default Upload;
