import React from 'react';
import { motion } from 'framer-motion';
import { slideIn } from '../framer';
import Avatar from './avatar';

type Props = {
  image?: React.ReactElement;
  seeker: string;
  position: React.ReactElement;
  schedule?: React.ReactElement;
  time: string;
  first?: boolean;
  is_activity?: boolean;
};

const Interview = ({
  image,
  seeker,
  position,
  schedule,
  time,
  first,
  is_activity,
}: Props) => {
  return (
    <motion.div
      variants={slideIn}
      className={`flex px-4 pl-2 py-5 space-x-2 ${
        first
          ? 'border-pj-green-200 shadow-md h-32 border-l-4'
          : 'h-24 border-t'
      }`}
    >
      <div className="flex justify-center px-2">
        <Avatar name="" url="" size={40} />
      </div>
      <div className="flex-auto">
        <div className={`flex ${schedule && 'pb-5'}`}>
          <div className="flex-auto">
            <p className={`text-sm font-semibold `}>{seeker}</p>
            <p className="text-xs text-pj-blue-100">{position}</p>
          </div>
          <div className="flex w-32 text-right justify-end">
            <p className="text-xs text-pj-green-200">{time}</p>
          </div>
        </div>
        {first && <div className="flex text-xs">{schedule}</div>}
      </div>
    </motion.div>
  );
};

export { Interview };
