import React from 'react';
import { motion } from 'framer-motion';
import { slideIn } from '../framer';
import Avatar from './avatar';

type Props = {
  name?: string;
  image?: string;
  type: string;
  position: React.ReactElement;
  schedule?: React.ReactElement;
  time: string;
};

const Activity = ({
  name,
  image,
  type,
  position,
  schedule,
  time,
}: Props) => {
  return (
    <motion.div
      variants={slideIn}
      className="flex px-4 pl-2 py-5 space-x-2 h-24 border-t"
    >
      <div className="flex justify-center px-2">
        <Avatar name={name ?? ''} url={image ?? ''} size={40} />
      </div>
      <div className="flex-auto">
        <div className={`flex ${schedule && 'pb-5'}`}>
          <div className="flex-auto">
            <p className="font-semibold text-gray-350 text-sm">{type}</p>
            <p className="text-xs text-pj-blue-100">{position}</p>
          </div>
          <div className="flex w-32 text-right justify-end">
            <p className="text-xs text-pj-blue-100">{time}</p>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export { Activity };
