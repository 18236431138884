import { useContext } from 'react';
import Unavailable from '../../../components/custom/unavailable';
import { BoardContext } from '../../../utilities/context';
import Search from '../../../components/forms/search';
import { SuggestedType } from '../../../utilities/types';
import SeekerProfile from '../board-components/profile';
import Loader from '../../../components/loader/loader';

const Match = () => {
  const { data, _inviteCandidate, handleSubmitSearch, _handleSearchMatch, register,
    //  match_loader 
  } = useContext(BoardContext);
  return (
    <div className="h-full">
      <Unavailable />
    </div>
    // commented for the meantime
    // <div className="p-4 grid grid-cols-2 gap-4 border-t">
    //   <div>
    //     <div
    //       className="border rounded-lg"
    //       style={{ backgroundColor: '#f7f7f7' }}
    //     >
    //       <div className="border-b p-4 text-left text-gray-400">
    //         <div className="text-sm pb-2 flex justify-between">
    //           <div>
    //             MATCHES ({data?.suggesteds.length})
    //           </div>
    //           { match_loader &&
    //             <div>
    //               <Loader/>
    //             </div>
    //           }
    //         </div>
    //         {/* <Search placeholder="Enter Name or Job Title" name="search" /> */}
    //       </div>
    //       <div className="pb-1 min-h-10">
    //         <div className="p-2 bg-white">
    //           <form
    //             onSubmit={
    //               handleSubmitSearch && handleSubmitSearch(_handleSearchMatch)
    //             }
    //           >
    //             {register && <Search name="search" register={register} />}
    //           </form>
    //         </div>
    //         {data?.suggesteds.map((seeker: SuggestedType) => (
    //           <div className="p-4 border-b">
    //             <SeekerProfile
    //               {...seeker}
    //               type={'match'}
    //               is_match
    //               onClick={_inviteCandidate}
    //               job_status={null}
    //             />
    //           </div>
    //         ))}
    //       </div>
    //     </div>
    //   </div>
    //   <div>
    //     <div
    //       className="border rounded-lg"
    //       style={{ backgroundColor: '#f7f7f7' }}
    //     >
    //       <div className="border-b p-4 text-left text-gray-400">
    //         <div className="text-sm pb-2">INVITED ({data?.invited.length})</div>
    //       </div>
    //       <div className="pb-1 min-h-10">
    //         {data?.invited.map((seeker: SuggestedType) => (
    //           <div className="p-4 border-b">
    //             <SeekerProfile {...seeker} job_status={null}  type={'match'} />
    //           </div>
    //         ))}
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Match;
