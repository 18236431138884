    import React, { useContext } from 'react'
    import { ManageUserContext } from '../../../../utilities/context';
    import Modal from '../../../../components/modal';
import ButtonNext from '../../../../components/buttons/button-next';
import { useForm } from 'react-hook-form';
import Select from '../../../../components/forms/select';

const AddExistingUser = () => {
    const {
        showExisting,
        employerUsers,
        errorMessage,
        closeShowExisting,
        addExistingUser,
    } = useContext(ManageUserContext);

    console.log(employerUsers, 'employerUsers')

    const {
        control,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        register,
        watch,
        reset,
    } = useForm<any>();

    return (
        <Modal title='Add Existing Users' closable show={showExisting} onClose={closeShowExisting}>
            <form
                className="p-4 bg-white rounded w-full text-left"
                onSubmit={handleSubmit && addExistingUser && handleSubmit(addExistingUser)}
            >
                <Select
                    control={control}
                    label="Users"
                    name="user"
                    rules={{ required: 'User is required' }}
                    error={errors?.user}
                    options={employerUsers}
                    defaultValue="default"
                />
                <span className="text-sm text-red-700">{errorMessage}</span>
                <div className="flex justify-center mt-4">
                    <ButtonNext
                        label="Add User"
                        buttonType="submit"
                        full
                    />
                </div>
            </form>
        </Modal>
    )
}

    export default AddExistingUser