import { employer_api, employer_api_v2 } from "./main";

export const updateJobApplication = async (data: any) => {
    let response = {};

    await employer_api.post('updateJobApplication', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
};

export const inviteCandidate = async (data: any) => {
    let response = {};
  
    await employer_api
      .post('inviteCandidate', data)
      .then(res => {
        console.log(res, 'res');
        response = res?.data;
      })
      .catch(err => {
        response = err?.response?.data;
      });
  
    return response;
  };
  
export const isResigned = async (data: any) => {
    let response = {};

    await employer_api
        .post('isResigned', data)
        .then(res => {
        console.log(res, 'res');
        response = res?.data;
        })
        .catch(err => {
        response = err?.response?.data;
        });

    return response;
};

export const createAnInterview = async (data: any) => {
    let response = {};

    await employer_api
        .post('createAnInterview', data)
        .then(res => {
        console.log(res, 'res');
        response = res?.data;
        })
        .catch(err => {
        response = err?.response?.data;
        });

    return response;
};

export const getApplicationDetails = async (data: any) => {
    let response = {};
  
    await employer_api.post('getApplicationDetails', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });
  
        return response
  };

  export const getSeekerPublicProfile = async (data: any) => {
    let response = {};
  
    await employer_api_v2.post('applicant_board/getSeekerPublicProfile', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });
  
        return response
  };

  export const getSeekerDocuments = async (data: any) => {
      let response = {};
    
      await employer_api_v2.post('applicant_board/getSeekerDocuments', data)
          .then(res => {
              response = res?.data;
          })
          .catch(err => {
              response = err?.response?.data;
          });
    
          return response
    };

export const incrementCvDownload = async ({ job_id } : { job_id:number }) => {
    let response = {};
    
    await employer_api_v2.post('applicant_board/incrementCvDownload', { job_id })
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });
    
        return response
};
export const getCVScrapperDetails = async ({ cja_id } : { cja_id:number }) => {
    let response = {};
    
    await employer_api_v2.post('applicant_board/getCVScrapperDetails', { cja_id })
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });
    
        return response
};

export const downloadCVScrapperPDF = async ({ cja_id } : { cja_id:number }) => {
    let response = {};
    await employer_api_v2.post('applicant_board/downloadCVScrapperPDF', { cja_id },{
        responseType: 'blob', // Important for handling binary data
        headers: {
            'Content-Type': 'application/json',
        }
    })
    .then(response => {        
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const a = document.createElement('a');
        a.href = url;
        a.download = 'document.pdf';  // Set the download filename
        document.body.appendChild(a);
        a.click();  // Trigger the download
        a.remove();  // Clean up the DOM
    })
    .catch(err => {
        response = err?.response?.data;
    });

    return response
}