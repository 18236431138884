import { motion } from 'framer-motion';
import { useContext } from 'react';
import { slideIn, stagger } from '../../../framer';
import { Board2Context } from '../../../utilities/context';

const Details = () => {
  const { job_details } = useContext(Board2Context);
  const job = job_details

  if (!job) {
    return null;
  } else {
    return (
      <div className="border-t">
        <div className="bg-white h-full overflow-hidden">
          <motion.div
            variants={stagger}
            animate="animate"
            initial="initial"
            className="col-span-2 overflow-y-scroll overflow-x-hidden"
            id="journal-scroll"
          >
            <motion.div variants={slideIn} className="p-4 pl-3">
              <div className="pl-3 flex-auto grid-rows-2 divide-y">
                <div className="grid grid-flow-col grid-cols-2 grid-rows-2 gap-x-4 gap-y-2">
                  <p className="text-base pj-blue-500 font-medium text-pj-blue-400 text-left">
                    Salary
                  </p>
                  <p className="text-sm pj-blue-100 text-pj-blue-100 text-left">
                    Php{' '}
                    {job.minimum_salary
                      ? `${Math.round(job.minimum_salary * 100) / 100}`
                      : 0}{' '}
                    - Php{' '}
                    {job.maximum_salary
                      ? `${Math.round(job.maximum_salary * 100) / 100}`
                      : 0}
                  </p>
                  <p className="text-base pj-blue-500 font-medium text-pj-blue-400 text-left">
                    Work Location
                  </p>
                  <p className="text-sm pj-blue-100 text-pj-blue-100 text-left">
                    {job.address ? `${job.address}` : '---'}
                  </p>
                </div>
                <p className="mt-6 mb-0"></p>
              </div>
            </motion.div>
            <motion.div
              variants={slideIn}
              className="pb-8 pr-4 pl-3 border-gray-200"
            >
              <div className="pl-3 flex-auto">
                <div className="grid grid-flow-col grid-cols-2 grid-rows-2 gap-x-4 gap-y-2">
                  <p className="text-base pj-blue-500 font-medium text-pj-blue-400 text-left">
                    Employment Type
                  </p>
                  <p className="text-sm pj-blue-100 text-pj-blue-100 text-left">
                    {job.job_employment_type}
                  </p>
                  <p className="text-base pj-blue-500 font-medium text-pj-blue-400 text-left">
                    Field
                  </p>
                  <p className="text-sm pj-blue-100 text-pj-blue-100 text-left">
                    {job.job_position_level}
                  </p>
                </div>
              </div>
            </motion.div>
            <motion.div
              variants={slideIn}
              className="p-3 border-b-2 border-t-2 border-gray-200 bg-gray-100"
            />
            <motion.div variants={slideIn} className="p-6 border-gray-200">
              <p className="text-base pj-blue-500 font-medium text-pj-blue-400 mb-5 text-left">
                Job Description
              </p>
              <div>
                {job.description &&
                  job.description
                    .split('\n')
                    .map(str => (
                      <p className="text-sm pj-blue-100 text-pj-blue-100 mb-2 text-left">
                        {str}
                      </p>
                    ))}
              </div>
            </motion.div>
            <motion.div
              variants={slideIn}
              className="p-3 border-b-2 border-t-2 border-gray-200 bg-gray-100"
            />
            <motion.div
              variants={slideIn}
              className="pt-4 pr-4 pl-3 border-gray-200"
            >
              <div className="pl-3 flex-auto justify-between">
                <div className="grid grid-flow-row grid-rows-4 divide-y">
                  <div>
                    <p className="text-base pj-blue-500 font-medium text-pj-blue-400 pt-2 text-left">
                      Qualifications
                    </p>
                    <p className="text-sm pj-blue-100 text-pj-blue-100 pt-2 text-left">
                      {job.minimum_qualification}
                    </p>
                  </div>
                  <div className="pt-4">
                    <p className="text-base pj-blue-500 font-medium text-pj-blue-400 text-left">
                      Work Experience
                    </p>
                    <p className="text-sm pj-blue-100 text-pj-blue-100 pt-2 pb-5 text-left">
                      {job.job_experience ? `${job.job_experience}` : '---'}
                    </p>
                  </div>
                  <div className="pt-4 pb-6">
                    <p className="text-base pj-blue-500 font-medium text-pj-blue-400 text-left">
                      Skills Required
                    </p>
                    <p className="text-sm pj-blue-100 text-pj-blue-100 pt-2 text-left">
                      {/* {job.skills
                        ? `${job.skills}`
                        : '---'} */}
                      {job.skills &&
                        job.skills.map(skill => (
                          <div className="p-2 rounded-full border border-gray-200 bg-gray-100 inline m-2 ml-0 text-xs text-pj-blue-100">
                            {skill.name}
                          </div>
                        ))}
                    </p>
                  </div>
                  <div className="pt-4">
                    <p className="text-base pj-blue-500 font-medium text-pj-blue-400 text-left">
                      Ideal Qualities{' '}
                      <span className="text-pj-blue-100">(Private to you)</span>
                    </p>
                    <p className="text-sm pj-blue-100 text-pj-blue-100 pt-2 text-left">
                      {job.trait_1}
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          </motion.div>
        </div>
      </div>
    );
  }
};

export default Details;
