import { motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { BsPlusCircle } from 'react-icons/bs';
import { Link, useLocation } from 'react-router-dom';
import ButtonExit from '../../../components/buttons/button-exit';
import Company from '../../../components/company';
import Loading from '../../../components/loader';
import { slideIn, stagger } from '../../../framer';
import { auth } from '../../../utilities/auth';
import { CreateJobContext } from '../../../utilities/context';
import queries from '../../../utilities/queries';
import {
  Company as CompanyType
} from '../../../utilities/types';
import { getCompanies } from '../../../utilities/api_v2/job_create';

const StepOne = () => {
  const {
    common_job: { company_detail_id },
    _selectedCompany,
  } = useContext(CreateJobContext);

  const [companies, setCompanies] = useState<CompanyType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  
  const _getCompanies = async () => {
    setLoading(true)
    await getCompanies({
      employer_id: auth.getId(),
    }).then(({data}:any)=>{
    if (data) {
      const items: CompanyType[] = data.map(
        ({
          name,
          total_jobs,
          id,
          company_image,
          address,
          province_id,
          city_id,
          common_location_city: { name: city_name },
          common_location_province: { name: province_name },
          common_users:{erp_owner}
        }) => ({
          name,
          id,
          logo: company_image,
          total_jobs: total_jobs.aggregate.count,
          address,
          city_id,
          province_id,
          city_name,
          province_name,
          erp_owner
        })
      );
      setCompanies(items);
    }
    }).finally(()=>{setLoading(false)})
  }

  // cehck if isADmin or not
  const location = useLocation();
  useEffect(() => {
    location.search ==  '?is_admin=true' ? localStorage.setItem('isAdmin', 'true') : localStorage.setItem('isAdmin', 'false') 
  },[])

  //useEffect for fetching _getCompanies
  useEffect(()=>{
    _getCompanies()
  },[])
  
  return (
    <div className="flex flex-col flex-1">
      <div
        className="flex-initial overflow-auto border-t border-b border-gray-200 h-screen/2"
        id="journal-scroll"
      >
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          className="w-full p-4 overflow-x-hidden overflow-y"
        >
          {companies.map((company, index: number) => (
            <motion.div variants={slideIn}>
              <Company
                {...company}
                key={`${index}`}
                onClick={_selectedCompany}
                active={company_detail_id === company.id}
              />
            </motion.div>
          ))}
          <Link
            to="/my-companies"
            className="flex items-center justify-between px-4 py-2 mt-5 border rounded-full"
          >
            <span className="text-pj-blue-100">Add a Company</span>
            <BsPlusCircle className="text-lg text-pj-blue-100" />
          </Link>
        </motion.div>
        <Loading show={loading} />
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row items-end flex-1 p-4">
          <div className="flex items-end justify-start w-1/2 h-20">
            <ButtonExit exit={true} />
          </div>
          <div className="flex items-end justify-end w-1/2 h-20">
            {/* <ButtonNext /> */}
          </div>
        </div>
      </div>
    </div>
  );
};

StepOne.defaultProp = {
  company_detail_id: null,
  onClick: () => {},
};

export default StepOne;
