import { motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import ButtonExit from '../../../components/buttons/button-exit';
import ButtonNext from '../../../components/buttons/button-next';
import { stagger } from '../../../framer';
import { CreateJobContext } from '../../../utilities/context';

const StepTwoZero = () => {
    const { control, handleSubmit, errors, _submitForm, setValue, common_job } =
      useContext(CreateJobContext);
  const [jobType, setJobType] = useState("office_based");

  const _onChange = (value: any) => {
    setJobType(value.target.value);
  };

  useEffect(() => {
    setJobType(common_job.blue_collar ? "blue_collar" : "office_based");
  }, [common_job]);

  useEffect(()=>{
    setValue("blue_collar",jobType==="blue_collar" ? true : false)
  },[jobType, setValue])

  return (
    
    <form
      className="flex flex-col flex-1"
      onSubmit={handleSubmit && _submitForm && handleSubmit(_submitForm)}
    >
      <motion.div
        variants={stagger}
        initial="initial"
        animate="animate"
        className="w-full overflow-x-hidden overflow-y-scroll"
        style={{ height: 'calc(100vh - 25rem)' }}
      >
        <div className="flex flex-col flex-1 overflow-x-hidden">
          <div className="overflow-y">
            <label
              className={`flex flex-row w-full p-4 cursor-pointer border-t border-b border-gray-200 ${
                jobType === "blue_collar" && 'bg-pj-green-100'
              }`}
            >
              <div className="flex items-start justify-start w-10">
                <input
                  type="radio"
                  name="blue_collar"
                  value={"blue_collar"}
                  onChange={_onChange}
                  checked={jobType === "blue_collar"}
                />
              </div>
              <div className="w-full" style={{ marginTop: -6 }}>
                <p className="p-0 m-0 text-pj-blue-500">{"Skilled workers/Blue collar"}</p>
                <p className="p-0 m-0 text-sm text-pj-blue-100">
                  Roles requiring manual labor.
                </p>
              </div>
            </label>

            <label
              className={`flex flex-row w-full p-4 cursor-pointer border-t border-b border-gray-200 ${
                jobType === "office_based" && 'bg-pj-green-100'
              }`}
            >
              <div className="flex items-start justify-start w-10">
                <input
                  type="radio"
                  name="blue_collar"
                  value={"office_based"}
                  onChange={_onChange}
                  checked={jobType==="office_based"}
                />
              </div>
              <div className="w-full" style={{ marginTop: -6 }}>
                <p className="p-0 m-0 text-pj-blue-500">{"Office-based"}</p>
                <p className="p-0 m-0 text-sm text-pj-blue-100">
                  Office-based desk jobs, highly-skilled or specialized jobs.
                </p>
              </div>
            </label>
          </div>
        </div>
      </motion.div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row items-end flex-1 p-4">
          <div className="flex items-end justify-start w-1/2">
            <ButtonExit />
          </div>
          <div className="flex items-end justify-end w-1/2">
            <ButtonNext />
          </div>
        </div>
      </div>
    </form>
  );
};

export default StepTwoZero;
