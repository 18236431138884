import React, { useEffect, useState } from 'react';
import { getSeekerDocuments, incrementCvDownload } from '../../../utilities/api_v2/candidate_profile';
import FileViewer from './file-viewer';
import { Button, Modal } from 'antd';
import { auth } from '../../../utilities/auth';
import { _getStatus } from '../../../utilities/states';

const Attachments = ({userData}: any) => {
  const [data, setData] = useState(null);
  const [attachments, setAttachment] = useState([]);
  const [activePdf, setActivePdf] = useState(null)
  const id = auth.getId()
  const downloadable = ["For Interview", "Shortlisted", "Hired"].includes(_getStatus[userData.status])
  const _getDocuments = async () => {
    const data = await getSeekerDocuments({seeker_id:userData.id})
    setData(data)
  };
  
  const handleDownload = async(e, {url}) => {
    e.preventDefault();
    await incrementCvDownload({job_id: userData.job_id}) // track cv download
    const file = url.split("/").pop()
    fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = file;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      })
      .catch(() => alert('Failed to download file.'));
  };

  const handleClose = () => {
    setActivePdf(null)
  }

  useEffect(() => {
    if (userData.id) {
      _getDocuments();
    }
  }, [userData]);

  useEffect(() => {
    if (data) {
      setAttachment(data.data);
    }
  }, [data]);
  return (
    <>
      <div className="pr-5 ">
      <Modal open={!!activePdf} footer={[]} width={700} className='flex items-center justify-center w-full top-4' onCancel={handleClose}>
        <div className='h-full p-2 overflow-hidden border border-gray-100 border-solid rounded'>
        <FileViewer url={activePdf}/>
        </div>
      </Modal>
            <div className="flex flex-row items-center gap-2 mt-3">
        {attachments.map((attachment: any, key) => {
          if(id === 5638 || downloadable){
           return <a className='px-2 py-1 text-sm text-gray-600 border rounded' download href={"/"} onClick={(e)=>handleDownload(e, {url:attachment.url})}>Attachment {key+1}</a>
          }
          return (
            <Button onClick={()=>setActivePdf(attachment?.url || null)}  key={attachment.id}>
                Attachment {key+1}
            </Button>
          );
        })}
        </div>
         {!attachments.length && (
          <div className="mt-4 text-gray-400 capitalize">
            no attachments
          </div>
         )}
      </div>
    </>
  );
};

export default Attachments;
