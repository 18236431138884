import CandidateProfileView from './view';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CandidateProfile, OnClickFunction } from '../../utilities/types';
import { CandidateProfileInitialState, _getStatus } from '../../utilities/states';
import { useForm } from 'react-hook-form';
import { signed_image } from '../../utilities/api';
import moment from 'moment';
import { updateJobApplication, createAnInterview, inviteCandidate, isResigned, getApplicationDetails, getSeekerPublicProfile } from '../../utilities/api_v2/candidate_profile';
import { updateBoardRecoilInfo } from '../../utilities/helper';
import { useRecoilState } from 'recoil';
import { boardAtom } from '../../utilities/atoms/applicant_board/boardAtoms';
import { notification } from 'antd';
import { auth } from '../../utilities/auth';

type NotificationType = 'success' | 'info' | 'warning' | 'error';

type Params = {
  id: string | undefined;
  job_id: string | undefined;
  application_id: string | undefined;
};

const CandidateProfileController = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();
  const { id, job_id, application_id }: Params = useParams();
  const [show, setShow] = useState<boolean>(false);
  const [showResigned, setShowResigned] = useState<boolean>(false);
  const [hire, setHire] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [error_message, setErrorMessage] = useState<string>('');
  const [invited, setInvited] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [appliedModal, setAppliedModal] = useState<boolean>(false);
  const [seeker, setSeeker] = useState<any>(null);
  const [isHiredOpen, setIsHiredOpen] = useState(false);
  const [boardInformationData, setBoardInformationData] = useRecoilState(boardAtom);
  const [candidate, setCandidate] = useState({
    ...CandidateProfileInitialState,
  } as CandidateProfile);

  const isLoading = () => {
    return loading
  }

  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = (message:string, description:string) => {
    api.open({
      message,
      description,
    });
  };

  const _updateApplication = async ({ id, status, job_id }: { id: number, status: string; job_id:number }) => {
    await updateJobApplication({
      id,
      status,
      job_id,
      user_id:auth.getId()
    }).then((data: any) => {
      if(data.error){
        openNotificationWithIcon(`You have no slots.`, `Free up slots by processing candidates in ${_getStatus[data.status]}.`)
      }else {
        _closeHandler();
        if (candidate.status) {
          updateBoardRecoilInfo([id], status, candidate.status, [data.data], boardInformationData, setBoardInformationData)
        }
        application_id && _getApplication(parseInt(application_id));
      }
    }).catch((e) => {
      _closeHandler();
      setError(true);
      setErrorMessage(e.message);
    })
  }

  const _isResigned = async ({ job_id, seeker_id }: { job_id: number, seeker_id: number }) => {
    await isResigned({ job_id, seeker_id }).then(() => {
      _closeHandler();
      application_id && _getApplication(parseInt(application_id));
    }).catch((e) => {
      setError(true);
      setErrorMessage(e.message);
    })
  }

  const _createAnInterview = async (payload: any) => {
    const {
      application_id,
      status,
    } = payload
    await createAnInterview(payload).then((cja: any) => {
      if(cja.error){
        openNotificationWithIcon(`You can't move this candidate.`, `${_getStatus[cja.status]} has no slots`)
      }else {
      setShow(false);
      updateBoardRecoilInfo([application_id], status, candidate.status, [cja.data], boardInformationData, setBoardInformationData)
      if (application_id) {
        _getApplication(parseInt(application_id));
      }
    }
    }).catch((e) => {
      setError(true);
      setErrorMessage(e.message);
    })
  }

  const _inviteCandidate = async () => {
    if (id && job_id) {
      await inviteCandidate({
        seeker_id: parseInt(id),
        job_id: parseInt(job_id),
      },
      ).then(() => {
        _getSeeker();
      })
    }
  };
  const _resigned = () => {
    if (id && job_id) {
      setShowResigned(true);
    }
  };

  const _updateStatus = (
    status:
      | 'Applied'
      | 'Rejected'
      | 'For Interview'
      | 'Shortlisted'
      | 'For Hire'
      | 'Hired') => {

    if(candidate && !candidate.alreadyHired){
      if (application_id) {
        if (status === 'For Interview') {
          setShow(true);
        } else if (status === 'Hired') {
          setHire(true);
        }
        else if (status === 'Shortlisted') {
          setAppliedModal(true)
        } else {
          _updateApplication({
            id: parseInt(application_id),
            status,
            job_id: job_id ? parseInt(job_id) : 0,
          });
        }
      }
    }else {
      setIsHiredOpen(true);
    }
  };

  const _getApplication = async (application_id: number) => {
    if (application_id) {
      setLoading(true)
      await getApplicationDetails({ application_id }).then((data: any) => {
        setCandidate(data.data)
        setLoading(false)
      })
    }
  };

  const _closeHandler = () => {
    setShow(false);
    setHire(false);
    setError(false);
    setShowResigned(false);
    setIsHiredOpen(false);
  };

  const _hireHandler = () => {
    if (application_id) {
      _updateApplication({
        id: parseInt(application_id),
        status: 'Hired',
        job_id: job_id ? parseInt(job_id) : 0,
      });
    }
  };
  
  const _confirmHandler = (status: any) => {
    if (application_id) {
      _updateApplication({
        id: parseInt(application_id),
        status: status,
        job_id: job_id ? parseInt(job_id) : 0,
      });

      setAppliedModal(false)
    }
  }

  const _resignedHandler = () => {
    _isResigned({
      job_id: parseInt(job_id),
      seeker_id: parseInt(id),
    },)
  };

  const _submitHandler: OnClickFunction =async (data: any) => {
    if (application_id) {

      let payload = {
        ...data,
        date: moment(data.date).utc().add(8, 'hours'),
        application_id: parseInt(application_id),
      }
      await _createAnInterview({
        application_id: parseInt(application_id),
        status: 'For Interview',
        data: {
          ...payload,
        },
      })
    }
  };

  const _getSeeker =async () => {
    const payload = {
      id: parseInt(id),
      job_id: parseInt(job_id),
    }
    if (id && job_id) {
      const data:any = await getSeekerPublicProfile(payload)
      setSeeker(data?.data)
    }
  };

  useEffect(() => {
    if (application_id) {
      _getApplication(parseInt(application_id));
    } else if (id && job_id) {
      _getSeeker();
    }
  }, [id, job_id, application_id]);

  useEffect(() => {
    if (seeker) {
      setCandidate({
        ...seeker,
      } as CandidateProfile);
      if (seeker.invited.length > 0) {
        setInvited(true);
      }
    }
  }, [seeker]);

  const attachmentButton = async (data: any) => {
    const url = data.split('/');
    const key = url[url.length - 1];
    const signedUrl = await signed_image(key);
    if (signedUrl) {
      window.open(signedUrl);
    }
  };

  const value: CandidateProfile = {
    ...candidate,
    job_id: job_id ? parseInt(job_id) : 0,
    control,
    handleSubmit,
    show,
    errors,
    hire,
    error,
    error_message,
    invited,
    showResigned,
    _hireHandler,
    _updateStatus,
    _closeHandler,
    _submitHandler,
    _inviteCandidate,
    _resignedHandler,
    attachmentButton,
    _resigned,
    appliedModal,
    setAppliedModal,
    _confirmHandler,
    isLoading,
    isHiredOpen,
    contextHolder
  };
  return <CandidateProfileView {...value}/>;
};

export default CandidateProfileController;
