import axios from 'axios';
import { AES } from 'crypto-js';
import { print } from 'graphql';
import queries from './queries';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

interface Login {
  email: string;
  password: string;
}

const auth = {
  async login(login: Login) {
    const fingerprint = await this.fingerprint();
    const data = this.encrypt({
      ...login,
      user_type: 'employer',
      installation_id: fingerprint,
      device_name: fingerprint,
    });
    return axios
      .post(`${process.env.REACT_APP_API_URL}`, {
        query: print(queries.LOGIN),
        variables: {
          data,
        },
      })
      .then(res => {
        return res;
      })
      .catch(err => {
        console.log(err);
      });
  },
  encrypt(data: object) {
    return AES.encrypt(
      JSON.stringify(data),
      `${process.env.REACT_APP_AES_KEY}`
    ).toString();
  },
  storeToken(token: string) {
    localStorage.setItem('token', token);
  },
  getToken() {
    return localStorage.getItem('token');
  },
  decode(token: string) {
    let base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(window.atob(base64));
  },
  getUserInfo() {
    const token = this.getToken();
    if (token) {
      const decodedData = this.decode(token as string);
      return decodedData.user;
    }
    return null;
  },
  getId() {
    const token: string | null = this.getToken();
    let decoded: any = null;
    if (token) {
      decoded = this.decode(token);
      const id = decoded['https://hasura.io/jwt/claims']['x-hasura-user-id'];
      return parseInt(id) as number;
    }
    return;
  },
  isAuthenticated() {
    const token: string | null = this.getToken();
    let decoded: any | null = null;
    if (token) {
      decoded = this.decode(token);
      if (Date.now() < decoded.exp * 1000 - 6000 && !this.isOTP()) {
        return true as boolean;
      }
    }
    return false as boolean;
  },
  async fingerprint() {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const visitorId = result.visitorId;
    return visitorId;
  },
  getOTP() {
    const token: string | null = this.getToken();
    let decoded: any = null;
    if (token) {
      decoded = this.decode(token);
      if (decoded?.otp?.is_otp) {
        return decoded?.otp?.expiration as TimeRanges;
      }
    }
    return 0;
  },
  isOTP() {
    const token: string | null = this.getToken();
    let decoded: any = null;
    if (token) {
      decoded = this.decode(token);
      if (decoded?.otp?.is_otp) {
        return true as boolean;
      }
    }
    return false as boolean;
  },
  clear() {
    localStorage.clear();
  },
  isAdmin(){
    const token: string | null = this.getToken();
    let decoded: any = null;
    if (token) {
      decoded = this.decode(token); 
      const role = decoded['https://hasura.io/jwt/claims']['x-hasura-role'];
      if (role === 'standard'){
        return true;
      } 
    }
    return false;
  },
  getUserData(){
    const token: string | null = this.getToken();

    return token ? this.decode(token) : null;
  }
};

export { auth };
