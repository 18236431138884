import { useContext, useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { abbreviate } from '../../../helpers';
import { lang } from '../../../lang';
import { CreateJobContext } from '../../../utilities/context';

const StepThreeTwo = () => {
  const { jobId, common_job, referral_count, referral_fee } =
    useContext(CreateJobContext);
  const location: any = useLocation();
  const [checkout, setCheckout] = useState<boolean>(false);

  useEffect(() => {
    if (
      referral_fee &&
      referral_count &&
      common_job.referral_fee &&
      (common_job.referral_fee > referral_fee ||
        common_job.referral_count > referral_count)
    ) {
      setCheckout(true);
    }
  }, [common_job]);

  useEffect(() => {
    if (!referral_fee && !referral_count) {
      setCheckout(true);
    } else {
      setCheckout(false);
    }
  }, [referral_fee, referral_count]);

  return (
    <div className="p-4">
      <div className="text-2xl text-center text-pj-blue-500 font-bold mb-10">
        {lang.step_three.job_posted}
      </div>
      <div className="w-full rounded-lg border mb-5 p-4 mt-4">
        <div
          className={`w-32 h-16 bg-cover bg-left flex justify-center items-center text-gray-500 ${
            (!common_job.company_logo || common_job.company_logo.length <= 0) &&
            'bg-gray-200 text-2xl tracking-wide'
          }`}
          style={{ backgroundImage: `url(${common_job.company_logo})` }}
        >
          {(!common_job.company_logo || common_job.company_logo.length <= 0) &&
            abbreviate(common_job.company_name ?? '')}
        </div>
        <div className="text-lg text-pj-blue-400 mt-2">{`${common_job.title} • ${common_job.company_name}`}</div>
      </div>
      <div className=" flex justify-center">
        <div className="text-center text-xl text-pj-blue-100 px-10 w-1/2">
          {lang.step_three.active}
        </div>
      </div>

      <div className="mt-10 flex flex-row justify-center space-between">
        <div className="w-auto mx-2">
          <Link to="/job-posts/active">
            <div className="py-2 px-4 text-lg text-pj-blue-500 border border-gray-300 bg-gray-200 rounded-full cursor-pointer">
              {lang.step_three.go_to_job}
            </div>
          </Link>
        </div>
        <div className="w-auto mx-2">
          <Link
            to={{
              pathname: `/checkout/4/1/${jobId}`,
              state: {
                fromCreateJob: true,
                prev: location?.pathname,
              },
            }}
          >
            <div className="py-2 px-4 text-lg  bg-pj-green-300 rounded-full text-white cursor-pointer">
              {lang.step_three.proceed_checkout}
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default StepThreeTwo;
