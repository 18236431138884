import { Route, useHistory } from 'react-router-dom';
import { auth } from '../utilities/auth';
import LoginTemplate from './templates/login-template';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
ReactGA.initialize('G-2VFFZHFM5T');

const Unauthenticated = ({ component: Component, ...rest }) => {
  const history = useHistory();

  if (history?.location?.pathname) {
    ReactGA.send({ hitType: "pageview", page: history?.location?.pathname });
  }

  if (auth.isAuthenticated()) {
    history.push('/dashboard');
  }
  return (
    <Route
      {...rest}
      render={() => {
        return (
          <LoginTemplate>
            <Component />
          </LoginTemplate>
        );
      }}
    />
  );
};

export default Unauthenticated;
