import { Link } from "react-router-dom";

type Props = {
  total: number | string;
  iconLeft: any;
  iconRight: any;
  title: string;
  url?: string;
};

const Stats = ({ total, iconLeft, iconRight, title, url }: Props) => {
  return (
    <>
      <h4 className="text-white text-2xl font-semibold">{total}</h4>
      <div className="flex py-3">
        <div className="w-10">{iconLeft}</div>
        <div className="flex-auto text-white text-sm font-semibold">
          {title}
        </div>
        <div className="w-5">
          <Link to={url ?? "/"}>{iconRight}</Link>
        </div>
      </div>
    </>
  );
};
export default Stats;
