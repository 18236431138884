import EmployerCreditView from './view'
import { useForm } from 'react-hook-form';
import { EmployerCreditContext } from '../../utilities/context';
import { EmployerCreditData, Option, TransactionList } from '../../utilities/types';
import { useState } from 'react';
import { auth } from '../../utilities/auth';
import { EmployerCreditInitialState } from '../../utilities/states';
import { useHistory } from 'react-router-dom';

const EmployerCreditController = () => {
  const { handleSubmit, register } = useForm();
  const history = useHistory();
  const [show, setShow] = useState<boolean>(true);
  const [transactionList, setTransactionList] = useState<TransactionList[]>([{
    amount: 20,
    transaction_id: "string",
    id: 123,
    job_position: "string",
    total_vat: 12,
    total_commission: 12,
    transaction_type: "top-up",
    sub_total: 20,
    posting_fee: 12,
    company_name: "sample"
  }, {
    amount: 20,
    transaction_id: "string",
    id: 1234,
    job_position: "string",
    total_vat: 12,
    total_commission: 12,
    transaction_type: "top-up",
    sub_total: 20,
    posting_fee: 12,
    company_name: "string | null"
  }]);

  const [transactionDetails, setTransactionDetails] =
    useState<any>(transactionList[0]);
  const [where, setWhere] = useState<object>({});
  const [sort, setSort] = useState<Option>({
    label: 'Most Recent',
    value: 'desc',
  });
  const [transacType, setTransacType] = useState<Option>({
    label: 'All Transactions',
    value: null,
  });

  const _handleSearch = (data: any) => {
    console.log(data)
  };

  const _handleSelect = (option: any) => {
    setWhere({});
    setTransacType(option);
  };

  const _clickHandler = (id: number) => {
    history.push(`/credits/${id}`);
    setTransactionDetails(transactionList?.filter((data) => data.id === id)[0])
    setShow(false);
    setTimeout(() => {
      setShow(true);
    }, 300);
  };
  const _handleSort = (option: any) => {
    setSort(option);
  };

  const value: EmployerCreditData = {
    show,
    transacType,
    sort,
    transactionList,
    transactionDetails,
    _handleSelect,
    _clickHandler,
    _handleSearch,
    _handleSort,
    handleSubmit,
    register
  }
  return (
    <EmployerCreditContext.Provider value={value}>
      <EmployerCreditView />
    </EmployerCreditContext.Provider>
  )
}

export default EmployerCreditController