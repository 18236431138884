import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import ButtonExit from '../../../components/buttons/button-exit';
import ButtonNext from '../../../components/buttons/button-next';
import { stagger } from '../../../framer';
import { lang } from '../../../lang';
import { CreateJobContext } from '../../../utilities/context';
import { Address, CompanyAddress } from '../../../utilities/types';
import NewAddress from '../components/new-address';

type Position = {
  label: string;
  value: string;
};

const StepTwoFive = () => {
  const {
    common_job,
    company,
    addresses,
    active,
    setActive,
    handleSubmit,
    _submitForm,
    setValue,
    _insertAddress,
  } = useContext(CreateJobContext);
  const [show, setShow] = useState(false as boolean);
  const _onChange = (value: string, data: Address) => {
    setActive(value);
    setValue &&
      Object.keys(data).map(k => {
        console.log(k, 'values');
        setValue(k, data[k]);
      });
  };

  return (
    <form
      className="flex flex-col flex-1"
      onSubmit={handleSubmit && _submitForm && handleSubmit(_submitForm)}
      id="journal-scroll"
    >
      <motion.div
        variants={stagger}
        initial="initial"
        animate="animate"
        className="w-full overflow-x-hidden overflow-y-scroll"
        style={{ height: 'calc(100vh - 25rem)' }}
      >
        <div className="flex flex-col flex-1 overflow-x-hidden">
          <div className="overflow-y">
            <label
              className={`flex flex-row w-full p-4 cursor-pointer border-t border-b border-gray-200 ${
                active === 'company_address' && 'bg-pj-green-100'
              }`}
              onClick={() =>
                _onChange('company_address', {
                  address: company?.address ?? '',
                  city_id: company?.city_id ?? 0,
                  province_id: company?.province_id ?? 0,
                  home_based: false,
                  city_name: company?.city_name,
                })
              }
            >
              <div className="flex items-start justify-start w-10">
                <input
                  type="radio"
                  name="address"
                  value="company_address"
                  checked={active === 'company_address'}
                />
              </div>
              <div className="w-64" style={{ marginTop: -6 }}>
                <p className="p-0 m-0 text-pj-blue-500">{company?.city_name}</p>
                <p className="p-0 m-0 text-sm text-pj-blue-100">
                  {company?.address}
                </p>
              </div>
            </label>
            <label
              className={`flex flex-row w-full p-4 cursor-pointer border-t border-b border-gray-200 ${
                active === 'home_based' && 'bg-pj-green-100'
              }`}
              onClick={() =>
                _onChange('home_based', {
                  address: common_job.address,
                  city_id: common_job.city_id,
                  province_id: common_job.province_id,
                  home_based: true,
                })
              }
            >
              <div className="flex items-start justify-start w-10">
                <input
                  type="radio"
                  name="address"
                  value="home_based"
                  checked={active === 'home_based'}
                />
              </div>
              <div className="w-64" style={{ marginTop: -6 }}>
                <p className="p-0 m-0 text-pj-blue-500">
                  {lang.step_two.home_base}
                </p>
              </div>
            </label>
            {addresses?.map((address: CompanyAddress, index: number) => {
              return (
                <label
                  className={`flex flex-row w-full p-4 cursor-pointer border-t border-b border-gray-200 ${
                    active === `${index}` && 'bg-pj-green-100'
                  }`}
                  onClick={() =>
                    _onChange(`${index}`, {
                      address: address.address,
                      city_id: address.city_id,
                      province_id: address.province_id,
                      home_based: false,
                      city_name: address?.city_name,
                    })
                  }
                >
                  <div className="flex items-start justify-start w-10">
                    <input
                      type="radio"
                      name="address"
                      value={`${index}`}
                      checked={active === `${index}`}
                    />
                  </div>
                  <div className="w-64" style={{ marginTop: -6 }}>
                    <p className="p-0 m-0 text-pj-blue-500 ">
                      {address?.city_name}
                    </p>
                    <p className="p-0 m-0 text-sm text-pj-blue-100">
                      {address.address}
                    </p>
                  </div>
                </label>
              );
            })}
          </div>
          <div className="p-4">
            <div
              className={`w-full p-2 px-3 bg-gray-100 border cursor-pointer ${
                show ? 'rounded-md' : 'rounded-full'
              }`}
              onClick={() => setShow(true)}
            >
              <div className="flex flex-row justify-between">
                <div className="flex items-center text-sm text-pj-blue-100">
                  Add another location
                </div>
                <div className="flex items-center text-lg text-pj-blue-100">
                  {show ? <AiOutlineMinusCircle /> : <AiOutlinePlusCircle />}
                </div>
              </div>
              {show && <NewAddress callback={_insertAddress} />}
            </div>
          </div>
        </div>
      </motion.div>
      {/* <Loading show={true} /> */}
      <div className="flex flex-col flex-1">
        <div className="flex flex-row items-end flex-1 p-4">
          <div className="flex items-end justify-start w-1/2 h-20">
            <ButtonExit />
          </div>
          <div className="flex items-end justify-end w-1/2 h-20">
            <ButtonNext />
          </div>
        </div>
      </div>
    </form>
  );
};

export default StepTwoFive;
