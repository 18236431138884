import { useContext, useState, useEffect } from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';

const AddingUsers = () => {

  return (
    <div className="h-full">
      <div className="p-4 px-8 flex flex-col justify-around bg-gray-100 text-lg text-pj-blue-100 pt-10 mt-10 ml-12">
        <div className="py-2">
          Tap the menu icon.
        </div>
        <div className="py-2">
          Tap Manage Companies.
        </div>
        <div className="py-2">
          Choose company.
        </div>
        <div className="py-2">
          Tap the plus (+) sign.
        </div>
        <div className="py-2">
          Add the details of the team member, nominate a password and click Save.
          Share the account credentials with the team member, noting that they
          can change the password after logging in.
        </div>
      </div>
    </div>
  );
};

export default AddingUsers;
