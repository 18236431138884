import Button from '../../components/forms/button';
import InputField from '../../components/forms/input';
import { lang } from '../../lang';
import { useContext } from 'react';
import { PageContext } from '../../utilities/context';
import { motion } from 'framer-motion';
import { slideIn } from '../../framer';
import Modal from '../../components/modal';
import { Link } from 'react-router-dom';

const ForgotPasswordView = () => {
  const {
    control,
    control2,
    _submitForm,
    handleSubmit,
    handleSubmit2,
    errors,
    errors2,
    showModal,
    modalMessage,
    closeConfirmModal,
    sendCode,
    showOtpModal,
    resetForm,
    emailError,
    emailErrorButton,
    setCode
  }: any = useContext(PageContext);

  return (
    <motion.div variants={slideIn} initial="initial" animate="animate">
      <h3 className="text-center pb-10 text-white text-xl">
        {lang.forgot_password.header}
      </h3>
      <div className="bg-white shadow w-full rounded-lg ">
        {showOtpModal ? (
          <div>
            <form onSubmit={handleSubmit(_submitForm)}>
              <div className="px-7 py-7">
                <InputField
                  label={lang.general.email}
                  type="email"
                  name="email"
                  control={control}
                  rules={{ required: 'Email is required' }}
                  error={errors.email}
                />
                <Button
                  label={lang.general.continue}
                  onClick={handleSubmit(_submitForm)}
                />
              </div>
            </form>
          </div>
        ) : (
          <div className="px-7 py-7">
            <div className={` -mx-3 pj-focus`}>
              <div className="w-full px-3 mb-5">
                <label
                  className={`text-sm mb-5 block text-left focus:border-green-200`}
                >
                  Otp Code
                  <div className="flex pt-1">
                    <input
                      type="number"
                      onChange={(e) => setCode(e.target.value)}
                      className={` focus:border-pj-green-200 w-full z-10 -mr-10 pl-3 py-2.5 rounded border border-gray-200 outline-none  text-sm`}
                    />
                  </div>
                </label>
              <Button label={lang.general.continue} onClick={sendCode} />
              </div>

            </div>
          </div>
        )}
        <Modal
          show={showModal}
          onClose={closeConfirmModal}
          width={480}
          title={emailError ? 'Email Verification Failed' : 'Successful'}
        >
          <div className="px-7 py-7">
            <p className="text-sm text-pj-blue-100 py-2">
              {modalMessage}
              {emailError ? (
                <div className="mt-5">
                  <Button
                    label={lang.general.continue}
                    onClick={emailErrorButton}
                  />
                </div>
              ) : (
                <Link
                  to="/"
                  className="text-pj-blue-400 ml-1 font-semibold text-center"
                  onClick={resetForm}
                >
                  Login here.
                </Link>
              )}
            </p>
          </div>
        </Modal>
      </div>
    </motion.div>
  );
};

export default ForgotPasswordView;
