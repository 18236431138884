import { useQuery } from '@apollo/client';
import { useEffect, useReducer, useState } from 'react';
import { auth } from '../../utilities/auth';
import { DashboardContext } from '../../utilities/context';
import queries from '../../utilities/queries';
import { DashboardInitialState } from '../../utilities/states';
import {
  Activity,
  Count,
  DashboarActions,
  DashboardData,
  JobStatus,
  Option,
  Schedule,
} from '../../utilities/types';
import DashboardView from './view';
import moment from 'moment';
import { companySchedules, dashboardActivity, dashboardJobPostsCount, getCounts, getEmployeeName, viewCompany } from '../../utilities/api_v2/dashboard';

const reducer = (
  state: DashboardData,
  action: DashboarActions
): DashboardData => {
  switch (action.type) {
    case 'count':
      return {
        ...state,
        count: action.payload as Count,
      };
    case 'schedules':
      return {
        ...state,
        schedules: action.payload as Schedule[],
      };
    case 'job_status':
      return {
        ...state,
        job_status: action.payload as JobStatus,
      };
    case 'activities':
      return {
        ...state,
        activities: action.payload as Activity[],
      };
    default:
      throw new Error();
  }
};

const DashboardController = () => {
  const [state, dispatch] = useReducer(reducer, DashboardInitialState);
  const [schedules, setSchedules] = useState<Schedule[]>([]);
  const [activities, setActivities] = useState<Activity[]>([]);
  const [companies, setCompanies] = useState<Option[]>();
  const [employeeName, setEmployeeName] = useState<string>();
  const [count, setCount] = useState<any>();
  const [companySchedule, setCompanySchedule] = useState<any>(null)
  const [jobPost, setJobPost] = useState<any>(null)
  const [companyActivities, setCompanyActivities] = useState<any>(null)
  const [company, setCompany] = useState<Option>({
    label: 'All Companies',
    value: null,
  });
  const [job, setJob] = useState<Option>({
    label: 'All Jobs',
    value: null,
  });

  const _getCounts = async({employer_id,company_id,visible}:{employer_id: number; company_id: any; visible: any;})=> {
    return await getCounts({employer_id,company_id,visible}).then((data:any)=>{
      setCount(data.data)
    })
  }

  const _viewCompany = async () => {
      return await viewCompany({
        employer_id:auth.getId(),
      }).then((data:any)=>{
        const temp = data?.data?.map((item: any) => {
          return {
            label: item.name,
            value: item.id,
          };
        });
        setCompanies([{ label: 'All Companies', value: null }, ...temp]);
      })
  }

  const _employeeName = async () => {
      return await getEmployeeName({
        id: auth.getId(),
      }).then((data:any)=>{
        const name = data?.data?.first_name;
        setEmployeeName(name);
      })
  }

  const _getCompanySchedule = async () => {
    return companySchedules( {
      company_id: company?.value,
      employer_id: auth.getId(),
      current_date: moment(),
    }).then((data:any) => {
      setCompanySchedule(data.data)
    })
  }
  
  //api-pending -> issue : return only undefine
  // const { data: companyActivities } = useQuery(queries.DASHBOARD_ACTIVITY, {
  //   fetchPolicy: 'no-cache',
  //   variables: {
  //     employer_id: auth.getId(),
  //   },
  // });

  const _getDashboardActivity = async () => {
   return await dashboardActivity({
    employer_id: auth.getId(),
  }).then((data:any)=>{
    setCompanyActivities(data.data)
  })
  }

  const getDashboardJobPostCounts = async () =>{
    await dashboardJobPostsCount({
      employer_id: auth.getId(),
      company_id: company?.value,
      visible: job?.value,
    }).then((data:any)=>{
      setJobPost(data.data)
    })
  }

  const _handleSchedule = (id: any) => {
    if (companies) {
      const options = companies.filter(
        (option: Option) => option.value === id.value
      );
      setCompany(options[0]);
    }
  };

  useEffect(() => {
    if (company) {
      _getCompanySchedule()
    }
  }, [company]);

  useEffect(() => {
    if (companySchedule) {
      const temp = companySchedule?.filter(
        (item: any) => moment(item.date).add(moment(item.start_time).get('hour'),'hours').add(moment(item.start_time).get('minute'),'minutes').isSameOrAfter(moment()));
      setSchedules(temp);
    }
  }, [companySchedule]);

  useEffect(() => {
    if (companyActivities) {
      const temp = companyActivities.map((item: any) => {
        return {
          name:
            item?.common_message?.application?.name ??
            item?.common_job_application?.name ?? 
            `${item?.common_job?.employer_company?.common_user?.first_name} ${item?.common_job?.employer_company?.common_user?.last_name}`,
          type: item.type,
          profile_picture:
            item?.common_job_application?.common_user?.seeker_profile?.profile_image,
          job_title:
            item?.common_message?.application?.job_title ??
            item?.common_job?.title,
          created_at: item.created_at,
          status:
            item?.common_message?.application?.status,
          is_generated:
            item?.common_message?.auto_generated,
          invite_status: item?.invite?.status,
          invite_name: item?.invite?.name,
          invite_profile: item?.invite?.profile_image,
          job_company: item?.common_job?.employer_company?.name,
          job_employer: `${item?.common_job?.employer_company?.common_user?.first_name} ${item?.common_job?.employer_company?.common_user?.last_name}`,
        };
      });
      setActivities(temp);
    }
  }, [companyActivities]);

  useEffect(() => {
    if (company) {
      _getCounts({
        employer_id: auth.getId(),
        company_id: company?.value,
        visible: job?.value,
      })
      
      getDashboardJobPostCounts()
    }
  }, [company, job]);

  //trigger to fetch viewCompany on render
  useEffect(()=>{
    _viewCompany()
    _employeeName()
    _getDashboardActivity()
  },[])

  const _handleJobSelect = (option: any) => {
    setJob(option);
  };

  useEffect(() => {
    if (count) {
      dispatch({
        type: 'count',
        payload: {
          applicants: count.applied.aggregate.count,
          for_interview: count.for_interview.aggregate.count,
          for_hire: count.for_hire.aggregate.count,
          filled: count.filled.aggregate.count,
          openings: count.openings.aggregate.sum ,
        } as Count,
      });
    }
  }, [count]);

  useEffect(() => {
    if (jobPost) {
      dispatch({
        type: 'job_status',
        payload: {
          active: jobPost.active.aggregate.count,
          with_applicants: jobPost.with_applicants.aggregate.count,
          shortlisted: jobPost.with_shortlisted.aggregate.count,
          pending: jobPost.pending.aggregate.count,
          archived: jobPost.archived.aggregate.count,
          total_jobs: jobPost.total_jobs.aggregate.count,
        } as JobStatus,
      });
    }
  }, [jobPost]);

  const value: DashboardData = {
    ...state,
    companies,
    activities,
    company,
    schedules,
    _handleSchedule,
    _handleJobSelect,
    job,
    employeeName,
  };

  return (
    <DashboardContext.Provider value={value}>
      <DashboardView />
    </DashboardContext.Provider>
  );
};

export default DashboardController;
