import { useContext, useState, useEffect } from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';

const PostingJob = () => {

  return (
    <div className="h-full">
      <div className="p-4 px-8 flex flex-col justify-around bg-gray-100 text-lg text-pj-blue-100 pt-10 mt-10 ml-12">
        <div className="py-2">
          Go to the Job Posts page. Click Create Post.
        </div>
        <div className="py-2">
          Swlect which company would you like to post a job for.
        </div>
        <div className="py-2">
          Fill in the following fields with the corresponding details:
          Job title, Level, Industry, Job category, Number of persons needed, Salary range,
          Requirements, Work address, Skills.
        </div>
        <div className="py-2">
          Save your job post.
        </div>
        <div className="py-2">
          On the web: Tap Proceed to Checkout.
        </div>
        <div className="py-2">
          Input your company's credit card information.
        </div>
        <div className="py-2">
          Click Checkout.
        </div>
      </div>
    </div>
  );
};

export default PostingJob;
