const Unavailable = () => {
  return (
    <div className="flex justify-center flex-col items-center h-full">
      <div className="font-bold w-1/4 mb-2">This service is temporarily unavailable.</div>
      <div className="text-sm w-1/2">
        We temporarily took down 
        this feature to give you a better 
        hiring experience. For questions and concerns, 
        please contact  
        <a className='text-blue-500'> team@pasajob.com.</a>
      </div>
    </div>
  )
}

export default Unavailable