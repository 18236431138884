import moment from 'moment';

type Props = {
  date: Date;
  start: Date;
};

const Time = ({ date, start }: Props) => {
  const today = moment();
  const end = moment(date)
    .startOf('day')
    .add(moment(start).get('hours'), 'hours');

  const days = end.diff(today, 'days');
  let hours = end.diff(today, 'hours');
  let minutes = end.diff(today, 'minutes');

  if (days > 0) {
    hours = end.subtract(days, 'days').diff(today, 'hours');
  }
  if (hours > 0) {
    minutes = end.subtract(hours, 'hours').diff(today, 'minutes');
  }

  return (
    <div className="text-pj-green-300 text-sm font-semibold">
      {`Starts in ${days > 0 ? `${days} day(s)` : ''} ${
        hours > 0 ? `${hours} hour(s)` : ''
      } ${`${minutes} minute(s)`}`}
    </div>
  );
};

export default Time;
