import Button from '../../components/forms/button';
import InputField from '../../components/forms/input';
import { lang } from '../../lang';
import { useContext } from 'react';
import { PageContext } from '../../utilities/context';
import { motion } from 'framer-motion';
import { slideIn } from '../../framer';

const OTPView = () => {
  const { control, errors, handleSubmit, _submitHandler }: any =
    useContext(PageContext);
  return (
    <motion.div variants={slideIn} initial="initial" animate="animate">
      <h3 className="text-center pb-10 text-white text-xl">
        {lang.otp.header}
      </h3>
      <div className="bg-white shadow w-full rounded-lg ">
        <div className="px-7 py-7">
          <form onSubmit={handleSubmit(_submitHandler)}>
            <InputField
              label={lang.otp.otp_code}
              type="number"
              name="code"
              control={control}
              rules={{
                required: 'OTP is required',
                minLength: {
                  value: 5,
                  message: 'Minimum length is 5',
                },
                maxLength: {
                  value: 5,
                  message: 'Maximum length is 5',
                },
              }}
              error={errors.otp}
            />
            <Button label={lang.general.continue} type="submit" />
          </form>
        </div>
      </div>
    </motion.div>
  );
};

export default OTPView;
