import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import ButtonExit from '../../../components/buttons/button-exit';
import ButtonNext from '../../../components/buttons/button-next';
import { stagger } from '../../../framer';
import { CreateJobContext } from '../../../utilities/context';

const StepTwoTwentyOne = () => {
  const { common_job, handleSubmit, _submitForm, setValue } =
    useContext(CreateJobContext);

  const [active, setActive] = useState<'full' | 'half' | 'third' | 'forth'>(
    common_job.disbursement_type
  );
  const _onChange = (value: 'full' | 'half' | 'third' | 'forth') => {
    setActive(value);
    setValue && setValue('disbursement_type', value);
  };

  useEffect(() => {
    setActive(common_job.disbursement_type);
  }, [common_job.disbursement_type]);

  return (
    <form
      className="flex flex-1 flex-col"
      onSubmit={handleSubmit && _submitForm && handleSubmit(_submitForm)}
      id="journal-scroll"
    >
      <motion.div
        variants={stagger}
        initial="initial"
        animate="animate"
        className="w-full overflow-y-scroll overflow-x-hidden"
        style={{ height: 'calc(100vh - 25rem)' }}
      >
        <div className="overflow-x-hidden flex flex-col flex-1">
          <div className="overflow-y">
            <label
              className={`flex flex-row w-full p-4 cursor-pointer ${
                active === 'full' && 'bg-pj-green-100'
              }`}
              onClick={() => _onChange('full')}
            >
              <div className="w-10 flex justify-start items-start">
                <input
                  type="radio"
                  name="disbursement_type"
                  value="disbursement_type"
                  checked={active === 'full'}
                />
              </div>
              <div className="w-70" style={{ marginTop: -6 }}>
                <p className="m-0 p-0 text-pj-blue-500">100% after 30 days</p>
              </div>
            </label>
            <label
              className={`flex flex-row w-full p-4 cursor-pointer ${
                active === 'half' && 'bg-pj-green-100'
              }`}
              onClick={() => _onChange('half')}
            >
              <div className="w-10 flex justify-start items-start">
                <input
                  type="radio"
                  name="disbursement_type"
                  value="disbursement_type"
                  checked={active === 'half'}
                />
              </div>
              <div className="w-70" style={{ marginTop: -6 }}>
                <p className="m-0 p-0 text-pj-blue-500">
                  50% after 30 days and 50% after 90 days
                </p>
              </div>
            </label>
            <label
              className={`flex flex-row w-full p-4 cursor-pointer ${
                active === 'third' && 'bg-pj-green-100'
              }`}
              onClick={() => _onChange('third')}
            >
              <div className="w-10 flex justify-start items-start">
                <input
                  type="radio"
                  name="disbursement_type"
                  value="disbursement_type"
                  checked={active === 'third'}
                />
              </div>
              <div className="w-70" style={{ marginTop: -6 }}>
                <p className="m-0 p-0 text-pj-blue-500">
                  50% after 30 days and 50% after 180 days
                </p>
              </div>
            </label>
            <label
              className={`flex flex-row w-full p-4 cursor-pointer ${
                active === 'forth' && 'bg-pj-green-100'
              }`}
              onClick={() => _onChange('forth')}
            >
              <div className="w-10 flex justify-start items-start">
                <input
                  type="radio"
                  name="disbursement_type"
                  value="disbursement_type"
                  checked={active === 'forth'}
                />
              </div>
              <div className="w-70" style={{ marginTop: -6 }}>
                <p className="m-0 p-0 text-pj-blue-500">
                  50% after 90 days and 50% after 180 days
                </p>
              </div>
            </label>
          </div>
        </div>
      </motion.div>
      {/* <Loading show={true} /> */}
      <div className="flex flex-col flex-1">
        <div className="flex flex-row flex-1 items-end p-4">
          <div className="h-20 w-1/2 flex justify-start items-end">
            <ButtonExit />
          </div>
          <div className="h-20 w-1/2 flex justify-end items-end">
            <ButtonNext />
          </div>
        </div>
      </div>
    </form>
  );
};

export default StepTwoTwentyOne;
