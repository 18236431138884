import { Button, Modal } from "antd";
import { getCVScrapperDetails } from "../../../utilities/api_v2/candidate_profile";
import { useState } from "react";
import { CVScrapperDataType } from "../../../utilities/types";
import { _getStatus } from "../../../utilities/states";
import { CgSpinner } from "react-icons/cg";
import { usePDF } from "react-to-pdf";
import { CVScrapperLayout, DownloadableCVScrapperLayout } from "./CVTemplates";

const CVScrapper = ({cja_id, status, name }:{cja_id:number; status:"Applied" | "Rejected" | "Shortlisted" | "For Interview" | "For Hire" | "Hired", name:string}) => {  
  const [cvScrapperData, setCVScrapperData] = useState<{
    data:CVScrapperDataType;
    download:boolean;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(false)
  const { toPDF, targetRef } = usePDF({filename: `${name}'s PasaJob CV.pdf`});

  const downloadable = ["For Interview", "Shortlisted", "Hired"].includes(_getStatus[status])

  const handleCancel = () => {
    setCVScrapperData(null);
  };
  const onClickAttachment = async () => {
    setIsLoading(true)
    if(downloadable) {
      await getCVScrapperDetails({cja_id:cja_id})
      .then(({data}:{data:CVScrapperDataType})=>{
        if(data){
          setCVScrapperData({data, download:true})
        }
      })
      .finally(()=>setIsLoading(false))
    } else {
      await getCVScrapperDetails({cja_id:cja_id}).then(({data}:{data:CVScrapperDataType})=>{
        if(data){
          setCVScrapperData({data, download:false})
        }
      }).finally(()=>setIsLoading(false))
    }
  }

  const _toPDF = () => {
    toPDF()
    setCVScrapperData(null)
  }
  
  return ( 
    <div className="py-3">
    <Button  onClick={onClickAttachment} disabled={isLoading}>
      { isLoading && <CgSpinner className=" animate-spin"/>}
        {downloadable ? "Download CV" : "View CV"}
    </Button>
      <Modal open={!!cvScrapperData && cvScrapperData.download === false} onCancel={handleCancel} footer={[]} width={840} style={{ top:0}}>
        <CVScrapperLayout cvScrapperData={cvScrapperData?.data}/>
      </Modal>
      {/* separate layout from PDF View */}
      <Modal open={!!cvScrapperData && cvScrapperData.download === true} onCancel={handleCancel} footer={[]} width={870} style={{ top:0}}>
        <DownloadableCVScrapperLayout targetRef={targetRef} cvScrapperData={cvScrapperData?.data} toPDF={_toPDF}/>
      </Modal>
    </div>
    );
}
 
export default CVScrapper;