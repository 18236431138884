import { Link } from 'react-router-dom';
import { OnClickFunction } from '../../utilities/types';

type Props = {
  label: string;
  onClick?: OnClickFunction;
  to: any;
  light: boolean;
};

const Button = (props: Props) => {
  return (
    <Link to={props.to}>
      <div
        className={`px-4 py-2 rounded-full cursor-pointer inline-block text-base ${
          props.light
            ? 'bg-gray-100 text-pj-blue-100 border border-gray-200'
            : 'bg-pj-green-200 text-white'
        }`}
      >
        {props.label}
      </div>
    </Link>
  );
};

Button.defaultProps = {
  light: false,
};

export default Button;
