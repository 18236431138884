import { motion } from 'framer-motion';
import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ButtonNext from '../../components/buttons/button-next';
import Button from '../../components/forms/button';
import Checkbox from '../../components/forms/checkbox';
import InputField from '../../components/forms/input';
import Modal from '../../components/modal';
import { slideIn } from '../../framer';
import { lang } from '../../lang';
import { PageContext, SignupContext } from '../../utilities/context';

const SignupView = () => {
  const {
    control,
    errors,
    handleSubmit,
    _submitForm,
    watch,
    setError,
    clearError,
    closeConfirmModal,
    showModal,
  }: any = useContext(SignupContext);

  const watchPassword = watch && watch('password');
  const watchConfirmPassword = watch && watch('confirm_password');

  useEffect(() => {
    if (watchPassword !== watchConfirmPassword) {
      setError &&
        setError('confirm_password', {
          type: 'focus',
          message: 'Password did not match!',
        });
    } else {
      clearError && clearError(true);
    }
  }, [watchPassword, watchConfirmPassword]);

  return (
    <motion.div variants={slideIn} initial="initial" animate="animate">
      <h3 className="text-center pb-10 text-white text-xl">
        {lang.create_acount.header}
      </h3>
      <div className="bg-white shadow w-full rounded-lg ">
        <div className="px-7 py-7 pb-3">
          <div className='flex flex-col text-sm text-center mb-8 text-pj-blue-500 font-medium'>
            <p>
              Looks like you're new to PasaJob.
            </p>
            <p>
              To get started, create an account first.
            </p>
          </div>
          <form onSubmit={handleSubmit(_submitForm)}>
            <div className="grid grid-cols-2 gap-3 xs:grid-cols-1">
              <div className="inline-block">
                <InputField
                  label={lang.general.first_name}
                  type="text"
                  name="first_name"
                  control={control}
                  rules={{ required: 'First name is required' }}
                  error={errors.first_name}
                />
              </div>
              <div className="inline-block">
                <InputField
                  label={lang.general.last_name}
                  type="text"
                  name="last_name"
                  control={control}
                  rules={{ required: 'Last name is required' }}
                  error={errors.last_name}
                />
              </div>
            </div>
            <InputField
              label={lang.general.email}
              type="email"
              name="email"
              control={control}
              rules={{ required: 'Email is required' }}
              error={errors.email}
            />
            <InputField
              label={lang.general.password}
              type="password"
              name="password"
              toggle
              control={control}
              rules={{ required: 'Password is required' }}
              error={errors?.password}
            />
            <InputField
              label={lang.general.confirm_password}
              type="password"
              name="confirm_password"
              toggle
              control={control}
              rules={{ required: 'Confirm password is required' }}
              error={errors?.confirm_password}
            />
            <Checkbox
              name="agreement"
              label=""
              rules={{ required: 'Accept terms and conditions' }}
              control={control}
              error={errors.agreement}
            >
              {`${lang.create_acount.agreement}`}
              <a href="https://www.pasajob.com/privacy-policy/" target="_blank">
                {lang.create_acount.privacy_policy}
              </a>
            </Checkbox>
          </form>
        </div>
        <div className="px-7 pb-10">
          <Button label="Continue" onClick={handleSubmit(_submitForm)} />
          <p className="text-sm text-center pt-6 text-pj-blue-100">
            {`${lang.create_acount.have_an_account} `}
            <Link to="/" className="text-pj-blue-400">
              Login here.
            </Link>
          </p>
        </div>
        <Modal
          show={showModal}
          onClose={closeConfirmModal}
          width={350}
          title="Successful"
        >
          <p className="text-sm text-pj-blue-100 py-2">
            You have now been registered!
            <Link to="/" className="text-pj-blue-400 ml-1 font-semibold">
              Login here.
            </Link>
          </p>
        </Modal>
      </div>
    </motion.div>
  );
};

export default SignupView;
