import Match from '../../../components/match';
import { Link } from 'react-router-dom';
import { BsChevronRight } from 'react-icons/bs';
import Avatar from '../../../components/avatar';
import { AiOutlineUserAdd } from 'react-icons/ai';
import { OnClickFunction } from '../../../utilities/types';
import { statusFixer } from '../../../utilities/helper';
import AgingProgress from './aging-progress';

type Props = {
  name: string;
  job_title: string | null;
  seeker_id: number;
  profile_image: string | null;
  application_id?: number;
  job_id: number;
  is_match?: boolean;
  onClick?: OnClickFunction;
  status?: 'Pending' | 'Accepted';
  job_status?: 'Pending' | 'Active' | 'Filled Up' | null | undefined;
  lane?: any;
  type?: string;
  agingDays?: number
};

const SeekerProfile = ({
  type = '',
  name,
  job_title,
  seeker_id,
  profile_image,
  application_id,
  job_id,
  is_match,
  status,
  onClick,
  job_status,
  lane,
  agingDays
}: Props) => {

  const showAgingProgress = ["Shortlisted", "For Interview", "For Hire", "Applied"].find(aging => aging === lane?.laneId)

  return (
    <div className='flex flex-row justify-between w-full'>
      <div className="flex flex-row profile">
    <Avatar name={name} url={profile_image} size={50} />
    <div className="flex-auto pl-4" style={{ maxWidth: 185 }}>
      <div className="text-base text-left truncate w-36 text-pj-blue-500">
        {name}
      </div>
      <div className="text-sm text-left truncate w-36 text-pj-blue-100">
        {job_title ? job_title : 'N/A'}
      </div>
      <div className="relative flex justify-between">
        <div className="flex items-center justify-center">
          <Match seeker_id={seeker_id} job_id={job_id} />
          {status && (
            <>
              <div className="w-1 h-1 mx-1 rounded-full bg-pj-blue-100" />
              <div className="text-xs text-pj-green-300">{statusFixer(status)} </div>
            </>
          )}
        </div>
      </div>
    </div>
    <div className="flex flex-col justify-between">
      <div
        className={`flex justify-end h-10 ${!['Hired'].includes(lane?.laneId) &&
          job_status === 'Filled Up' &&
          'hidden'
          }`}
      >
        <Link
          to={`/candidate-profile/${seeker_id}/${job_id}/${application_id ?? ''}${type == 'match' ? '?match=true' : ''}`}
          className="flex flex-row cursor-pointer"
        >
          {/* <div className="flex items-center justify-center w-4 h-4 mr-1 text-xs text-white bg-red-500 rounded-full">
          2
        </div> */}
          <BsChevronRight className="text-large text-pj-blue-100" />
        </Link>
      </div>
      {is_match && (
        <div
          className="flex flex-row items-center justify-center pt-5 text-xs uppercase cursor-pointer text-pj-green-300"
          onClick={() => onClick && onClick(seeker_id, job_id)}
        >
          <AiOutlineUserAdd className="mr-1 text-base" />
          Invite to Applysß
        </div>
      )}
    </div>
  </div>
  {showAgingProgress &&  <AgingProgress remainingDays={agingDays>0 ? agingDays : 0} application_id={application_id} name={name} status={lane.laneId} data={lane}/>}
    </div>
  );
};

export default SeekerProfile;
