import { useEffect, useState } from 'react';
import { getScore } from '../utilities/api';

type Props = {
  seeker_id: number;
  job_id: number;
};

const Match = ({ seeker_id, job_id }: Props) => {
  const [score, setScore] = useState<number>(0);
  useEffect(() => {
    (async () => {
      // const result: any = await getScore(seeker_id, job_id);
      // if (result?.status) {
      //   const score = result?.data?.data?.match_score?.score;
      //   setScore(score || 0);
      // }
    })();
  }, [seeker_id, job_id]);

  return (
    <p className={`text-xs text-left 
      ${
        score > 70 ? 'text-pj-green-300' : 'text-pj-orange-200'
      }`}
    >
      {/* {`${score}% Match`} */}
    </p>
  );
};

export default Match;
