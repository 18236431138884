import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const AgingProgress = ({remainingDays}:{remainingDays:number}) => {
  
  const value = remainingDays > 11 ? 0 : remainingDays > 7 ? 24 : remainingDays > 3 ? 49 : remainingDays > 0 ? 73 : 100
  return (
      <div className='flex flex-row items-center gap-2 px-2 text-xs text-gray-400 uppercase border border-gray-400 rounded-full h-7'>
        <div className={`w-5 h-5 border-1.5 rounded-full ${remainingDays <= 5 ? "border-red-400":"border-gray-400"}`}>
          <CircularProgressbar 
          value={value}
          strokeWidth={50}
           styles={buildStyles({
            strokeLinecap:"butt",
            trailColor:remainingDays <= 5 ? "#F87171" : "#9CA3AF",
            pathColor:"white",
          })}
           />
        </div>
        <div>
          {remainingDays} days to move
        </div>
      </div>
  );
};

export default AgingProgress;
