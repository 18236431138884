import gql from 'graphql-tag';

const mutations = {
  VERIFY_FORGOT_PASSWORD_CODE: gql`
    mutation MyQuery($data: String!) {
      forgot_password_new_password_email(object: { data: $data }) {
        email
      }
    }
  `,
  READ_NOTIFICATION: gql`
    mutation readNotification($id: Int!) {
      update_employer_notifications(
        where: { id: { _eq: $id } }
        _set: { is_read: true }
      ) {
        returning {
          id
        }
      }
    }
  `,
  EDIT_PROFILE: gql`
    mutation update_employer_profile(
      $id: Int!
      $common_user: common_users_set_input = {}
      $employer_profile: employer_profile_set_input = {}
    ) {
      update_common_users(where: { id: { _eq: $id } }, _set: $common_user) {
        affected_rows
      }
      update_employer_profile(
        where: { employer_id: { _eq: $id } }
        _set: $employer_profile
      ) {
        affected_rows
      }
    }
  `,
  CHANGE_PASSWORD: gql`
    mutation change_password($data: String!) {
      change_password(object: { data: $data }) {
        message
      }
    }
  `,
  REMOVE_EMPLOYEE: gql`
    mutation remove_employee($user_id: Int) {
      update_seeker_profile(
        where: { user_id: { _eq: $user_id } }
        _set: { company_id: null }
      ) {
        affected_rows
      }
    }
  `,
  UPDATE_SUB_EMPLOYERS: gql`
    mutation update_sub_employer($data: String!) {
      UpdateSubEmployer(data: $data) {
        message
      }
    }
  `,
  VERIFY_OTP: gql`
    mutation verify_otp($data: String!) {
      verify_otp(object: { data: $data }) {
        activated
        token
      }
    }
  `,
  CREATE_JOB: gql`
    mutation create_job(
      $title: String
      $description: String
      $job_employment_type: String
      $job_position_level: String
      $maximum_salary: Float
      $minimum_salary: Float
      $province_id: Int
      $job_type_id: Int
      $industry_id: Int
      $employer_id: Int
      $company_detail_id: Int
      $minimum_qualification: String
      $job_experience: String
      $home_based: Boolean
      $traits: [employer_traits_insert_input!]!
      $common_job_skills: [common_job_skills_insert_input!]!
      $city_id: Int
      $address: String
      $referral_count: Int
      $referral_fee: Float
      $show_salary: Boolean
      $visible: Boolean
      $disbursement_type: String
    ) {
      insert_common_jobs(
        objects: {
          common_job_informations: {
            data: {
              job_employment_type: $job_employment_type
              job_position_level: $job_position_level
              maximum_salary: $maximum_salary
              minimum_salary: $minimum_salary
              minimum_qualification: $minimum_qualification
              job_experience: $job_experience
              show_salary: $show_salary
            }
          }
          title: $title
          visible: $visible
          home_based: $home_based
          job_type_id: $job_type_id
          industry_id: $industry_id
          employer_id: $employer_id
          description: $description
          province_id: $province_id
          city_id: $city_id
          address: $address
          company_detail_id: $company_detail_id
          common_job_skills: { data: $common_job_skills }
          employer_traits: { data: $traits }
          common_orders: {
            data: {
              referral_count: $referral_count
              referral_fee: $referral_fee
              disbursement_type: $disbursement_type
            }
          }
        }
      ) {
        affected_rows
        returning {
          id
          created_at
        }
      }
    }
  `,
  CREATE_TRANSACTION: gql`
    mutation transact($data: String!) {
      MagPieCharge(data: $data) {
        success
        message
      }
    }
  `,
  CREATE_COMPANY: gql`
    mutation insert_employer_company(
      $objects: [employer_companies_insert_input!]!
    ) {
      insert_employer_companies(objects: $objects) {
        returning {
          id
        }
      }
    }
  `,
  UPDATE_COMPANY: gql`
    mutation update_company(
      $id: Int
      $_set: employer_companies_set_input = {}
    ) {
      update_employer_companies(where: { id: { _eq: $id } }, _set: $_set) {
        affected_rows
      }
    }
  `,
  CREATE_INTERVIEW: gql`
    mutation create_interview($data: [common_job_interviews_insert_input!]!) {
      insert_common_job_interviews(objects: $data) {
        returning {
          id
        }
      }
    }
  `,
  CREATE_AN_INTERVIEW: gql`
    mutation create_interview(
      $application_id: Int!
      $status: String!
      $data: [common_job_interviews_insert_input!]!
    ) {
      update_common_job_applications(
        where: { id: { _eq: $application_id } }
        _set: { status: $status }
      ) {
        returning {
          id
        }
      }

      insert_common_job_interviews(objects: $data) {
        returning {
          id
        }
      }
    }
  `,
  UPDATE_APPLICATION: gql`
  mutation update_application($ids: [Int!], $status: String!) {
    update_common_job_applications(
      where: { id: { _in: $ids } }
      _set: { status: $status }
    ) {
      returning {
        id
        status
      }
    }
  }
  `,
  SEND_FORGOT_PASSWORD_CODE: gql`
    mutation forgot_password($data: String!) {
      forgot_password_code_email(object: { data: $data }) {
        email
      }
    }
  `,
  INVITE_CANDIDATE: gql`
    mutation suggested_candidates($seeker_id: Int, $job_id: Int) {
      insert_common_shortlists(
        objects: { job_id: $job_id, seeker_id: $seeker_id, status: "Pending" }
      ) {
        returning {
          common_user {
            id
            first_name
            last_name
          }
        }
      }
    }
  `,
  SOCIAL_LOGIN: gql`
    mutation social_login(
      $email: String!
      $first_name: String!
      $last_name: String!
      $user_type: String!
      $is_social_account: Boolean!
    ) {
      social_signup(
        object: {
          email: $email
          first_name: $first_name
          last_name: $last_name
          is_social_account: $is_social_account
          user_type: $user_type
        }
      ) {
        token
        user_info {
          id
          first_name
          last_name
          user_type
          is_social_account
          email
          created_at
          updated_at
        }
      }
    }
  `,
  SET_READ: gql`
    mutation update_message_status($seeker_id: Int!, $job_id: Int!) {
      update_common_messages(
        where: { seeker_id: { _eq: $seeker_id }, job_id: { _eq: $job_id } }
        _set: { is_read: true }
      ) {
        returning {
          id
        }
      }
    }
  `,
  SEND_MESSAGE: gql`
    mutation send_message($data: [common_messages_insert_input!]!) {
      insert_common_messages(objects: $data) {
        returning {
          id
        }
      }
    }
  `,
  INSERT_COMPANY_FILES: gql`
    mutation insert_files($files: [company_files_insert_input!]!) {
      insert_company_files(objects: $files) {
        returning {
          id
        }
      }
    }
  `,
  UPDATE_JOB: gql`
    mutation update_job(
      $id: Int!
      $title: String
      $description: String
      $job_employment_type: String
      $job_position_level: String
      $maximum_salary: Float
      $minimum_salary: Float
      $province_id: Int
      $job_type_id: Int
      $industry_id: Int
      $employer_id: Int
      $company_detail_id: Int
      $minimum_qualification: String
      $job_experience: String
      $home_based: Boolean
      $traits: [employer_traits_insert_input!]!
      $common_job_skills: [common_job_skills_insert_input!]!
      $city_id: Int
      $address: String
      $referral_count: Int
      $referral_fee: Float
      $show_salary: Boolean
      $visible: Boolean
      $disbursement_type: String
    ) {
      update_common_jobs(
        where: { id: { _eq: $id } }
        _set: {
          title: $title
          visible: $visible
          home_based: $home_based
          job_type_id: $job_type_id
          industry_id: $industry_id
          employer_id: $employer_id
          description: $description
          province_id: $province_id
          city_id: $city_id
          address: $address
          company_detail_id: $company_detail_id
        }
      ) {
        returning {
          id
        }
      }
      update_common_job_information(
        where: { job_id: { _eq: $id } }
        _set: {
          job_employment_type: $job_employment_type
          job_position_level: $job_position_level
          maximum_salary: $maximum_salary
          minimum_salary: $minimum_salary
          minimum_qualification: $minimum_qualification
          job_experience: $job_experience
          show_salary: $show_salary
        }
      ) {
        returning {
          id
        }
      }
      update_common_orders(
        where: { job_id: { _eq: $id } }
        _set: {
          referral_fee: $referral_fee
          referral_count: $referral_count
          disbursement_type: $disbursement_type
        }
      ) {
        returning {
          id
        }
      }
      delete_common_job_skills(where: { job_id: { _eq: $id } }) {
        returning {
          id
        }
      }
      delete_employer_traits(where: { job_id: { _eq: $id } }) {
        returning {
          id
        }
      }
      insert_common_job_skills(objects: $common_job_skills) {
        returning {
          id
        }
      }
      insert_employer_traits(objects: $traits) {
        returning {
          id
        }
      }
    }
  `,
  INSERT_TRANSACTIONS: gql`
    mutation insert_transaction($data: [employer_transactions_insert_input!]!) {
      insert_employer_transactions(objects: $data) {
        returning {
          id
        }
      }
    }
  `,
  SET_RESIGNED: gql`
    mutation setResigned($job_id: Int!, $seeker_id: Int!) {
      update_common_job_applications(
        _set: { is_resigned: true }
        where: { job_id: { _eq: $job_id }, seeker_id: { _eq: $seeker_id } }
      ) {
        returning {
          id
          is_resigned
        }
      }
    }
  `,
  GENERATE_API_KEY: gql`
    mutation generate_api($data: [sys_public_api_creds_insert_input!]!) {
      insert_sys_public_api_creds(objects: $data) {
        returning {
          id
        }
      }
    }
  `,

  DELETE_JOB: gql`
    mutation myMutation($id: Int!) {
      delete_common_jobs(where: { id: { _eq: $id } }) {
        affected_rows
      }
    }
  `,
  INSERT_SUB_EMPLOYER_COMPANY: gql`
    mutation insert_sub_employers_company($company_id: Int!, $sub_employer_id: Int!) {
      insert_sub_employers_company(objects: { company_id: $company_id, sub_employer_id:$sub_employer_id}) {
        returning {
          id
        }
      }
    }
  `,
};

export default mutations;
