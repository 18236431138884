import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import { auth } from '../../utilities/auth';
import { TransactionsContext } from '../../utilities/context';
import { TransactionsInitialState } from '../../utilities/states';
import {
  Option,
  TransactionData,
  TransactionDetails,
  TransactionList,
} from '../../utilities/types';
import TransactionsView from './view';
import { getTransactionDetails, getTransactions as getTransactions2 } from '../../utilities/api_v2/transaction';

type Params = {
  id: string;
};

const TransactionsController = () => {
  const { handleSubmit, register } = useForm();
  const [show, setShow] = useState<boolean>(false);
  const params: Params = useParams();
  const history = useHistory();
  const [transactionList, setTransactionList] = useState<TransactionList[]>([]);
  const [transactionDetails, setTransactionDetails] =
    useState<TransactionDetails>({
      ...TransactionsInitialState.transactionDetails,
    });
    const [where, setWhere] = useState<object>({ 
      OR:[
          {
              employer_id:auth.getId(),
          },
          // {
              // common_jobs:{
              //     employer_companies:{
              //         sub_employers_company:{
              //             some:{
              //                 sub_employer_id:auth.getId(),
              //             }
              //         }
              //     }
              // }
          // }
          {
              common_jobs:{
                  employer_companies:{
                      employer_id:auth.getId(),
                  }
              }
          }
      ],
   },);

  const [sort, setSort] = useState<Option>({
    label: 'Most Recent',
    value: 'desc',
  });

  const [transacType, setTransacType] = useState<Option>({
    label: 'All Transactions',
    value: null,
  });

  const _getTransactions = async ({where, order}:any) => {
    return await getTransactions2({
      where:where,
      order:sort.value
    }).then((tr:any)=>{
      if(tr){
      const items: TransactionList[] = tr.data.map(
        transactions => {
          let total_vat = transactions.total_vat;
          let amount = transactions.amount;
          let total_com = transactions.total_commission;
          let posting = transactions.posting_fee;
          let total_amount = amount - total_vat - total_com - posting;
          return {
            job_position: transactions.common_job ? transactions.common_job.title : '',
            transaction_id: transactions.transaction_id,
            date: transactions.created_at,
            logo: transactions.common_job?.employer_company ? transactions.common_job.employer_company.company_image : '',
            amount: transactions.amount,
            id: transactions.id,
            total_vat: transactions.total_vat,
            total_commission: transactions.total_commission,
            transaction_type: transactions.transaction_type,
            posting_fee: transactions.posting_fee,
            sub_total: total_amount.toLocaleString(),
            company_name: transactions.common_job?.employer_company ? transactions.common_job.employer_company.name : '',
          };
        }
      );
      setTransactionList(items);
      }
    })
  }
  
  const _getTransactionDetails = async () => {
    await getTransactionDetails({
      id: parseInt(params.id),
    }).then((tr:any)=>{
      console.log(tr)
    if (tr) {
      const data = tr.data;
      let total_vat = data?.total_vat;
      let total_com = data?.total_commission;
      let amount = data?.amount;
      let posting = data?.posting_fee;
      let total_amount = amount - total_vat - total_com - posting;
      const transaction_info: TransactionDetails = {
        amount: data?.amount,
        employer_id: auth.getId(),
        transaction_id: data?.transaction_id,
        updated_at: data?.updated_at,
        created_at: data?.created_at,
        job_id: data?.job_id,
        payment_type: data?.payment_type,
        total_vat: data?.total_vat,
        total_commission: data?.total_commission,
        id: data?.id,
        job_position: data?.common_job ? data.common_job.title : '',
        logo: data?.common_job ? data.common_job.employer_company.company_image : '',
        company_name: data?.common_job ? data.common_job.employer_company.name : '',
        transaction_type: data?.transaction_type,
        total_amount: total_amount,
        posting_fee: data?.posting_fee,
      };
      setTransactionDetails(transaction_info);
    }
    })
  }

  useEffect(() => {
    if (params.id) {
      _getTransactionDetails()
      setShow(true);
    }
  }, [params]);

  useEffect(() => {
    _getTransactions({
      where:where,
      order:sort.value
    })
    setTransactionDetails({ ...TransactionsInitialState.transactionDetails });
  }, [where, sort]);



  const _handleSearch = (data: any) => {
    // NOTE: assume that field name is search
    if (data.search.length > 0) {
      setWhere(prev=>{
        return {
          ...prev,
          common_jobs:{
            title:{
              contains:data.search||"",
              mode:"insensitive"
          }
          }
        }
      })
    } else {
      const newWhere: any = { ...where };
      delete newWhere?.common_job.title;
      setWhere({...newWhere})    
  }
  };

  useEffect(() => {
    if (transacType.value) {
      setWhere(prev=>({
        ...prev,
        transaction_type: transacType.value 
      }));
    }else {
      const newWhere: any = { ...where };
      delete newWhere?.transaction_type;
      setWhere({...newWhere});
    }
  }, [transacType]);

  const _handleSort = (option: any) => {
    setSort(option);
  };

  const _handleSelect = (option: any) => {
    setTransacType(option);
  };

  const _clickHandler = (id: number) => {
    history.push(`/transactions/${id}`);
    setShow(false);
    setTimeout(() => {
      setShow(true);
    }, 300);
  };

  const value: TransactionData = {
    show,
    transactionList,
    transactionDetails,
    sort,
    transacType,
    // view,
    register,
    handleSubmit,
    _handleSort,
    _handleSelect,
    _clickHandler,
    _handleSearch,
  };

  return (
    <TransactionsContext.Provider value={value}>
      <TransactionsView />
    </TransactionsContext.Provider>
  );
};

export default TransactionsController;
