import { motion } from 'framer-motion';
import React from 'react';
import { useState } from 'react';
import { RiArrowLeftSLine } from 'react-icons/ri';
import { menu, rotate } from '../../framer';
import SidebarOne from '../sidebar/sidebar-1';
import SidebarTwo from '../sidebar/sidebar-2';
import FloatingView from '../notification/floating-view';
import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ProfileSelect from '../profile';

type Parent = {
  children: React.ReactNode;
  hide?: boolean;
  show: boolean;
};

const MainTemplate = ({ children, hide, show }: Parent) => {
  const [open, setOpen] = useState<boolean>(true);
  const [showNotification, setShowNotfication] = useState<boolean>(false);

  useEffect(() => {
    setOpen(show);
  }, [show]);

  return (
    <div className="flex">
      <ToastContainer />
      <SidebarOne
        showNotification={showNotification}
        setShowNotfication={setShowNotfication}
      />
      {showNotification && (
        <FloatingView setShowNotfication={setShowNotfication} />
      )}
      {!hide && (
        <motion.div
          variants={menu}
          initial="show"
          animate={open ? 'show' : 'hide'}
          className=" h-screen sticky top-0 shadow-xl z-50"
        >
          <ProfileSelect open={open} />
          <SidebarTwo show={open} />
          <motion.div
            variants={rotate}
            animate={open ? 'show' : 'hide'}
            whileTap={{
              scale: 1.2,
            }}
            className="absolute p-2 bg-pj-blue-100 round-full content-center -right-4 bottom-20 rounded-full cursor-pointer z-50"
            onClick={() => setOpen(open => !open)}
          >
            <RiArrowLeftSLine
              className="w-5 h-5 text-white"
              style={{ width: 18, height: 18 }}
            />
          </motion.div>
        </motion.div>
      )}
      <main className="w-full bg-gray-200 flex flex-col justify-between max-h-screen">
        <div className="flex-auto min-h-0">
          <div style={{ height: 'calc(100vh - 3rem)' }}>{children}</div>
        </div>
        <footer className="p-4 h-12">
          <p className="text-sm text-pj-blue-400 p-0 text-right">
            Copyrighted (c) 2020 PasaJob. Trademarks and brands are the property
            of their respective owners. <span className="text-xs">v1.0.2</span>
          </p>
        </footer>
      </main>
    </div>
  );
};

MainTemplate.defaultProps = {
  hide: false,
};

export default MainTemplate;
