import { useEffect, useState, useContext } from 'react';
import ButtonNext from '../../../components/buttons/button-next';
import InputField from '../../../components/forms/input';
import Select from '../../../components/forms/select';
import Upload from '../../../components/forms/upload';
import { CompanyContext } from '../../../utilities/context';
import CompanyTemplate from '../template';
import { useParams } from 'react-router-dom';
import { Option } from '../../../utilities/types';
import Loading from '../../../components/loader';
import { auth } from '../../../utilities/auth';
import { getIndustries, getRegions } from '../../../utilities/api_v2/job_create';

type Params = {
    id: string;
};

const Create = () => {
    const {
        control,
        company,
        errors,
        file,
        id,
        loading,
        setValue,
        watch,
        handleSubmit,
        _submitForm,
        _beforeUpload,
        resetFields,
        errorImage,
        errorWebsite,
        loader
    } = useContext(CompanyContext);

    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [regions, setRegions] = useState<Option[]>([]);
    const [provinces, setProvinces] = useState<Option[]>([]);
    const [cities, setCities] = useState<Option[]>([]);
    const [indutries, setIndustries] = useState<Option[]>([]);
    const [delay, setDelay] = useState<boolean>(true);
    const [_regions, _setRegions] = useState<any>(null);
    const [_industries, _setIndustries] = useState<any>(null);

    const params: Params = useParams();

    const _getRegions = async () => {
         await getRegions().then((data)=>{
            if(data){
                _setRegions(data.data)
            }
        })
    }

    const _getIndustries = async () => {
         await getIndustries().then((data)=>{
            if(data){
                _setIndustries(data)
            }
        })
    }

    useEffect(()=>{
        _getRegions()
        _getIndustries()
    },[])

    const watchRegion = watch && watch('region_id');
    const watchProvince = watch && watch('province_id');

    useEffect(() => {
        console.log(_regions)
        if (_regions) {
            setRegions([{ label: '', value: null }, ..._regions.country[0].regions]);
        }
    }, [_regions]);

    useEffect(() => {
        if (id) {
            setIsEdit(true);
        }
    }, [id]);

    useEffect(() => {
        if (_industries) {
            const items = _industries.data.map(industry => ({
                label: industry.industry_name,
                value: industry.id,
            }));
            setIndustries([{ label: '', value: '' }, ...items]);
            // setRegions([{ label: '', value: null }, ..._industries.country[0].regions]);
        }
    }, [_regions]);

    useEffect(() => {
        if (watchRegion) {
            const items = regions.filter(
                ({ value }) => value === parseInt(watchRegion)
            );
            setIsEdit(false);
            if (!isEdit && setValue) {
                setValue('province_id', null);
                setValue('city_id', null);
            }
            if (items[0]?.children) {
                setProvinces([{ label: '', value: null }, ...items[0].children]);
                setCities([{ label: '', value: null }]);
            }
        }
    }, [watchRegion]);

    useEffect(() => {
        if (watchProvince) {
            let items = regions.filter(
                ({ value }) => value === parseInt(watchRegion)
            );
            if (items[0]?.children) {
                items = items[0].children.filter(
                    ({ value }) => value === parseInt(watchProvince)
                );
                if (items[0]?.children) {
                    setCities([{ label: '', value: null }, ...items[0].children]);
                }
            }
        }
    }, [watchProvince]);

    useEffect(() => {
        if (loading == true) {
            setDelay(true);
        } else {
            setTimeout(() => {
                setDelay(false);
            }, 500);
        }
    }, [loading]);

    return (
        <CompanyTemplate>
            <div className="w-full h-full p-4 px-20 bg-white bg-opacity-50">
                {loading || delay ? (
                    <Loading show={loading || delay} />
                ) : (
                    <form
                        onSubmit={handleSubmit && _submitForm && handleSubmit(_submitForm)}
                        hidden={delay}
                    >
                        <div className="flex justify-center my-10">
                            <Upload file={file} onChange={_beforeUpload} disabled={!auth.isAdmin()} />
                        </div>
                        {errorImage && (
                            <p className="block mb-5 text-sm text-center text-red-500">
                                Please upload less than or equal to 10 MB image.
                            </p>
                        )}
                        <InputField
                            control={control}
                            label="Company Name"
                            name="name"
                            defaultValue={company.name}
                            rules={{ required: 'Company name is required' }}
                            error={errors?.name}
                            disabled={!auth.isAdmin()}
                        />

                        <div className="flex flex-row space-between">
                            <div className="w-1/2 pr-4">
                                <Select
                                    control={control}
                                    label="Region"
                                    name="region_id"
                                    defaultValue={company.region_id}
                                    rules={{ required: 'Region is required' }}
                                    error={errors?.region_id}
                                    options={regions}
                                    disabled={!auth.isAdmin()}
                                />
                            </div>
                            <div className="w-1/2 pl-4">
                                <Select
                                    control={control}
                                    label="Province"
                                    name="province_id"
                                    defaultValue={company.province_id}
                                    rules={{ required: 'Province is required' }}
                                    error={errors?.province_id}
                                    options={provinces}
                                    disabled={!auth.isAdmin()}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row space-between">
                            <div className="w-1/2 pr-4">
                                <Select
                                    control={control}
                                    label="City"
                                    name="city_id"
                                    defaultValue={company.city_id}
                                    rules={{ required: 'City is required' }}
                                    error={errors?.city_id}
                                    options={cities}
                                    disabled={!auth.isAdmin()}
                                />
                            </div>
                            <div className="w-1/2 pl-4">
                                <InputField
                                    control={control}
                                    label="Address"
                                    name="address"
                                    defaultValue={company.address}
                                    rules={{ required: 'Address is required' }}
                                    error={errors?.address}
                                    disabled={!auth.isAdmin()}
                                />
                            </div>
                        </div>
                        <div className="flex flex-row space-between">
                            <div className="w-1/2 pr-4">
                                <InputField
                                    control={control}
                                    label="Contact Number"
                                    name="tel"
                                    defaultValue={company.tel}
                                    rules={{ required: 'Contact number is required' }}
                                    error={errors?.tel}
                                    disabled={!auth.isAdmin()}
                                />
                            </div>
                            <div className="w-1/2 pl-4">
                                <Select
                                    control={control}
                                    label="Industry"
                                    name="industry_id"
                                    defaultValue={company.industry_id}
                                    rules={{ required: 'Industry is required' }}
                                    error={errors?.industry_id}
                                    options={indutries}
                                    disabled={!auth.isAdmin()}
                                />
                            </div>
                        </div>
                        <InputField
                            control={control}
                            label="Website"
                            name="website"
                            defaultValue={company.website}
                            disabled={!auth.isAdmin()}
                        />
                        {errorWebsite && (
                            <p className="block mb-5 text-sm text-red-500">
                                Please insert a valid link.
                            </p>
                        )}
                        <InputField
                            control={control}
                            label="Company Description"
                            name="company_description"
                            defaultValue={company.company_description}
                            disabled={!auth.isAdmin()}
                        />
                        {auth.isAdmin() &&
                            <div className="flex justify-center mt-5">
                                {loader ? 
                                    <ButtonNext disabled label='loading ...' light/>
                                    :
                                    <ButtonNext
                                        label={!params.id ? 'Add Company' : 'Edit Company'}
                                        buttonType="submit"
                                        light
                                    />
                                }
                            </div>}
                    </form>
                )}
            </div>
        </CompanyTemplate>
    );
};

export default Create;
