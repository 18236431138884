import { motion } from 'framer-motion';
import {
    Control,
    Controller,
    FieldError,
    FieldName,
    RegisterOptions,
} from 'react-hook-form';
import { slideIn } from '../../framer';
import { OnClickFunction } from '../../utilities/types';

interface TInputProps {
    rules?: Exclude<
        RegisterOptions,
        'valueAsNumber' | 'valueAsDate' | 'setValueAs'
    >;
    defaultValue?: any;
    name: FieldName<String>;
    error?: FieldError | any;
    control?: Control;
}

type Option = {
    label: string;
    value: any;
};

interface Input extends TInputProps {
    label: string;
    type?: string;
    toggle?: boolean;
    max?: string | number | undefined;
    min?: string | number | undefined;
    maxlength?: number | undefined;
    placeholder?: string;
    rows?: number;
    dark?: boolean;
    onIncrement?: OnClickFunction;
    pattern?: string;
    options?: Option[];
    disabled?: boolean;
}

const Select = ({
    label,
    type,
    rules,
    defaultValue = '',
    name,
    error,
    control,
    placeholder,
    dark = false,
    options,
    disabled,
}: Input) => {
    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, name, ref } }) => (
                <div className="flex -mx-3 pj-focus">
                    <div className="w-full px-3 mb-5">
                        <label
                            className={`text-sm focus:border-green-200 ${dark ? 'text-pj-blue-500' : 'text-pj-blue-100'
                                }`}
                        >
                            {label}
                            <div
                                className={`mt-1 flex p-1 pr-2.5 bg-white rounded border border-gray-200 ${error
                                        ? 'border-red-400 focus:border-red-400'
                                        : 'focus:border-pj-green-200'
                                    }`}
                            >
                                <select
                                    className="w-full z-10 p-1.5 -mr-10  outline-none  text-sm"
                                    onChange={onChange}
                                    value={value}
                                    name={name}
                                    ref={ref}
                                    placeholder={placeholder}
                                    disabled={disabled}
                                >
                                    <option value="default" selected disabled>Select an option...</option>
                                    {options &&
                                        options.map((val, index) => (
                                            <option key={`${index}`} value={val.value}>
                                                {val.label}
                                            </option>
                                        ))}
                                </select>
                            </div>
                        </label>
                        {error && (
                            <motion.div
                                variants={slideIn}
                                initial="initial"
                                animate="animate"
                                className="text-xs text-red-400 pt-1"
                            >
                                {error.message}
                            </motion.div>
                        )}
                    </div>
                </div>
            )}
        />
    );
};

export default Select;
