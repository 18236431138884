import { useCallback, useEffect, useState } from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';
import { abbreviate } from '../helpers';
import { lang } from '../lang';
import { Company, OnClickFunction } from '../utilities/types';
import Modal from './modal';
import { useForm } from 'react-hook-form';
import { cloudinary, file_upload } from '../utilities/api';
import Resizer from 'react-image-file-resizer';
import { BsUpload } from 'react-icons/bs';
import mutations from '../utilities/mutations';
import ButtonNext from './buttons/button-next';
import { auth } from '../utilities/auth';
import Avatar from './avatar';
import Dropzone, { useDropzone } from 'react-dropzone';
import { uploadFile } from '../utilities/helper';
import { getCompanyFiles, insertCompanyFiles } from '../utilities/api_v2/companies';

const CompanyList = (props: Company) => {
  const { handleSubmit } = useForm();
  const [show, setShow] = useState<boolean>(false);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [enabled, setEnabled] = useState<boolean>(false);
  const [file, setFile] = useState<any>([]);
  const [upFile, setUpFile] = useState<any>();
  const [open, setOpen] = useState<boolean>(false);
  const closeModal: OnClickFunction = () => {
    setShow(false);
  };
  const openModal: OnClickFunction = () => {
    setShow(true);
  };
  const closeConfirmModal: OnClickFunction = () => {
    setConfirm(false);
  };
  const _openConfirm: OnClickFunction = () => {
    setConfirm(true);
  };
  
  const showManageUser = !props.pending && !props.rejected
  
  const _getCompanyFiles = async ({
    company_id
  }:{company_id:number}) => {
    return await getCompanyFiles({company_id})
    .then((file:any)=>{
        if (file) {
          const items = file.data[0];
          setUpFile(items);
        }
    })
  }

  const _insertCompanyFiles = async (files:any) => {
    await insertCompanyFiles(files).then(()=>{
      setShow(false);
      closeModal && closeModal(true);
      setConfirm(true);
      _getCompanyFiles({
        company_id: props.id,
      })
    })
  }

  const resizeFile = async (file: any) =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        'JPEG',
        50,
        0,
        uri => {
          resolve(uri);
        },
        'base64'
      );
    }).then(res => {
      return res;
    });

  // const _beforeUpload = async (e: any) => {
  //   const result = await resizeFile(e.target.files[0]);
  //   setFile([...file, e.target.files[0]]);
  // };

  const onDrop = useCallback(acceptedFiles => {
    setFile(prev => [...prev, ...acceptedFiles]);
  }, []);

  // const { getRootProps, getInputProps } = useDropzone({
  //   accept: 'image/*',
  //   onDrop: acceptedFiles => {
  //     setFile(acceptedFiles.map(file => Object.assign(file, {
  //       preview: URL.createObjectURL(file)
  //     })));
  //   }
  // });

  const _handleUpload = async () => {
    let company_id = props.id;
    const results = await Promise.all(
      file.map(async (files: any) => {
        return await uploadFile(files);
      })
    );
    const files = results.map((result: any) => ({
      file_source: result.url,
      company_id,
      employer_id: auth.getId(),
    }));
    _insertCompanyFiles(files)
  };

  useEffect(() => {
    if (file.length === 0) {
      setEnabled(false);
    } else {
      setEnabled(true);
    }
  }, [file]);

  const _getCompanyList = () => {
    _getCompanyFiles({
      company_id: props.id,
    })
  };

  useEffect(() => {
    _getCompanyList();
  }, []);

  const _disableButton = () => {
    setEnabled(false);
    _handleUpload();
  };

  const UploadFile = () => {
    return (
      <div className="items-center pt-4 mt-1 mb-2 bg-white border-2 border-gray-200 rounded-lg">
        <label className="flex flex-row">
          <div className="flex flex-row mb-6">
            <Dropzone onDrop={onDrop}>
              {({ getRootProps, getInputProps }) => (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="flex items-center justify-center px-4 pt-4 bg-white">
                    <div className="flex-auto ml-2 text-sm font-medium text-gray-400 cursor-pointer pj-blue-500">
                      Please upload a photo or scanned copy here. <br />
                      Click or drag the file to this area to upload.
                    </div>
                    <BsUpload className="ml-32 text-2xl text-gray-400" />
                  </div>
                </div>
              )}
            </Dropzone>
            {/* <section>
              <div {...getRootProps()}>
                <input {...getInputProps()} className="cursor-pointer" />
                <div className="flex items-center justify-center px-4 pt-4 bg-white">
                  <div className="flex-auto ml-2 text-sm font-medium text-gray-400 pj-blue-500">
                    Please upload a photo or scanned copy here. <br />
                    Click or drag the file to this area to upload.
                  </div>
                  <BsUpload className="ml-32 text-2xl text-gray-400 cursor-pointer" />
                </div>
              </div>
            </section> */}
          </div>
        </label>
      </div>
    );
  };

  return (
    <div className="flex flex-row items-center justify-center w-full p-4 border-b border-gray-200">
      <div className="flex items-center justify-center w-12 h-12">
        <Link to={`/my-companies/${props.id}`}>
          <div
            className={`w-12 h-12 rounded-full border-2 border-gray-300 bg-cover bg-center flex justify-center items-center text-gray-500 text-xl ${
              (!props.logo || props.logo.length <= 0) &&
              'bg-gray-200 tracking-wide'
            }`}
            style={{ backgroundImage: `url(${props.logo})` }}
          >
            {(!props.logo || props.logo.length <= 0) && abbreviate(props.name)}
          </div>
        </Link>
      </div>
      <div className="flex flex-col justify-center flex-auto pl-4">
        <Link to={`/my-companies/${props.id}`}>
          <div className="flex-auto text-sm text-pj-blue-400">
            {lang.formatString(lang.company.company_name, props.name)}
          </div>
          <div className="flex-auto text-xs text-pj-blue-100">
            {lang.formatString(lang.company.total_jobs, props.total_jobs)}
          </div>
        </Link>
      </div>
      {showManageUser ?(
        <div className="w-30">
          {auth.isAdmin() && (
            <Link to={`/my-companies/${props.id}/manage-user/hr-team`}>
              <div
                className={
                  'p-2 px-3 rounded-full bg-pj-green-200 text-sm text-white'
                }
              >
                Manage Users
              </div>
            </Link>
          )}
        </div>
      ): (
        <div className="w-30">
          {auth.isAdmin() && (
            <button
              onClick={
                upFile?.total_files.aggregate.count ? _openConfirm : openModal
              }
            >
              <div
                className={`p-2 px-3 rounded-full bg-red-400 text-sm text-white ${
                  upFile?.total_files.aggregate.count &&
                  'p-2 px-3 rounded-full bg-pj-orange-200 text-sm text-white'
                }`}
              >
                {upFile?.total_files.aggregate.count === 0
                  ? `Verify Company`
                  : `Waiting for Review`}
              </div>
            </button>
          )}
        </div>
      )}
      <Modal
        closable
        show={show}
        onClose={closeModal}
        title="Company Verification"
        width={550}
      >
        <div className="items-center justify-center p-2">
          <div className="flex items-center justify-center px-4 py-2 mt-3 bg-gray-100 border-2 border-gray-200 rounded-lg">
            <div
              className={`w-12 h-12 rounded-full border-2 border-gray-300 bg-cover bg-center flex justify-center items-center text-gray-500 text-xl ${
                (!props.logo || props.logo.length <= 0) &&
                'bg-gray-200 tracking-wide'
              }`}
              style={{ backgroundImage: `url(${props.logo})` }}
            >
              {(!props.logo || props.logo.length <= 0) &&
                abbreviate(props.name)}
            </div>
            <div className="flex-auto ml-4 text-pj-blue-500 text-semibold">
              {lang.formatString(lang.company.company_name, props.name)}
            </div>
          </div>
          <div className="pt-5 text-pj-blue-100">DTI or SEC Registration</div>
          <form
            onSubmit={
              handleSubmit && _handleUpload && handleSubmit(_handleUpload)
            }
          >
            <UploadFile />
            {file.length ? (
              <div className="py-4 mt-1 bg-white border-2 border-gray-200 rounded-lg">
                <div className="grid grid-flow-row pl-6">
                  {file?.length
                    ? file?.map((upFiles, index) => (
                        <div className="flex-auto text-sm font-normal text-gray-500 pj-blue-500">
                          <p className="pb-1">
                            File {`${parseInt(index) + 1}`}
                          </p>
                        </div>
                      ))
                    : null}
                </div>
              </div>
            ) : null}
            <div className="flex justify-center mt-12">
              {enabled ? (
                <button
                  name="submit"
                  style={{ width: 150 }}
                  className="p-2 px-3 text-white rounded-full bg-pj-green-200 text-semibold"
                  onClick={_disableButton}
                >
                  Submit
                </button>
              ) : (
                <button
                  disabled
                  name="submit"
                  style={{ width: 150 }}
                  className="p-2 px-3 text-white bg-gray-300 rounded-full cursor-default text-semibold"
                >
                  Submit
                </button>
              )}
            </div>
          </form>
        </div>
      </Modal>
      <Modal closable show={confirm} onClose={closeConfirmModal} width={450}>
        <div className="items-center justify-center p-2">
          <div className="flex items-center justify-center pb-4 mb-2 text-center">
            <p className="text-xl font-light pj-blue-100 text-pj-blue-100">
              Thank you for sending your <br />
              verification documents.
            </p>
          </div>
          <div className="flex items-center justify-center text-center">
            <p className="text-sm font-light pj-blue-100 text-pj-blue-100">
              Please give us 48 hours to verify your company <br />
              registration. If you have already posted a job, <br />
              it will be automatically posted after validation.
            </p>
          </div>
        </div>
        <div className="flex justify-center w-full px-40 py-4">
          <ButtonNext label="OK" onClick={closeConfirmModal} full />
        </div>
      </Modal>
      <div className="flex items-center justify-end w-20 h-10">
        <Link to={`/my-companies/${props.id}`}>
          <RiArrowRightSLine className="text-2xl text-pj-blue-100" />
        </Link>
      </div>
    </div>
  );
};

export default CompanyList;
