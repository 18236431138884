import { BiCalendarEvent } from 'react-icons/bi';
import { FaMapMarkerAlt } from 'react-icons/fa';
import moment from 'moment';
import { Interview, OnClickFunction } from '../utilities/types';

interface Props extends Interview {
  onClick?: OnClickFunction;
  applicant?: any;
  short?: boolean;
}

const InterviewTemplate = ({
  address,
  date,
  start_time,
  applicant,
  onClick,
  short,
}: Props) => {
  return (
    <div>
      <div className="flex flex-col w-full p-1 mt-3 bg-gray-100 border rounded-lg">
        <div className="flex flex-row py-1 text-xs tex-pj-blue-500">
          <div className="flex items-center justify-center pr-2 text-base text-gray-400">
            <BiCalendarEvent />
          </div>
          <div className="flex items-center">{`${moment(date).format(
            'MMM DD, YY'
          )}, ${moment(start_time).format('h:mm a')}`}</div>
        </div>
        <div className="flex flex-row py-1 text-xs tex-pj-blue-500">
          <div className="flex items-center justify-center pr-2 text-base text-gray-400">
            <FaMapMarkerAlt />
          </div>
          <div className="flex items-center">{`${
            short
              ? `${address?.substr(0, 30)}${
                  address && address?.length > 30 ? '...' : ''
                }`
              : address
          }`}</div>
        </div>
      </div>
      <div
        className="flex justify-end pt-2 text-sm cursor-pointer text-pj-blue-400"
        onClick={() => onClick && onClick(applicant)}
      >
        Reschedule
      </div>
    </div>
  );
};

export default InterviewTemplate;
