import { useEffect } from 'react';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { auth } from '../utilities/auth';
import MainTemplate from './templates/dashboard-template';
import ReactGA from 'react-ga4';
ReactGA.initialize('G-2VFFZHFM5T');

const Authenticated = ({
  component: Component,
  hide = false,
  show = true,
  ...rest
}) => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: rest?.title ?? "Pasajob Employer" });
  }, [location, rest])


  if (!auth.isAuthenticated()) {
    history.push('/');
  }
  return (
    <Route
      {...rest}
      render={() => {
        return (
          <MainTemplate hide={hide} show={show}>
            <Component />
          </MainTemplate>
        );
      }}
    />
  );
};

export default Authenticated;
