import { ApolloProvider } from '@apollo/client';
import './App.css';
import { client } from './utilities/client';
import Routes from './utilities/router';
import { RecoilRoot } from 'recoil';

function App() {
  return (
    <ApolloProvider client={client}>
      <RecoilRoot>
        <Routes />
      </RecoilRoot>
    </ApolloProvider>
  );
}

export default App;
