import { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import Button from '../../../components/buttons/button';
import ButtonNext from '../../../components/buttons/button-next';
import InputField from '../../../components/forms/input';
import Loading from '../../../components/loader';
import { lang } from '../../../lang';
import { CheckoutContext } from '../../../utilities/context';

const StepFourTwo = () => {
    const {
        errors,
        control,
        loading,
        payment_type,
        checkout,
        handleSubmit,
        _submitForm,
        _changePayment,
        _checkout,
    } = useContext(CheckoutContext);

    const location: any = useLocation();


    return (
        <div>
            <div className="text-pj-blue-100 text-base p-4">
                {lang.create_job.choose_payment_method}
            </div>
            <div className="hidden">
                <label
                    className={`flex flex-row w-full p-4 cursor-pointer border-gray-200 ${payment_type === 'card' && 'bg-pj-green-100'
                        }`}
                    onClick={() => _changePayment('card')}
                >
                    <div className="w-10 flex justify-start items-start">
                        <input
                            type="radio"
                            name="address"
                            value="company_address"
                            checked={payment_type === 'card'}
                        />
                    </div>
                    <div className="w-64" style={{ marginTop: -6 }}>
                        <p className="m-0 p-0 text-pj-blue-500">Credit Card</p>
                    </div>
                </label>
            </div>
            <div>
                <label
                    className={`flex flex-row w-full p-4 cursor-pointer border-gray-200 ${payment_type === 'bank_transfer' && 'bg-pj-green-100'
                        }`}
                    onClick={() => _changePayment('bank_transfer')}
                >
                    <div className="w-10 flex justify-start items-start">
                        <input
                            type="radio"
                            name="address"
                            value="company_address"
                            checked={payment_type === 'bank_transfer'}
                        />
                    </div>
                    <div className="w-64" style={{ marginTop: -6 }}>
                        <p className="m-0 p-0 text-pj-blue-500">Bank Transfer</p>
                    </div>
                </label>
            </div>
            <div className="border-b">
                <label
                    className={`flex flex-row w-full p-4 cursor-pointer border-gray-200 ${payment_type === 'send_bill' && 'bg-pj-green-100'
                        }`}
                    onClick={() => _changePayment('send_bill')}
                >
                    <div className="w-10 flex justify-start items-start">
                        <input
                            type="radio"
                            name="address"
                            value="company_address"
                            checked={payment_type === 'send_bill'}
                        />
                    </div>
                    <div className="w-64" style={{ marginTop: -6 }}>
                        <p className="m-0 p-0 text-pj-blue-500">Send Bill</p>
                    </div>
                </label>
            </div>
            {payment_type === 'card' ? (
                <div className="p-4">
                    <img src="/cards.svg" alt="Cards" />
                    <form
                        onSubmit={handleSubmit && _submitForm && handleSubmit(_submitForm)}
                    >
                        <div className="mt-4">
                            <InputField
                                name="customer_name"
                                control={control}
                                label="Name on Card"
                                dark
                                rules={{ required: true }}
                                error={errors && errors.customer_name}
                            />
                            <InputField.Tel
                                name="card_number"
                                control={control}
                                label="Card Number"
                                dark
                                maxlength={19}
                                rules={{ required: true }}
                                error={errors && errors.card_number}
                            />
                            <div className="flex flex-row space-between">
                                <div className="w-1/2 pr-2">
                                    <InputField
                                        name="expiry"
                                        control={control}
                                        label="Expiry Date (MM/YY)"
                                        dark
                                        maxlength={5}
                                        rules={{ required: true }}
                                        error={errors && errors.expiry}
                                    />
                                </div>
                                <div className="w-1/2 pl-2">
                                    <InputField.Tel
                                        name="cvc"
                                        control={control}
                                        label="CVC"
                                        dark
                                        min={3}
                                        max={3}
                                        maxlength={3}
                                        rules={{ required: true }}
                                        error={errors && errors.cvc}
                                    />
                                </div>
                            </div>
                            <Loading show={loading} />
                        </div>
                        <div className="flex flex-row justify-end mt-32">
                            {!loading && (
                                <div className="pr-1">
                                    <Button label="Cancel" light to={location?.state?.prev} />
                                </div>
                            )}
                            <ButtonNext label="Confirm Payment" buttonType="submit" />
                        </div>
                    </form>
                </div>
            ) : payment_type === 'bank_transfer' ? (
                <div className="p-4">
                    <div className="text-sm text-gray-500 font-semibold">
                        TOTAL AMOUNT
                    </div>
                    <div className="text-lg text-pj-blue-500 font-semibold">
                        {lang.formatString(
                            lang.general.php_total,
                            checkout.total.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                            })
                        )}
                    </div>
                    <div className="text-sm text-pj-blue-500 pt-2">
                        1. Send payment to the following account.
                    </div>
                    <div className="text-sm text-pj-blue-500 pt-3 font-semibold">
                        UnionBank Checking Account
                    </div>
                    <div className="text-sm text-pj-blue-500">
                        <span className="font-semibold">Account Name:</span> WE SEARCH @ SEARCHERS AND STAFFERS CORP
                    </div>
                    <div className="text-sm text-pj-blue-500 ">
                        <span className="font-semibold">Checking Account No.:</span> 0021 7001 2807
                    </div>
                    {/* <div className="text-sm text-pj-blue-500 pt-3 font-semibold">
            PBCOM
          </div>
          <div className="text-sm text-pj-blue-500">
            <span className="font-semibold">Account Name:</span> PasaJob Inc.
          </div>
          <div className="text-sm text-pj-blue-500">
            <span className="font-semibold">Checking Account No.:</span>{' '}
            219-10-100308-8
          </div> */}
                    <div className="text-sm text-pj-blue-500 pt-3">
                        2. Email the following to info@pasajob.com:
                        <div className="ml-4 flex flex-col">
                            <span>
                                a. Proof of payment (screenshot of bank transfer/deposit slip)
                            </span>
                            <span>
                                b. transaction ID number
                            </span>
                        </div>
                    </div>
                    <div className="text-sm text-pj-blue-500 pt-3">
                        3. Once confirmed, your job post will be published within the next
                        24 hours.
                    </div>
                    <Loading show={loading} />
                    <div className="flex flex-row justify-end mt-32">
                        {!loading && (
                            <div className="pr-1">
                                <Button label="Cancel" light to={location?.state?.prev} />
                            </div>
                        )}
                        <ButtonNext
                            label="Checkout"
                            buttonType="button"
                            onClick={_checkout}
                        />
                    </div>
                </div>
            ) : (
                <div className="p-4">
                    <div className="text-sm text-gray-500 font-semibold">
                        TOTAL AMOUNT
                    </div>
                    <div className="text-lg text-pj-blue-500 font-semibold">
                        {lang.formatString(
                            lang.general.php_total,
                            checkout.total.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                            })
                        )}
                    </div>
                    <div className="text-pj-blue-500 pt-3">
                        If you have an active send bill arrangement with us, click Checkout
                        and your job will be posted within the next 24 hours.
                    </div>
                    <div className="text-pj-blue-500 pt-3">
                        For questions or concerns, please contact your Account Manager or
                        email info@pasajob.com.
                    </div>
                    <div className="text-pj-blue-500 pt-3">Thank you!</div>
                    <Loading show={loading} />
                    <div className="flex flex-row justify-end mt-32">
                        {!loading && (
                            <div className="pr-1">
                                <Button label="Cancel" light to={location?.state?.prev} />
                            </div>
                        )}
                        <ButtonNext
                            label="Checkout"
                            buttonType="button"
                            onClick={_checkout}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default StepFourTwo;
