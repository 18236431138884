import React from 'react';
import { Link } from 'react-router-dom';
import Main from '../main';
import ReactGA from 'react-ga';

const LoginTemplate = ({ children }: any) => {
  return (
    <Main>
      <div className="h-screen bg-pj-blue-500 flex flex-col sm:py-12">
        <div className="p-10 xs:p-0 mx-auto lg:w-full lg:max-w-lg md:w-full md:max-w-md">
          <section className="hero container max-w-screen-lg mx-auto pb-10 flex justify-center">
            <Link to="/">
              <img
                src="/login-logo.svg"
                className="object-contain"
                alt="PasaJob"
              />
            </Link>
          </section>
          {children}
        </div>
      </div>
    </Main>
  );
};

export default LoginTemplate;
