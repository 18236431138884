import { ManageUserContext } from '../../../utilities/context';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import CompanyTemplate from '../template';

const ManageUserView = () => {
  const { view, data, type, id } = useContext(ManageUserContext);
  return (
    <CompanyTemplate>
      <div className="w-full text-center h-full bg-white">
        <div className="p-4 pb-0 border-b">
          <ul className="flex justify-center items-center pb-0">
            <li className="w-32 px-1">
              <Link
                to={`/my-companies/${id}/manage-user/hr-team`}
                className={`cursor-pointer py-4 flex px-4 text-gray-500 border-b-4 ${type === 'hr-team'
                  ? 'border-pj-green-300 text-pj-blue-400 font-medium'
                  : 'border-white pj-blue-100'
                  } content-center uppercase text-xs`}
              >
                <div className="w-full text-center">HR TEAM</div>
              </Link>
            </li>
            <li className="w-32 px-1">
              <Link
                to={`/my-companies/${id}/manage-user/employees`}
                className={`cursor-pointer py-4 flex px-2 text-gray-500 border-b-4 ${type === 'employees'
                  ? 'border-pj-green-300 text-pj-blue-400 font-medium'
                  : 'border-white pj-blue-100'
                  } content-center uppercase text-xs pj-blue-100`}
              >
                <div className="w-full text-center">ENROLLED USERS</div>
              </Link>
            </li>
            <li className="w-32 px-1">
              <Link
                to={`/my-companies/${id}/manage-user/api-key`}
                className={`cursor-pointer py-4 flex px-4 text-gray-500 border-b-4 ${type === 'api-key'
                  ? 'border-pj-green-300 text-pj-blue-400 font-medium'
                  : 'border-white pj-blue-100'
                  } content-center uppercase text-xs pj-blue-100`}
              >
                <div className="w-full text-center">API KEY</div>
              </Link>
            </li>
          </ul>
        </div>
        <div>{view}</div>
      </div>
    </CompanyTemplate>
  );
};

export default ManageUserView;
