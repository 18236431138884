import { common } from '@material-ui/core/colors';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormHandleSubmit,
} from 'react-hook-form';
import ButtonExit from '../../../components/buttons/button-exit';
import ButtonNext from '../../../components/buttons/button-next';
import InputField from '../../../components/forms/input';
import { stagger } from '../../../framer';
import { lang } from '../../../lang';
import { CreateJobContext } from '../../../utilities/context';
import { Job, OnClickFunction } from '../../../utilities/types';

const StepTwoNineteen = () => {
  const {
    control,
    errors,
    with_applicants,
    handleSubmit,
    _submitForm,
    common_job,
    setValue,
  } = useContext(CreateJobContext);

  const [min, setMin] = useState<number>(common_job.referral_count);

  const onIncrement: OnClickFunction = (type: string) => {
    if (min === 1 && type === 'minus') {
      setMin(1);
    } else if (type === 'minus') {
      setMin(min => min - 1);
    } else {
      setMin(min => min + 1);
    }
  };

  useEffect(() => {
    setValue && setValue('referral_count', min);
  }, [min]);

  return (
    <form
      onSubmit={handleSubmit && _submitForm && handleSubmit(_submitForm)}
      className="flex flex-1 flex-col"
    >
      <div className="flex-initial h-screen/2">
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          className="w-full p-4"
        >
          <InputField.Count
            label={lang.step_two.head_count}
            name="referral_count"
            type="text"
            control={control}
            rules={{ required: true }}
            error={errors?.referral_count}
            placeholder={min.toString()}
            min={min}
            onIncrement={onIncrement}
            defaultValue={common_job.referral_count}
            disabled={with_applicants}
          />
        </motion.div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row flex-1 items-end p-4">
          <div className="h-20 w-1/2 flex justify-start items-end">
            <ButtonExit />
          </div>
          <div className="h-20 w-1/2 flex justify-end items-end">
            <ButtonNext />
          </div>
        </div>
      </div>
    </form>
  );
};

export default StepTwoNineteen;
