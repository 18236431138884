import { lang } from '../lang';
import { auth } from './auth';
import {
  BoardData,
  Checkout,
  CompanyData,
  CompanyType,
  Job,
  JobDetails,
  JobType,
  CandidateProfile,
  Skill,
  CommonJob,
  JobData,
  TransactionData,
  ManageUserData,
  CompanyAddress,
  Signup,
  DashboardData,
  ScheduleData,
  LoginData,
  ProfileData,
  FaqsData,
  EmployerCreditData,
  CheckboxesData,
  Board2Data,
} from './types';

export const CreateJobInitialState: Job = {
  steps: {
    1: false,
    2: false,
    3: false,
    4: false,
  },
  details: {
    totalSteps: 1,
    subStep: {
      heading: lang.create_job.step_one,
      step: 1,
      view: null,
    },
  },
  common_job: {
    title: null,
    description: null,
    employer_id: auth.getId(),
    job_type_id: null,
    province_id: null,
    industry_id: null,
    company_detail_id: null,
    home_based: false,
    city_id: null,
    address: null,
    status: null,
    visible: true,
    erp_owner:null,
    minimum_salary: 5000,
    maximum_salary: 20000,
    job_employment_type: null,
    job_position_level: null,
    job_experience: null,
    minimum_qualification: null,
    show_salary: true,
    skills: [],
    trait_1: null,
    trait_2: null,
    trait_3: null,
    trait_4: null,
    referral_fee: null,
    referral_count: 1,
    disbursement_type: 'full',
    blue_collar : false
  },
  step: 0,
  sub: 0,
  loading: false,
  jobId: 0,
  with_applicants: false,
  referral_fee: null,
  referral_count: null,
  _privacyHandler: () => {},
  confirmJobPostData:null,
  setConfirmJobPostData:() => {},
  _createJobPost: () => {},
};

export const CheckoutInitialState: Checkout = {
  steps: {
    1: false,
    2: false,
    3: false,
    4: false,
  },
  details: {
    totalSteps: 1,
    subStep: {
      heading: lang.create_job.step_one,
      step: 1,
      view: null,
    },
    isCheckout: false,
  },
  checkout: {
    referral_fee: 0,
    referral_count: 0,
    old_referral_fee: 0,
    old_referral_count: 0,
    processing_fee: 0,
    posting_fee: 999,
    vat: 0,
    total: 0,
    company_image: null,
    company_name: '',
    job_title: '',
    id: 0,
    email: '',
  },
  loading: false,
  prev: '',
  payment_type: 'card',
  _changePayment: () => {},
  _checkout: () => {},
};

export const CommonJobInitialState: JobDetails = {
  common_job: {
    title: null,
    description: null,
    employer_id: null,
    job_type_id: null,
    province_id: null,
    industry_id: null,
    company_detail_id: null,
    home_based: false,
    city_id: null,
    address: null,
    status: null,
    visible: true,
    minimum_salary: 1000,
    maximum_salary: 15000,
    job_employment_type: null,
    job_position_level: null,
    job_experience: null,
    minimum_qualification: null,
    show_salary: true,
    skills: [{ name: '' }],
    trait_1: null,
    trait_2: null,
    trait_3: null,
    trait_4: null,
    referral_fee: null,
    referral_count: 1,
    disbursement_type: 'full',
  },
};

export const CompanyInitialState: CompanyType = {
  company: {
    employer_id: auth.getId(),
    region_id: null,
    province_id: null,
    address: null,
    job_type_id: null,
    is_default: false,
    company_image: null,
    name: null,
    tel: null,
    website: null,
    city_id: null,
    industry_id: null,
    company_description: null,
  } as CompanyData,
  loading: false,
  errorImage: false,
};

export const JobPostInitialState: JobType = {
  job: {
    title: null,
    employer_id: auth.getId(),
    description: null,
    job_type_id: null,
    province_id: null,
    industry_id: null,
    company_detail_id: null,
    home_based: null,
    city_id: null,
    address: null,
    status: null,
    visible: null,
    trait_1: null,
    trait_2: null,
    trait_3: null,
    trait_4: null,
    minimum_salary: 0,
    maximum_salary: 0,
    job_employment_type: null,
    job_position_level: null,
    job_experience: null,
    minimum_qualification: null,
    show_salary: false,
    skills: [],
    referral_count: 0,
    referral_fee: null,
    traits: null,
    disbursement_type: 'full',
  } as CommonJob,
  jobPosts: [],
  status: '',
  id: 0,
  loading: false,
  _handleSort: () => {},
  _handleSelect: () => {},
  _handleRefresh: () => {},
  limit : 10,
  addTenToLimit : () => {},
  jobsCount : 0
};

export const BoardInitialState: BoardData = {
  view: null,
  company_id: 0,
  data: null,
  type: 'company_info',
  payload_type: 'board',
  id: null,
  jobs: [],
  title: '',
  company_name: '',
  error: false,
  error_message: '',
  _handleSort: () => {},
  _handleSelect: () => {},
  limit : 10,
  addTenToLimit : () => {},
  jobsCount : 0,
  loading: false,
  isChecked : ()=>false,
  checkApplicant: ()=>{},
  selectToCheckCard :()=>{},
  activeToCheckCard :()=>false,
  checkAllApplicant: ()=>{},
  allIsChecked : ()=>false,
  selectableIds : ()=>[],
};

export const Board2InitialState: Board2Data = {
  view: null,
  jobsCount : 0,
  _handleSelect: () => {},
  _handleSort: () => {},
  loading: false,
  _handleSearch: () => {},
  _addTenToLimit: () => {},
  selectedCompany:null,
  sort:null,
  jobs:null,
  limit:0,
  id: 0,
  jobData:null,
  viewType: null,
  job_details:null
};


export const CheckboxesState : CheckboxesData = {
  // checkedApplicants:{
  //   status: "",
  //   applicantIds: [],
  // },
  // setCheckedApplicant:()=>{},
  _onMoveMany: ()=>{},
  // company_id: 0,
  // board_data:null,
  _editInterview:()=>{},
  agingHeaderDatas:[],
  moveThroughAgingModal:()=>{}
  // industry_name:""
}

export const CandidateProfileInitialState: CandidateProfile = {
  id: 0,
  name: '',
  mobile_number: '',
  email: '',
  profile_image: null,
  job_title: null,
  linkedin_url: '',
  status: null,
  is_resigned: false,
  job_id: 0,
  show: false,
  showResigned: false,
  hire: false,
  error: false,
  error_message: '',
  invited: false,
  job_experiences: [],
  _updateStatus: () => {},
  _closeHandler: () => {},
  _submitHandler: () => {},
  _hireHandler: () => {},
  _inviteCandidate: () => {},
  _resigned: () => {},
  _resignedHandler: () => {},
  isLoading : ()=> true,
  alreadyHired:false,
  isHiredOpen:false
};

export const JobStatusInitialState: JobData = {
  view: null,
  data: null,
  type: null,
  status: null,
};

export const TransactionsInitialState: TransactionData = {
  transactionList: [],
  transactionDetails: {
    amount: 0,
    employer_id: null,
    transaction_id: null,
    updated_at: null,
    created_at: null,
    job_id: null,
    payment_type: null,
    total_vat: 0,
    total_commission: 0,
    id: null,
    job_position: null,
    logo: null,
    company_name: null,
    total_amount: 0,
  },
  _handleSort: () => {},
  _handleSelect: () => {},
  // view: null,
};
export const EmployerCreditInitialState: EmployerCreditData = {
  transactionList: [],
  transactionDetails: {
    amount: 0,
    employer_id: null,
    transaction_id: null,
    updated_at: null,
    created_at: null,
    job_id: null,
    payment_type: null,
    total_vat: 0,
    total_commission: 0,
    id: null,
    job_position: null,
    logo: null,
    company_name: null,
    total_amount: 0,
  },
  _handleSort: () => {},
  _handleSelect: () => {},
  // view: null,
};

export const ManageUserInitialState: ManageUserData = {
  view: null,
  data: [],
  type: null,
  id: 0,
};

export const CompanyAddressInitialState: CompanyAddress = {
  city_id: null,
  province_id: null,
  address: null,
  home_based: false,
};

export const SignupInitialState: Signup = {
  view: null,
  data: {
    first_name: null,
    last_name: null,
    email: null,
    password: null,
  },
};

export const DashboardInitialState: DashboardData = {
  count: {
    filled: 0,
    for_hire: 0,
    applicants: 0,
    for_interview: 0,
    openings: 0,
  },
  schedules: [],
  job_status: {
    active: 0,
    with_applicants: 0,
    shortlisted: 0,
    pending: 0,
    archived: 0,
    total_jobs: 0,
  },
  activities: [],
  _handleJobSelect: () => {},
  _handleSchedule: () => {},
};

export const ScheduleInitialState: ScheduleData = {
  schedules: [],
  show: false,
  isEdit: false,
  _dateChange: () => {},
  _editHandler: () => {},
  _closeHandler: () => {},
  _showInfo: () => {},
  _submitHandler: () => {},
};

export const LoginInitialState: LoginData = {
  show: false,
  _submitHandler: () => {},
  _googleSuccess: () => {},
  _googleFailure: () => {},
  _facebookHandler: () => {},
  _linkedinSuccess: () => {},
  _linkedinFailure: () => {},
};

export const FaqsInitialState: FaqsData = {
  view: null,
  show: false,
};

export const SettingsState: ProfileData = {
  view: null,
  loading: false,
};

export const _getStatus: object = {
  Rejected: 'Not Qualified',
  Applied: 'Applied',
  Shortlisted: 'Pre-Screened',
  'For Interview': 'For Interview',
  'For Hire': 'Shortlisted',
  Hired: 'Hired',
};
