import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../components/buttons/button';
import { abbreviate } from '../../../helpers';
import { lang } from '../../../lang';
import { CheckoutContext } from '../../../utilities/context';
import moment from 'moment';
import { getLatestTransaction } from '../../../utilities/api_v2/checkout';

const StepFourThree = () => {
  const { checkout, currentTransactionId } = useContext(CheckoutContext);
  const location = useHistory();
  const [transaction, setTransaction] = useState<any>(null);

  const _getLatestTransaction = async ({ job_id }: { job_id: number }) => {
    await getLatestTransaction({ job_id }).then((data: any) => {
      setTransaction(data.data[0]);
    })
  }

  useEffect(() => {
    if (checkout.id) {
      _getLatestTransaction({
        job_id: checkout.id
      })
    }
  }, [checkout.id]);

  useEffect(() => {
    console.log(transaction)
  })

  const table: { title: string; value: string }[] = [
    {
      title: lang.step_four.referral_fee,
      value: lang.formatString(
        lang.step_four.amount,
        checkout.referral_fee.toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })
      ) as string,
    },
    {
      title: lang.step_four.number_of_individual,
      value: checkout.referral_count.toString(),
    },
    {
      title: lang.step_four.posting_fee,
      value: lang.formatString(lang.step_four.amount, 0) as string,
    },
    {
      title: lang.step_four.processing_fee,
      value: lang.formatString(
        lang.step_four.amount,
        checkout.processing_fee.toLocaleString(undefined, {
          minimumFractionDigits: 2,
        })
      ) as string,
    },
    {
      title: lang.step_four.vat,
      value: lang.formatString(
        lang.step_four.amount,
        checkout.vat.toLocaleString(undefined, { minimumFractionDigits: 2 })
      ) as string,
    },
  ];

  return (
    <div className="px-4">
      <div className="text-center text-2xl text-pj-blue-400 mb-10">
        {lang.step_four.payment_complete}
      </div>
      <div className="flex flex-row space-between">
        <div className="w-1/2 text-lg text-pj-green-300">
          {lang.step_four.new_job}
        </div>
        <div className="w-1/2 text-right text-lg text-pj-blue-400">
          {lang.formatString(
            lang.step_four.amount,
            checkout.total.toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })
          )}
        </div>
      </div>
      <div className="w-full rounded-lg border-2 border-gray-200 p-4 mt-4">
        <div
          className={`w-32 h-16 bg-cover bg-left flex justify-center items-center text-gray-500 ${(!checkout.company_image || checkout.company_image.length <= 0) &&
            'bg-gray-200 text-2xl tracking-wide'
            }`}
          style={{ backgroundImage: `url(${checkout.company_image})` }}
        >
          {(!checkout.company_image || checkout.company_image.length <= 0) &&
            abbreviate(checkout.company_name ?? '')}
        </div>
        <div className="text-lg text-pj-blue-400 mt-2">{`${checkout.job_title} • ${checkout.company_name}`}</div>
      </div>
      <div className="py-2 mt-4">
        <div className="flex justify-between pb-2">
          <div className="text-sm pj-blue-100 text-pj-blue-100">
            Transaction ID
          </div>
          <div className="text-sm pj-blue-100 text-pj-blue-100">
            {transaction?.transaction_id}
          </div>
        </div>
        <div className="flex justify-between">
          <div className="text-sm pj-blue-100 text-pj-blue-100">
            Date of Transaction
          </div>
          <div className="text-sm pj-blue-100 text-pj-blue-100">
            {transaction?.created_at
              ? moment(transaction?.created_at).format('MMMM Do YYYY, h:mm a')
              : '---'}
          </div>
        </div>
      </div>
      <div className="text-center text-lg text-pj-blue-100 mt-16 mb-10 px-20">
        {lang.formatString(lang.step_four.activated, checkout.email)}
      </div>
      <div className="flex flex-row content-center justify-center mt-32">
        <div className="mx-1">
          <Button
            to={{
              pathname: `/transactions/${currentTransactionId}`,
              state: { prev: location.location.pathname },
            }}
            label={lang.step_four.view_transaction}
            light
          />
        </div>
        <div className="mx-1">
          <Button
            to={{
              pathname: `/job-posts/pending`,
              state: { prev: location.location.pathname },
            }}
            label={lang.step_four.start_hiring}
          />
        </div>
      </div>
    </div>
  );
};

export default StepFourThree;
