import { useEffect } from 'react';
import { useState } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import { motion } from 'framer-motion';
import { OnClickFunction } from '../utilities/types';
import { IoMdClose } from 'react-icons/io';

type Props = {
  children?: React.ReactNode;
  show?: boolean;
  onClose?: OnClickFunction | undefined;
};

const SearchModal = ({ children, show, onClose }: Props) => {
  const [visible, setVisible] = useState<boolean>(false);

  useEffect(() => {
    if (show !== undefined && show !== visible) {
      setVisible(show);
    }
  }, [show]);

  return (
    <motion.div
      className={`bg-pj-blue-500 shadow main-modal fixed w-full inset-0 z-50 overflow-hidden flex flex-col items-center animated fadeIn faster bg-opacity-80 ${
        !visible && 'hidden'
      }`}
    >
      <div className="w-full h-10 flex justify-end items-center p-4">
        <IoMdClose
          className="text-lg text-white cursor-pointer"
          onClick={() => {
            setVisible(visible => !visible);
            onClose && onClose(false);
          }}
        />
      </div>
      <div className="flex flex-auto justify-center items-center w-full">
        <div className="w-3/6 h-10">{children}</div>
      </div>
    </motion.div>
  );
};

export default SearchModal;
