import { employer_api_v2 } from "./main";


export const viewCompany = async (data: any) => {
  let response = {};

  await employer_api_v2
    .post('dashboard/viewCompany', data)
    .then(res => {
      console.log(res, 'res');
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response;
};

export const getEmployeeName = async (data: any) => {
    let response = {};
  
    await employer_api_v2
      .post('dashboard/employeeName', data)
      .then(res => {
        console.log(res, 'res');
        response = res?.data;
      })
      .catch(err => {
        response = err?.response?.data;
      });
  
    return response;
  };

export const getNotificationCount = async (data: any) => {
  let response = {};

  await employer_api_v2
    .post('dashboard/getNotificationCount', data)
    .then(res => {
      console.log(res, 'res');
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response;
};

export const getCounts = async (data: any) => {
    let response = {};
  
    await employer_api_v2
      .post('dashboard/getCounts', data)
      .then(res => {
        console.log(res, 'res');
        response = res?.data;
      })
      .catch(err => {
        response = err?.response?.data;
      });
  
    return response;
  };

export const dashboardActivity = async (data: any) => {
  let response = {};

  await employer_api_v2
    .post('dashboard/dashboardActivity', data)
    .then(res => {
      console.log(res, 'res');
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response;
};

export const companySchedules = async (data: any) => {
    let response = {};
  
    await employer_api_v2
      .post('dashboard/companySchedule', data)
      .then(res => {
        console.log(res, 'res');
        response = res?.data;
      })
      .catch(err => {
        response = err?.response?.data;
      });
  
    return response;
  };

  export const dashboardJobPostsCount = async (data: any) => {
      let response = {};
    
      await employer_api_v2
        .post('dashboard/dashboardJobPostsCount', data)
        .then(res => {
          console.log(res, 'res');
          response = res?.data;
        })
        .catch(err => {
          response = err?.response?.data;
        });
    
      return response;
    };