import LocalizedStrings, { LocalizedStringsMethods } from 'react-localization';

type GeneralText = {
  email: string;
  password: string;
  confirm_password: string;
  continue: string;
  facebook: string;
  google: string;
  linkedin: string;
  first_name: string;
  last_name: string;
  php: string;
  php_total: string;
};

type SignupText = {
  header: string;
  forgot_password: string;
  continue_with: string;
  create_acount: string;
  no_account: string;
};

type ForgotPassworText = {
  header: string;
};

type CreateAccountText = {
  header: string;
  agreement: string;
  privacy_policy: string;
  login_now: string;
  have_an_account: string;
};

type OTPText = {
  header: string;
  otp_code: string;
  placeholder: string;
};

type MenuText = {
  dashboard: string;
  job_post: string;
  applicant_board: string;
  my_companies: string;
  scheduler: string;
  messages: string;
  transaction: string;
};

type StepsText = {
  company: string;
  job_details: string;
  review: string;
  payment: string;
};

type CreateJobText = {
  step_one: string;
  step_two_zero: string;
  step_two_one: string;
  step_two_two: string;
  step_two_three: string;
  step_two_four: string;
  step_two_five: string;
  step_two_six: string;
  step_two_seven: string;
  step_two_eight: string;
  step_two_nine: string;
  step_two_ten: string;
  step_two_eleven: string;
  step_two_twelve: string;
  step_two_thirteen: string;
  step_two_ninteen: string;
  step_two_twenty: string;
  step_two_twenty_one: string;
  step_four_one: string;
  step_four_two: string;
  step_four_three: string;
  choose_payment_method: string;
};

type StepTwoText = {
  job_title: string;
  job_title_placeholder: string;
  select_industry: string;
  select_job_category: string;
  select_level: string;
  select_employment_type: string;
  enter_a_skill: string;
  show_salary: string;
  enter_amount: string;
  select_work_experience: string;
  add_another_location: string;
  referral_placeholder: string;
  home_base: string;
  php: string;
  add_skill: string;
  select_qualification: string;
  job_description: string;
  describe_job_placeholder: string;
  quality: string;
  head_count: string;
};

type StepThreeText = {
  title: string;
  edit: string;
  industry: string;
  location: string;
  job_title: string;
  job_category: string;
  position_level: string;
  employment_type: string;
  skill: string;
  salary: string;
  referral_fee: string;
  experience: string;
  minimum_qualification: string;
  description: string;
  qualities: string;
  job_posted: string;
  active: string;
  go_to_job: string;
  proceed_checkout: string;
  head_count: string;
  privacy: string;
  disbursement: string;
};

type StepFourText = {
  new_job: string;
  amount: string;
  sub_total: string;
  ewt: string;
  processing_fee: string;
  total_amount: string;
  continue_to_payment: string;
  name_on_card: string;
  secure_card_payment: string;
  card_number: string;
  expiry_date: string;
  cvc: string;
  cancel: string;
  confirm_payment: string;
  payment_complete: string;
  activated: string;
  view_transaction: string;
  start_hiring: string;
  referral_fee: string;
  number_of_individual: string;
  additional_headcount: string;
  total_number_of_individual:string;
  posting_fee: string;
  vat: string;
};

type CompanyText = {
  company_name: string;
  total_jobs: string;
};

export interface IStrings extends LocalizedStringsMethods {
  general: GeneralText;
  signup: SignupText;
  forgot_password: ForgotPassworText;
  create_acount: CreateAccountText;
  otp: OTPText;
  menu: MenuText;
  steps: StepsText;
  create_job: CreateJobText;
  company: CompanyText;
  step_two: StepTwoText;
  step_three: StepThreeText;
  step_four: StepFourText;
}

let lang: IStrings;
lang = new LocalizedStrings({
  en: {
    general: {
      email: 'Email',
      password: 'Password',
      confirm_password: 'Confirm Password',
      continue: 'Continue',
      facebook: 'Facebook',
      google: 'Google',
      linkedin: 'LinkedIn',
      first_name: 'First Name',
      last_name: 'Last Name',
      php: 'Php {0}',
      php_total: 'PHP {0}',
    },
    signup: {
      header: 'Log in to your account',
      forgot_password: 'Forgot Password',
      continue_with: 'or continue with',
      create_acount: 'Create an account',
      no_account: "Don't have an account?",
    },
    forgot_password: {
      header: 'Reset your password',
    },
    create_acount: {
      header: 'Create your PasaJob account',
      have_an_account: 'Already have an account?',
      login_now: 'Log in now',
      privacy_policy: 'Term of Use and Privacy Policy',
      agreement: "I agree to PasaJobs's ",
    },
    otp: {
      header: 'One time password',
      otp_code: 'OTP Code',
      placeholder: 'OTP',
    },
    menu: {
      dashboard: 'Dashboard',
      job_post: 'Job Posts',
      applicant_board: 'Applicant Board',
      my_companies: 'My Companies',
      scheduler: 'Calendar',
      messages: 'Messages',
      transaction: 'Transactions',
    },
    steps: {
      company: 'Company',
      job_details: 'Job Details',
      review: 'Review',
      payment: 'Payment',
    },
    create_job: {
      step_one: 'Select the company you want to post a job for.',
      step_two_zero: 'What type of job are you posting?',
      step_two_one: 'What are you looking for?',
      step_two_two: 'What industry does the job fall under?',
      step_two_three: 'What category does your job post fall under?',
      step_two_four: 'What position level?',
      step_two_five: 'Select a work location.',
      step_two_six: 'What employment type is required for this post?',
      step_two_seven: 'What skills are you looking for?',
      step_two_eight: 'How much salary are you offering for this job?',
      step_two_nine: 'How much is the referral fee for this job?',
      step_two_ten: 'What level of experience does your job require?',
      step_two_eleven: "What is your job's minimum qualifications?",
      step_two_twelve: 'Tell applicants more about your job post.',
      step_two_thirteen: 'What are the qualities ideal for the role?',
      step_two_ninteen:
        'How many individuals would you like to hire for this role?',
      step_two_twenty: 'What is the privacy of this job post?',
      step_two_twenty_one:
        'What is your preferred referral fee disbursement schedule for this job post?',
      step_four_one: 'Checkout',
      step_four_two: 'Secure Card Payment',
      step_four_three: 'Payment Complete!',
      choose_payment_method: 'Choose your payment method.',
    },
    company: {
      company_name: '{0}',
      total_jobs: '{0} Job Posted',
    },
    step_two: {
      job_title: 'Job Title',
      job_title_placeholder: 'e.g. Admin Assistant, Copywriter',
      select_industry: 'Select an industry',
      select_job_category: 'Select job category',
      select_level: 'Select level',
      add_another_location: 'Add another location',
      select_employment_type: 'Select employment type',
      enter_a_skill: 'Enter a skill',
      show_salary: 'Show salary',
      enter_amount: 'Enter amount',
      select_work_experience: 'Select work experience',
      referral_placeholder: 'e.g. Php ',
      home_base: 'Home-based',
      php: 'Php {0}',
      add_skill: 'Add a skill',
      select_qualification: 'Select qualification',
      job_description: 'Job description',
      describe_job_placeholder: 'Describe your job further',
      quality: 'Select a quality',
      head_count: 'Head count',
    },
    step_three: {
      title: 'You are posting a job for:',
      industry: 'Industry',
      location: 'Work Location',
      job_title: 'Job Title',
      job_category: 'Job Category',
      position_level: 'Position Level',
      employment_type: 'Employment Type',
      skill: 'Skills',
      salary: 'Salary',
      experience: 'Work Experience',
      minimum_qualification: 'Minimum Qualification',
      description: 'Job Description',
      qualities: 'Ideal Qualities',
      edit: 'Edit',
      referral_fee: 'Referral Fee',
      job_posted: 'Almost there!',
      active:
        'To activate your job, kindly proceed to checkout for processing.',
      go_to_job: 'Go to Job',
      proceed_checkout: 'Proceed to Checkout',
      head_count: 'Head Count',
      privacy: 'Job Post Privacy',
      disbursement: 'Referral Fee Disbursement type',
    },
    step_four: {
      new_job: 'New Job',
      amount: 'PHP {0}',
      sub_total: 'Subtotal',
      ewt: 'EWT',
      processing_fee: 'Processing Fee (15%)',
      total_amount: 'TOTAL AMOUNT',
      continue_to_payment: 'Continue to Payment',
      name_on_card: 'Name on Card',
      secure_card_payment: 'Secure Card Payment',
      card_number: 'Card Number',
      expiry_date: 'Expiry date (MM/YY)',
      cvc: 'CVC',
      cancel: 'Cancel',
      confirm_payment: 'Confirm Payment',
      payment_complete: 'Payment Complete!',
      activated:
        'For credit card transactions, your job post has  already been published. For bank transfer and send  bill options, your job post will be published soon.',
      view_transaction: 'View Transaction',
      start_hiring: 'Start Hiring',
      referral_fee: 'Referral Fee',
      number_of_individual: 'Number of individuals needed',
      total_number_of_individual: 'Total Number of Individuals Needed',
      additional_headcount: 'Additional Headcount',
      posting_fee: 'Posting Fee',
      vat: 'VAT (12%)',
    },
  },
});

export { lang };
