import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import ButtonExit from '../../../components/buttons/button-exit';
import ButtonNext from '../../../components/buttons/button-next';
import InputField from '../../../components/forms/input';
import Loading from '../../../components/loader';
import { stagger } from '../../../framer';
import { lang } from '../../../lang';
import { CreateJobContext } from '../../../utilities/context';
import { OnClickFunction } from '../../../utilities/types';
import { getCategories } from '../../../utilities/api_v2/job_create';

type Category = {
  id: number;
  name: string;
};

const StepTwoThree = () => {
  const { control, handleSubmit, errors, _submitForm, setValue, common_job } =
    useContext(CreateJobContext);

  const [categories, setCategories] = useState<Category[]>([]);
  const [select, setSelect] = useState<number | null>();
  const [loading, setLoading] = useState(false)

  const _getCategories = async () => {
    setLoading(true)
    if(common_job.industry_id){
    return await getCategories({
      id: common_job.industry_id,
    }).then((cat)=>{
      let items: Category[] = cat.data?.map(
        ({ id, job_type_name }) => ({ id, name: job_type_name })
      ) || [];
      const others = [...items].filter(({ name }) => name === 'Others');
      items = [...items].filter(({ name }) => name !== 'Others');
      items = [...items, ...others];
      setCategories(items);
    }).finally(()=>{
      setLoading(false)
    })
    } else {
      setLoading(false)
      return []
    }
  }

  const _onClick: OnClickFunction = <T extends Category>(val: T) => {
    setSelect(val.id);
    setValue && setValue('job_type_id', val.id);
    localStorage.setItem('category', val.name);
  };

  useEffect(()=>{
    _getCategories()
  },[common_job.industry_id])

  useEffect(() => {
    setSelect(common_job.job_type_id);
  }, [common_job.job_type_id]);

  return (
    <form
      className="flex flex-col flex-1"
      onSubmit={handleSubmit && _submitForm && handleSubmit(_submitForm)}
    >
      <InputField
        name="job_type_id"
        label=""
        control={control}
        error={errors?.job_type_id}
        type="hidden"
        defaultValue={select}
        rules={{ required: true }}
      />
      <div className="flex flex-initial p-4 border-b">
        <p className="p-0 m-0 text-base text-pj-blue-100">
          {lang.step_two.select_job_category}
        </p>
      </div>
      <div
        className="flex-initial overflow-auto h-screen/2"
        id="journal-scroll"
      >
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          className="w-full overflow-x-hidden overflow-y"
        >
          <div className="flex flex-col flex-1 overflow-x-hidden">
            <div className="overflow-y">
              {categories.map(({ id, name }, index) => {
                return (
                  <div
                    className={`p-4 py-6 text-pj-blue-300 text-lg flex flex-initial cursor-pointer ${
                      select === id && 'bg-pj-green-100'
                    }`}
                    onClick={() => _onClick({ id, name })}
                    key={`${index}`}
                  >
                    {name}
                  </div>
                );
              })}
            </div>
          </div>
        </motion.div>
        <Loading show={loading} />
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row items-end flex-1 p-4">
          <div className="flex items-end justify-start w-1/2 h-20">
            <ButtonExit />
          </div>
          <div className="flex items-end justify-end w-1/2 h-20">
            <ButtonNext />
          </div>
        </div>
      </div>
    </form>
  );
};

export default StepTwoThree;
