import { motion } from 'framer-motion';
import { useState, forwardRef } from 'react';
import {
  Control,
  Controller,
  FieldError,
  FieldName,
  RegisterOptions,
} from 'react-hook-form';
import { slideIn } from '../../framer';
import { OnClickFunction } from '../../utilities/types';
import DatePicker from 'react-datepicker';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import moment from 'moment';
import { BiCalendarEvent } from 'react-icons/bi';
import { FiClock } from 'react-icons/fi';
interface TInputProps {
  rules?: Exclude<
    RegisterOptions,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  defaultValue?: any;
  name: FieldName<String>;
  error?: FieldError;
  control?: Control;
}

interface Input extends TInputProps {
  label: string;
  type?: string;
  toggle?: boolean;
  max?: string | number | undefined;
  min?: string | number | undefined;
  maxlength?: number | undefined;
  placeholder?: string;
  rows?: number;
  dark?: boolean;
  onIncrement?: OnClickFunction;
  pattern?: string;
  children?: React.ReactNode;
}

const Checkbox = ({
  label,
  type,
  rules,
  defaultValue = '',
  name,
  error,
  control,
  children,
}: Input) => {
  const [show, setShow] = useState<boolean>(false);

  const _onToggle = () => {
    setShow(!show);
  };
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field: { onChange, value, name, ref } }) => (
        <div
          className={`flex justify-start -mx-3 pj-focus ${
            type === 'hidden' && 'hidden'
          }`}
        >
          <div className="w-full px-3 mb-5">
            <label className="flex justify-center items-start pb-1">
              <div className="bg-white border-2 rounded border-gray-400 w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
                <input
                  type="checkbox"
                  className="opacity-0 absolute focus:border-none focus:border-green-200"
                  onChange={onChange}
                  checked={value}
                />
                <svg
                  className="fill-current hidden w-4 h-4 pj-green-300 pointer-events-none"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
              </div>
              <div className="select-none text-sm text-pj-blue-100">
                {children ? children : label}
              </div>
            </label>
            {error && (
              <motion.div
                variants={slideIn}
                initial="initial"
                animate="animate"
                className="text-xs text-red-400 pt-1 block text-left"
              >
                {error.message}
              </motion.div>
            )}
          </div>
        </div>
      )}
    />
  );
};

export default Checkbox;
