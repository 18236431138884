type Props = {
  label: string;
  value: number;
  className?: string;
};

const Status = ({ label, value, className }: Props) => {
  return (
    <div
      className={`flex justify-between text-sm text-pj-blue-500 font-semibold mb-4 ${className}`}
    >
      <div>{label}</div>
      <div>{value}</div>
    </div>
  );
};

export default Status;
