import { RiArrowRightSLine } from 'react-icons/ri';
import { abbreviate } from '../helpers';
import { lang } from '../lang';
import { Company as CompanyType, OnClickFunction } from '../utilities/types';

interface Props extends CompanyType {
  active: boolean;
  onClick?: OnClickFunction;
}

const Company = (props: Props) => {
  return (
    <div
      className={`flex flex-initial w-full h-32 flex-row p-4 py-1 rounded-lg border border-gray-200 justify-center items-center mb-4 cursor-pointer ${
        props.active && 'bg-gray-200'
      }`}
      onClick={() => props.onClick && props.onClick(props)}
    >
      <div className="w-20 h-20 flex justify-center items-center">
        <div
          className={`w-20 h-20 rounded-full border-2 border-gray-300 bg-cover bg-center flex justify-center items-center text-gray-500 ${
            (!props.logo || props.logo.length <= 0) &&
            'bg-gray-200 text-2xl tracking-wide'
          }`}
          style={{ backgroundImage: `url(${props.logo})` }}
        >
          {(!props.logo || props.logo.length <= 0) && abbreviate(props.name)}
        </div>
      </div>
      <div className="flex-auto flex-col flex justify-center pl-5">
        <div className="flex-auto p-1 text-pj-blue-500 font-semibold text-lg">
          {lang.formatString(lang.company.company_name, props.name)}
        </div>
        <div className="flex-auto p-1 text-pj-blue-100 text-base">
          {lang.formatString(lang.company.total_jobs, props.total_jobs)}
        </div>
      </div>
      <div className="w-5 h-5 flex content-start items-center">
        <RiArrowRightSLine className="text-3xl text-pj-blue-100" />
      </div>
    </div>
  );
};

export default Company;
