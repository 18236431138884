import { AiOutlineArrowLeft, AiOutlineQuestionCircle } from 'react-icons/ai';
import Match from '../../components/match';
import Status from './components/status';
import ContactInfo from './components/contact-info';
import Profile from './components/profile';
import Referrer from './components/referrer';
import { CandidateProfile } from '../../utilities/types';
import { Link } from 'react-router-dom';
import DownloadPDF from '../../components/download-pdf';
import Avatar from '../../components/avatar';
import { useHistory } from 'react-router-dom';
import MoveButton from '../../components/move-button';
import Modal from '../../components/modal';
import Interview from '../../components/schedule/interview';
import ButtonNext from '../../components/buttons/button-next';
import MatchProfile from './components/match-profile';
import Attachments from './components/attachments';
import { CircularProgress } from '@material-ui/core';
import { Tooltip } from 'antd';
import AgingProgress from './components/aging-progress';
import { isRecruiters } from '../../utilities/helper';
import CVScrapper from './components/CVScrapper';

const CandidateProfileView = (props: CandidateProfile) => {
  const { isLoading, application_id } = props
  const urlParams = new URLSearchParams(window.location.search);
  const is_from_match_profile = urlParams.get('match') == 'true' ? true : false;

  const history = useHistory();

  if (isLoading()) {
    return <div className="flex items-center justify-center h-full pl-8 bg-pj-green-100">
      <div className='flex items-center justify-center w-full h-full bg-gray-50'>
        <CircularProgress style={{ color: "#afdfd8" }} size={50} />
      </div>
    </div>
  }
  
  const showAgingProgress = ["Shortlisted", "For Interview", "For Hire", "Applied"].includes(props.status)
  
  return (
    <div className="grid min-h-screen grid-cols-3 gap-3 bg-pj-green-100">
      {props.contextHolder}
      <div className="flex flex-col w-full pl-8 border-l-2 border-r-2 flex-between">
        <div className="w-full h-full bg-white">
          <div className="relative w-full p-4 border-b">
            <AiOutlineArrowLeft
              className="text-xl cursor-pointer text-pj-blue-500"
              onClick={() => history.goBack()}
            />
            <div className='flex flex-row items-center w-full gap-2 place-content-end'>
            {showAgingProgress && <AgingProgress remainingDays={props.agingDays} />}
            <Status
            status={
              props.status
                ? props.status
                : props.invited
                  ? 'Invited'
                  : 'Not Yet Invited'
            }
          />
            </div>
            <div className="absolute" style={{ top: 60 }}>
              <Avatar name={props.name} url={props.profile_image} size={100} />
            </div>
          </div>
          <div className="px-4 pt-20 pb-4 border-b">
            <div className="text-lg font-semibold text-pj-blue-500">
              {props.name}
            </div>
            <div className="text-base text-pj-blue-100">{props.job_title}</div>
            <Match seeker_id={props.id} job_id={props.job_id} />
            <Referrer
              referrer={props.referrer}
              id={props.referrer_id ?? 0}
              job_id={props.job_id}
            />
          </div>
          <div className="flex flex-row items-center justify-center w-full p-4 border-b">
            <div className='w-full'>
              <ContactInfo type="number" info={props.mobile_number} />
              <ContactInfo type="email" info={props.email} />
              {/* <ContactInfo type="linkedin" info={props.linkedin_url} /> */}
              { isRecruiters() &&
                <div className="flex flex-row w-full mt-4">
                  <DownloadPDF
                    renderer={
                      <div className={`${(props.status==="For Interview" || props.status === "For Hire")? " bg-pj-green-200 text-white":"text-gray-400 bg-gray-200 border-gray-200 "}  rounded-full border font-semibold mr-2 p-2 px-4`}>
                        Convert Profile to PDF
                      </div>
                    }
                    disabled={(props.status==="For Interview" || props.status === "For Hire")}
                    name={props.name}
                    education={props.education ?? []}
                    email={props.email}
                    mobile_number={props.mobile_number}
                    experience={
                      props.experience
                        ? props.experience
                        : {
                          experience_level: '',
                          since_year: null,
                          is_since_year: null,
                        }
                    }
                    job_experiences={props.job_experiences ?? []}
                    job_title={props.job_title ?? ''}
                    position=""
                    skills={props.skills ?? []}
                  />
                  {/* {props.status && (
                    <Link
                      to={`/messages/${props.id}/${props.job_id}`}
                      className="w-full p-2 font-semibold text-center text-gray-400 bg-gray-200 border border-gray-200 rounded-full"
                    >
                      Chat
                    </Link>
                  )} */}
                </div>
              }
            </div>
            <div className="">
              {props.status && ['Rejected', 'Applied'].includes(props.status) && (
                <Tooltip
                  arrow={false}
                  color={"#1c4464"}
                  title={<>
                    <div className="mb-2 text-sm font-semibold">
                      See Contact Details
                    </div>
                    <div>
                      Move applicant cards to Pre-screened to view their contact
                      details.
                    </div>
                  </>}
                  key={"question"}
                  placement="bottomRight">
                  <AiOutlineQuestionCircle className="text-gray-400 hover:text-pj-blue-500" size={16} />
                </Tooltip>
              )}
            </div>
          </div>
          {!['Rejected', 'Applied'].includes(props.status) &&
            (
              <div>
                <div className="p-8 py-4 text-gray-400 border-b bg-gray-50">
                  CV ATTACHMENT
                </div>
                {!is_from_match_profile &&
                  <div className="pl-2 pr-5 ml-6 ">
                    {/* {props.uploaded_resume &&
                      <div className="mt-3">
                        <a href={convertStringToHttps(props?.uploaded_resume)} target="_blank" download className="text-sm text-blue-500 underline " >
                          Attachment
                        </a>
                      </div>
                    } */}
                    {/* {!props.uploaded_resume && ( */}
                    {
                      !!props.cv_scraped ? <CVScrapper name={props.name} cja_id={application_id} status={props.status}/> : <Attachments userData={props} />
                    }
                  </div>
                }
              </div>
            )
          }
        </div>
        <div className="p-4 bg-white">
          <MoveButton
            status={props.status}
            onClick={props._updateStatus}
            onInvite={props._inviteCandidate}
            invite={props.invited}
            onResigned={props._resigned}
            isResigned={props.is_resigned}
          />
        </div>
      </div>
      <div className="relative h-full col-span-2 bg-white">
        <div style={{ height: 'calc(100vh - 3rem)', overflow: 'initial' }}>
          {/* {(is_from_match_profile || props.status === 'Applied') &&
            <MatchProfile {...props} />
          }
          {(!is_from_match_profile || props.status != 'Applied') &&(
            <Profile {...props} />
          )} */}
          {
            (is_from_match_profile || ['Applied', 'Rejected'].includes(props.status)) ? <MatchProfile {...props} /> : <Profile {...props} />
          }
        </div>
      </div>
      <Modal
        title="Schedule an interview"
        closable
        onClose={props._closeHandler}
        show={props.show}
      >
        <Interview
          info={{
            name: props.name,
            profile_image: props.profile_image,
            company_name: props.common_job?.company?.name ?? '',
            company_image: props.common_job?.company?.company_image ?? null,
            job_title: props.job_title ?? '',
          }}
          control={props.control}
          handleSubmit={props.handleSubmit}
          _submitHandler={props._submitHandler}
          _onClose={props._closeHandler}
          errors={props.errors}
        />
      </Modal>
      <Modal
        title="Already Hired"
        closable
        onClose={props._closeHandler}
        show={props.isHiredOpen}
      >
        <div className='p-4 text-sm '>
        This candidate has already been hired by another company.
        </div>
      </Modal>
      <Modal title="Candidate Info Acknowledgment" closable onClose={() => props.setAppliedModal(false)} show={props.appliedModal}>
        <div className="p-2 text-left text-pj-blue-500">
          By moving the candidate's card, you acknowledge you acquired this applicant's information via PasaJob. Once candidate details have been shared, you cannot move the candidate card back to the Applied bin.
        </div>
        <div className="flex flex-row px-10 space-around">
          <div className="w-full p-2">
            <ButtonNext label="CANCEL" onClick={() => props.setAppliedModal(false)} full light />
          </div>
          <div className="w-full p-2">
            <ButtonNext label="CONFIRM" onClick={() => props._confirmHandler('Shortlisted')} full />
          </div>
        </div>
      </Modal>
      <Modal
        title="Error"
        closable
        onClose={props._closeHandler}
        show={props.error}
        width={400}
      >
        <div className="p-2 py-5 text-pj-blue-500">{props.error_message}</div>
        <div className="flex flex-row justify-center">
          <ButtonNext label="OK" onClick={props._closeHandler} />
        </div>
      </Modal>
      <Modal
        title="Hire"
        closable
        onClose={props._closeHandler}
        show={props.hire}
      >
        <div className="p-2 text-pj-blue-500">
          Once you move the candidate card to Hired, referral fees will be
          released based on your disbursement preference. Proceed?
        </div>
        <div className="flex flex-row px-10 space-around">
          <div className="w-full p-2">
            <ButtonNext
              label="CANCEL"
              onClick={props._closeHandler}
              full
              light
            />
          </div>
          <div className="w-full p-2">
            <ButtonNext label="OK" onClick={props._hireHandler} full />
          </div>
        </div>
      </Modal>
      <Modal
        title="Resigned"
        closable
        onClose={props._closeHandler}
        show={props.showResigned}
      >
        <div className="p-2 text-pj-blue-500">
          Once you mark the candidate as resigned, referral fees will be removed
          from their wallet. Proceed?
        </div>
        <div className="flex flex-row px-10 space-around">
          <div className="w-full p-2">
            <ButtonNext
              label="CANCEL"
              onClick={props._closeHandler}
              full
              light
            />
          </div>
          <div className="w-full p-2">
            <ButtonNext label="OK" onClick={props._resignedHandler} full />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CandidateProfileView;
