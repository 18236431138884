import moment from 'moment';
import { useRef, useState } from 'react';
import { lang } from '../../../lang';
import { CandidateProfile } from '../../../utilities/types';
import Attachments from './attachments';
import { industryHelper, specializationHelper } from '../../../utilities/helper';
const MatchProfile = (props: CandidateProfile) => {
    const aboutRef: any = useRef(null);
    const containerRef: any = useRef(null);
    return (
        <div className="w-full max-h-screen">
            <div className="flex justify-center p-5 font-semibold border-b text-pj-blue-500">
                PROFILE
            </div>
            <div
                style={{ height: 'calc(100vh - 7rem)', overflow: 'auto' }}
                id="journal-scroll"
                ref={containerRef}
                className='flex flex-col '
            >
                <div className="h-auto">
                    <div
                        className="p-8 py-4 text-gray-400 border-b bg-gray-50"
                        ref={aboutRef}
                    >
                        ABOUT
                    </div>
                    <div className="px-8 py-4 text-gray-400 border-b">
                        <div className="text-base font-semibold capitalize text-pj-blue-500">
                            current job title
                        </div>
                        <div className="text-sm text-pj-blue-100">
                            {props.job_title ?? 'No data Available'}
                        </div>
                    </div>
                    <div className="px-8 py-4 text-gray-400 border-b">
                        <div className="text-base font-semibold capitalize text-pj-blue-500">
                            position level
                        </div>
                        <div className="text-sm text-pj-blue-100">
                            {props?.position ?? 'No data Available'}
                        </div>
                    </div>
                    <div className="px-8 py-4 text-gray-400 border-b">
                        <div className="text-base font-semibold capitalize text-pj-blue-500">
                            experience level
                        </div>
                        <div className="text-sm text-pj-blue-100">
                            {props?.experience?.since_year ? `${props?.experience?.experience_level} ${ props?.experience?.experience_level === 'I have been working since' ? moment(props?.experience?.since_year).format('MMMM YYYY') : ''}`: 'No data available'}
                        </div>
                    </div>
                    <div className="px-8 py-4 text-gray-400 border-b">
                        <div className="text-base font-semibold capitalize text-pj-blue-500">
                            industry
                        </div>
                        <div className="text-sm text-pj-blue-100">
                            {industryHelper(props)}
                            {/* {props?.job_experiences.length ? (props?.job_experiences?.[0]?.common_industries?.industry_name ?? props?.profile_industry ) : 'No data available'} */}
                        </div>
                    </div>
                    <div className="px-8 py-4 text-gray-400 border-b">
                        <div className="text-base font-semibold capitalize text-pj-blue-500">
                            specialization
                        </div>
                        <div className="text-sm text-pj-blue-100">
                            {specializationHelper(props)}
                            {/* {props?.job_experiences.length ? props?.job_experiences?.[0]?.specialization : 'No data available'} */}
                        </div>
                    </div>
                </div>
                <div className='flex-1 w-full max-h-screen py-5 bg-gray-50'>
                    <div className='flex justify-center h-32 text-sm text-pj-blue-100'>
                        <p className='text-center w-52'>
                            Move candidate card to Pre-screened to view {props.name.toUpperCase()}'s profile
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MatchProfile;
