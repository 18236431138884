import moment from 'moment';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { auth } from '../../utilities/auth';
import { ScheduleContext } from '../../utilities/context';
import { ScheduleInitialState } from '../../utilities/states';
import { Schedule, ScheduleData, PerDayScheduler, Scheduler } from '../../utilities/types';
import SchedulerView from './view';
import { createInterview } from '../../utilities/api_v2/jobs';
import { getSchedules as getSchedulesV2 } from '../../utilities/api_v2/scheduler';
import { companySchedules } from '../../utilities/api_v2/dashboard';

const SchedulerController = () => {
  const { handleSubmit, control, setValue } = useForm<any>();
  const [schedules, setSchedules] = useState<PerDayScheduler[]>([]);
  const [nextSchedules, setNextSchedules] = useState<Schedule[]>([]);
  const [show, setShow] = useState<boolean>(ScheduleInitialState.show);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [activeDate, setActiveDate] = useState(moment().toDate())
  const [info, setInfo] = useState<Scheduler>();
  const [schedule, setSchedule] = useState<any>(null)
  const [companySchedule, setCompanySchedule] = useState<any>(null)

  const _createInterview = async (data) => {
    const payload = {
      ...data
    }
    return await createInterview(payload).then((data) => {
      _getSchedule();
      _closeHandler();
      return data
    }).catch((error) => {
      console.log(error);
    })
  }


  const _getCompanySchedule = async () => {
    return companySchedules( {
      employer_id: auth.getId(),
      current_date: moment(),
    }).then((data:any) => {
      setCompanySchedule(data.data)
    })
  }

  const _dateChange = (date: Date) => {
    setActiveDate(moment(date).toDate())
  };

  const _editHandler = (val: Scheduler) => {
    setValue('address', val.address);
    setValue('date', moment(val.date).toDate());
    setValue('start_time', moment(val.start_time).toDate());
    setValue('end_time', moment(val.end_time).toDate());
    setShow(false);
    setIsEdit(true);
  };

  const _closeHandler = () => {
    setShow(false);
    setIsEdit(false);
  };

  const _showInfo = (data: Scheduler) => {
    setInfo(data);
    setShow(true);
  };

  const _submitHandler = (data: any) => {
    data = {
      ...data,
      date: moment(data.date).format('YYYY-MM-DD'),
    };
    _createInterview({
      ...data,
      application_id: info?.application_id,
    })
  };

  const _getSchedule = () => {
    getSchedulesV2({
      startOfMonth:moment(activeDate).startOf('month').toDate(),
      endOfMonth:moment(activeDate).endOf('month').toDate(), 
      employer_id:auth.getId()}).then((sched:any)=>{
        setSchedule(sched.data)
      })
  };

  useEffect(() => {
    _getSchedule();
  }, [activeDate]);

  useEffect(() => {
    const perDayAppointments = {}
    if (schedule) {
      schedule.forEach((val: any) => {
        const key = moment(val.date)
          .startOf('day')
          .add(moment(val.start_time).get('hours'), 'hours')
          .format("MM/DD/YY")
        const prevAppointments = perDayAppointments[key]?.arrayOfAppointments || []
        perDayAppointments[key] = {
          arrayOfAppointments: [...prevAppointments, {
            ...val,
            startDate: moment(val.date)
              .startOf('day')
              .add(moment(val.start_time).get('hours'), 'hours')
              .toISOString(),
            endDate: moment(val.date).startOf('day').add(1, 'day').toISOString(),
            disabled: moment().isAfter(val.date),
          }],
          startDate: moment(val.date)
            .startOf('day')
            .add(moment(val.start_time).get('hours'), 'hours')
            .toISOString(),
          endDate: moment(val.date).startOf('day').add(1, 'day').toISOString(),
          disabled: moment().isAfter(val.date),
        }
        return {
          ...val,
          startDate: moment(val.date)
            .startOf('day')
            .add(moment(val.start_time).get('hours'), 'hours')
            .toISOString(),
          endDate: moment(val.date).startOf('day').add(1, 'day').toISOString(),
          disabled: moment().isAfter(val.date),
        } as Scheduler;
      });
      setSchedules(Object.values(perDayAppointments) as PerDayScheduler[]);
    }
  }, [schedule]);

  useEffect(() => {
    if (companySchedule) {
      const temp = companySchedule.map((item: any) => {
        return {
          name: item.name,
          profile_picture: item.profile_image,
          position: item.job_title,
          date: moment(item.date),
          start: moment(item.start_time),
        };
      });
      setNextSchedules(temp);
    }
  }, [companySchedule]);

  useEffect(() => {
    if (schedule) {
      _getCompanySchedule()
    }
  }, [schedule]);

  const value: ScheduleData = {
    schedules,
    show,
    control,
    info,
    isEdit,
    nextSchedules,
    handleSubmit,
    _closeHandler,
    _dateChange,
    _editHandler,
    _showInfo,
    _submitHandler,
  };

  return (
    <ScheduleContext.Provider value={value}>
      <SchedulerView />
    </ScheduleContext.Provider>
  );
};

export default SchedulerController;
