import { common } from '@material-ui/core/colors';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useContext } from 'react';
import {
  Control,
  FieldErrors,
  FieldValues,
  useFieldArray,
  UseFormHandleSubmit,
  UseFormRegister,
} from 'react-hook-form';
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from 'react-icons/ai';
import { skipPartiallyEmittedExpressions } from 'typescript';
import AddButton from '../../../components/add-button';
import ButtonExit from '../../../components/buttons/button-exit';
import ButtonNext from '../../../components/buttons/button-next';
import InputField from '../../../components/forms/input';
import { stagger } from '../../../framer';
import { lang } from '../../../lang';
import { CreateJobContext } from '../../../utilities/context';
import { Job, OnClickFunction, Skill } from '../../../utilities/types';

const StepTwoSeven = () => {
  const {
    control,
    handleSubmit,
    errors,
    _submitForm,
    common_job,
    getValues,
    setValue,
    register,
  } = useContext(CreateJobContext);

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'skills',
  });

  const _onRemove = () => {
    remove(fields.length - 1);
  };

  useEffect(() => {
    if (fields.length <= 0) {
      append({ name: '' });
    }
  }, [fields]);

  return (
    <form
      onSubmit={handleSubmit && _submitForm && handleSubmit(_submitForm)}
      className="flex flex-1 flex-col"
    >
      <div
        className="flex-initial overflow-auto h-screen/2"
        id="journal-scroll"
      >
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          className="w-full p-4"
        >
          <div className="flex flex-col flex-1">
            <div className="overflow-y">
              {fields.map((field, index) => {
                let reg = {} as any;
                let defaultValue = null;
                if (register !== undefined && getValues !== undefined) {
                  reg = { ...register(`skills.${index}.value`) } as any;
                  if (reg.name) {
                    defaultValue = getValues(reg?.name);
                  }
                }
                if (index === fields.length - 1 && index !== 0) {
                  return (
                    <div className="flex flex-row">
                      <div className="flex-auto">
                        <InputField
                          label={lang.step_two.enter_a_skill}
                          defaultValue={defaultValue}
                          name={reg.name}
                          control={control}
                          error={errors?.[reg.name]}
                          type="text"
                          rules={{ required: true }}
                        />
                      </div>
                      <div
                        className="w-12 flex justify-center items-center text-red-400 cursor-pointer"
                        onClick={() => _onRemove()}
                      >
                        <AiOutlineMinusCircle />
                      </div>
                    </div>
                  );
                }
                return (
                  <InputField
                    label={lang.step_two.enter_a_skill}
                    defaultValue={defaultValue}
                    name={reg.name}
                    control={control}
                    error={errors?.[reg.name]}
                    type="text"
                    rules={{ required: true }}
                  />
                );
              })}
            </div>
          </div>
          <AddButton
            label={lang.step_two.add_skill}
            onClick={() => append({ name: '' })}
          />
        </motion.div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row flex-1 items-end p-4">
          <div className="h-20 w-1/2 flex justify-start items-end">
            <ButtonExit />
          </div>
          <div className="h-20 w-1/2 flex justify-end items-end">
            <ButtonNext />
          </div>
        </div>
      </div>
    </form>
  );
};

export default StepTwoSeven;
