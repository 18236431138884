import { useMutation } from '@apollo/client';
import moment from 'moment';
import { useEffect, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { lang } from '../../lang';
import { auth } from '../../utilities/auth';
import { CheckoutContext } from '../../utilities/context';
import mutations from '../../utilities/mutations';
import {
  CheckoutInitialState
} from '../../utilities/states';
import {
  Action,
  Checkout,
  CheckoutData,
  MainStep,
  Steps
} from '../../utilities/types';
import StepFourOne from './steps/step-four-one';
import StepFourThree from './steps/step-four-three';
import StepFourTwo from './steps/step-four-two';
import CheckoutView from './view';
import { useRecoilState } from 'recoil';
import { checkoutAtom } from '../../utilities/atoms/checkout';
import { checkoutJob, getSettings, insertTransaction, updateJob } from '../../utilities/api_v2/checkout';

type Step = {
  title: string;
  elem: React.ReactNode;
};

const stepFour: Step[] = [
  {
    title: lang.create_job.step_four_one,
    elem: <StepFourOne />,
  },
  {
    title: lang.create_job.step_four_one,
    elem: <StepFourTwo />,
  },
  {
    title: '',
    elem: <StepFourThree />,
  },
];

type Params = {
  step: string;
  sub: string;
  job_id: string;
};

const reducer = (state: Checkout, action: Action): Checkout => {
  switch (action.type) {
    case 'steps':
      switch (action.payload) {
        case 1:
          return {
            ...state,
            steps: { 1: true, 2: false, 3: false, 4: false },
          };
        case 2:
          return {
            ...state,
            steps: { 1: false, 2: true, 3: false, 4: false },
          };
        case 3:
          return {
            ...state,
            steps: { 1: false, 2: false, 3: true, 4: false },
          };
        case 4:
          return {
            ...state,
            steps: { 1: false, 2: false, 3: false, 4: true },
          };
        default:
          return { ...state };
      }
    case 'details':
      return {
        ...state,
        details: {
          ...(action.payload as MainStep),
        },
      };
    case 'data':
      return {
        ...state,
        checkout: {
          ...(action.payload as CheckoutData),
        },
      };
    default:
      throw new Error();
  }
};

const CheckoutController = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    register,
    watch,
  } = useForm<any>();

  const cardNumber = watch('card_number');
  const cvc = watch('cvc');
  const expiry = watch('expiry');
  const history = useHistory();

  const steps: Steps = {
    1: false,
    2: false,
    3: false,
    4: true,
  };

  const [commonJobs, setCommonJobs] = useRecoilState(checkoutAtom)
  const [state, dispatch] = useReducer(reducer, CheckoutInitialState);
  const [loading, setLoading] = useState<boolean>(false);
  const [prev, setPrev] = useState<string>('');
  const [postingFee, setPostingFee] = useState<number>(0);
  const [currentTransactionId, setCurrentTransaction] = useState<number>(0);
  const [postingAmount, setPostingAmount] = useState<number>(0);
  const [postingFeeChecker, setPostingFeeChecker] = useState<boolean>(false);
  const [job, setJob] = useState(null)
  const [processingFeeChecker, setProcessingFeeChecker] =
    useState<boolean>(false);
  const [payment_type, setPaymentType] = useState<
    'card' | 'bank_transfer' | 'send_bill'
  >('bank_transfer');
  const { step, sub, job_id }: Params = useParams();
  const location: any = useLocation();

  const _checkoutJob = async () => {
    const data: any = await checkoutJob({
      id: parseInt(job_id),
    })
    if (data?.data) {
      setJob(data.data)
    }
  }

  const _getSettings = async () => {
    const settings: any = await getSettings()
    setPostingAmount(settings.data.referral_fee);
  }

  const _updateJob = async (data: any) => {
    await updateJob(data).then((job: any) => {
      setLoading(loading => !loading);
    }).catch((e) => {
      console.log(e)
    })
  }

  const [checkout] = useMutation(mutations.CREATE_TRANSACTION, {
    onCompleted(res) {
      _insertTransaction({
        job_id,
        total_vat: state.checkout.vat,
        total_commission: state.checkout.processing_fee,
        amount: state.checkout.total,
        transaction_id: res.MagPieCharge.message,
        payment_type, 
        posting_fee: postingFeeLatestValue(),
        employer_id: auth.getId(),
      },);
    },
    onError(error) {
      setLoading(false);
    },
  });

  const _insertTransaction = async (data: any) => {
    await insertTransaction(data).then((dt: any) => {
      const res = dt.data
      setCurrentTransaction(res.id);
      setLoading(false);
      history.push(`/checkout/4/3/${job_id}`);
      setCommonJobs(null)
    }).catch(e => {
      console.log(e)
      setLoading(false);
    })
  }

  const getView = (step: number, sub_step: number) => {
    let totalSteps: number = 0;
    let view: React.ReactNode;
    let heading: string = '';
    switch (step) {
      case 4:
        totalSteps = 3;
        view = stepFour[sub_step - 1].elem;
        heading = stepFour[sub_step - 1].title;
        break;
      default:
        break;
    }
    dispatch({
      type: 'details',
      payload: {
        totalSteps,
        subStep: {
          heading,
          step: sub_step,
          view,
        },
        isCheckout: location?.state?.fromCreateJob ?? false,
      },
    });
  };

  const _processingFee = (amount: number) => {
    return amount * 0.15;
  };

  const _vat = (amount: number, postingFee: number) => {
    return (_processingFee(amount) + amount + postingFee) * 0.12;
  };

  const _numberFormat = (card_number: string) => {
    const formatted = card_number
      .replace(/\D/g, '')
      .replace(/\s?/g, '')
      .replace(/(\d{4})/g, '$1 ')
      .trim();
    return formatted;
  };

  const _dateFormat = (card_number: string) => {
    if (card_number.length === 2 && parseInt(card_number) > 12) {
      return 12;
    }
    if (card_number.length <= 4) {
      const formatted = card_number
        .replace(/\D/g, '')
        .replace(/\s?/g, '')
        .replace(/(\d{2})/g, '$1/')
        .trim();
      return formatted;
    }
    return card_number;
  };

  const _submitForm = (data: any) => {
    setLoading(true);
    const expiry = data.expiry.split('/');
    data = {
      name: data.customer_name,
      number: data.card_number,
      cvc: data.cvc,
      exp_month: expiry[0],
      exp_year: expiry[1],
      amount: state.checkout.total,
    };
    data = auth.encrypt({ ...data });
    console.log({data})
    checkout({
      variables: {
        data,
      },
    });
  };

  useEffect(() => {
    _checkoutJob()
    _getSettings()
  }, [])

  useEffect(() => {
    dispatch({ type: 'steps', payload: parseInt(step) });
    getView(parseInt(step), parseInt(sub));
  }, [step, sub, location]);

  useEffect(() => {
    if (cardNumber) {
      setValue('card_number', _numberFormat(cardNumber));
      setValue('cvc', _numberFormat(cvc));
    }
  }, [cardNumber, cvc]);

  useEffect(() => {
    if (expiry) {
      setValue('expiry', _dateFormat(expiry));
    }
  }, [expiry]);

  useEffect(() => {
    if (prev === '') {
      setPrev(location.state.prev);
    }
  }, [prev, location]);

  useEffect(() => {
    console.log(state);
  }, [state]);

  useEffect(() => {
    if (commonJobs && !!job) {
      const data = job;
      setPostingFeeChecker(false);
      setProcessingFeeChecker(data.company.settings.processing_fee);
      const isNewJob = commonJobs.isNewJob
      const referral_fee = commonJobs.referral_fee;
      const referral_count = commonJobs.referral_count;
      const old_referral_fee = commonJobs.old_referral_fee_2;
      const old_referral_count = commonJobs.old_referral_count_2;
      let fee = referral_fee * referral_count;

      if (
        !isNewJob && old_referral_fee &&
        referral_fee > old_referral_fee &&
        referral_count > old_referral_count
      ) {
        fee = (referral_fee - old_referral_fee) * old_referral_count;
        fee = fee + referral_fee * (referral_count - old_referral_count);
        setPostingFee(0);
      } else if (
        !isNewJob && old_referral_fee &&
        referral_fee > old_referral_fee &&
        referral_count === old_referral_count
      ) {
        fee = (referral_fee - old_referral_fee) * referral_count;
        setPostingFee(0);
      } else if (
        !isNewJob && old_referral_fee &&
        referral_fee === old_referral_fee &&
        referral_count > old_referral_count
      ) {
        fee = referral_fee * (referral_count - old_referral_count);
        setPostingFee(0);
      } else {
        setPostingFee(0);
      }
      let posting_fee = !postingFeeChecker ? postingAmount : 0;
      if (moment() > moment(new Date('2023-04-31'))) {
        posting_fee = postingAmount;
      }
      const processingFee = data.company.settings.processing_fee
        ? _processingFee(fee)
        : _processingFee(fee);
      const postingFeeVal = commonJobs.isNewJob ? postingFeeLatestValue() : 0
      const total = fee + processingFee + _vat(fee, postingFeeVal) + postingFeeVal;
      dispatch({
        type: 'data',
        payload: {
          job_title: data.title,
          company_image: data.company.company_image,
          company_name: data.company.name,
          referral_fee: fee,
          referral_count,
          old_referral_fee,
          old_referral_count,
          processing_fee: processingFee,
          posting_fee: postingFeeVal,
          total,
          vat: _vat(fee, postingFeeVal),
          id: parseInt(job_id),
          email: data.user.email,
        },
      });
    } else {
      if (!!job) {
        const data = job;
        setPostingFeeChecker(data.company.settings.posting_fee);
        setProcessingFeeChecker(data.company.settings.processing_fee);
        const referral_fee = data.order[0].referral_fee;
        const referral_count = data.order[0].referral_count;
        const old_referral_fee = data.order[0].old_referral_fee;
        const old_referral_count = data.order[0].old_referral_count;
        let fee = referral_fee * referral_count;

        if (
          old_referral_fee &&
          referral_fee > old_referral_fee &&
          referral_count > old_referral_count
        ) {
          fee = (referral_fee - old_referral_fee) * old_referral_count;
          fee = fee + referral_fee * (referral_count - old_referral_count);
          setPostingFee(0);
        } else if (
          old_referral_fee &&
          referral_fee > old_referral_fee &&
          referral_count === old_referral_count
        ) {
          fee = (referral_fee - old_referral_fee) * referral_count;
          setPostingFee(0);
        } else if (
          old_referral_fee &&
          referral_fee === old_referral_fee &&
          referral_count > old_referral_count
        ) {
          fee = referral_fee * (referral_count - old_referral_count);
          setPostingFee(0);
        } else {
          setPostingFee(state.checkout.posting_fee);
        }

        let posting_fee = !postingFeeChecker ? postingAmount : 0;
        if (moment() > moment(new Date('2023-04-31'))) {
          posting_fee = postingAmount;
        }
        const processingFee = data.company.settings.processing_fee
          ? _processingFee(fee)
          : _processingFee(fee);
        const total = fee + processingFee + _vat(fee, postingFeeLatestValue()) + postingFeeLatestValue();
        dispatch({
          type: 'data',
          payload: {
            job_title: data.title,
            company_image: data.company.company_image,
            company_name: data.company.name,
            referral_fee: fee,
            referral_count,
            old_referral_fee,
            old_referral_count,
            processing_fee: processingFee,
            posting_fee: postingFeeLatestValue(),
            total,
            vat: _vat(fee, postingFeeLatestValue()),
            id: parseInt(job_id),
            email: data.user.email,
          },
        });
      }
    }

  }, [job, postingFeeChecker, postingAmount]);

  const _changePayment = (type: 'card' | 'bank_transfer' | 'send_bill') => {
    setPaymentType(type);
  };

  const postingFeeLatestValue = () => {
    const is_public = localStorage.getItem('visible')
    let result = 0
    let start_date = moment(new Date('2023-06-01'))
    let end_date = moment(new Date('2023-08-31'))
    let today = moment()

    if (is_public) {
      // commented for the meantime its the old calculation of the postingfees
      // if( today < start_date){
      //   result = 0
      // }
      // else if(today.isBetween(start_date, end_date)){
      //   result = 500
      // }
      // else if( today > end_date){
      //   result = 1000
      // }

      // new posting fee for public jobs
      result = 1000
    }

    return result
  }

  const _checkout = () => {
    setLoading(true)
    if (commonJobs) {
      const updateData = Object.fromEntries(
        Object.entries(commonJobs)
          .filter(([key]) => {
            return !(key === "old_referral_count_2" || key === "old_referral_fee_2" || key === "isNewJob")
          })
      );
      _updateJob({
        ...updateData,
      }).then(() => {
        _insertTransaction({
          job_id: parseInt(job_id),
          total_vat: state.checkout.vat,
          total_commission: state.checkout.processing_fee,
          amount: state.checkout.total,
          transaction_id: `${moment().unix()}`,
          payment_type,
          // posting_fee: postingFeeChecker ? 0 : postingFee,
          posting_fee: state.checkout.posting_fee,
          employer_id: auth.getId(),
        })
      });
    } else {
      _insertTransaction({
        job_id: parseInt(job_id),
        total_vat: state.checkout.vat,
        total_commission: state.checkout.processing_fee,
        amount: state.checkout.total,
        transaction_id: `${moment().unix()}`,
        payment_type,
        // posting_fee: postingFeeChecker ? 0 : postingFee,
        posting_fee: state.checkout.posting_fee,
        employer_id: auth.getId(),
      })
    }

    localStorage.removeItem('visible')
  };

  const value: Checkout = {
    ...state,
    steps,
    control,
    errors,
    loading,
    prev,
    payment_type,
    currentTransactionId,
    handleSubmit,
    _submitForm,
    _changePayment,
    _checkout,
  };

  return (
    <CheckoutContext.Provider value={value}>
      <CheckoutView />
    </CheckoutContext.Provider>
  );
};

export default CheckoutController;
