import { Variants } from 'framer-motion';

const easing: number[] = [0.6, -0.05, 0.01, 0.99];

const slideIn: Variants = {
  initial: {
    opacity: 0,
    x: 50,
  },
  animate: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.4,
      easing: easing,
    },
  },
};

const slideUp: Variants = {
  initial: {
    opacity: 0,
    y: 50,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.4,
      easing: easing,
    },
  },
};

const menu: Variants = {
  show: {
    width: 240,
    transition: {
      duration: 0.5,
    },
  },
  hide: {
    width: 64,
    transition: {
      duration: 0.5,
    },
  },
  textVisible: {
    display: 'inline-block',
    opacity: 1,
    transition: {
      duration: 0.1,
      delay: 0.5,
    },
  },
  textInvisible: {
    display: 'none',
    opacity: 0,
    transition: {
      duration: 0.1,
    },
  },
};

const rotate: Variants = {
  show: {
    rotate: 0,
    transition: {
      duration: 0.4,
    },
  },
  hide: {
    rotate: 180,
    transition: {
      duration: 0.4,
    },
  },
};

const stagger: Variants = {
  initial: { opacity: 0.5 },
  animate: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const scrollUp: Variants = {
  initial: {
    opacity: 0,
    y: 100,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.2,
    },
  },
};

export { slideIn, menu, slideUp, stagger, rotate, scrollUp };
