import { Link } from 'react-router-dom';
import { ProfilePageContext } from '../../utilities/context';
import { useContext } from 'react';
import { motion } from 'framer-motion';
import { slideIn, stagger } from '../../framer';

const ProfilePageView = () => {
  const { view, pageType } = useContext(ProfilePageContext);
  return (
    <div className="grid grid-cols-3 gap-0 min-h-screen overflow-hidden">
      <div className="bg-white">
        <div className="p-4 border-b-2 my-2">
          <div className="relative text-gray-600">
            <form>
              <div className="relative text-gray-600 w-full my-4">
                <input
                  type="search"
                  placeholder="Search"
                  className="bg-gray-100 w-full h-10 px-5 pr-10 rounded-full text-sm focus:outline-none"
                />
                <button
                  type="submit"
                  className="absolute right-0 top-0 mt-3 mr-4"
                >
                  <svg
                    className="h-4 w-4 fill-current"
                    version="1.1"
                    id="Capa_1"
                    x="0px"
                    y="0px"
                    viewBox="0 0 56.966 56.966"
                  >
                    <path d="M55.146,51.887L41.588,37.786c3.486-4.144,5.396-9.358,5.396-14.786c0-12.682-10.318-23-23-23s-23,10.318-23,23  s10.318,23,23,23c4.761,0,9.298-1.436,13.177-4.162l13.661,14.208c0.571,0.593,1.339,0.92,2.162,0.92  c0.779,0,1.518-0.297,2.079-0.837C56.255,54.982,56.293,53.08,55.146,51.887z M23.984,6c9.374,0,17,7.626,17,17s-7.626,17-17,17  s-17-7.626-17-17S14.61,6,23.984,6z" />
                  </svg>
                </button>
              </div>
            </form>
          </div>
        </div>
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          className="w-full pt-10"
          style={{ height: 'calc(100vh - 8rem)' }}
          id="journal-scroll"
        >
          <motion.div variants={stagger} initial="initial" animate="animate">
            <Link
              to={`/settings/profile`}
              className={`cursor-pointer text-pj-blue-100 flex py-4 text-base text-pj-blue-100 cursor-pointer pl-8
                            ${
                              pageType === 'profile'
                                ? 'bg-gray-100 text-pj-blue-500 font-medium w-full'
                                : 'bg-white text-pj-blue-100'
                            }`}
            >
              Profile
            </Link>
          </motion.div>
          <motion.div variants={stagger} initial="initial" animate="animate">
            <Link
              to={`/settings/change-password`}
              className={`cursor-pointer text-pj-blue-100 flex py-4 text-base text-pj-blue-100 cursor-pointer pl-8
                            ${
                              pageType === 'change-password'
                                ? 'bg-gray-100 text-pj-blue-500 font-medium w-full'
                                : 'bg-white text-pj-blue-100'
                            }`}
            >
              Change Password
            </Link>
          </motion.div>
        </motion.div>
      </div>
      <div className="col-span-2 overflow-y-scroll overflow-x-hidden h-full w-full  bg-gray-50">
        <motion.div
          variants={stagger}
          animate="animate"
          initial="initial"
          className="col-span-2 overflow-y-scroll overflow-x-hidden"
          id="journal-scroll"
        >
          <motion.div variants={slideIn} className="pb-4 pr-4 pl-4 pt-2">
            {view}
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default ProfilePageView;
