import { motion } from 'framer-motion';
import { useState } from 'react';
import { useEffect } from 'react';
import { useContext } from 'react';
import ButtonExit from '../../../components/buttons/button-exit';
import ButtonNext from '../../../components/buttons/button-next';
import InputField from '../../../components/forms/input';
import { stagger } from '../../../framer';
import { lang } from '../../../lang';
import { CreateJobContext } from '../../../utilities/context';

type Range = {
  minPrice: number;
  maxPrice: number;
  min: number;
  max: number;
  minThumb: number;
  maxThumb: number;
};

const StepTwoEight = () => {
  const { common_job, handleSubmit, _submitForm, control, setValue } =
    useContext(CreateJobContext);

  const min = 500;
  const max = 200000;
  const minPrice = common_job.minimum_salary;
  const maxPrice = common_job.maximum_salary;
  const minThumb = ((minPrice - min) / (max - min)) * 100;
  const maxThumb = 100 - ((maxPrice - min) / (max - min)) * 100;

  const [minmax, setMinMax] = useState<Range>({
    min,
    max,
    minPrice,
    maxPrice,
    minThumb,
    maxThumb,
  });

  const [checked, setChecked] = useState<boolean>(common_job.show_salary);

  const minTrigger = (value: number) => {
    const minPrice = Math.min(value, minmax.maxPrice - 500);
    const minThumb =
      ((minPrice - minmax.min) / (minmax.max - minmax.min)) * 100;
    setMinMax({
      ...minmax,
      minPrice,
      minThumb,
    });
    setValue && setValue('minimum_salary', minPrice);
  };

  const maxTrigger = (value: number) => {
    const maxPrice = Math.max(value, minmax.minPrice + 500);
    const maxThumb =
      100 - ((maxPrice - minmax.min) / (minmax.max - minmax.min)) * 100;
    setMinMax({
      ...minmax,
      maxPrice,
      maxThumb,
    });
    setValue && setValue('maximum_salary', maxPrice);
  };
  return (
    <form
      onSubmit={handleSubmit && _submitForm && handleSubmit(_submitForm)}
      className="flex flex-1 flex-col"
    >
      <div className="flex-initial h-screen/2" id="journal-scroll">
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          className="w-full p-4"
        >
          <div className="mb-5">
            <label className="flex justify-start items-start pb-6">
              <div className="bg-white border-2 rounded border-gray-400 w-5 h-5 flex flex-shrink-0 justify-center items-center mr-2 focus-within:border-blue-500">
                <input
                  type="checkbox"
                  className="opacity-0 absolute focus:border-none focus:border-green-200"
                  name="show_salary"
                  checked={checked}
                  onChange={() => {
                    setChecked(!checked);
                    setValue && setValue('show_salary', !checked);
                  }}
                />
                <svg
                  className="fill-current hidden w-4 h-4 text-pj-blue-500 pointer-events-none"
                  viewBox="0 0 20 20"
                >
                  <path d="M0 11l2-2 5 5L18 3l2 2L7 18z" />
                </svg>
              </div>
              <div className="select-none text-sm text-pj-blue-500">
                {lang.step_two.show_salary}
              </div>
            </label>
          </div>
          <div className="flex justify-center items-center">
            <div className="relative w-full">
              <div>
                <input
                  type="range"
                  step="500"
                  className="absolute pointer-events-none appearance-none z-20 h-2 w-full opacity-0 cursor-pointer"
                  min={minmax.min}
                  max={minmax.max}
                  onChange={val => minTrigger(parseInt(val.target.value))}
                  value={minmax.minPrice}
                />

                <input
                  type="range"
                  step="500"
                  className="absolute pointer-events-none appearance-none z-20 h-2 w-full opacity-0 cursor-pointer"
                  min={minmax.min}
                  max={minmax.max}
                  onChange={val => maxTrigger(parseInt(val.target.value))}
                  value={minmax.maxPrice}
                />

                <div className="relative z-10 h-2">
                  <div className="absolute z-10 left-0 right-0 bottom-0 top-0 rounded-md bg-gray-200"></div>

                  <div
                    className="absolute z-20 top-0 bottom-0 rounded-md bg-gray-400"
                    style={{
                      right: `${minmax.maxThumb}%`,
                      left: `${minmax.minThumb}%`,
                    }}
                  ></div>

                  <div
                    className="absolute z-30 w-6 h-6 bg-white top-0 left-0 border-2 border-gray-200 rounded-full -mt-2 -ml-3"
                    style={{
                      left: `${minmax.minThumb}%`,
                    }}
                  ></div>

                  <div
                    className="absolute z-30 w-6 h-6 bg-white top-0 right-0 border-2 border-gray-200 rounded-full -mt-2 -mr-3"
                    style={{
                      right: `${minmax.maxThumb}%`,
                    }}
                  ></div>
                </div>
              </div>

              <div className="flex justify-between items-center py-5 space-x-4">
                <div className="w-full">
                  <InputField
                    label=""
                    name="minimum_salary"
                    type="hidden"
                    defaultValue={minmax.minPrice}
                    control={control}
                  />
                  <div className="w-full p-4 bg-gray-200 rounded-lg text-gray-400">
                    {lang.formatString(
                      lang.step_two.php,
                      minmax.minPrice.toLocaleString()
                    )}
                  </div>
                </div>
                <div className="w-full">
                  <div className="w-full p-4 bg-gray-200 rounded-lg text-gray-400">
                    {lang.formatString(
                      lang.step_two.php,
                      minmax.maxPrice.toLocaleString()
                    )}
                  </div>
                  <InputField
                    label=""
                    name="maximum_salary"
                    type="hidden"
                    defaultValue={minmax.maxPrice}
                    control={control}
                  />
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row flex-1 items-end p-4">
          <div className="h-20 w-1/2 flex justify-start items-end">
            <ButtonExit />
          </div>
          <div className="h-20 w-1/2 flex justify-end items-end">
            <ButtonNext />
          </div>
        </div>
      </div>
    </form>
  );
};

export default StepTwoEight;
