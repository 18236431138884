import { useEffect, useState } from 'react';
import { PageContext } from '../../utilities/context';
import NotificationsView from './view';
import moment from 'moment';
import { auth } from '../../utilities/auth';
import { getNotifications, readNotification } from '../../utilities/api_v2/notifications';

const NotificationsController = () => {
  let employerID = auth.getId();
  const [newNotifs, setNewNotifs] = useState<any>([]);
  const [earlierNotifs, setEarlierNotifs] = useState<any>([]);
  const [notifications, setNotifications] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [sort, setSort] = useState<{label:string; value:"asc" | "desc"}>({
    label: 'Newest',
    value: 'desc',
  });

  const _getNotifications = async () => {
    setLoading(true)
    return await getNotifications({
      ...where,
      order: sort.value
    }).then((data :any)=>{
      if(data){
        setNotifications(data.data)
      }
    }).finally(()=>{
      setLoading(false)
    })
  }

  const _readNotification = async (id:number) => {
    await readNotification({id}).then(()=>{
      _getNotifications()
    })
  }

  const [where, setWhere] = useState<object>({
    search: '%%',
    limit: 100,
    employer_id: employerID,
  });

  useEffect(() => {
    if(where){
      _getNotifications()
    }
  }, [where, sort]);

  useEffect(() => {
    if (notifications) {
      const newItems = notifications.filter(
        (item: any) => moment().diff(moment(item.created_at), 'days') <= 1
      );
      const earlierItems = notifications.filter(
        (item: any) => moment().diff(moment(item.created_at), 'days') > 1
      );
      setNewNotifs([...newItems]);
      setEarlierNotifs([...earlierItems]);
    }
  }, [notifications]);

  const readNotifs = (data: any) => {
    _readNotification(parseInt(data.id))
  }

  const _handleSearch = (data: any) => {
    if(data.search.length > 0){
      setWhere({
        ...where,
        search: `%${data.search}%`,
      });
    } else {
      const newWhere: any = { ...where };
      delete newWhere?.search;
      setWhere({ ...newWhere });
    }
  }

  const _handleFilter = (option: any) => {
    setSort(option)
    console.log(option);
    
  }
  const value: object = { newNotifs, earlierNotifs, readNotifs, _handleSearch, _handleFilter, sort,loading };

  return (
    <PageContext.Provider value={value}>
      <NotificationsView />
    </PageContext.Provider>
  );
};

export default NotificationsController;
