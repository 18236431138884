import { abbreviate } from '../helpers';
import { lang } from '../lang';
import { Company } from '../utilities/types';

const MiniCompany = (props: Company) => {
  return (
    <div
      className={`flex w-full flex-row justify-center items-center mb-4 cursor-pointer`}
    >
      <div className="w-8 h-8 flex justify-center items-center">
        <div
          className={`w-8 h-8 rounded-full border-2 border-gray-300 bg-cover bg-center flex justify-center items-center text-gray-500 text-xs ${
            (!props.logo || props.logo.length <= 0) &&
            'bg-gray-200 tracking-wide'
          }`}
          style={{ backgroundImage: `url(${props.logo})` }}
        >
          {(!props.logo || props.logo.length <= 0) && abbreviate(props.name)}
        </div>
      </div>
      <div className="flex-auto flex-col flex justify-center">
        <div className="flex-auto p-1 text-pj-blue-100 text-sm">
          {lang.formatString(lang.company.company_name, props.name)}
        </div>
      </div>
    </div>
  );
};

export default MiniCompany;
