import { Tooltip } from "antd";
import { _getStatus } from "../../../utilities/states";

const AgingHeader = ({icon, slots, status} : {icon:JSX.Element; slots:number; status:string}) => {

    return ( 
        <Tooltip
        arrow={false}
        color={"#1c4464"}
        title={<>
          <div className="p-2 ">
            You can view up to {slots} profiles under {_getStatus[status]}. Keep processing candidates' applications by either moving them forward or moving them to 'Not Selected' to view more profiles.
          </div>
        </>}
        key={"download"}
        placement="bottomRight">
        <div className={`flex flex-row items-center gap-1 text-sm font-semibold cursor-pointer ${!!slots ? "text-pj-blue-500" : "text-red-500"}`}>
            {icon}
            <div>{slots} SLOTS</div> 
        </div>
      </Tooltip>);
}
 
export default AgingHeader;