import { IconContext } from 'react-icons';
import { RiArrowRightSLine } from 'react-icons/ri';

type Props = {
  name: string;
  label: string;
  id: number;
  icon?: any;
};

const Info = ({ name, label, id, icon }: Props) => {
  return (
    <div className="p-1 flex flex-row justify-around bg-gray-100 border rounded-lg mb-2">
      <div className="w-1/6 flex justify-start"></div>
      <div className="w-2/6 flex justify-start text-pj-blue-100 text-sm">
        {name}
      </div>
      <div className="w-3/6 flex justify-start text-pj-blue-100 text-sm flex flex-row justify-between">
        <div>{label}</div>
        <div className="text-lg text-pj-blue-100 cursor-pointer">
          {/* <RiArrowRightSLine /> */}
          {icon}
        </div>
      </div>
    </div>
  );
};

export default Info;
