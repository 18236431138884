import React from 'react';
import { Link } from 'react-router-dom';

type Props = {
  children: React.ReactNode;
  status?: string | null | undefined;
};

const JobTab = ({ children, status }: Props) => {
  return (
    <div>
      <div className="px-4 border-b-2">
        <ul className="flex justify-center items-center pb-0">
          <li className="flex-auto px-6">
            <Link
              to={`/job-posts/active`}
              className={`cursor-pointer py-4 flex px-4 text-gray-500 border-b-4 ${
                status === 'active'
                  ? 'border-green-400 pj-blue-500 font-medium'
                  : 'border-white pj-blue-100'
              } content-center uppercase text-xs`}
            >
              <div className="w-full text-center">Active</div>
            </Link>
          </li>
          <li className="flex-auto px-6">
            <Link
              to={`/job-posts/pending`}
              className={`cursor-pointer py-4 flex px-4 text-gray-500 border-b-4 ${
                status === 'pending'
                  ? 'border-green-400 pj-blue-500 font-medium'
                  : 'border-white pj-blue-100'
              } content-center uppercase text-xs pj-blue-100`}
            >
              <div className="w-full text-center">Pending</div>
            </Link>
          </li>
          <li className="flex-auto px-6">
            <Link
              to={`/job-posts/archived`}
              className={`cursor-pointer py-4 flex px-4 text-gray-500 border-b-4 ${
                status === 'archived'
                  ? 'border-green-400 pj-blue-500 font-medium'
                  : 'border-white pj-blue-100'
              } content-center uppercase text-xs pj-blue-100`}
            >
              <div className="w-full text-center">Archived</div>
            </Link>
          </li>
        </ul>
      </div>
      <div
        className="overflow-y-scroll overflow-x-hidden"
        style={{ height: 'calc(100vh - 8rem)' }}
        id="journal-scroll"
      >
        {children}
      </div>
    </div>
  );
};

JobTab.defaultProps = {
  active: 'Active',
};

export default JobTab;
