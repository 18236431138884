import {  employer_api_v2 } from "./main";

export const getTransactionDetails = async (data: any) => {
    let response = {};

    await employer_api_v2.post('transaction/getTransactionDetails', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
};
export const getTransactions = async (data: any) => {
    let response = {};

    await employer_api_v2.post('transaction/getTransactions', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
};
