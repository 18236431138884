import { useContext, useState, useEffect } from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';

const CreateAccount = () => {

  return (
    <div className="h-full">
      <div className="p-4 px-8 flex flex-col justify-around bg-gray-50 text-lg text-pj-blue-100 pt-10 mt-10 ml-12">
        <div className="py-2">
          Go to the Sign Up page.
        </div>
        <div className="py-2">
          Enter first and last names, and your work email.
        </div>
        <div className="py-2">
          Input your desired password.
        </div>
        <div className="py-2">
          Tick the box for Terms of Use and Privacy Policy.
        </div>
        <div className="py-2">
          Click Register.
        </div>
        <div className="py-2">
          Once you see the 'Registration Successful' prompt appear, click Login Now.
        </div>
        <div className="py-2">
          Input your email address or mobile number and your chosen password. Click Login.
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
