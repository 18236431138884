import firebase from 'firebase/app';
import 'firebase/analytics';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

type Parent = {
  children: React.ReactNode;
};

const Main = ({ children }: Parent) => {
  const history = useHistory();

  useEffect(() => {
    const firebaseConfig = {
      apiKey: process.env.REACT_APP_API_KEY,
      authDomain: process.env.REACT_APP_AUTH_DOMAIN,
      databaseURL: process.env.REACT_APP_DATABASE_URL,
      projectId: process.env.REACT_APP_PROJECT_ID,
      storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_SENDER_ID,
      appId: process.env.REACT_APP_APP_ID,
      measurementId: process.env.REACT_APP_MEASUREMENT_ID,
    };
    if (!firebase.apps.length) {
      firebase.initializeApp(firebaseConfig);
    }
  }, []);

  const _getActualPageName = (path: string) => {
    const page = {
      '/': 'Login Page',
      '/forgot-password': 'Forgot Password',
    };

    return page?.[path] ? page[path] : 'Login Page';
  };

  const _logEvent = (page: string) => {
    firebase.analytics().logEvent(page);
  };

  useEffect(() => {
    history.listen(location => {
      let page = _getActualPageName(location.pathname);
      if (!page) {
        page = _getActualPageName(history.location.pathname);
      }
      _logEvent(page);
    });
  }, [history]);

  return <div>{children}</div>;
};

export default Main;
