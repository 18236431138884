import { atom } from "recoil";
import { auth } from "../../auth";

const jobDataInitialState = {
  type: 'company_info',
  title: '',
  company_name: '',
  logo: '',
  id: null,
  company_id:undefined
}

const applicantCSVHeader = [
  {
    label: 'Applicants Name',
    key: 'name',
  },
  {
    label: 'Email',
    key: 'email',
  },
  {
    label: 'Mobile Number',
    key: 'mobile_number',
  },
  {
    label: 'Date Applied',
    key: 'date',
  },
]

const preScreenedCSVHeader = [
  {
    label: 'Job Title',
    key: 'job_title',
  },
  {
    label: 'Company Name',
    key: 'company_name',
  },
  {
    label: 'First Name',
    key: 'first_name',
  },
  {
    label: 'Last Name',
    key: 'last_name',
  },
  {
    label: 'Email',
    key: 'email',
  },
  {
    label: 'Mobile Number',
    key: 'mobile_number',
  },
  {
    label: 'Date Applied',
    key: 'date',
  },
  {
    label: 'Current Job Title',
    key: 'current_job_title',
  },
  {
    label: 'Referrer',
    key: 'referrer',
  },
]

export const boardFilterJobsAtom = atom<any|null>({
    key: 'boardFilterJobs',
    default: {
      limit:10,
      sort:{
        label: 'Most Recent',
        value: 'desc',
      },
      selectedCompany:{ 
        label: 'New Applicants', 
        value: 'new applicants' 
      },
      searchText:"",
      where:null,
    }
  });

export const whereCheckerAtom = atom<string>({
  key:"whereChecker",
  default:""
})
  
export const jobIdAtom = atom<number>({
  key: 'jobId',
  default: 0,
});

export const jobListAtom = atom<any|null>({
  key: 'jobList',
  default: {
    jobLists:null,
    count:0
  },
});

export const jobDetailsAtom = atom<any|null>({
  key: 'jobDetails',
  default: null,
});

export const jobDataAtom = atom<any|null>({
  key: 'jobData',
  default: jobDataInitialState,
});

export const applicantCSVAtom = atom<any|null>({
  key: 'CSVData',
  default: {
    applicantCSVData:[],
    applicantCSVHeader,
    preScreenedCSVData:[],
    preScreenedCSVHeader
  },
});

  