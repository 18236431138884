import { motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import ButtonExit from '../../../components/buttons/button-exit';
import ButtonNext from '../../../components/buttons/button-next';
import InputField from '../../../components/forms/input';
import { stagger } from '../../../framer';
import { lang } from '../../../lang';
import { CreateJobContext } from '../../../utilities/context';
import { getPositions } from '../../../utilities/api_v2/job_create';
import { canHaveMinimumRF } from '../../../utilities/helper';

type Position = {
  label: string;
  value: string;
  referral_amount: number;
};

const StepTwoNine = () => {
  const { watch, control, errors, with_applicants, handleSubmit, _submitForm, min, common_job } =
    useContext(CreateJobContext);
  const [minReferral, setMinReferral] = useState(0);
  const [positionErrorText, setPositionErrorText] = useState("");
  const [position_level, setPositionLevel] = useState<string>('');
  const [positions, sePositions] = useState<Position[]>([]);
  const [loading, setLoading] = useState(false);
  const _getPositions = async () => {
    setLoading(true)
    return await getPositions().then((p)=>{
      const items: Position[] = p.data.map(
        ({ label, value, referral_amount, }) => ({ label, value, referral_amount, })
      );
      console.log(items)
      sePositions(items);
    })
    .finally(()=>setLoading(false))
  }

  const minimumReferral : number = canHaveMinimumRF() ? 50 : common_job.blue_collar ? 100 : minReferral;

  useEffect(()=>{
    _getPositions()
  },[])

  useEffect(() => {
    if(watch){
      let level = watch('job_position_level')
      setPositionLevel(level)
    }
  }, [watch])

  useEffect(() => {
    if(common_job.erp_owner){
      setMinReferral(100)
      setPositionErrorText("The minimum referral fee amount for erp user is Php ")
    }else{
    if(position_level && positions.length){
      let selected = positions.find(position => {
        return position.value == position_level
      })
      setMinReferral(selected?.referral_amount ?? 0)
    }
    if(position_level==="Fresh Grad (1 < yr work experience)" || position_level==="Entry Level (1 - 4 yrs work experience)"){
      setPositionErrorText("The minimum referral fee amount for Fresh Grad and Entry-Level roles is PHP ")
    }else if(position_level==="Supervisor (or > 5 yrs work experience)"){
      setPositionErrorText("The minimum referral fee amount for Supervisor roles is PHP ")
    }else if(position_level==="Manager / Assistant Manager"){
      setPositionErrorText("The minimum referral fee amount for Manager/Assistant Manager roles is PHP ")
    }
    }
  }, [position_level, positions])
  return (
    <form
      onSubmit={handleSubmit && _submitForm && handleSubmit(_submitForm)}
      className="flex flex-col flex-1"
      id="journal-scroll"
    >
      <div className="flex-initial h-screen/2">
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          className="w-full p-4"
        >
          <InputField
            label={lang.step_two.enter_amount}
            name="referral_fee"
            type="number"
            control={control}
            rules={{ required: true, min: minimumReferral }}
            error={errors?.referral_fee ? {...errors?.referral_fee, message:positionErrorText + minReferral + "."}:null}
            placeholder={lang.step_two.referral_placeholder + minReferral}
            disabled={with_applicants || common_job.blue_collar}
            // min={minReferral}
          />
        </motion.div>
        { common_job.blue_collar &&
          <div className='mx-4 text-sm italic text-pj-blue-100'>
            PasaJob will subsidize the PHP 100 referral fee for blue collar job posts. This offer is available until December 31, 2024.
          </div>
        }
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row items-end flex-1 p-4">
          <div className="flex items-end justify-start w-1/2 h-20">
            <ButtonExit />
          </div>
          <div className="flex items-end justify-end w-1/2 h-20">
            <ButtonNext />
          </div>
        </div>
      </div>
    </form>
  );
};

export default StepTwoNine;
