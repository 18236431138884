import { atom } from "recoil";
export type CheckedApplicant = {
    status: string,
    applicantIds: number[]
  }
const CheckedApplicantDefault: CheckedApplicant = {
    status: "",
    applicantIds: []
  }
export const checkBoxAtom = atom<any>({
    key: 'checkboxes',
    default: CheckedApplicantDefault
});