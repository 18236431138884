import { useContext, useState, useEffect } from 'react';
import ButtonNext from '../../../../components/buttons/button-next';
import Button from '../../../../components/forms/button';
import InputField from '../../../../components/forms/input';
import Modal from '../../../../components/modal';
import { lang } from '../../../../lang';
import { ManageUserContext } from '../../../../utilities/context';
import Info from '../components/info';
import { RiArrowRightSLine } from 'react-icons/ri';
import { OnClickFunction } from '../../../../utilities/types';
import AddExistingUser from '../components/add-existing-user';

const HRTeam = () => {
  const {
    data,
    control,
    handleSubmit,
    errors,
    watch,
    _submitForm,
    setError,
    clearErrors,
    closeModal,
    setValue,
    setId,
    addEmployee,
    _editEmployee,
    clearError,
    show,
    isEdit,
    confirmButton,
    confirm,
    modalMessage,
    closeConfirmModal,
    addExistingUser,
    openShowExisting,
  } = useContext(ManageUserContext);
  const watchPassword = watch && watch('password');
  const watchConfirmPassword = watch && watch('confirm_password');
  
  useEffect(() => {
    if (watchPassword !== watchConfirmPassword) {
      setError &&
        setError('confirm_password', {
          type: 'focus',
          message: 'Password did not match!',
        });
    } else {
      clearError && clearError(true);
    }
  }, [watchPassword, watchConfirmPassword]);

  return (
    <div>
      <div className="p-4 px-8 flex flex-row justify-around bg-gray-100 border-b">
        <div className="w-1/6 flex justify-start"></div>
        <div className="w-2/6 flex justify-start text-pj-blue-100 text-sm font-semibold">
          Name
        </div>
        <div className="w-3/6 flex justify-start text-pj-blue-100 text-sm font-semibold">
          Email
        </div>
      </div>
      <div className="p-4 px-8">
        {data.map((val: any) => (
          <Info
            name={val.name}
            label={val.email}
            id={val.id}
            icon={
              <RiArrowRightSLine
                onClick={() => _editEmployee && _editEmployee(val)}
              />
            }
          />
        ))}
      </div>
      <div className="p-4 px-8 flex justify-center">
        <div
          className="inline p-2 px-4 border rounded-full text-pj-blue-100 bg-gray-100 text-sm font-semibold cursor-pointer mr-4"
          onClick={addEmployee}
        >
          Add User
        </div>
        <div
          className="inline p-2 px-4 border rounded-full text-pj-blue-100 bg-gray-100 text-sm font-semibold cursor-pointer"
          onClick={openShowExisting}
        >
          Add Existing User
        </div>
      </div>
      <AddExistingUser/>
      <Modal closable show={show} onClose={closeModal}>
        <form
          className="p-4 bg-white rounded w-full"
          onSubmit={handleSubmit && _submitForm && handleSubmit(_submitForm)}
        >
          <div className="grid grid-cols-2 gap-3 xs:grid-cols-1">
            <div className="inline-block">
              <InputField
                control={control}
                label={lang.general.first_name}
                type="text"
                name="first_name"
                rules={{ required: 'First name is required' }}
                error={errors?.first_name}
              />
            </div>
            <div className="inline-block">
              <InputField
                control={control}
                label={lang.general.last_name}
                type="text"
                name="last_name"
                rules={{ required: 'Lastname is required' }}
                error={errors?.last_name}
              />
            </div>
          </div>
          <InputField
            control={control}
            label={lang.general.email}
            type="text"
            name="email"
            rules={{ required: 'Email is required' }}
            error={errors?.email}
          />
          <InputField
            control={control}
            label={lang.general.password}
            type="password"
            name="password"
            toggle
            rules={{ required: !isEdit ? 'Password is required' : false }}
            error={errors?.password}
          />
          <InputField
            control={control}
            label={lang.general.confirm_password}
            type="password"
            name="confirm_password"
            toggle
            rules={{
              required: !isEdit ? 'Confirm Password is required' : false,
            }}
            error={errors?.confirm_password}
          />
          <div className="flex justify-center mt-4">
            <ButtonNext
              label={!isEdit ? 'Submit' : 'Update'}
              buttonType="submit"
              full
            />
          </div>
        </form>
      </Modal>
      <Modal show={confirm} onClose={closeConfirmModal} width={300}>
        <div className="flex justify-center text-xl text-gray-600">
          <div className="text-lg text-pj-blue-500">{modalMessage}</div>
        </div>
        <div className="flex justify-center mt-5 w-full px-24">
          <ButtonNext label="OK" onClick={confirmButton} full />
        </div>
      </Modal>
    </div>
  );
};

export default HRTeam;
