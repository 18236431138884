import { employer_api } from "./main";

export const checkoutJob = async (data: any) => {
    let response = {};
  
    await employer_api
      .post('checkoutJob', data)
      .then(res => {
        console.log(res, 'res');
        response = res?.data;
      })
      .catch(err => {
        response = err?.response?.data;
      });
  
    return response;
  };
  
  export const getSettings = async () => {
    let response = {};
  
    await employer_api
      .post('getSettings')
      .then(res => {
        console.log(res, 'res');
        response = res?.data;
      })
      .catch(err => {
        response = err?.response?.data;
      });
  
    return response;
  };


export const updateJob = async (data: any) => {
    let response = {};
  
    await employer_api
      .post('updateJob', data)
      .then(res => {
        console.log(res, 'res');
        response = res?.data;
      })
      .catch(err => {
        response = err?.response?.data;
      });
  
    return response;
  };
  
  export const insertTransaction = async (data: any) => {
    let response = {};
  
    await employer_api
      .post('insertTransaction', data)
      .then(res => {
        console.log(res, 'res');
        response = res?.data;
      })
      .catch(err => {
        response = err?.response?.data;
      });
  
    return response;
  };  

  export const getLatestTransaction = async (data: any) => {
    let response = {};
  
    await employer_api
      .post('getLatestTransaction', data)
      .then(res => {
        console.log(res, 'res');
        response = res?.data;
      })
      .catch(err => {
        response = err?.response?.data;
      });
  
    return response;
  };