import { AiOutlineMail, AiOutlinePhone } from 'react-icons/ai';
import { RiComputerLine, RiLinkedinLine } from 'react-icons/ri';

type Type = 'number' | 'email' | 'website' | 'linkedin';

type Props = {
  type: Type;
  info: string;
};

const ContactInfo = (props: Props) => {
  const getIcons = (type: Type) => {
    const icons = {
      number: <AiOutlinePhone />,
      email: <AiOutlineMail />,
      website: <RiComputerLine />,
      linkedin: <RiLinkedinLine />,
    };
    return icons[type];
  };

  return (
    <div className="flex flex-row mb-2">
      <div className="text-lg text-pj-blue-100 pr-2">
        {getIcons(props.type)}
      </div>
      <div className="text-sm text-pj-blue-100">
        {props.type === 'linkedin' ? (
          <a
            href={`https://${props?.info
              ?.replace('https://www.', '')
              ?.replace('www.', '')}`}
            className="inline pb-1 border-b border-blue-200"
          >
            {props?.info?.replace('https://www.', '')?.replace('www.', '')}
          </a>
        ) : (
          props.info
        )}
      </div>
    </div>
  );
};

export default ContactInfo;
