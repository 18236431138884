import React from 'react';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import Authenticated from '../components/authenticated';
import Unauthenticated from '../components/unauthenticated';
import BoardController from '../private/board/index';
import CandidateProfileController from '../private/candidate-profile';
import CheckoutController from '../private/checkout';
import CompanyController from '../private/companies/create';
import ManageUserController from '../private/companies/manage-user';
import DashboardController from '../private/dashboard';
import FaqsController from '../private/faqs';
import CreateJobController from '../private/job-create';
import JobsController from '../private/jobs';
import ActiveController from '../private/jobs/active';
import ArchivedController from '../private/jobs/archived';
import PendingController from '../private/jobs/pending';
import LogoutController from '../private/logout';
import MessageController from '../private/messages';
import NotificationsController from '../private/notifications';
import ProfilePageController from '../private/profile-page';
import SchedulerController from '../private/scheduler';
import TransactionsController from '../private/transactions';
import ForgotPasswordController from '../public/forgot-password';
import EmployerCreditController from '../private/employer-credit';
import LoginController from '../public/login';
import OTPController from '../public/otp';
import SignupController from '../public/signup';
const Routes = () => {
  return (
    <Router>
      <Switch>
        <Unauthenticated exact path="/" component={LoginController} />
        <Unauthenticated exact path="/signup" component={SignupController} />
        <Unauthenticated
          exact
          path="/forgot-password"
          component={ForgotPasswordController}
        />
        <Unauthenticated
          exact
          path="/one-time-password"
          component={OTPController}
        />
        <Authenticated
          exact
          path="/dashboard"
          component={DashboardController}
          title="Dashboard"
        />
        <Authenticated
          exact
          path="/job-posts/:status?/:id?"
          component={JobsController}
          title="Jobs"
        />
        <Authenticated
          exact
          path="/create-job/:step/:sub/:job_id?/:is_duplicate?/"
          component={CreateJobController}
          hide={true}
          title="Create Job"
        />
        <Authenticated
          exact
          path="/checkout/:step/:sub/:job_id"
          component={CheckoutController}
          hide={true}
          title="Checkout"
        />
        <Authenticated
          exact
          path="/applicant-board/:id?/:type?"
          component={BoardController}
          title="Applicant Board"
        />
        <Authenticated
          exact
          path="/notifications"
          component={NotificationsController}
          hide={true}
          title="Notifications"
        />
        <Authenticated
          exact
          path="/transactions/:id?"
          component={TransactionsController}
          title="Transactions"
        />
        <Authenticated
          exact
          path="/my-companies/:id?"
          component={CompanyController}
          title="My Companies"
        />
        <Authenticated
          exact
          path="/my-companies/:id/:manage-user/:type?"
          component={ManageUserController}
          title="Manage Companies"
        />
        <Authenticated
          exact
          path="/candidate-profile/:id/:job_id/:application_id?/:page?"
          name
          component={CandidateProfileController}
          title="Candidate Profile"
        />
        {/* <Authenticated
          exact
          path="/messages/:seeker_id?/:job_id?/:page?"
          name
          show={false}
          component={MessageController}
          title="Messages"
        /> */}
        <Authenticated
          exact
          path="/calendar"
          name
          show={true}
          component={SchedulerController}
          title="Calendar"
        />
        <Authenticated
          exact
          path="/settings/:page?"
          name
          show={true}
          component={ProfilePageController}
          title="Settings"
        />
        <Authenticated
          path="/faqs/:page"
          name
          show={true}
          component={FaqsController}
          title="FAQ"
        />
        <Authenticated
          path="/logout"
          name
          show={true}
          component={LogoutController}
          title="Logout"
        />
        {/* employer credit routes */}
        <Authenticated
          exact
          path="/credits/:id?"
          component={EmployerCreditController}
          title="Transactions"
        />
      </Switch>
    </Router>
  );
};

export default Routes;
