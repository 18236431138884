import JobItem from '../../components/jobs/job-item';
import JobTab from '../../components/tabs/job-posts';
import { JobPostContext } from '../../utilities/context';
import { motion } from 'framer-motion';
import { slideIn, stagger } from '../../framer';
import AnimatedSelect from '../../components/animated-select';
import { useContext } from 'react';
import { RiArrowRightSLine } from 'react-icons/ri';
import Search from '../../components/forms/search';
import { AiOutlinePlus } from 'react-icons/ai';
import { Link } from 'react-router-dom';
import Loading from '../../components/loader';
import { abbreviate } from '../../helpers';
import Avatar from '../../components/avatar';
import Question from '../../components/question';

const JobView = () => {
  const {
    show,
    jobPosts,
    job,
    status,
    _handleSearch,
    selectedCompany,
    sort,
    id,
    loading,
    register,
    handleSubmit,
    _handleSelect,
    _handleSort,
    _clickHandler,
    _handleRefresh,
    addTenToLimit,
    limit,
    jobsCount
  } = useContext(JobPostContext);

  return (
    <div className="grid h-full grid-cols-3 gap-0 overflow-hidden bg-white">
      <div className="relative border-r-2">
        <JobTab status={status}>
          <div className="p-4">
            <div className="relative text-gray-600">
              <form onSubmit={handleSubmit && handleSubmit(_handleSearch)}>
                {register && <Search name="search" register={register} />}
              </form>
            </div>
          </div>
          <div className="flex px-4 py-1 border-b-2">
            <div className="flex-1">
              <AnimatedSelect
                options={[
                  { label: 'All Jobs', value: null },
                  { label: 'Active', value: true },
                  { label: 'Private', value: false },
                  { label: 'New Applicants', value: 'new applicants' },
                ]}
                onClick={_handleSelect}
                label={selectedCompany && selectedCompany?.label}
                left={-80}
              />
            </div>
            <div className="items-end flex-1" />
            <div className="flex-1">
              <AnimatedSelect
                options={[
                  { label: 'Most Recent', value: 'desc' },
                  { label: 'Oldest', value: 'asc' },
                ]}
                onClick={_handleSort}
                label={sort && sort.label}
              />
            </div>
          </div>
          <motion.div variants={stagger} initial="initial" animate="animate">
            {loading && limit <= 10 ? (
              <div className="w-full h-72">
                <Loading show={loading} />
              </div>
            ) : (
              jobPosts.length ? <>
                {jobPosts.map((jobItem, index) => (
                  <JobItem
                    {...jobItem}
                    status={status}
                    _handleRefresh={_handleRefresh}
                    onClick={_clickHandler}
                    key={`${index}`}
                  />
                ))}
                {loading ?
                  <div className='w-full my-3 text-center  text-gray-350'>Loading ...</div> :
                  (limit < jobsCount ? <div className='w-full my-3 text-center text-gray-600 cursor-pointer ' onClick={addTenToLimit}>Load more</div> : <></>)
                }
              </> : <div className='flex items-center justify-center w-full  text-gray-350 mt-36'>Currently no data available</div>
            )}

          </motion.div>
        </JobTab>
        <div className="absolute right-5 bottom-5">
          <Link to="/create-job/1/1">
            <motion.div
              whileHover={{
                rotate: 360,
                scale: 1.2,
                transition: {
                  duration: 0.2,
                },
              }}
              style={{ left: '47px', position: 'relative' }}
              className="p-4 text-2xl text-white rounded-full shadow-md cursor-pointer bg-pj-green-200"
              title="Add Job"
            >
              <AiOutlinePlus />
            </motion.div>
          </Link>
        </div>
      </div>
      {show && (
        <motion.div
          variants={stagger}
          animate="animate"
          initial="initial"
          className="col-span-2 overflow-x-hidden overflow-y-scroll"
          id="journal-scroll"
        >
          <motion.div variants={slideIn} className="pt-4 pb-2 pl-4 pr-4">
            <div className="flex justify-between pb-3 border-b">
              <div className="flex items-center justify-center">
                {job.status === 'Active' && (
                  <div className="inline-flex flex-auto pl-1">
                    <a
                      href={`${encodeURI(
                        `https://seeker.pasajob.com/jobpage/${job?.title &&
                        job.title
                          .replace(/ /g, '-')
                          .replace(/[^\w\s]/gi, '-')
                          .replace('/', '-')
                        }?id=${id}`
                      )}`}
                      className="text-sm text-gray-400 w-15"
                      target="_blank"
                    >
                      OPEN IN NEW TAB
                    </a>
                    <div className="w-5 text-sm text-gray-400">
                      <RiArrowRightSLine className="text-lg" />
                    </div>
                  </div>
                )}
              </div>
              {job.status !== 'Filled Up' && (
                <Link
                  to={`/create-job/3/1/${id}`}
                  className="text-sm text-gray-400 w-15"
                >
                  EDIT
                </Link>
              )}
            </div>
          </motion.div>
          <motion.div variants={slideIn} className="p-4">
            <div className="flex items-center">
              <div className="max-w-max">
                <div className="flex items-center justify-start w-32 h-16 text-2xl text-pj-blue-500">
                  {job.company_logo && job.company_logo !== '' ? (
                    <div
                      className="w-32 h-16 bg-gray-100 bg-center bg-cover border border-gray-200"
                      style={{ backgroundImage: `url("${job.company_logo}")` }}
                    ></div>
                  ) : (
                    // job.company_name
                    <Avatar
                      name={job.company_name ?? ''}
                      url={null}
                      size={50}
                    />
                  )}
                </div>
                <h4 className="mt-4 text-lg font-normal pj-blue-500 text-pj-blue-400">
                  {job.title}
                </h4>
                <div className="grid grid-flow-col grid-cols-3 grid-rows-1 gap-96">
                  <div className="inline-flex col-span-2">
                    <p className="text-sm font-light pj-blue-300 text-pj-blue-400">
                      {job.company_name}
                    </p>
                  </div>
                  {/* <div className="md:w-full">
                    <span className="text-sm text-pj-blue-100 md:w-max">EDIT</span>
                  </div> */}
                </div>
              </div>
            </div>
            <div className="flex items-center justify-center p-3 mt-3 bg-gray-100 border-2 border-gray-200 rounded-lg">
              <div className="flex-auto">
                <p className="text-sm text-pj-blue-100">
                  {`${job.applied ?? 0} Applied`}
                </p>
                <p className="text-sm text-pj-blue-100">
                  {`${job.processed ?? 0} Processed Applicants`}
                </p>
              </div>
              <Link
                to={`/applicant-board/${id}/board`}
                className="text-sm text-gray-400 w-15"
              >
                GO TO BOARD
              </Link>
              <div className="w-5 text-sm text-gray-400">
                <RiArrowRightSLine className="text-lg" />
              </div>
            </div>
            <div className="flex-auto grid-rows-2 divide-y">
              <div className="flex-auto p-3 pl-2 mt-3">
                <div className="grid grid-flow-col grid-cols-2 grid-rows-2 gap-4">
                  <p className="text-base font-medium pj-blue-500 text-pj-blue-400">
                    Job Openings
                  </p>
                  <p className="text-sm pj-blue-100 text-pj-blue-100">
                    {job.opening ? `${Math.round(job.opening * 100) / 100}` : 0}{' '}
                    Openings -{' '}
                    {job.filled ? `${Math.round(job.filled * 100) / 100}` : 0}{' '}
                    Filled
                  </p>
                  <p className="text-base font-medium pj-blue-500 text-pj-blue-400">
                    Referral Fee
                  </p>
                  <p className="text-sm pj-blue-100 text-pj-blue-100">
                    Php{' '}
                    {job.referral_fee
                      ? `${Math.round(job.referral_fee * 100) / 100}`
                      : 0}
                  </p>
                </div>
              </div>
              <p className="mt-3 mb-0"></p>
            </div>
            {/* <p className="pt-4 pb-0 text-sm pj-blue-100">
              Posted {job.posted ? moment(job.posted).format('LL') : '---'}
            </p> */}
          </motion.div>
          <motion.div
            variants={slideIn}
            className="pt-2 pb-4 pl-4 pr-4 border-gray-200"
          >
            <div className="flex items-center justify-center mb-4">
              <button className="relative flex items-center justify-center h-10 bg-gray-100 border-2 border-gray-200 rounded-full w-96 text-m text-pj-blue-100">
                <div>What is Boosting? </div>
                <div>
                  <Question>
                    Boosting referral fees will move up the job post to the top
                    of the list, and will increase the likelihood of getting
                    more referrals and applications.
                  </Question>
                </div>
              </button>
              <Link
                to={{
                  pathname: `/create-job/2/9/${id}`,
                  state: { boost: true },
                }}
                className="flex items-center justify-center w-48 h-10 ml-4 text-white border-2 rounded-full text-m bg-pj-green-200 border-pj-green-200"
              >
                Boost
              </Link>
            </div>
          </motion.div>
          <motion.div
            variants={slideIn}
            className="p-3 bg-gray-100 border-t-2 border-b-2 border-gray-200 "
          />
          <motion.div variants={slideIn} className="p-4 pl-3">
            <div className="flex-auto grid-rows-2 pl-3 divide-y">
              <div className="grid grid-flow-col grid-cols-2 grid-rows-2 gap-x-4 gap-y-2">
                <p className="text-base font-medium pj-blue-500 text-pj-blue-400">
                  Salary
                </p>
                <p className="text-sm pj-blue-100 text-pj-blue-100">
                  Php{' '}
                  {job.minimum_salary
                    ? `${Math.round(job.minimum_salary * 100) / 100}`
                    : 0}{' '}
                  - Php{' '}
                  {job.maximum_salary
                    ? `${Math.round(job.maximum_salary * 100) / 100}`
                    : 0}
                </p>
                <p className="text-base font-medium pj-blue-500 text-pj-blue-400">
                  Work Location
                </p>
                {job.home_based === true ? (
                  <p className="text-sm pj-blue-100 text-pj-blue-100">
                    Home-Based
                  </p>
                ) : (
                  <p className="text-sm pj-blue-100 text-pj-blue-100">
                    {job.address}
                  </p>
                )}
              </div>
              <p className="mt-6 mb-0"></p>
            </div>
          </motion.div>
          <motion.div
            variants={slideIn}
            className="pb-8 pl-3 pr-4 border-gray-200"
          >
            <div className="flex-auto pl-3">
              <div className="grid grid-flow-col grid-cols-2 grid-rows-2 gap-x-4 gap-y-2">
                <p className="text-base font-medium pj-blue-500 text-pj-blue-400">
                  Employment Type
                </p>
                <p className="text-sm pj-blue-100 text-pj-blue-100">
                  {job.job_employment_type}
                </p>
                <p className="text-base font-medium pj-blue-500 text-pj-blue-400">
                  Field
                </p>
                <p className="text-sm pj-blue-100 text-pj-blue-100">
                  {job.job_position_level}
                </p>
              </div>
            </div>
          </motion.div>
          <motion.div
            variants={slideIn}
            className="p-3 bg-gray-100 border-t-2 border-b-2 border-gray-200"
          />

          <motion.div variants={slideIn} className="p-6 border-gray-200">
            <p className="mb-5 text-base font-medium pj-blue-500 text-pj-blue-400">
              Job Description
            </p>
            <div>
              {job.description &&
                job.description
                  .split('\n')
                  .map((str, index) => (
                    <p key={index} className="mb-2 text-sm pj-blue-100 text-pj-blue-100">
                      {str}
                    </p>
                  ))}
            </div>
          </motion.div>
          <motion.div
            variants={slideIn}
            className="p-3 bg-gray-100 border-t-2 border-b-2 border-gray-200"
          />
          <motion.div
            variants={slideIn}
            className="pt-4 pl-3 pr-4 border-gray-200"
          >
            <div className="justify-between flex-auto pl-3">
              <div className="grid grid-flow-row grid-rows-4 divide-y">
                <div>
                  <p className="pt-2 text-base font-medium pj-blue-500 text-pj-blue-400">
                    Qualifications
                  </p>
                  <p className="pt-2 text-sm pj-blue-100 text-pj-blue-100">
                    {job.minimum_qualification &&
                      `${job.minimum_qualification}`}
                  </p>
                </div>
                <div className="pt-4">
                  <p className="text-base font-medium pj-blue-500 text-pj-blue-400">
                    Work Experience
                  </p>
                  <p className="pt-2 pb-5 text-sm pj-blue-100 text-pj-blue-100">
                    {job.job_experience && `${job.job_experience}`}
                  </p>
                </div>
                <div className="pt-4 pb-6">
                  <p className="text-base font-medium pj-blue-500 text-pj-blue-400">
                    Skills Required
                  </p>
                  <p className="pt-2 mt-3 text-sm pj-blue-100 text-pj-blue-100">
                    {job.skills &&
                      job.skills.map((skill, index) => (
                        <div
                          className="inline p-2 px-4 m-2 ml-0 text-sm bg-gray-100 border border-gray-200 rounded-full text-pj-blue-100"
                          key={`${index}`}
                        >
                          {skill.name}
                        </div>
                      ))}
                  </p>
                </div>
                <div className="pt-4">
                  <p className="text-base font-medium pj-blue-500 text-pj-blue-400">
                    Ideal Qualities (private to you)
                  </p>
                  <p className="pt-2 mb-5 text-sm pj-blue-100 text-pj-blue-100">
                    {job.traits &&
                      job.traits.map((trait, index) => (
                        <div
                          className="p-1 text-sm text-pj-blue-100"
                          key={`${index}`}
                        >
                          - {trait.name}
                        </div>
                      ))}
                  </p>
                </div>
              </div>
            </div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default JobView;
