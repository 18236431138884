import { useContext } from 'react';
import { CheckoutContext } from '../../utilities/context';
import { motion } from 'framer-motion';
import { slideIn } from '../../framer';
import JobSteps from '../../components/job-post/step';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { AiOutlineClose } from 'react-icons/ai';
import { CircularProgress } from '@material-ui/core';

const CheckoutView = () => {
  const { steps, details, prev, loading }: any = useContext(CheckoutContext);
  const location: any = useLocation();
  const history = useHistory();

  return (
    <motion.div
      variants={slideIn}
      className="flex fixed items-center justify-center w-full h-screen bg-pj-blue-500"
    >
      <div className="flex flex-col h-screen w-full">
        <div className="h-32 flex justify-center items-center">
          <JobSteps {...steps} />
        </div>
        <motion.div
          variants={slideIn}
          animate="animate"
          initial="initial"
          className="flex flex-1 justify-center"
        >
          <div className="w-2/5 flex">
            <div className="w-full flex-1 rounded-tl-lg relative overflow-hidden rounded-tr-lg bg-white flex flex-col ">
              {loading && <div className='absolute flex items-center justify-center top-0 bottom-0 left-0 right-0 bg-black opacity-10' >
                <CircularProgress style={{ color: "white" }} size={50} />
              </div>}              <div className="flex p-4">
                <div className="text-2xl flex-auto">
                  {details.subStep.heading}
                </div>
                <div
                  className={`w-10 transparent flex items-start justify-center ${details.totalSteps > 1 ? 'items-start' : 'items-center'
                    }`}
                >
                  <Link to={prev}>
                    <AiOutlineClose className="text-xl text-pj-blue-100" />
                  </Link>
                </div>
              </div>
              {details.subStep.view}
              {/*{step !== 3 && (
                <div className="h-24 w-full border-t border-gray-200">
                  {company && (
                    <div className="p-4">
                      <div className="text-base text-pj-blue-500 pb-2">
                        You are posting a job for:
                      </div>
                      <MiniCompany {...company} />
                    </div>
                  )}
                </div>
              )} */}
            </div>
          </div>
        </motion.div>
      </div>
    </motion.div>
  );
};

export default CheckoutView;
