import {  employer_api_v2 } from "./main";

export const getSchedules = async (data: any) => {
    let response = {};

    await employer_api_v2.post('scheduler/getSchedules', data)
        .then(res => {
            response = res?.data;
        })
        .catch(err => {
            response = err?.response?.data;
        });

        return response
};
