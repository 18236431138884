import { MdErrorOutline } from "react-icons/md";
import PdfViewer from './react-pdf';

export default function FileViewer({ url }) {
  const fileType = url.split('.').pop().toLowerCase();
  if(!url) return <div>No attachment found</div>

  if(fileType === "pdf"){
    return <PdfViewer url={url}/>
  } else {
    return <CantView fileType={fileType}/>
}
}

const CantView = ({fileType}:{fileType:string}) => (
  <div className='flex flex-col items-center justify-center p-5 w-80'>
    <MdErrorOutline size={50} color='orange' className='mb-2 ' />
    <div className='text-xl font-semibold '>Document Viewing Error</div>
    <div className='text-sm font-medium '>Unable to View {fileType.toUpperCase()} File</div>
  </div>
)