import { employer_api, employer_api_v2 } from './main';

export const getCommonJobs = async (data: any) => {
  let response = {};

  await employer_api
    .post('getCommonJobs', data)
    .then(res => {
      console.log(res, 'res');
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response;
};

export const getCommonJobsCount = async (data: any) => {
  let response = {};

  await employer_api
    .post('getCommonJobsCount', data)
    .then(res => {
      console.log(res, 'res');
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response;
};

export const getJobPost = async (data: any) => {
  let response = {};

  await employer_api
    .post('getJobPost', data)
    .then(res => {
      console.log(res, 'res');
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response;
};

export const deleteJob = async ({id}:{id:number}) => {
  let response = {};

  await employer_api_v2
    .post('job-posts/deleteJob', {id})
    .then(res => {
      console.log(res, 'res');
      response = res?.data;
    })
    .catch(err => {
      response = err?.response?.data;
    });

  return response;
};


