import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { BiChevronsDown, BiDownload } from 'react-icons/bi';
import { motion } from 'framer-motion';
import { slideUp } from '../../../framer';
import React, { useState } from 'react';
import { useContext } from 'react';
import { Board2Context, BoardContext, CheckboxesContext } from '../../../utilities/context';
import { useEffect } from 'react';
import Question from '../../../components/question';
// import ToolTip from '../../../components/tooltip';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import { auth } from '../../../utilities/auth';
import { Checkbox, Popover } from '@material-ui/core';
import { useRecoilState, useRecoilValue } from 'recoil';
import { applicantCSVAtom, jobDataAtom } from '../../../utilities/atoms/applicant_board/applicantBoardAtoms';
import { boardAtom } from '../../../utilities/atoms/applicant_board/boardAtoms';
import { checkBoxAtom } from '../../../utilities/atoms/applicant_board/checkedApplicantAtoms';
import { Tooltip } from 'antd';
import AgingHeader from './aging-header';
import { employerToShowDownloadCVApplicant, showDownloadCVsToTeam } from '../../../utilities/helper';




//getter for key base on title
const getKey = (title: string) => {
  let key = ""
  switch (title) {
    case "Rejected":
      key = "rejected";
      break;
    case "Applied":
      key = "applied";
      break;
    case "Shortlisted":
      key = "pre_screened";
      break;
    case "For Interview":
      key = "for_interview";
      break;
    case "For Hire":
      key = "shortlisted";
      break;
    case "Hired":
      key = "hired";
      break;
  }
  return key
}

const selectableIds = (idNotInclude: string) => {
  const arrayOfCardTitle = [{
    id: "Rejected",
    title: "Not Selected"
  }, {
    id: "Shortlisted",
    title: "Pre-screened"
  }, {
    id: "For Hire",
    title: "Shortlisted"
  }]
  return arrayOfCardTitle.filter((data) => data.id !== idNotInclude)
}

const Header = (val: any) => {
  const {
    applicantCSVData,
    applicantCSVHeader,
    preScreenedCSVData,
    preScreenedCSVHeader
  } = useRecoilValue(applicantCSVAtom)
  const [checkedApplicants, setCheckedApplicant] = useRecoilState(checkBoxAtom)
  const board_data = useRecoilValue(boardAtom)
  const { title } = useRecoilValue(jobDataAtom);
  const {
    _onMoveMany,
    agingHeaderDatas
  } = useContext(CheckboxesContext);
  const { jobData: {company_id} } = useContext(Board2Context);
  const showDownloadCVsApplicant = employerToShowDownloadCVApplicant.includes(company_id)

  const [isEmpty, setIsEmpty] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  useEffect(() => {
    if (
      board_data?.rejected &&
      board_data?.applied &&
      board_data?.pre_screened &&
      board_data?.for_interview &&
      board_data?.shortlisted &&
      board_data?.hired
    ) {
      if (
        board_data.rejected.length <= 0 &&
        board_data.applied.length <= 0 &&
        board_data.pre_screened.length <= 0 &&
        board_data.for_interview.length <= 0 &&
        board_data.shortlisted.length <= 0 &&
        board_data.hired.length <= 0
      ) {
        setIsEmpty(true);
      } else {
        setIsEmpty(false);
      }
    }
  }, [board_data]);

  const handleClickSelect = () => {
    setCheckedApplicant(prev => {
      return {
        status: val.id,
        applicantIds: []
      }
    })
  }

  //function to check all applicants in active card
  const handleSelectAll = () => {
    const key = getKey(val.id)
    const checked = allIsChecked()
    if (!checked) {
      setCheckedApplicant((prev) => ({ ...prev, applicantIds: board_data?.[key].map((data) => data.id) }))
    } else {
      setCheckedApplicant((prev) => ({ ...prev, applicantIds: [] }))
    }
  }

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const activeToCheckCard = () => {
    return val.id === checkedApplicants.status
  }

  const includedToSelectMultiple = () => {
    switch (val.id.toLowerCase()) {
      case "applied":
        return true;
      case "shortlisted":
        return true;
      case "for hire":
        return true;
      default:
        return false;
    }
  }

  //checker if all applicant in active card is checked
  const allIsChecked = () => {
    const key = getKey(val.id)
    let checker = true
    board_data?.[key]?.forEach((data) => {
      if (!checkedApplicants.applicantIds.includes(data.id)) {
        checker = false
      }
    })
    return checker
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const agingHeader = agingHeaderDatas.find(aging => aging.status === val.id)

  return (
    <div className="w-full whitespace-pre-line">
      <div className='flex flex-col '>
        <div className="flex flex-row p-2 border-b">
          <div className="flex flex-row justify-start flex-auto">
            <div className="text-sm text-left text-gray-400 uppercase">
              {val.title}
            </div>

            {val.label > 0 && (
              <div className="pl-2 text-sm text-left text-gray-400 uppercase">
                ({val.label}/{board_data.counts[val.id]})
              </div>
            )}
          </div>
          {val.title === 'Applied' && (
            <div className="flex flex-row w-18">
              <div className="flex items-center justify-end w-6 text-gray-400 cursor-pointer">
                {/*  */}
                {(showDownloadCVsApplicant) &&
                  <div>
                    <CSVLink
                      data={applicantCSVData}
                      headers={applicantCSVHeader}
                      filename={`${title} applicants ${moment().format('LL')}.csv`}
                    >

                    <Tooltip
                      arrow={false}
                      color={"#1c4464"}
                      title={<>
                        <div className="mb-2 text-sm font-semibold">
                          Download Applied CSV
                        </div>
                        <div>
                          Download Applied csv to view information.
                        </div>
                      </>}
                      key={"download"}
                      placement="bottomRight">
                      <div className='flex items-center justify-center h-full mx-1 '>
                        <BiDownload className="text-gray-400 hover:text-pj-blue-500" />
                      </div>
                    </Tooltip>
                    </CSVLink>
                  </div>
                }
              </div>
              <div
                className="flex items-center justify-end w-6 cursor-pointer"
                onClick={val.onDoubleClick}
              >
                <BiChevronsDown className="text-gray-400" />
              </div>
              {/* <Question>
                <div className="mb-2 text-sm font-semibold">
                  See Contact Details
                </div>
                <div>
                  Move applicant cards to Pre-screened to view their contact
                  details.
                </div>
              </Question> */}
              <Tooltip
                arrow={false}
                color={"#1c4464"}
                title={<>
                  <div className="mb-2 text-sm font-semibold">
                    See Contact Details
                  </div>
                  <div>
                    Move applicant cards to Pre-screened to view their contact
                    details.
                  </div>
                </>}
                key={"question"}
                placement="bottomRight">
                <div className='flex items-center justify-center h-full mx-1 '>
                  <AiOutlineQuestionCircle className="text-gray-400 hover:text-pj-blue-500" />
                </div>
              </Tooltip>
            </div>
          )}
          {agingHeader && <AgingHeader slots={agingHeader.slots > 0 ? agingHeader.slots : 0} icon={agingHeader.icon} status={agingHeader.status}/>}
          {val.title === 'Pre-Screened' && (
            <div className="flex flex-row w-18">
              <div className="flex items-center justify-end w-6 text-gray-400 cursor-pointer">

              {(board_data.industry_name === "BPO" || showDownloadCVsToTeam(auth.getId())) &&   <div>
                  <CSVLink
                    data={preScreenedCSVData}
                    headers={preScreenedCSVHeader}
                    filename={`${title} (pre-screened) - ${moment().format('LL')}.csv`}
                  >

                    {/* <ToolTip
                      onClick={() => { }}
                      trigger={<BiDownload className="" />}
                    >
                      <div className="mb-2 text-sm font-semibold">
                        Download Pre-screened CSV
                      </div>
                      <div>
                        Download Pre-screened csv to view information.
                      </div>
                    </ToolTip> */}
                    {/* <Tooltip title="prompt text" color={"red"} key={"red"}>
                      <BiDownload className="" />
                    </Tooltip> */}

                    <Tooltip
                      arrow={false}
                      color={"#1c4464"}
                      title={<>
                        <div className="mb-2 text-sm font-semibold">
                          Download Pre-screened CSV
                        </div>
                        <div>
                          Download Pre-screened csv to view information.
                        </div>
                      </>}
                      key={"download"}
                      placement="bottomRight">
                      <div className='flex items-center justify-center h-full mx-1 '>
                        <BiDownload className="text-gray-400 hover:text-pj-blue-500" />
                      </div>
                    </Tooltip>
                  </CSVLink>
                </div>}

              </div>
            </div>
          )}
        </div>
        {includedToSelectMultiple() && !!val.label ?
          (
            activeToCheckCard() ? (
              <div className='flex items-center justify-between p-1 text-sm border-b '>
                <div onClick={handleSelectAll} className='p-1 px-2 text-sm text-left text-gray-400 uppercase rounded cursor-pointer hover:text-gray-700 hover:bg-gray-200'>
                  <Checkbox size='small' style={{ padding: 0, marginRight: 5 }} color='primary'
                    checked={allIsChecked()}
                  />
                  ALL
                </div>
                <button aria-describedby={id} className='p-1 px-2 mr-2 text-sm text-gray-400 uppercase rounded cursor-pointer hover:text-gray-700 hover:bg-gray-200'
                  onClick={handleClick}
                >
                  {"MOVE TO"}
                </button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <div className=' text-sm uppercase p-1 flex flex-col gap-0.5 bg-gray-100'>
                    {
                      selectableIds(val.id).map((data) => {
                        return <div onClick={() => {
                          handleClose()
                          _onMoveMany(val.id, data.id, checkedApplicants.applicantIds,)
                        }} className='p-1 px-2 text-gray-600 bg-white rounded cursor-pointer hover:bg-gray-50'>{data.title}</div>
                      })
                    }
                  </div>
                </Popover>
              </div>
            ) : (
              <div className='flex p-1 text-sm border-b '>
                <div onClick={handleClickSelect} className='p-1 px-2 text-sm text-left text-gray-400 uppercase rounded cursor-pointer hover:text-gray-700 hover:bg-gray-200'>SELECT MULTIPLE</div>
              </div>
            )) : <></>}
      </div>
      {
        isEmpty && val.id === 'Applied' && (
          <p className="w-full p-2 m-0 text-sm text-left break-all border-b text-pj-blue-400">
            You have no Applicants yet. You will be notified as soon as you get
            one.
          </p>
        )
      }
    </div >
  );
};

export default Header;
