import { createContext } from 'react';
import {
  BoardInitialState,
  Board2InitialState,
  CheckoutInitialState,
  CompanyInitialState,
  CreateJobInitialState,
  JobPostInitialState,
  CandidateProfileInitialState,
  ManageUserInitialState,
  TransactionsInitialState,
  SignupInitialState,
  DashboardInitialState,
  ScheduleInitialState,
  LoginInitialState,
  SettingsState,
  FaqsInitialState,
  EmployerCreditInitialState,
  CheckboxesState
} from './states';
import {
  BoardData,
  Checkout,
  CompanyType,
  DashboardData,
  Job,
  ManageUserData,
  JobType,
  CandidateProfile,
  TransactionData,
  Signup,
  ProfileData,
  EmployerCreditData,
  CheckboxesData,
  Board2Data,
} from './types';

const PageContext = createContext<object>({
  profile: Object,
});
const DashboardContext = createContext({
  ...(DashboardInitialState as DashboardData),
});
const CreateJobContext = createContext<Job>({
  ...CreateJobInitialState,
});

const CheckoutContext = createContext({ ...CheckoutInitialState } as Checkout);
const CompanyContext = createContext({ ...CompanyInitialState } as CompanyType);
const BoardContext = createContext<BoardData>({ ...BoardInitialState });
const Board2Context = createContext<any>({ ...Board2InitialState });
const CheckboxesContext = createContext<CheckboxesData>({...CheckboxesState})
const JobPostContext = createContext<JobType>({ ...JobPostInitialState });
const ProfilePageContext = createContext<ProfileData>({
  ...SettingsState,
});
const CandidateProfileContext = createContext({
  ...CandidateProfileInitialState,
} as CandidateProfile);
const TransactionsContext = createContext<TransactionData>({
  ...TransactionsInitialState,
});
const ManageUserContext = createContext<ManageUserData>({
  ...ManageUserInitialState,
});
const EmployerCreditContext = createContext<EmployerCreditData>({
  ...EmployerCreditInitialState
})

const SignupContext = createContext<Signup>({ ...SignupInitialState });

const ScheduleContext = createContext({ ...ScheduleInitialState });

const LoginContext = createContext({ ...LoginInitialState });

const FaqsContext = createContext({ ...FaqsInitialState })

export {
  BoardContext,
  PageContext,
  DashboardContext,
  CreateJobContext,
  CheckoutContext,
  CompanyContext,
  JobPostContext,
  CandidateProfileContext,
  TransactionsContext,
  ManageUserContext,
  SignupContext,
  ScheduleContext,
  LoginContext,
  ProfilePageContext,
  FaqsContext,
  EmployerCreditContext,
  CheckboxesContext,
  Board2Context
};
