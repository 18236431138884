import { G } from '@react-pdf/renderer';
import gql from 'graphql-tag';

const queries = {
  EMPLOYEE_NAME: gql`
    query employee($id: Int) {
      common_users(where: { id: { _eq: $id } }) {
        id
        first_name
        last_name
        user_type
        mobile_number
        email
        employer_companies(limit: 1) {
          name
        }
        employer_profile {
          position
          profile_image
        }
      }
    }
  `,
  DASHBOARD_JOB_POSTS: gql`
    query count($employer_id: Int!, $company_id: Int, $visible: Boolean) {
      active: common_jobs_aggregate(
        where: {
          status: { _eq: "Active" }
          employer_id: { _eq: $employer_id }
          company_detail_id: { _eq: $company_id }
          visible: { _eq: $visible }
        }
      ) {
        aggregate {
          count
        }
      }
      pending: common_jobs_aggregate(
        where: {
          status: { _eq: "Pending" }
          employer_id: { _eq: $employer_id }
          company_detail_id: { _eq: $company_id }
          visible: { _eq: $visible }
        }
      ) {
        aggregate {
          count
        }
      }
      archived: common_jobs_aggregate(
        where: {
          status: { _eq: "Filled Up" }
          employer_id: { _eq: $employer_id }
          company_detail_id: { _eq: $company_id }
          visible: { _eq: $visible }
        }
      ) {
        aggregate {
          count
        }
      }
      with_applicants: view_job_with_applicants_aggregate(
        where: {
          employer_id: { _eq: $employer_id }
          company_detail_id: { _eq: $company_id }
          visible: { _eq: $visible }
        }
      ) {
        aggregate {
          count
        }
      }
      with_shortlisted: view_job_with_shortlisted_aggregate(
        where: {
          employer_id: { _eq: $employer_id }
          company_detail_id: { _eq: $company_id }
          visible: { _eq: $visible }
        }
      ) {
        aggregate {
          count
        }
      }
      total_jobs: common_jobs_aggregate(
        where: {
          employer_id: { _eq: $employer_id }
          company_detail_id: { _eq: $company_id }
          visible: { _eq: $visible }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  DASHBOARD_ACTIVITY: gql`
    query dashboard_activity($employer_id: Int) {
      employer_activities_new(
        order_by: { created_at: desc }
        limit: 6
        where: { employer_id: { _eq: $employer_id } }
      ) {
        application_id
        created_at
        employer_id
        id
        invitation_id
        job_id
        message_id
        type
        common_message {
          auto_generated
          application {
            job_title
            status
            name
          }
        }
        common_job_application {
          name
          status
          profile_image
        }
        invite {
          name
          profile_image
          status
        }
        common_job {
          title
          employer_company {
            name
            common_user {
              first_name
              last_name
            }
          }
        }
      }
    }
  `,
  // DASHBOARD_ACTIVITY: gql`
  //   query dashboard_activity($employer_id: Int) {
  //     employer_notifications(
  //       order_by: { created_at: desc }
  //       limit: 6
  //       where: { employer_id: { _eq: $employer_id } }
  //     ) {
  //       created_at
  //       employer_id
  //       id
  //       is_read
  //       job_id
  //       message
  //       message_id
  //       seeker_id
  //       type
  //       updated_at
  //       job_application_message {
  //         status
  //         job_title
  //       }
  //       common_message {
  //         auto_generated
  //         application {
  //           status
  //           name
  //           job_title
  //         }
  //       }
  //       common_job_application {
  //         common_job {
  //           id
  //           title
  //         }
  //         common_user {
  //           first_name
  //           last_name
  //           seeker_profile {
  //             profile_image
  //           }
  //         }
  //       }
  //       public_application {
  //         name
  //         profile_image
  //         status
  //         common_job {
  //           title
  //         }
  //       }
  //     }
  //   }
  // `,
  COMPANY_SCHEDULE: gql`
    query MyQuery($company_id: Int, $current_date: date, $employer_id: Int) {
      view_job_interviews(
        where: {
          company_id: { _eq: $company_id }
          date: { _gte: $current_date }
          _or: [
            { employer_id: { _eq: $employer_id } }
            {
              common_job: {
                sub_employers: { sub_employer_id: { _eq: $employer_id } }
              }
            }
            {
              common_job: {
                sub_employers: {
                  employer_company: { employer_id: { _eq: $employer_id } }
                }
              }
            }
          ]
        }
        limit: 3
        order_by: { date: asc }
      ) {
        name
        profile_image
        job_title
        date
        start_time
        company_name
      }
    }
  `,
  VIEW_COMPANY: gql`
    query view_company($employer_id: Int) {
      employer_companies(where: { employer_id: { _eq: $employer_id } }) {
        id
        name
        employer_id
        common_user {
          first_name
        }
      }
    }
  `,
  EMPLOYEES: gql`
    query employees($company_id: Int!) {
      common_seekers(
        where: { seeker_profile: { company_id: { _eq: $company_id } } }
      ) {
        id
        first_name
        last_name
        email
        mobile_number
      }
    }
  `,
  SUB_EMPLOYERS: gql`
    query hr_team($company_id: Int!) {
      sub_employers_company(where: { company_id: { _eq: $company_id } }) {
        common_user {
          id
          first_name
          last_name
          email
          logins(limit: 1, order_by: { id: desc }) {
            created_at
          }
        }
      }
    }
  `,
  REGISTER_USER: gql`
    mutation MyMutation($data: String!) {
      auth_register(object: { data: $data }) {
        email
      }
    }
  `,
  LOGIN: gql`
    query MyQuery($data: String!) {
      auth_login(object: { data: $data }) {
        token
        user_info {
          id
          first_name
          last_name
          user_type
          is_social_account
          email
          created_at
          updated_at
        }
        lockout
      }
    }
  `,
  COMPANIES: gql`
    query EmployerCompanies($where: employer_companies_bool_exp, $limit:Int) {
      employer_companies(where: $where, order_by: { id: desc }, limit:$limit) {
        id
        address
        company_banner
        company_image
        is_default
        employer_id
        job_type_id
        industry_id
        name
        province_id
        city_id
        tel
        pending
        common_location_city {
          name
        }
        common_location_province {
          name
        }
        common_job_type {
          job_type_name
          industry {
            id
            industry_name
          }
        }
        company_files {
          file_source
        }
        total_jobs: common_jobs_aggregate {
          aggregate {
            count
          }
        }
        total_files: company_files_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  `,
  COMPANIES_COUNT : gql`
  query EmployerCompanies($where: employer_companies_bool_exp) {
    employer_companies_aggregate(where: $where) {
      aggregate {
        count
      }
    }
  }
  `,
  // COMPANIES: gql`
  //   query EmployerCompanies($employer_id: Int!) {
  //     employer_companies(
  //       where: { employer_id: { _eq: $employer_id } }
  //       order_by: { id: desc }
  //     ) {
  //       id
  //       address
  //       company_banner
  //       company_image
  //       is_default
  //       employer_id
  //       job_type_id
  //       industry_id
  //       name
  //       province_id
  //       city_id
  //       tel
  //       pending
  //       common_location_city {
  //         name
  //       }
  //       common_location_province {
  //         name
  //       }
  //       common_job_type {
  //         job_type_name
  //         industry {
  //           id
  //           industry_name
  //         }
  //       }
  //       company_files {
  //         file_source
  //       }
  //       total_jobs: common_jobs_aggregate {
  //         aggregate {
  //           count
  //         }
  //       }
  //     }
  //   }
  // `,
  INDUSTRIES: gql`
    query {
      industries: common_industries(order_by: { industry_name: asc }) {
        id
        industry_name
      }
    }
  `,
  CATEGORIES: gql`
    query job_categories($id: Int!) {
      categories: common_job_types(
        where: { industry_id: { _eq: $id } }
        order_by: { job_type_name: asc }
      ) {
        id
        job_type_name
      }
    }
  `,
  POSITIONS: gql`
    query {
      positions: common_positions(order_by: { id: asc }) {
        label
        value
        referral_amount
      }
    }
  `,
  EXPERIENCES: gql`
    query {
      experiences: common_experiences {
        label
        value
      }
    }
  `,
  QUALIFICATIONS: gql`
    query {
      qualifications: common_qualifications {
        label
        value
      }
    }
  `,
  EMPLOYMENT_TYPES: gql`
    query {
      employment_types: common_employment_types {
        label
        value
      }
    }
  `,
  TRAITS: gql`
    query traits($group: Int!) {
      traits: common_traits(where: { group: { _eq: $group } }) {
        label
        value
        group
      }
    }
  `,
  CHECKOUT_JOB: gql`
    query checkoutJob($id: Int!) {
      common_jobs(where: { id: { _eq: $id } }) {
        title
        company: employer_company {
          name
          company_image
          settings {
            posting_fee
            processing_fee
          }
        }
        order: common_orders {
          referral_fee
          referral_count
          old_referral_count
          old_referral_fee
        }
        user: common_user {
          email
        }
      }
    }
  `,
  REGIONS: gql`
    query Countries {
      country: common_location_countries(where: { id: { _eq: 127 } }) {
        id
        name
        regions {
          value: id
          label: name
          children: provinces {
            value: id
            label: name
            children: common_location_cities(order_by: { name: asc }) {
              value: id
              label: name
            }
          }
        }
      }
    }
  `,
  //         {
  // 					common_user:{ first_name: {_ilike: $search }}
  //         },
  //         {
  // 					common_user:{ last_name: {_ilike: $search }}
  //         }
  NOTIFICATIONS: gql`
    query notifications(
      $employer_id: Int
      $search: String
      $order: order_by
      $limit: Int
    ) {
      employer_notifications(
        where: {
          _and: [
            { _or: [{ employer_id: { _eq: $employer_id } }] }
            {
              _or: [
                { job_application_message: { job_title: { _ilike: $search } } }
                { job_application_message: { name: { _ilike: $search } } }
                { type: { _ilike: $search } }
              ]
            }
          ]
        }
        order_by: { created_at: $order }
        limit: $limit
      ) {
        created_at
        employer_id
        id
        is_read
        job_id
        message
        message_id
        seeker_id
        type
        updated_at
        transaction_id
        job_application_message {
          status
          job_title
        }
        common_message {
          auto_generated
          application {
            status
            name
            job_title
          }
        }
        common_job_application {
          common_job {
            id
            title
          }
          common_user {
            first_name
            last_name
            seeker_profile {
              profile_image
            }
          }
        }
        public_application {
          name
          common_job {
            title
          }
        }
      }
    }
  `,
  // NOTIFICATIONS: gql`
  //   query notifications($limit: Int, $employer_id: Int!) {
  //     employer_notifications(
  //       where: { employer_id: { _eq: $employer_id } }
  //       order_by: { created_at: desc }
  //       limit: $limit
  //     ) {
  //       created_at
  //       employer_id
  //       id
  //       is_read
  //       job_id
  //       message
  //       message_id
  //       seeker_id
  //       type
  //       updated_at
  //       transaction_id
  //       job_application_message {
  //         status
  //         job_title
  //       }
  //       common_message {
  //         auto_generated
  //         application {
  //           status
  //           name
  //           job_title
  //         }
  //       }
  //       common_job_application {
  //         common_job {
  //           id
  //           title
  //         }
  //         common_user {
  //           first_name
  //           last_name
  //           seeker_profile {
  //             profile_image
  //           }
  //         }
  //       }
  //       public_application {
  //         name
  //         common_job {
  //           title
  //         }
  //       }
  //     }
  //   }
  // `,
  GET_COMPANY: gql`
    query getCompany($id: Int!) {
      employer_companies(where: { id: { _eq: $id } }) {
        id
        address
        city_id
        company_image
        employer_id
        industry_id
        is_default
        job_type_id
        name
        province_id
        tel
        website
        company_description
        common_location_province {
          common_location_region {
            id
          }
        }
        common_industry {
          id
          industry_name
        }
      }
    }
  `,
  // GET_COMPANY: gql`
  //   query getCompany($id: Int!) {
  //     employer_companies(where: { id: { _eq: $id } }) {
  //       address
  //       city_id
  //       company_image
  //       employer_id
  //       industry_id
  //       job_type_id
  //       name
  //       province_id
  //       tel
  //       website
  //       pending
  //       company_description
  //       common_location_province {
  //         name
  //         common_location_region {
  //           name
  //         }
  //       }
  //       common_location_city {
  //         name
  //       }
  //       common_industry {
  //         industry_name
  //       }
  //       total_jobs: common_jobs_aggregate {
  //         aggregate {
  //           count
  //         }
  //       }
  //     }
  //   }
  // `,
  GET_JOB_INFORMATION: gql`
    query getJobDetails($id: Int!) {
      common_jobs(where: { id: { _eq: $id }, status: { _eq: "Active" } }) {
        title
        industry_id
        company_detail_id
        employer_company {
          name
          company_image
        }
      }
    }
  `,
  GET_BOARD_INFORMATION: gql`query getApplicants($id: Int!) {
    common_jobs(where: {id: {_eq: $id}, status: {_in: ["Active", "Filled Up"]}}) {
      status
      company_detail_id
      rejected: view_applications(where: {status: {_eq: "Rejected"}}, distinct_on: seeker_id) {
        id: application_id
        profile_image
        name
        job_title
        referrer
        seeker_id
        job_id
        common_job_application {
          updated_at
        }
      }
      applied: view_applications(where: {status: {_eq: "Applied"}}, distinct_on: seeker_id) {
        id: application_id
        profile_image
        name
        job_title
        referrer
        seeker_id
        job_id
        common_job_application {
          updated_at
        }
      }
      pre_screened: view_applications(where: {status: {_eq: "Shortlisted"}}, distinct_on: seeker_id) {
        id: application_id
        profile_image
        name
        job_title
        referrer
        seeker_id
        job_id
        common_job_application {
          updated_at
        }
      }
      for_interview: view_applications(where: {status: {_eq: "For Interview"}}, distinct_on: seeker_id) {
        id: application_id
        profile_image
        name
        job_title
        referrer
        seeker_id
        job_id
        interviews(limit: 1, order_by: {id: desc}) {
          date
          start_time
          end_time
          address
          application_id
        }
        common_job_application {
          updated_at
        }
      }
      shortlisted: view_applications(where: {status: {_eq: "For Hire"}}, distinct_on: seeker_id) {
        id: application_id
        profile_image
        name
        job_title
        referrer
        seeker_id
        job_id
        common_job_application {
          updated_at
        }
      }
      hired: view_applications(where: {status: {_eq: "Hired"}}, distinct_on: seeker_id) {
        id: application_id
        profile_image
        name
        job_title
        referrer
        seeker_id
        job_id
        common_job_application {
          updated_at
        }
      }
    }
  }  
  `,
  GET_JOBS: gql`
    query jobs(
      $where: common_jobs_bool_exp
      $order: order_by!
      $date: timestamptz
      $limit : Int
    ) {
      job: common_jobs(where: $where, order_by: { id: $order }, limit:$limit) {
        id
        title
        status
        company_detail_id
        company: employer_company {
          name
          company_image
        }
        sub_employers {
          sub_employer_id
        }
        new_applicants: view_common_job_applications_aggregate(
          where: { status: { _eq: "Applied" }, created_at: { _gte: $date } }, distinct_on: seeker_id
        ) {
          aggregate {
            count
          }
        }
        unread: view_applications_aggregate(
          where: { is_viewed: { _eq: false } },
          distinct_on: seeker_id
        ) {
          aggregate {
            count
          }
        }
      }
    }
  `,
  GET_SCORE: gql`
    mutation match($job_id: Int!, $seeker_id: Int!) {
      match_score(object: { job_id: $job_id, seeker_id: $seeker_id }) {
        score
      }
    }
  `,
  GET_JOB_POSTS: gql`
    query job_posts(
      $where: common_jobs_bool_exp
      $order: order_by!
      $date: timestamptz
      $limit:Int
    ) {
      common_jobs(where: $where, order_by: { id: $order }, limit:$limit) {
        title
        address
        city_id
        company_detail_id
        description
        employer_id
        home_based
        id
        industry_id
        job_type_id
        province_id
        qualifications
        status
        visible
        employer_company {
          name
          id
          company_image
        }
        common_orders {
          referral_fee
          referral_count
          disbursement_type
        }
        common_job_informations {
          job_employment_type
          job_experience
          minimum_salary
          maximum_salary
          minimum_qualification
          show_salary
          job_position_level
        }
        common_job_skills {
          skill_name
        }
        new_applicants: view_common_job_applications_aggregate(
          where: { status: { _eq: "Applied" }, created_at: { _gte: $date } }, distinct_on: seeker_id
        ) {
          aggregate {
            count
          }
        }
        unread: view_applications_aggregate(
          where: { is_viewed: { _eq: false }} , distinct_on: seeker_id
        ) {
          aggregate {
            count
          }
        }
      }
    }
  `,
  GET_JOB_POST_COUNT: gql`
  query job_posts($where: common_jobs_bool_exp, $order: order_by!) {
    common_jobs_aggregate(order_by: {id: $order}, where: $where) {
      aggregate {
        count
      }
    }
  }`,
  GET_APPLICATION: gql`
    query application($application_id: Int) {
      view_common_job_applications(
        where: { application_id: { _eq: $application_id } }
      ) {
        id: seeker_id
        name
        mobile_number
        email
        profile_image
        status
        referrer
        referrer_id
        job_title
        application_id
        linkedin_url
        is_resigned
        uploaded_resume
        educations {
          graduate_date
          grade
          field_of_study
          institute_university
          location
          major
          additional_information
          qualification
        }
        skills {
          name
        }
        job_experiences {
          company_name
          joined_date_from
          joined_date_to
          monthly_salary
          position_level
          position_title
          experience_description
          is_present
          specialization
        }
        other: seeker_information {
          cv_url
          date_of_birth
          expected_salary
          gender
          job_title
          location
          profile_image
          telephone_number
          position
        }
        experience {
          since_year
          experience_level
          is_since_year
        }
        common_job {
          company: employer_company {
            name
            company_image
          }
        }
      }
    }
  `,
  GET_JOB_DETAILS: gql`
    query job_posts($id: Int!) {
      common_jobs(where: { id: { _eq: $id } }) {
        created_at
        title
        address
        city_id
        company_detail_id
        description
        employer_id
        home_based
        id
        industry_id
        job_type_id
        home_based
        province_id
        qualifications
        status
        visible
        employer_traits {
          trait
        }
        employer_company {
          name
          company_image
          id
          address
          city_id
          province_id
        }
        common_orders {
          referral_fee
          referral_count
        }
        common_job_informations {
          job_employment_type
          job_experience
          minimum_salary
          maximum_salary
          minimum_qualification
          show_salary
          job_position_level
        }
        common_job_skills {
          skill_name
        }
        openings: common_orders {
          referral_count
        }
        filled: common_job_applications_aggregate(
          where: { status: { _eq: "Hired" } }
        ) {
          aggregate {
            count
          }
        }
        applicants: common_job_applications_aggregate(
          where: { status: { _eq: "Applied" } }
        ) {
          aggregate {
            count
          }
        }
        processed: common_job_applications_aggregate(
          where: { status: { _nin: ["Applied", "Rejected", "Hired"] } }
        ) {
          aggregate {
            count
          }
        }
      }
    }
  `,

  GET_SEEKER_PUBLIC_PROFILE: gql`
      query seeker($id: Int, $job_id: Int) {
        view_applicants(where: { id: { _eq: $id } }) {
          id
          position
          name
          mobile_number
          email
          profile_image
          job_title
          linkedin_url
          educations {
            graduate_date
            grade
            field_of_study
            institute_university
            location
            major
            additional_information
            qualification
          }
          skills {
            name
          }
          job_experiences: experiences(order_by: {joined_date_to: desc}) {
            company_name
            joined_date_from
            joined_date_to
            monthly_salary
            position_level
            position_title
            experience_description
            is_present
            specialization
            common_industries: industry {
              industry_name
            }
          }
          other: profile {
            cv_url
            date_of_birth
            gender
            job_title
            location
            profile_image
          }
          attachments {
            id
            name
            seeker_id
            url
          }
          experience {
            experience_level
            is_since_year
            since_year
          }
        }
        invited: common_shortlists(
          where: { seeker_id: { _eq: $id }, job_id: { _eq: $job_id } }
        ) {
          id
        }
      }
  `,
  GET_TRANSACTIONS: gql`
    query transactions(
      $where: employer_transactions_bool_exp
      $order: order_by!
    ) {
      employer_transactions(where: $where, order_by: { id: $order }) {
        amount
        employer_id
        transaction_id
        updated_at
        created_at
        job_id
        payment_type
        total_vat
        total_commission
        id
        transaction_type
        posting_fee
        common_job {
          title
          employer_company {
            company_image
            name
          }
        }
      }
    }
  `,
  GET_LATEST_TRANSACTION: gql`
    query latest_transaction_details($job_id: Int!) {
      data: employer_transactions(
        where: { job_id: { _eq: $job_id } }
        order_by: { created_at: asc }
        limit: 1
      ) {
        transaction_id
        id
        created_at
        amount
        total_vat
      }
    }
  `,
  GET_TRANSACTION_DETAILS: gql`
    query transaction_details($id: Int!) {
      employer_transactions(where: { id: { _eq: $id } }) {
        employer_id
        transaction_id
        updated_at
        created_at
        job_id
        payment_type
        amount
        total_vat
        total_commission
        id
        transaction_type
        posting_fee
        common_job {
          title
          employer_company {
            company_image
            name
          }
        }
      }
    }
  `,
  GET_COMMON_USERS: gql`
    query common_users($email: String) {
      common_users(where: { email: { _eq: $email } }) {
        email
        first_name
        last_name
        password
      }
    }
  `,
  GET_COUNT: gql`
    query count($employer_id: Int!, $company_id: Int, $visible: Boolean) {
      applied: view_common_job_applications_aggregate(
        distinct_on: seeker_id,
        where: {
          status: { _eq: "Applied" }
          common_job: {
            _or: [
              { employer_id: { _eq: $employer_id } }
              { sub_employers: { sub_employer_id: { _eq: $employer_id } } }
              {
                sub_employers: {
                  employer_company: { employer_id: { _eq: $employer_id } }
                }
              }
            ]
            company_detail_id: { _eq: $company_id }
            visible: { _eq: $visible }
          }
        }
      ) {
        aggregate {
          count
        }
      }
      for_interview: view_common_job_applications_aggregate(
        distinct_on: seeker_id,
        where: {
          status: { _eq: "For Interview" }
          common_job: {
            _or: [
              { employer_id: { _eq: $employer_id } }
              { sub_employers: { sub_employer_id: { _eq: $employer_id } } }
              {
                sub_employers: {
                  employer_company: { employer_id: { _eq: $employer_id } }
                }
              }
            ]
            company_detail_id: { _eq: $company_id }
            visible: { _eq: $visible }
          }
        }
      ) {
        aggregate {
          count
        }
      }
      for_hire: view_common_job_applications_aggregate(
        distinct_on: seeker_id,
        where: {
          status: { _eq: "For Hire" }
          common_job: {
            _or: [
              { employer_id: { _eq: $employer_id } }
              { sub_employers: { sub_employer_id: { _eq: $employer_id } } }
              {
                sub_employers: {
                  employer_company: { employer_id: { _eq: $employer_id } }
                }
              }
            ]
            company_detail_id: { _eq: $company_id }
            visible: { _eq: $visible }
          }
        }
      ) {
        aggregate {
          count
        }
      }
      filled: view_common_job_applications_aggregate(
        distinct_on: seeker_id,
        where: {
          status: { _eq: "Hired" }
          common_job: {
            _or: [
              { employer_id: { _eq: $employer_id } }
              { sub_employers: { sub_employer_id: { _eq: $employer_id } } }
              {
                sub_employers: {
                  employer_company: { employer_id: { _eq: $employer_id } }
                }
              }
            ]
            company_detail_id: { _eq: $company_id }
            visible: { _eq: $visible }
          }
        }
      ) {
        aggregate {
          count
        }
      }
      openings: common_orders_aggregate(
        where: {
          common_job: {
            _or: [
              { employer_id: { _eq: $employer_id } }
              { sub_employers: { sub_employer_id: { _eq: $employer_id } } }
              {
                sub_employers: {
                  employer_company: { employer_id: { _eq: $employer_id } }
                }
              }
            ]
            company_detail_id: { _eq: $company_id }
            visible: { _eq: $visible }
          }
        }
      ) {
        aggregate {
          sum {
            referral_count
          }
        }
      }
      archived: common_jobs_aggregate(
        where: {
          _or: [
            { employer_id: { _eq: $employer_id } }
            { sub_employers: { sub_employer_id: { _eq: $employer_id } } }
            {
              sub_employers: {
                employer_company: { employer_id: { _eq: $employer_id } }
              }
            }
          ]
          company_detail_id: { _eq: $company_id }
          visible: { _eq: $visible }
          status: { _eq: "Filled Up" }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `,
  GET_SCHEDULES: gql`
    query schedules($where: view_job_interviews_bool_exp) {
      view_job_interviews(where: $where) {
        address
        date
        start_time
        end_time
        job_title
        name
        profile_image
        company_name
        company_image
        seeker_id
        job_id
        application_id
      }
    }
  `,
  GET_SUGGESTED_CANDIDATES: gql`
    query seekers($where: view_applicants_bool_exp, $job_id: Int!) {
      view_applicants(where: $where) {
        id
        name
        profile_image
        job_title
        position
        skills {
          id
          name
        }
        shortlists(where: { job_id: { _eq: $job_id } }) {
          status
        }
        applications(where: { status: { _nin: ["For Hire", "Hired"] } }) {
          job_id
          status
        }
      }
      view_applicants_aggregate(where: $where) {
        aggregate {
          count
        }
      }
      invited: view_shortlists(where: { job_id: { _eq: $job_id } }) {
        name
        seeker_id
        job_id
        profile_image: image
        status
      }
    }
  `,
  GET_JOB_DETAIL: gql`
    query job_details($job_id: Int!) {
      common_jobs(where: { id: { _eq: $job_id } }) {
        id
        title
        description
        job_type_id
        province_id
        city_id
        industry_id
        company_detail_id
        qualifications
        home_based
        address
        status
        visible
        common_city {
          name
        }
        information: common_job_informations {
          minimum_salary
          maximum_salary
          job_position_level
          job_position_level
          job_experience
          minimum_qualification
          show_salary
          job_employment_type
        }
        traits: employer_traits {
          trait
        }
        skills: common_job_skills {
          name: skill_name
          value: skill_name
        }
        order: common_orders {
          referral_fee
          referral_count
          old_referral_fee
          old_referral_count
          disbursement_type
        }
        company: employer_company {
          id
          name
          logo: company_image
          address
          city_id
          province_id
          province_name: common_location_province {
            name
          }
          city_name: common_location_city {
            name
          }
          total_jobs: common_jobs_aggregate {
            aggregate {
              count
            }
          }
        }
        transactions: employer_transactions {
          id
          job_id
        }
        applicants: view_applications_aggregate {
          aggregate {
            count
          }
        }
        hired: hired_applicants_aggregate {
          aggregate {
            count
          }
        }
      }
    }
  `,
  COMPANY_FILES: gql`
    query company_files($company_id: Int) {
      employer_companies(where: { id: { _eq: $company_id } }) {
        id
        pending
        total_files: company_files_aggregate {
          aggregate {
            count
          }
        }
        company_files {
          file_source
        }
      }
    }
  `,
  GET_API_KEY: gql`
    query get_api_key($company_id: Int!) {
      api_key: sys_public_api_creds(
        where: { company_id: { _eq: $company_id } }
      ) {
        id
        username
        key
      }
    }
  `,
  GET_SETTINGS: gql`
    query {
      settings(where: { type: { _eq: "posting_fee" } }) {
        referral_fee
      }
    }
  `,
  GET_SEEKER_DOCUMENTS: gql`
    query MyQuery($id: Int) {
      data: seeker_documents(where: { seeker_id: { _eq: $id } }) {
        url
        name
      }
    }
  `,

  GET_EMPLOYER_HR: gql`
    query MyQuery($id: Int!) {
      data:common_users(where: {employer_id: {_eq: $id}}) {
        id
        last_name
        first_name
        email
      }
    }
  `,

  GET_APPLICATIONS_BY_JOB_ID: gql`
  query MyQuery($id: Int!, $status: String!) {
    data: common_job_applications(where: {job_id: {_eq: $id}, status: {_eq: $status}}) {
      id
      status
      job_id
      created_at
      common_user {
        email
        last_name
        first_name
        mobile_number
      }
    }
  }
  `
};

export default queries;
