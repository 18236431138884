import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  cloudinary,
  file_upload,
  file_upload_new,
} from '../../../utilities/api';
import { CompanyContext } from '../../../utilities/context';
import { CompanyInitialState } from '../../../utilities/states';
import {
  CompanyData,
  CompanyType,
  OnClickFunction,
  Company,
} from '../../../utilities/types';
import Create from './view';
import Resizer from 'react-image-file-resizer';
import { useParams } from 'react-router-dom';
import { CHECK_IF_CONTAINS_LINK, uploadFile } from '../../../utilities/helper';
import { createCompany, getCompany, updateCompany } from '../../../utilities/api_v2/companies';

type Params = {
  id: string;
};

const CompanyController = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    register,
    watch,
    reset,
  } = useForm<any>({
    defaultValues: {
      ...CompanyInitialState.company,
    },
  });

  const params: Params = useParams();

  const [companyInfo, setCompanyInfo] = useState<CompanyData>();
  const [errorImage, setErrorImage] = useState<boolean>(false);
  const [errorWebsite, setErrorWebsite] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [company, setCompany] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  const _createCompany = async ({data}:{data:CompanyData}) => {
    await createCompany(data).then(()=>{
          window.location.reload();
          setLoader(false)
    })
  }

  const _updateCompany = async ({data}:{data:any}) => {
    await updateCompany(data).then(()=>{
          window.location.reload();
          setLoader(false)
    })
  }

  const _getCompany = async () => {
    setLoading(true)
    await getCompany({ id: parseInt(params.id) }).then((data: any) => {
      setCompany(data.data)
      setLoading(false)
    })
  }

  useEffect(() => {
    if(params.id){
      _getCompany()
    }
  }, [params.id])

  useEffect(() => {
    if (company) {
      const data = company;
      setFile(data.company_image);
      const companyDetails = {
        employer_id: data.employer_id,
        region_id: data.common_location_province.common_location_region.id,
        province_id: data.province_id,
        address: data.address,
        job_type_id: data.job_type_id,
        is_default: data.is_default,
        company_image: data.company_image,
        name: data.name,
        tel: data.tel,
        website: data.website,
        city_id: data.city_id,
        industry_id: data.common_industries?.id,
        company_description: data.company_description,
      };
      setTimeout(() => {
        setCompanyInfo(companyDetails);
        Object.keys(companyDetails).map(k => setValue(k, companyDetails[k]));
      }, 500);
    }
  }, [company]);

  const resizeFile = async (file: any) =>
    new Promise(resolve => {
      Resizer.imageFileResizer(
        file,
        300,
        300,
        'JPEG',
        50,
        0,
        uri => {
          resolve(uri);
        },
        'base64'
      );
    }).then(res => {
      return res;
    });

  const [file, setFile] = useState<any>(null);
  const [image, setImage] = useState<any>(null);

  const _beforeUpload = async (e: any) => {
    // console.log(e);
    // return;
    // // base64(e.target.files[0], (res: any) => {
    // //   setFile(`data:${res.filetype};base64,${res.base64}`);
    // // });
    setValue('company_image', e.target.files[0]);
    const result = await resizeFile(e.target.files[0]);
    setFile(result);
    setImage(e.target.files[0])
  };

  const _submitForm: OnClickFunction = async (formdata: CompanyData) => {
    let data = {
      ...formdata,
      city_id: formdata.city_id? parseInt(formdata.city_id.toString()):null,
      industry_id: formdata.industry_id? parseInt(formdata.industry_id.toString()):null,
      province_id: formdata.province_id? parseInt(formdata.province_id.toString()):null,
    }
    setErrorImage(false);
    setLoader(true)
    setErrorWebsite(false);

    if (!CHECK_IF_CONTAINS_LINK(data?.website)) {
      setErrorWebsite(true)
      return
    }
    if (image) {
      const url: any = await uploadFile(image)
      if (!url.success) {
        setErrorImage(true);
        return;
      }
      data = {
        ...data,
        company_image: url.url,
        image_key: url.url,
      };
    }
    if (params.id) {
      _updateCompany({data:{id:parseInt(params.id), ...data}})
    } else {
      delete data?.region_id;
      _createCompany({data})
    }
  };

  const resetFields = () => {
    reset();
  };

  const value: CompanyType = {
    ...CompanyInitialState,
    control,
    errors,
    file,
    id: params.id,
    loading,
    handleSubmit,
    _submitForm,
    watch,
    setValue,
    _beforeUpload,
    resetFields,
    errorImage,
    errorWebsite,
    loader
  };
  return (
    <CompanyContext.Provider value={value}>
      <Create />
    </CompanyContext.Provider>
  );
};

export default CompanyController;
