import { motion } from 'framer-motion';
import { useContext } from 'react';
import ButtonExit from '../../../components/buttons/button-exit';
import ButtonNext from '../../../components/buttons/button-next';
import InputField from '../../../components/forms/input';
import { stagger } from '../../../framer';
import { lang } from '../../../lang';
import { CreateJobContext } from '../../../utilities/context';

const StepTwoTwenty = () => {
  const { _privacyHandler, common_job, handleSubmit, _submitForm } =
    useContext(CreateJobContext);
  return (
    <form
      className="flex flex-1 flex-col"
      onSubmit={handleSubmit && _submitForm && handleSubmit(_submitForm)}
      id="journal-scroll"
    >
      <div>
        <label
          className={`flex flex-row w-full p-4 cursor-pointer border-gray-200 ${
            common_job.visible && 'bg-pj-green-100'
          }`}
          onClick={() => _privacyHandler(true)}
        >
          <div className="w-10 flex justify-start items-start">
            <input
              type="radio"
              name="address"
              value="company_address"
              checked={common_job.visible === true}
            />
          </div>
          <div style={{ marginTop: -6 }}>
            <p className="text-lg m-0 p-0 text-pj-blue-500">
              Public{' '}
              <span className="text-sm">
                (I want it to appear on the job list)
              </span>
            </p>
          </div>
        </label>
      </div>
      <div>
        <label
          className={`flex flex-row w-full p-4 cursor-pointer border-gray-200 ${
            !common_job.visible && 'bg-pj-green-100'
          }`}
          onClick={() => _privacyHandler(false)}
        >
          <div className="w-10 flex justify-start items-start">
            <input
              type="radio"
              name="address"
              value="company_address"
              checked={common_job.visible === false}
            />
          </div>
          <div style={{ marginTop: -6 }}>
            <p className="text-lg m-0 p-0 text-pj-blue-500">
              Private{' '}
              <span className="text-sm">
                (I want it to be visible within my company or link recipients)
              </span>
            </p>
          </div>
        </label>
      </div>
      <div className="flex flex-col flex-1">
        <div className="flex flex-row flex-1 items-end p-4">
          <div className="h-20 w-1/2 flex justify-start items-end">
            <ButtonExit />
          </div>
          <div className="h-20 w-1/2 flex justify-end items-end">
            <ButtonNext />
          </div>
        </div>
      </div>
    </form>
  );
};

export default StepTwoTwenty;
