import { RiArrowRightSLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';

type Props = {
  referrer: string | null;
  id: number;
  job_id: number;
};

const Referrer = ({ referrer, id, job_id }: Props) => {
  return referrer ? (
    <Link
      to={`/candidate-profile/${id}/${job_id}?match=true`}
      className="mt-4 p-2 rounded-md border border-gray-300 flex flex-row justify-between"
    >
      <div className="text-pj-blue-300 text-sm">Referred By: {referrer}</div>
      <div className="flex justify-center items-center text-gray-400">
        <RiArrowRightSLine />
      </div>
    </Link>
  ) : null;
};

Referrer.defaultProps = {
  referrer: null,
};

export default Referrer;
