import { Link } from 'react-router-dom';
import Button from '../../components/forms/button';
import InputField from '../../components/forms/input';
import SocialLogin from '../../components/forms/social-button';
import { lang } from '../../lang';
import { useContext } from 'react';
import { LoginContext, PageContext } from '../../utilities/context';
import { motion } from 'framer-motion';
import { slideIn } from '../../framer';
import { GoogleLogin } from 'react-google-login';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
// import { LinkedIn } from 'react-linkedin-login-oauth2';

const LoginView = () => {
  const {
    show,
    control,
    errors,
    handleSubmit,
    _submitHandler,
    _googleSuccess,
    _googleFailure,
    _linkedinSuccess,
    _linkedinFailure,
    _facebookHandler,
  } = useContext(LoginContext);

  return (
    <motion.div variants={slideIn} initial="initial" animate="animate">
      <h3 className="text-center pb-10 text-white text-xl">
        Log in to your account
      </h3>
      <div className="bg-white shadow w-full rounded-lg ">
        <div className="px-7 py-7">
          <form onSubmit={handleSubmit && handleSubmit(_submitHandler)}>
            <InputField
              label={lang.general.email}
              type="email"
              name="email"
              control={control}
              rules={{ required: 'Email address is required' }}
              error={errors?.email}
              dark
            />
            <InputField
              label="Password"
              type="password"
              name="password"
              control={control}
              rules={{ required: 'Password is required' }}
              error={errors?.password}
              toggle
              dark
            />
            {show && (
              <div className="text-sm text-red-400 text-center w-full my-1">
                Invalid email or password. Please try again!
              </div>
            )}
            <p className="text-center pb-5 text-pj-blue-400 text-sm">
              <Link to="/forgot-password">{lang.signup.forgot_password}</Link>
            </p>
            <Button label="Continue" />
          </form>
        </div>
        <p className="text-center pb-5 text-pj-blue-100 text-sm">
          {lang.signup.continue_with}
        </p>
        <div className="px-7">
          <GoogleLogin
            clientId="875549208112-e2tfquhp4dl18fs2safhlvngr9hopt1p.apps.googleusercontent.com"
            render={renderProps => (
              <SocialLogin
                onClick={renderProps.onClick}
                label={lang.general.google}
              />
            )}
            onSuccess={_googleSuccess}
            onFailure={_googleFailure}
            cookiePolicy={'single_host_origin'}
          />

          {/* <FacebookLogin
            appId="1418062991703103"
            autoLoad={false}
            fields="first_name,last_name,email,picture"
            callback={_facebookHandler}
            render={renderProps => (
              <SocialLogin
                onClick={renderProps.onClick}
                label={lang.general.facebook}
              />
            )}
          /> */}

          {/* <LinkedIn
            clientId={process.env.REACT_APP_LINKED_IN_CLIENT_ID}
            onFailure={_linkedinFailure}
            onSuccess={_linkedinSuccess}
            redirectUri="https://employer.pasajob.com/"
            renderElement={({ onClick, disabled }) => (
              <SocialLogin onClick={onClick} label={lang.general.linkedin} />
            )}
          /> */}

          {/* 
          <SocialLogin label={lang.general.linkedin} /> */}
        </div>
        <p className="text-sm text-center pt-5 pb-10 text-pj-blue-100">
          {`${lang.signup.no_account} `}
          <Link to="/signup" className="text-pj-blue-400">
            {lang.signup.create_acount}
          </Link>
        </p>
      </div>
    </motion.div>
  );
};

export default LoginView;
