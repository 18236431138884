import JobItem from '../../components/jobs/job-item';
import { Board2Context, BoardContext } from '../../utilities/context';
import { motion } from 'framer-motion';
import { stagger } from '../../framer';
import AnimatedSelect from '../../components/animated-select';
import { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Modal from '../../components/modal';
import Interview from '../../components/schedule/interview';
import Loading from '../../components/loader';
import Search from '../../components/forms/search';

const BoardView = () => {
  const {
    handleSubmitSearch,
    register,
    _handleSearch,
    _handleSelect,
    _handleSort,
    _addTenToLimit,
    loading,
    selectedCompany,
    sort,
    jobs,
    limit,
    view,
    jobData,
    jobsCount,
    id,
    viewType,
  } = useContext(Board2Context);
  const history = useHistory();
  const { title, company_name } = jobData
  const navigate = (id: number) => {
    history.push(`/applicant-board/${id}/board`);
  };
  const Body = () => {
    return (
      <div className="w-full text-center h-full bg-white">
        <div className="h-32 w-full flex justify-between flex-col">
          <h1 className="text-lg text-pj-blue-400">{title}</h1>
          <h3 className="text-sm text-pj-blue-100">{company_name}</h3>
          <div className="p-4 pb-0">
            <ul className="flex justify-center items-center pb-0">
              <li className="w-32 px-6 flex justify-between flex-col">
                <Link
                  to={`/applicant-board/${id}/board`}
                  className={`cursor-pointer py-4 flex px-4 text-gray-500 ${viewType === 'board'
                    ? 'text-pj-blue-400 font-medium'
                    : 'pj-blue-100'
                    } content-center uppercase text-xs`}
                >
                  <div className="w-full text-center">Board</div>
                </Link>
                <div
                  className={`pt-1 rounded-t rounded-tl rounded-tr ${viewType === 'board' ? 'bg-pj-green-300' : 'bg-white'
                    }`}
                ></div>
              </li>
              <li className="w-32 px-6 flex justify-between flex-col">
                <Link
                  to={`/applicant-board/${id}/details`}
                  className={`cursor-pointer py-4 flex px-4 text-gray-500 ${viewType === 'details'
                    ? 'text-pj-blue-400 font-medium'
                    : 'pj-blue-100'
                    } content-center uppercase text-xs`}
                >
                  <div className="w-full text-center">Details</div>
                </Link>
                <div
                  className={`pt-1 rounded-t rounded-tl rounded-tr ${viewType === 'details' ? 'bg-pj-green-300' : 'bg-white'
                    }`}
                ></div>
              </li>
              <li className="w-32 px-6 flex justify-between flex-col">
                <Link
                  to={`/applicant-board/${id}/match`}
                  className={`cursor-pointer py-4 flex px-4 text-gray-500 ${viewType === 'match'
                    ? 'text-pj-blue-400 font-medium'
                    : 'pj-blue-100'
                    } content-center uppercase text-xs`}
                >
                  <div className="w-full text-center">Matches </div>
                </Link>
                <div
                  className={`pt-1 rounded-t rounded-tl rounded-tr ${viewType === 'match' ? 'bg-pj-green-300' : 'bg-white'
                    }`}
                ></div>
              </li>
            </ul>
          </div>
        </div>
        <div
          className="overflow-y-scroll bg-white"
          style={{ height: 'calc(100vh - 11rem)' }}
          id="journal-scroll"
        >
          {view}
        </div>
      </div>
    );
  };
  return (
    <div className="grid grid-cols-3 gap-0 h-full">
      <div className="border-r border-l h-full bg-white">
        <div className="h-32">
          <div className="p-4">
            <div className="relative text-gray-600">
              <form
                onSubmit={
                  handleSubmitSearch && handleSubmitSearch(_handleSearch)
                }
              >
                {register && <Search name="search" register={register} />}
              </form>
            </div>
          </div>
          <div className="border-b-2 flex px-4 py-1">
            <div className="flex-1">
              <AnimatedSelect
                options={[
                  { label: 'New Applicants', value: 'new applicants' },
                  { label: 'All Jobs', value: null },
                  { label: 'Active', value: true },
                  { label: 'Private', value: false },
                ]}
                onClick={_handleSelect}
                label={selectedCompany && selectedCompany?.label}
                left={-80}
              />
            </div>
            <div className="flex-1 items-end" />
            <div className="flex-1">
              <AnimatedSelect
                options={[
                  { label: 'Most Recent', value: 'desc' },
                  { label: 'Oldest', value: 'asc' },
                ]}
                onClick={_handleSort}
                label={sort && sort?.label}
              />
            </div>
          </div>
        </div>
        <motion.div
          variants={stagger}
          initial="initial"
          animate="animate"
          id="journal-scroll"
          style={{ height: 'calc(100vh - 11rem)' }}
          className="overflow-y-scroll overflow-x-hidden"
        >
          {loading && limit <= 10 ? (
            <div className="w-full h-72">
              <Loading show={loading} />
            </div>
          ) : (
            jobs?.length ? <>
              {jobs.map((job, index) => (
                <JobItem
                  title={job?.title}
                  company_name={job?.company_name}
                  key={`${index}`}
                  onClick={() => navigate(job?.id)}
                  active={job?.id === parseInt(id)}
                  logo={job?.logo}
                  newly={job?.newly}
                  id={job?.id}
                />
              )).splice(0, limit)}
              {loading ?
                <div className=' text-gray-350 my-3 w-full text-center'>Loading ...</div> :
                (limit < jobsCount ? <div className=' text-gray-600 my-3 cursor-pointer  w-full text-center' onClick={_addTenToLimit}>Load more</div> : <></>)
              }
            </> : <div className=' w-full flex justify-center items-center text-gray-350 mt-36'>Currently no data available</div>)}
        </motion.div>
      </div>
      <div className="col-span-2 h-full bg-white">{
        viewType && <Body />
      }</div>
    </div>
  );
};

export default BoardView;
